import {
  Policy,
  PolicyHistory,
  PolicyHistorySchema,
  PolicySchema,
} from '@stardust-monorepo/types/sd-private';

import { authenticatedRequest, validatedRequestWithAuth } from './api-utils';

export async function getPolicies(params?: {
  activeOnly?: boolean;
  gameId?: number;
}): Promise<Policy[]> {
  return validatedRequestWithAuth(PolicySchema.array(), 'policy/get-all', {
    params,
  });
}

export async function getPolicyHistories(params: {
  acceptorId: string;
  gameId?: number;
  activeOnly?: boolean;
}): Promise<PolicyHistory[]> {
  return validatedRequestWithAuth(
    PolicyHistorySchema.array(),
    'policy-history/get-all',
    {
      params,
    }
  );
}

export async function getUnacceptedPolicies(userId: string): Promise<Policy[]> {
  return Promise.all([
    getPolicies({ activeOnly: true }),
    getPolicyHistories({ acceptorId: userId, activeOnly: true }),
  ] as const).then(([policies, policyHistories]) => {
    const historyIds = new Set(
      policyHistories.map((history) => history.policyId)
    );
    return policies.filter((policy) => !historyIds.has(policy.id));
  });
}

export async function createPolicyHistory(
  acceptorId: string,
  policyIds: number[]
): Promise<void> {
  return authenticatedRequest('policy-history/create', {
    method: 'POST',
    data: { acceptorId, policyIds },
  });
}
