import {
  Count,
  CountSchema,
  Player,
  PlayerInventoryItem,
  PlayerInventoryItemSchema,
  PlayerSchema,
  Properties,
  Wallet,
  WalletSchema,
} from '@stardust-monorepo/types/sd-private';
import { z } from 'zod';

import { disableCachingHeaders } from '../api-utils';
import { authenticatedRequest, validatedRequestWithAuth } from './api-utils';

export async function getPlayers(
  gameId: number,
  start?: number,
  limit?: number,
  filter?: string,
  includeWallet?: boolean
): Promise<Player[]> {
  return validatedRequestWithAuth(PlayerSchema.array(), 'player/get-all', {
    params: {
      gameId,
      start,
      limit,
      filter,
      includeWallet,
    },
    headers: {
      ...disableCachingHeaders,
    },
  });
}

export async function getPlayerCount(
  gameId: number,
  filter?: string
): Promise<Count> {
  return validatedRequestWithAuth(CountSchema, 'player/count', {
    params: {
      gameId,
      filter,
    },
  });
}

export interface PlayerArgs {
  gameId: number;
  playerId: string;
}

export async function getPlayer(args: PlayerArgs): Promise<Player> {
  return validatedRequestWithAuth(PlayerSchema, 'player/get', {
    params: args,
  });
}

export interface GetPlayerInventoryArgs {
  gameId: number;
  playerId: string;
  start?: number;
  limit?: number;
  returnExplorerLinks?: boolean;
}

export async function getPlayerInventory(
  args: GetPlayerInventoryArgs
): Promise<PlayerInventoryItem[]> {
  return validatedRequestWithAuth(
    PlayerInventoryItemSchema.array(),
    'player/get-inventory',
    {
      params: {
        ...args,
      },
    }
  );
}

export async function deletePlayer(
  args: PlayerArgs
): Promise<Record<string, never>> {
  return authenticatedRequest('player/remove', {
    method: 'DELETE',
    params: args,
  });
}

export async function getPlayerWallet(args: PlayerArgs): Promise<Wallet> {
  return validatedRequestWithAuth(WalletSchema, 'player/wallet-get', {
    params: args,
    headers: {
      ...disableCachingHeaders,
    },
  });
}

export interface CreatePlayerArgs {
  gameId: number;
  uniqueId: string;
  userData: Properties;
}

export async function createPlayer(args: CreatePlayerArgs) {
  return validatedRequestWithAuth(
    z.object({
      playerId: z.string(),
    }),
    'player/create',
    {
      method: 'POST',
      data: args,
    }
  );
}

export interface MutatePlayerArgs {
  gameId: number;
  playerId: string;
  props: Properties;
}

export async function mutatePlayer(
  args: MutatePlayerArgs
): Promise<Record<string, never>> {
  return authenticatedRequest('player/mutate', {
    method: 'PUT',
    data: args,
  });
}

export interface DeletePlayerPropertyArgs {
  gameId: number;
  playerId: string;
  names: string[];
}

export function deletePlayerProperty({
  names,
  ...args
}: DeletePlayerPropertyArgs): Promise<Record<string, never>> {
  return authenticatedRequest('player/props-remove', {
    method: 'DELETE',
    params: {
      ...args,
      props: JSON.stringify(names),
    },
  });
}
