import { Box, SxProps } from '@mui/material';
import { useEffect, useState } from 'react';

import { validateImage } from '../../utils';

export interface ValidatedImageProps {
  image?: string;
  size: string;
  width?: string;
  testId?: string;
  sx?: SxProps;
}

export const ValidatedImage = ({
  image,
  size,
  width,
  testId,
  sx = {},
}: ValidatedImageProps) => {
  const [validatedImage, setValidatedImage] = useState<string | undefined>(
    validateImage(image) ? image : undefined
  );
  useEffect(() => {
    setValidatedImage(validateImage(image) ? image : undefined);
  }, [image]);

  return validatedImage ? (
    <Box
      sx={{
        height: size,
        width: width || size,
        backgroundImage: `url(${validatedImage})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        borderRadius: 2,
        ...sx,
      }}
      data-testid={testId || 'validated-image'}
    />
  ) : (
    <Box
      sx={{
        height: size,
        width: width || size,
        borderRadius: 2,
        backgroundColor: 'text.hint',
        ...sx,
      }}
      data-testid={
        testId ? `${testId}-placeholder` : 'validated-image-placeholder'
      }
    />
  );
};
