import { ApiLogCount } from '@stardust-monorepo/types/sd-private';
import {
  extractData,
  isDoneLoading,
  SdPrivateApi,
} from '@stardust-monorepo/web-sdk-apps-shared';
import { UseQueryResult } from '@tanstack/react-query';
import { startOfQuarter, subMonths, subQuarters, subWeeks } from 'date-fns';
import { useEffect, useMemo, useState } from 'react';

import { TimeFrame } from '../types/timeframe';

const getTotalApiCalls = (apiLogQueries: UseQueryResult<ApiLogCount>[]) => {
  const apiLogs = extractData(apiLogQueries);
  return apiLogs.reduce((acc, al) => {
    acc += al.apiLogs;
    return acc;
  }, 0);
};

export const useApiCallCount = (
  timeFrame: TimeFrame,
  gameIds: number[],
  calculatePriorStats?: boolean
) => {
  const now = useMemo(() => new Date(), []);
  const [currentApiCalls, setCurrentApiCalls] = useState(0);
  const [priorApiCalls, setPriorApiCalls] = useState(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const startDate =
    timeFrame === 'week'
      ? subWeeks(now, 1)
      : timeFrame === 'month'
      ? subMonths(now, 1)
      : startOfQuarter(now);

  const priorStartDate =
    timeFrame === 'week'
      ? subWeeks(now, 2)
      : timeFrame === 'month'
      ? subMonths(now, 2)
      : subQuarters(now, 2);

  const allCurrentApiLogQueries = SdPrivateApi.useAllApiKeyLogCounts({
    games: gameIds,
    startTimeISO: startDate.toISOString(),
    endTimeISO: now.toISOString(),
  });
  const doneLoadingCurrent = isDoneLoading(allCurrentApiLogQueries);

  const allPriorApiLogQueries = SdPrivateApi.useAllApiKeyLogCounts({
    games: gameIds,
    startTimeISO: priorStartDate.toISOString(),
    endTimeISO: startDate.toISOString(),
    enabled: calculatePriorStats,
  });
  const doneLoadingPrior =
    !calculatePriorStats || isDoneLoading(allPriorApiLogQueries);

  useEffect(() => {
    if (doneLoadingCurrent && doneLoadingPrior) {
      setCurrentApiCalls(getTotalApiCalls(allCurrentApiLogQueries));
      setPriorApiCalls(getTotalApiCalls(allPriorApiLogQueries));
      setIsLoading(false);
    } else {
      setIsLoading(true);
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [doneLoadingCurrent, doneLoadingPrior]);

  return {
    currentApiCalls,
    priorApiCalls,
    isLoading,
  };
};
