import { Stack, StackProps, styled, Typography } from '@mui/material';
import { ReactNode } from 'react';

import { color } from '../../theming';

const OverlayChipContainer = styled(Stack)(({ theme }) => ({
  boxShadow: theme.shadows[1],
  background: theme.palette.background.white,
  flexDirection: 'row',
  alignItems: 'center',
  borderRadius: '10px',
  padding: '3px',
  border: `1px solid ${theme.palette.divider}`,
}));

type OverlayChipProps = StackProps & {
  renderIcon: () => ReactNode;
  text: string;
  color?: 'primary' | 'warning';
};

export const OverlayChip = ({
  renderIcon,
  text,
  color: colorProp = 'primary',
  ...props
}: OverlayChipProps) => {
  return (
    <OverlayChipContainer {...props}>
      <Stack
        sx={{
          background:
            colorProp === 'warning'
              ? color.intent.warning.light
              : color.brand.primary[50],
          paddingY: 0.5,
          paddingX: 1.25,
          borderRadius: '6px',
        }}
      >
        {renderIcon()}
      </Stack>
      <Typography
        variant="body2"
        sx={{
          paddingX: 1.5,
        }}
      >
        {text}
      </Typography>
    </OverlayChipContainer>
  );
};
