import { TemplateSchema } from '@stardust-monorepo/types/marketplace';
import { z } from 'zod';

const PriceMoneySchema = z.object({
  amount: z.string(),
  currency: z.string(),
});

export const PurchasableSchema = z
  .object({
    // activeListing is missing on the purchasable embedded in sales history
    // but exists in purchasable/get-all
    activeListing: z.optional(z.boolean()),
    cap: z.nullable(z.string()),
    currencyISOCode: z.string(),
    gameId: z.number(),
    id: z.number(),
    image: z.string().url(),
    name: z.string(),
    price: z.string(),
    totalSupply: z.string(),
  })
  .describe('PurchasableSchema');
export type Purchasable = z.infer<typeof PurchasableSchema>;

export const PurchasableItemSchema = z
  .object({
    purchasableId: z.number(),
    templateId: z.number(),
    id: z.number(),
    amount: z.string(),
    template: TemplateSchema,
    props: z
      .object({
        mutable: z.record(z.number().or(z.string()).or(z.boolean())).nullable(),
        immutable: z
          .record(z.number().or(z.string()).or(z.boolean()))
          .nullable(),
      })
      .optional(),
  })
  .describe('PurchasableItemSchema');
export type PurchasableItem = z.infer<typeof PurchasableItemSchema>;

export const PurchasableSaleSummarySchema = z.object({
  purchasable: PurchasableSchema,
  totalPrice: z.string(),
  totalPriceMoney: PriceMoneySchema,
  totalSales: z.string(),
  totalSold: z.string(),
});
export type PurchasableSaleSummary = z.infer<
  typeof PurchasableSaleSummarySchema
>;

export const PurchasableSaleSchema = z.object({
  amount: z.string(),
  playerId: z.string().uuid(),
  price: z.string(),
  purchasable: PurchasableSchema,
  soldDate: z.string().datetime(),
});
export type PurchasableSale = z.infer<typeof PurchasableSaleSchema>;
