import { z } from 'zod';

//Inventory doesn't have a cap type/limit specification and so might warrant a separate type
export interface MetadataFormValue {
  externalLink?: string;
  supplyType?: string;
  totalSupply?: string;
  promoImage?: string;
  bannerImage?: string;
}

export const metadataSchemaExtension = {
  bannerImage: z
    .string()
    .url()
    .or(z.literal(''))
    .or(z.string({ coerce: true })),
  promoImage: z
    .string()
    .url()
    .or(z.literal(''))
    .or(z.string({ coerce: true })),
};

export const metadataDefaultFormValue = {
  promoImage: '',
  bannerImage: '',
};

export const standaloneTokenDefaultFormValue = {
  externalLink: '',
  supplyType: '',
  totalSupply: '0', //256 bit int
};

export const standaloneTokenSchemaExtension = {
  externalLink: z.string(),
  supplyType: z.string().min(1, 'Capacity (Total Supply) is required'),
  totalSupply: z.string(),
};
