import { Stats, StatsSchema } from '@stardust-monorepo/types/sd-private';

import { validatedRequestWithAuth } from './api-utils';

export interface StatsArgs {
  gameId: number;
  startDate: number;
  endDate: number;
}

export async function getStats(args: StatsArgs): Promise<Stats> {
  return validatedRequestWithAuth(StatsSchema, 'stats/get', { params: args });
}
