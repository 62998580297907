import { CollectionType } from '@stardust-monorepo/types/sd-core';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import {
  getCollection,
  getCollections,
  GetCollectionArgs,
  GetCollectionsArgs,
  createCollectionFa,
  createCollectionFt,
  createCollectionNft,
  updateCollection,
} from '../collection-api';

export const collectionKeyRoot = {
  collection: 'collection',
  collections: 'collections',
} as const;

export const collectionKeys = {
  collections: ({
    gameId,
    type,
    blockchainId,
    start,
    limit,
  }: GetCollectionsArgs) => [
    collectionKeyRoot.collections,
    { gameId },
    ...(type || blockchainId || start || limit
      ? [{ type, blockchainId, start, limit }]
      : []),
  ],
  collection: ({ id }: GetCollectionArgs) => [
    collectionKeyRoot.collection,
    { id },
  ],
};

export function useCollection(args: GetCollectionArgs, enabled = true) {
  return useQuery({
    queryKey: collectionKeys.collection(args),
    queryFn: () => getCollection(args),
    enabled: enabled || !(args.id === undefined || args.id === null),
  });
}

export function useCollections(args: GetCollectionsArgs) {
  return useQuery({
    queryKey: collectionKeys.collections(args),
    queryFn: () => getCollections(args),
    enabled: !(args.gameId === undefined || args.gameId === null),
  });
}

const useCreateCollectionBase = (
  createFn:
    | typeof createCollectionFa
    | typeof createCollectionFt
    | typeof createCollectionNft
    | null
) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: createFn || undefined,
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({
        queryKey: [collectionKeyRoot.collections, { gameId: variables.gameId }],
      });
    },
  });
};

export const useCreateCollection = (type?: CollectionType | null) => {
  const updateFn =
    type === 'FA'
      ? createCollectionFa
      : type === 'FT'
      ? createCollectionFt
      : type === 'NFT'
      ? createCollectionNft
      : null;

  return useCreateCollectionBase(updateFn);
};

export const useUpdateCollection = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: updateCollection,
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({
        queryKey: [collectionKeyRoot.collections],
      });
      queryClient.invalidateQueries({
        queryKey: [collectionKeys.collection({ id: variables.id })],
      });
    },
  });
};
