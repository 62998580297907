import { Box, Card, Skeleton, SxProps, Typography } from '@mui/material';
import {
  Icon as PhosphorIcon,
  TrendDown,
  TrendUp,
} from '@phosphor-icons/react';

export interface SmallIconStatProps {
  Icon: PhosphorIcon;
  iconColor: string;
  iconBackgroundColor: string;
  statValue: number | string;
  isLoading?: boolean;
  priorValue?: number;
  valueAdornment?: string;
  title: string;
  sx?: SxProps;
}

export const SmallIconStat = ({
  Icon,
  iconColor,
  iconBackgroundColor,
  title,
  statValue,
  priorValue = 0,
  valueAdornment = '',
  isLoading,
  sx,
}: SmallIconStatProps) => {
  let percentChange = 0;
  if (typeof statValue === 'number' && priorValue > 0) {
    percentChange = (statValue - priorValue) / priorValue;
  }

  return (
    <Card
      variant="outlined"
      sx={{ flex: '1 1 0', borderRadius: 2, padding: 2, ...sx }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          justifyContent: 'space-between',
          mb: 1.25,
        }}
      >
        <Typography variant="overline" color="text.hint">
          {title}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: iconBackgroundColor,
            color: iconColor,
            height: '32px',
            width: '32px',
            borderRadius: 2,
          }}
        >
          <Icon size={24} weight="duotone" />
        </Box>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
        {isLoading ? (
          <Skeleton
            variant="rounded"
            width={'100%'}
            height={28}
            data-testid="small-icon-stat-loading-skeleton"
          />
        ) : (
          <Typography variant="h4">
            {valueAdornment}
            {statValue.toLocaleString()}
          </Typography>
        )}
        {percentChange !== 0 ? (
          <Box
            sx={{
              ml: 1,
              color: percentChange > 0 ? 'success.main' : 'error.main',
              display: 'flex',
              alignItems: 'flex-end',
            }}
            data-testid="percent-change-info"
          >
            {percentChange > 0 ? (
              <TrendUp size={16} />
            ) : (
              <TrendDown size={16} />
            )}
            <Typography variant="caption" sx={{ ml: 0.25 }}>
              {percentChange > 0 ? '+' : ''}
              {(percentChange * 100).toFixed(2)}%
            </Typography>
          </Box>
        ) : null}
      </Box>
    </Card>
  );
};
