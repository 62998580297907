import { Box, Paper, Stack, Typography } from '@mui/material';
import { Icon as PhosphorIcon } from '@phosphor-icons/react/dist/lib/types';
import {
  Collapsible,
  color,
  SearchTable,
  SearchTableProps,
  showPagination,
} from '@stardust-monorepo/web-sdk-apps-shared';

export type CollapsibleTableProps = Omit<SearchTableProps, 'isLoading'> & {
  Icon?: PhosphorIcon;
  label: string;
};

export const CollapsibleTable = ({
  count = 0,
  Icon,
  label,
  page,
  setPage,
  rows,
  headings,
}: CollapsibleTableProps) => (
  <Paper
    elevation={0}
    sx={{
      px: 2,
      pt: 2,
      pb: showPagination(setPage, count) ? 0 : 2,
      backgroundColor: 'background.white',
      width: '100%',
      borderRadius: 3,
      border: '1px solid',
      borderColor: 'divider',
    }}
  >
    <Collapsible
      expanded={count > 0}
      titleContent={
        <Stack
          direction="row"
          spacing={1}
          sx={{
            alignItems: 'center',
          }}
        >
          {Icon && (
            <Box
              sx={{
                display: 'flex',
                color: 'text.secondary',
                padding: 0.5,
                borderRadius: 1,
                backgroundColor: color.neutral[100],
              }}
            >
              <Icon size={20} weight="fill" />
            </Box>
          )}
          <Typography variant="subtitle2">
            {label} ({count < 10 ? `0${count}` : count.toLocaleString()})
          </Typography>
        </Stack>
      }
    >
      {rows.length > 0 && (
        <Box sx={{ mt: 2 }}>
          <SearchTable
            count={count}
            page={page}
            setPage={setPage}
            rows={rows}
            rowBorder={true}
            isLoading={false}
            showSearchBar={false}
            headings={headings}
            showHeadings={false}
          />
        </Box>
      )}
    </Collapsible>
  </Paper>
);
