import { SdPrivateApi } from '@stardust-monorepo/web-sdk-apps-shared';
import {
  useMutation,
  useQueries,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';

import { GetApiKeyLogCountArgs, GetApiKeyLogsArgs } from '../api-key-api';

export const apiKeys = {
  apiKeys: (gameId: number) => ['apiKeys', { gameId }] as const,
  apiLogCounts: (args: GetApiKeyLogCountArgs) =>
    ['apiLogCounts', args] as const,
  apiLogs: (args: GetApiKeyLogsArgs) => ['apiLogs', args] as const,
};

export function useApiKeys(
  args: {
    gameId: number | undefined;
  },
  enabled = true
) {
  return useQuery({
    queryKey: apiKeys.apiKeys(
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      args.gameId!
    ),
    queryFn: () =>
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      SdPrivateApi.getApiKeys(args.gameId!),
    enabled: enabled && args.gameId !== undefined,
  });
}

export function useCreateApiKey() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: SdPrivateApi.createApiKey,
    onSuccess: (data, variables) => {
      // Refresh the API keys
      queryClient.invalidateQueries({
        queryKey: apiKeys.apiKeys(variables.gameId),
      });
    },
  });
}

export function useToggleApiKeyActive() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: SdPrivateApi.toggleApiKeyActive,
    onSuccess: (data, variables) => {
      // Refresh the API keys
      queryClient.invalidateQueries({
        queryKey: apiKeys.apiKeys(variables.gameId),
      });
    },
  });
}

export function useAllApiKeyLogCounts({
  games,
  startTimeISO,
  endTimeISO,
  enabled = true,
}: {
  games: number[];
  startTimeISO: string;
  endTimeISO: string;
  enabled?: boolean;
}) {
  return useQueries({
    queries: games.map((gameId) => ({
      queryKey: apiKeys.apiLogCounts({ gameId, startTimeISO, endTimeISO }),
      queryFn: () =>
        SdPrivateApi.getApiKeyLogCount({ gameId, startTimeISO, endTimeISO }),
      enabled,
    })),
  });
}

export function useApiKeyLogs(args: GetApiKeyLogsArgs) {
  return useQuery({
    queryKey: apiKeys.apiLogs(args),
    queryFn: () => SdPrivateApi.getApiKeyLogs(args),
    enabled: args.gameId !== undefined && args.apiKeyId.length > 0,
  });
}
