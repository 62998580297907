import { mockToken } from './token-mock';

export const mockPlayer = {
  id: 'mock-player-id',
  image: 'some-image',
};

export const mockPlayerWallet = {
  wallet: [
    {
      blockchain: 'polygon',
      address: '0x0b6be1d671a06dd80b60709b87f31deae0deea7c',
    },
  ],
};

export const mockSdPlayer = {
  gameId: 70,
  playerId: 'd51bb81a-881a-4ff6-b3f5-4531f16c9552',
  uniqueId: 'unique-id',
  id: 38525,
  lastSeen: '2022-11-23T23:10:51.557Z',
  image:
    'https://sd-game-assets-dev-lhv834fnkjsd.s3.amazonaws.com/game_70/players/38525',
  userData: { test: 'value' },
  wallet: mockPlayerWallet.wallet,
};

export const mockInventoryItem = { ...mockToken, tokenId: 32712, amount: '1' };
