export const amplifyConfig = {
  aws_project_region: 'us-east-1',
  aws_cognito_identity_pool_id:
    'us-east-1:ffde1b03-9eda-4938-86fe-b5de7e81ccbf',
  aws_cognito_region: 'us-east-1',
  aws_user_pools_id: 'us-east-1_7HaN0s3vK',
  aws_user_pools_web_client_id: '63sn0gvao4hkujmduc6s0it55p',
  oauth: {
    domain: 'stardustplayers-dev.auth.us-east-1.amazoncognito.com',
    scope: [
      'aws.cognito.signin.user.admin',
      'email',
      'openid',
      'phone',
      'profile',
    ],
    redirectSignIn:
      'http://localhost:3000,https://dev.d3twdqo7cb5otm.amplifyapp.com/',
    redirectSignOut:
      'http://localhost:3000,https://dev.d3twdqo7cb5otm.amplifyapp.com/',
    responseType: 'code',
  },
  federationTarget: 'COGNITO_USER_POOLS',
};
