import { zodResolver } from '@hookform/resolvers/zod';
import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Stack,
  Typography,
} from '@mui/material';
import { TokenType } from '@stardust-monorepo/types/marketplace';
import {
  Player,
  PlayerInventoryItem,
} from '@stardust-monorepo/types/sd-private';
import {
  BasicDialog,
  FormField,
  track,
  SdPrivateApi,
  useToast,
  InputLabel,
  useHandleError,
} from '@stardust-monorepo/web-sdk-apps-shared';
import React, { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import { PlayerSearch } from '../mint-token/PlayerSearch';

export const formControls = {
  amount: 'amount',
  playerId: 'playerId',
} as const;

const formSchema = (existingAmount: number) =>
  z
    .object({
      [formControls.amount]: z.preprocess(
        (amount) =>
          typeof amount === 'string'
            ? parseInt(z.string().parse(amount || '0'))
            : amount,
        z
          .number()
          .gte(1, 'Amount must be at least 1')
          .and(
            z
              .number()
              .lte(
                existingAmount,
                `Amount cannot be more than ${existingAmount}`
              )
          )
      ),
      [formControls.playerId]: z.string().min(1, 'Target Player is required'),
    })
    .required();

interface FormValues {
  amount: number | string;
  playerId: string;
}

export interface TokenTransferModalProps {
  gameId: number;
  open: boolean;
  onClose: () => void;
  fromPlayer: Player;
  item: PlayerInventoryItem;
  tokenType: TokenType;
}

export const TokenTransferModal = ({
  gameId,
  open,
  onClose,
  fromPlayer,
  item,
  tokenType,
}: TokenTransferModalProps) => {
  const submitFormButtonRef = useRef<HTMLButtonElement | null>(null);
  const toaster = useToast();
  const handleError = useHandleError();
  const transferToken = SdPrivateApi.useTransferToken();
  const [selectedPlayer, setSelectedPlayer] = useState<Player | null>(null);

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<FormValues>({
    defaultValues: {
      [formControls.amount]: 1,
      [formControls.playerId]: '',
    },
    resolver: zodResolver(formSchema(Number(item.amount))),
  });

  const doTransferToken = async (formValues: FormValues) => {
    const trackingData = {
      gameId,
      fromPlayerId: fromPlayer.playerId,
      toPlayerId: formValues.playerId,
      tokenId: item.tokenId,
    };
    try {
      await transferToken.mutateAsync({
        gameId,
        fromPlayerId: fromPlayer.playerId,
        toPlayerId: formValues.playerId,
        amount: String(formValues.amount),
        tokenId: item.tokenId,
        templateId: item.templateId,
      });
      track('Transfer Token', 'Success', trackingData);
      toaster(`${item.name} successfully transferred`);
      onClose();
    } catch (e) {
      handleError(
        e,
        'An error occurred while transferring',
        'Transfer Token',
        trackingData
      );
    }
  };

  return (
    <BasicDialog open={open} onClose={onClose}>
      <DialogTitle>Transfer Token </DialogTitle>
      <DialogContent sx={{ width: '450px' }}>
        <Stack
          component="form"
          sx={{ flexGrow: 1 }}
          onSubmit={handleSubmit((data) => {
            doTransferToken(data);
          })}
        >
          <Typography variant="body1" color="text.secondary">
            {tokenType === 'NFT'
              ? `Are you sure you wish to transfer token ${item.name} (ID ${item.tokenId}) from ${fromPlayer.uniqueId}?`
              : `Enter the number of tokens you wish to transfer for ${item.name} (ID ${item.tokenId}) from ${fromPlayer.uniqueId}.`}
          </Typography>
          <FormControl error={!!errors[formControls.playerId]} sx={{ my: 1.5 }}>
            <>
              <InputLabel htmlFor="player-search" sx={{ mb: 0.5 }}>
                Select Target Player Unique ID (Destination)
              </InputLabel>
              <PlayerSearch
                gameId={gameId}
                value={selectedPlayer}
                onChange={(p) => {
                  setSelectedPlayer(p);
                  setValue(formControls.playerId, p?.playerId || '');
                }}
              />
              {errors[formControls.playerId] && (
                <FormHelperText>
                  {errors[formControls.playerId]?.message}
                </FormHelperText>
              )}
            </>
          </FormControl>
          {tokenType === 'FT' ? (
            <FormField
              formControlName={formControls.amount}
              control={control}
              inputSx={{ maxWidth: '120px' }}
              label="Amount"
              type="number"
              hasError={!!errors[formControls.amount]}
              renderHelpText={() =>
                errors[formControls.amount] && (
                  <FormHelperText>
                    {errors[formControls.amount]?.message}
                  </FormHelperText>
                )
              }
            />
          ) : null}
          <button ref={submitFormButtonRef} style={{ display: 'none' }} />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={transferToken.isLoading}
          onClick={onClose}
          variant="outlined"
          color="secondary"
        >
          Cancel
        </Button>
        <Button
          type="submit"
          disabled={transferToken.isLoading}
          autoFocus
          variant="contained"
          onClick={() => {
            track('Confirm Transfer Token ', 'Clicked', {
              gameId,
              fromPlayerId: fromPlayer.playerId,
              tokenId: item.tokenId,
            });
            submitFormButtonRef.current?.click();
          }}
        >
          {transferToken.isLoading ? (
            <CircularProgress size="18px" color="primary" />
          ) : (
            'Transfer'
          )}
        </Button>
      </DialogActions>
    </BasicDialog>
  );
};
