import { paginatedResultsWithFilterSchema } from '@stardust-monorepo/types/common';
import { z } from 'zod';

import { PlanConfigSchema } from './plan';

export const SubscriptionSchema = z
  .object({
    id: z.string().uuid(),
    stripeId: z.string(),
    rootUserId: z.string().uuid(),
    active: z.boolean(),
    planSnapshot: PlanConfigSchema,
  })
  .describe('SubscriptionSchema');
export type Subscription = z.infer<typeof SubscriptionSchema>;

export const SubscriptionResultsSchema = paginatedResultsWithFilterSchema(
  SubscriptionSchema
).describe('SubscriptionResultsSchema');
export type SubscriptionResults = z.infer<typeof SubscriptionResultsSchema>;
