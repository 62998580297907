import { Box, Button, Drawer, Stack, styled, Typography } from '@mui/material';
import { ArrowLeft, ArrowsClockwise } from '@phosphor-icons/react';
import { ApiKey } from '@stardust-monorepo/types/sd-private';
import {
  ContainedTable,
  EmptyContainer,
  PAGE_SIZE,
  SdPrivateApi,
  useTrack,
} from '@stardust-monorepo/web-sdk-apps-shared';
import { format } from 'date-fns';
import { useState } from 'react';

import { ApiKeyToggle } from './ApiKeyToggle';

const headings = [
  { label: 'Operation' },
  { label: 'Payload' },
  { label: 'Event Time' },
];

const StyledPre = styled('pre')({ margin: 0 });
interface ApiLogsProps {
  apiKey?: ApiKey;
  gameId: number;
  onClose: () => void;
}

export const ApiLogs = ({ apiKey, gameId, onClose }: ApiLogsProps) => {
  const [page, setPage] = useState(0);
  const {
    data: logs,
    isLoading,
    refetch,
  } = SdPrivateApi.useApiKeyLogs({
    apiKeyId: apiKey?.apiKeyId || '',
    gameId,
    start: page * PAGE_SIZE,
    limit: PAGE_SIZE,
  });

  useTrack('API Logs', { gameId });

  const rows = (logs?.apiLogs || []).map((apiLog) => {
    return [
      `${apiLog.operation} ${apiLog.endpoint}`,
      <StyledPre>{JSON.stringify(apiLog.payload, null, 2).trim()}</StyledPre>,
      format(new Date(apiLog.created_at), 'MMM. dd, h:mma'),
    ];
  });

  return (
    <Drawer
      anchor="right"
      open={!!apiKey}
      onClose={onClose}
      PaperProps={{ sx: { width: '70%' } }}
    >
      <Box
        sx={{
          padding: 3,
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
            mb: 2.5,
            color: 'text.secondary',
          }}
        >
          <Stack
            direction="row"
            gap={0.75}
            sx={{ cursor: 'pointer', alignItems: 'center' }}
            onClick={onClose}
          >
            <ArrowLeft size={18}></ArrowLeft>
            <Typography variant="body2">Back</Typography>
          </Stack>
          <Stack direction="row" gap={1.5} sx={{ alignItems: 'center' }}>
            <Typography variant="body2">Active</Typography>
            {apiKey && (
              <ApiKeyToggle
                apiKey={apiKey}
                gameId={gameId}
                toggleTrackEvent="API Log Drawer Toggle"
              />
            )}
          </Stack>
        </Box>
        <Typography variant="h6" sx={{ mb: 3 }}>
          {apiKey
            ? `${apiKey.apiKey.slice(0, 2)}...${apiKey.apiKey.slice(
                apiKey.apiKey.length - 2,
                apiKey.apiKey.length
              )}`
            : ''}
        </Typography>
        <ContainedTable
          count={logs?.totalApiLogCount}
          page={page}
          setPage={setPage}
          type="Events"
          headings={headings}
          rows={rows}
          isLoading={isLoading}
          actions={
            <Button
              variant="outlined"
              color="secondary"
              sx={{ minWidth: '46px', color: 'text.disabled' }}
              onClick={() => refetch()}
            >
              <ArrowsClockwise size={18} />
            </Button>
          }
          emptyScreen={
            <EmptyContainer sx={{ textAlign: 'center' }}>
              <Typography>No logs for this API Key</Typography>
            </EmptyContainer>
          }
        />
      </Box>
    </Drawer>
  );
};
