import {
  Box,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { Icon as PhosphorIcon } from '@phosphor-icons/react';
import { color } from '@stardust-monorepo/web-sdk-apps-shared';
import { MouseEvent, useState } from 'react';
import { matchPath, Outlet, useLocation, useNavigate } from 'react-router-dom';

interface NavItemProps {
  Icon: PhosphorIcon;
  label: string;
  onClick: (e: MouseEvent<HTMLElement>) => void;
  selected: boolean;
  disabled?: boolean;
  disabledMessage?: string;
}

const NavItem = ({
  Icon,
  label,
  onClick,
  selected,
  disabled,
  disabledMessage,
}: NavItemProps) => {
  const [hover, setHover] = useState(false);
  const [focus, setFocus] = useState(false);
  return (
    <Tooltip
      placement="top"
      title={disabled && disabledMessage}
      PopperProps={{
        sx: {
          maxWidth: '200px',
        },
      }}
    >
      <ListItemButton
        selected={selected}
        onClick={onClick}
        sx={{
          color: 'text.secondary',
          borderRadius: '90px',
          flexGrow: 0,
        }}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
      >
        <ListItemIcon
          sx={{
            color: disabled
              ? 'text.disabled'
              : hover || focus
              ? 'text.primary'
              : selected
              ? color.accent.blue[900]
              : 'text.hint',
          }}
        >
          <Icon
            size={18}
            weight={selected || hover || focus ? 'duotone' : undefined}
          />
        </ListItemIcon>
        <ListItemText
          disableTypography={true}
          primary={
            <Typography
              sx={
                disabled
                  ? {
                      color: 'text.disabled',
                    }
                  : {}
              }
              variant={selected ? 'subtitle2' : 'body2'}
            >
              {label}
            </Typography>
          }
        />
      </ListItemButton>
    </Tooltip>
  );
};

export interface RouteInfo {
  icon: PhosphorIcon;
  label: string;
  path: string;
  disabled?: boolean;
  disabledMessage?: string;
}

export interface TopNavProps {
  basePath: string;
  routes: RouteInfo[];
}

export const TopNav = ({ basePath, routes }: TopNavProps) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  return (
    <>
      <Box
        sx={{
          padding: 2,
          borderBottom: '1px solid',
          borderColor: 'divider',
          backgroundColor: 'background.default',
        }}
      >
        <List
          component={Stack}
          direction="row"
          sx={{
            py: 0,
            '& .MuiListItemButton-root': {
              '&:hover': {
                color: 'text.primary',
                backgroundColor: color.neutral[100],
              },
              '&:focus': {
                color: 'text.primary',
                backgroundColor: color.neutral[100],
              },
            },
            '& .MuiListItemButton-root.Mui-selected': {
              backgroundColor: color.accent.blue[50],
              color: color.accent.blue[900],
              '&:hover': {
                backgroundColor: color.accent.blue[100],
                color: 'text.primary',
              },
              '&:focus': {
                backgroundColor: color.accent.blue[100],
                color: 'text.primary',
              },
            },
            '& .MuiButtonBase-root': {
              py: 1,
              px: 2,
            },
            '& .MuiListItemText-root ': {
              margin: 0,
            },
            '& .MuiListItemIcon-root': {
              minWidth: '18px',
              mr: 0.5,
            },
          }}
        >
          {routes.map((route) => (
            <NavItem
              key={route.label}
              Icon={route.icon}
              label={route.label}
              disabled={route.disabled}
              disabledMessage={route.disabledMessage}
              onClick={(e: MouseEvent<HTMLElement>) => {
                e.currentTarget.blur();
                if (!route.disabled) {
                  navigate(route.path);
                }
              }}
              selected={Boolean(
                matchPath(
                  {
                    path: `${basePath}/${route.path}`,
                    end: !route.path,
                  },
                  pathname
                )
              )}
            />
          ))}
        </List>
      </Box>
      <Box
        sx={{
          px: 2,
          pb: 2,
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
        }}
      >
        <Outlet />
      </Box>
    </>
  );
};
