import { Box } from '@mui/material';

export interface SlashedLineProps {
  left?: string;
  right?: string;
  px?: number | string;
}

export const SlashedLine = ({ left, right, px = 1 }: SlashedLineProps) => {
  return (
    <Box sx={{ display: 'flex' }}>
      <span>{left}</span>
      <Box sx={{ color: 'text.hint', px }}>/</Box>
      <span>{right}</span>
    </Box>
  );
};
