import { Box, Link, Stack, Tooltip, Typography, useTheme } from '@mui/material';
import { ArrowSquareOut } from '@phosphor-icons/react';
import { ReactElement } from 'react';

import { useToast } from '../toast';
import { ClipboardCopy } from './clipboard-copy';

export interface InfoItemProps {
  clipboardToast?: string;
  tooltip?: string;
  icon?: ReactElement;
  label: string;
  link?: string;
  maskValue?: boolean;
  value: string | number;
  formatDisplayValue?: (value: string) => string;
  maxWidth?: string;
}

const defaultEmptyValue = (value: string | number | null | undefined) =>
  value === undefined || value === null || value === '' ? '--' : value;

export const InfoItem = ({
  clipboardToast,
  tooltip: info,
  icon,
  label,
  link,
  maskValue,
  value,
  formatDisplayValue,
  maxWidth = '400px',
}: InfoItemProps) => {
  const toaster = useToast();
  const theme = useTheme();

  return (
    <Stack
      direction="row"
      spacing={0.5}
      sx={{
        alignItems: 'center',
      }}
    >
      <Tooltip placement="top" title={info}>
        <Stack
          direction="row"
          spacing={0.5}
          sx={{
            alignItems: 'center',
          }}
        >
          {icon ? icon : null}
          <Typography variant="subtitle2" sx={{ whiteSpace: 'nowrap' }}>
            {label}
          </Typography>
        </Stack>
      </Tooltip>
      {link ? (
        <>
          <Link target="_blank" rel="noopener" href={link} variant="body2">
            {value}
          </Link>
          <Box sx={{ color: 'primary.light', display: 'flex' }}>
            <ArrowSquareOut size={18} />
          </Box>
        </>
      ) : clipboardToast ? (
        <Box
          sx={{
            color: 'text.secondary',
            ...theme.typography.body2,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            ...(maxWidth ? { maxWidth } : {}),
          }}
        >
          <ClipboardCopy
            maxWidth={maxWidth}
            maskValue={maskValue}
            onCopy={() => toaster(clipboardToast)}
            text={String(value)}
            formatDisplayValue={formatDisplayValue}
          />
        </Box>
      ) : (
        <Typography variant="body2" color="text.secondary">
          {defaultEmptyValue(value)}
        </Typography>
      )}
    </Stack>
  );
};
