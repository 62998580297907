import { SdPrivateApi } from '@stardust-monorepo/web-sdk-apps-shared';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { GetTokenArgs, GetTokenMintsArgs } from '../token-api';
import { playerKeys } from './usePlayer';
import { templateKeys } from './useTemplate';

const tokenKeys = {
  tokens: ({ gameId, ...args }: GetTokenArgs) =>
    ['tokens', { gameId }, { ...args }] as const,
  tokenMints: (args: GetTokenMintsArgs) => ['tokenMints', { ...args }] as const,
};

export function useTokens(args: GetTokenArgs) {
  return useQuery({
    queryKey: tokenKeys.tokens(args),
    queryFn: () => SdPrivateApi.getTokens(args),
    enabled: args.tokenIds.length >= 1,
  });
}

export function useTokenMints(args: GetTokenMintsArgs) {
  return useQuery({
    queryKey: tokenKeys.tokenMints(args),
    queryFn: () => SdPrivateApi.getTokenMints(args),
    enabled: true,
  });
}

export function useMintToken() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: SdPrivateApi.mintToken,
    onSuccess: (data, variables) => {
      // Refresh the players inventory and token mints
      queryClient.invalidateQueries({
        queryKey: playerKeys.playerInventory(variables),
      });
      queryClient.invalidateQueries({
        queryKey: tokenKeys.tokenMints({
          gameId: variables.gameId,
          templateId: variables.templateId,
        }),
      });
      queryClient.invalidateQueries({
        queryKey: templateKeys.templates(variables.gameId),
      });
      queryClient.invalidateQueries({
        queryKey: templateKeys.template(variables.gameId, variables.templateId),
      });
    },
  });
}

export function useBurnToken() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: SdPrivateApi.burnToken,
    onSuccess: (data, variables) => {
      // Refresh the players inventory and token mints
      queryClient.invalidateQueries({
        queryKey: playerKeys.playerInventory(variables),
      });
      queryClient.invalidateQueries({
        queryKey: tokenKeys.tokenMints({
          gameId: variables.gameId,
          templateId: variables.templateId,
        }),
      });
      queryClient.invalidateQueries({
        queryKey: templateKeys.templates(variables.gameId),
      });
      queryClient.invalidateQueries({
        queryKey: templateKeys.template(variables.gameId, variables.templateId),
      });
    },
  });
}

export function useTransferToken() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: SdPrivateApi.transferToken,
    onSuccess: (data, variables) => {
      // Refresh the players inventory and token mints
      queryClient.invalidateQueries({
        queryKey: playerKeys.playerInventory({
          gameId: variables.gameId,
          playerId: variables.fromPlayerId,
        }),
      });
      queryClient.invalidateQueries({
        queryKey: playerKeys.playerInventory({
          gameId: variables.gameId,
          playerId: variables.toPlayerId,
        }),
      });
      queryClient.invalidateQueries({
        queryKey: tokenKeys.tokenMints({
          gameId: variables.gameId,
          templateId: variables.templateId,
        }),
      });
      queryClient.invalidateQueries({
        queryKey: templateKeys.templates(variables.gameId),
      });
      queryClient.invalidateQueries({
        queryKey: templateKeys.template(variables.gameId, variables.templateId),
      });
    },
  });
}
