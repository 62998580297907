import { useState } from 'react';

import { ConfirmAccount } from './ConfirmAccount';
import { NewAccountForm } from './NewAccountForm';

export const Register = ({ onRegister }: { onRegister?: () => void }) => {
  const [username, setUsername] = useState<string>();
  return username ? (
    <ConfirmAccount username={username} />
  ) : (
    <NewAccountForm
      onAccountCreateSuccess={(userName) => {
        setUsername(userName);
        onRegister?.();
      }}
    />
  );
};
