import { Box, Typography } from '@mui/material';
import { Template } from '@stardust-monorepo/types/marketplace';
import { Spinner } from '@stardust-monorepo/web-sdk-apps-shared';
import { FieldErrors, useFieldArray, UseFormWatch } from 'react-hook-form';
import { Control } from 'react-hook-form/dist/types/form';

import { TemplateSearch } from '../mint-token';
import { TokenRow } from './TokenRow';
import { FormValues } from './types';

interface TokenSelectorProps {
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any>;
  errors: FieldErrors<FormValues>;
  gameId: number;
  selectedTemplates: Template[];
  setSelectedTemplates: (templates: Template[]) => void;
  watch: UseFormWatch<FormValues>;
}

export const TokenSelector = ({
  control,
  errors,
  gameId,
  selectedTemplates,
  setSelectedTemplates,
  watch,
}: TokenSelectorProps) => {
  const {
    fields: tokenFields,
    append: appendToken,
    remove: removeToken,
  } = useFieldArray({
    control,
    name: 'tokens',
  });

  return (
    <>
      <Typography variant="h6">Tokens</Typography>
      <Typography
        variant={'body2'}
        color={'text.secondary'}
        sx={{
          mt: 0.5,
        }}
      >
        Select the templates for tokens that will be sent to customers upon
        purchase.
      </Typography>
      <Box
        sx={{
          mt: 3,
          pb: 2,
          borderRadius: 2,
          border: '1px solid',
          borderColor: 'divider',
          backgroundColor: 'background.cool',
        }}
      >
        <Box
          sx={{
            width: '100%',
            py: 1.5,
            borderBottom: '1px solid',
            borderColor: 'divider',
            px: 2,
          }}
        >
          <Typography variant="subtitle2" color="text.secondary">
            Add Tokens
          </Typography>
        </Box>
        <Box
          sx={{
            px: 2,
            pt: 2,
          }}
        >
          <TemplateSearch
            gameId={gameId}
            value={null}
            onlyActiveTemplates={true}
            placeholder="Select Tokens..."
            onChange={(newTemplate) => {
              if (newTemplate) {
                appendToken({
                  amount: newTemplate.type === 'NFT' ? 1 : '',
                  props: [],
                });
                setSelectedTemplates([...selectedTemplates, newTemplate]);
              }
            }}
          />
          {tokenFields.length > 0 ? (
            <>
              <Box sx={{ display: 'flex', mt: 2.5, mb: 1, px: 1.5 }}>
                <Typography variant="caption" sx={{ width: '50%' }}>
                  Token
                </Typography>
                <Typography variant="caption" sx={{ width: '15%' }}>
                  Type
                </Typography>
                <Typography variant="caption" sx={{ width: '35%' }}>
                  Amount
                </Typography>
              </Box>
              {selectedTemplates.length === 0 ? (
                <Spinner height="150px" />
              ) : null}
            </>
          ) : (
            <Box
              sx={{
                width: '100%',
                pt: 4,
                pb: 1,
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Typography variant="body2" color="text.hint">
                Select a token to add...
              </Typography>
            </Box>
          )}
          {tokenFields.map((tokenField, tokenFieldIndex) =>
            selectedTemplates[tokenFieldIndex] ? (
              <TokenRow
                key={tokenField.id}
                control={control}
                errors={errors.tokens?.[tokenFieldIndex]}
                tokenFieldIndex={tokenFieldIndex}
                removeToken={() => {
                  removeToken(tokenFieldIndex);
                  setSelectedTemplates(
                    selectedTemplates.filter(
                      (template, templateIndex) =>
                        templateIndex !== tokenFieldIndex
                    )
                  );
                }}
                template={selectedTemplates[tokenFieldIndex]}
                watch={watch}
              />
            ) : null
          )}
        </Box>
      </Box>
    </>
  );
};
