import { z } from 'zod';

export const BlockchainNameSchema = z
  .enum(['unassigned', 'solana', 'immutablex', 'polygon', 'flow'])
  .describe('BlockchainNameSchema');
export type BlockchainName = z.infer<typeof BlockchainNameSchema>;

export const BlockchainSchema = z
  .object({
    id: z.number(),
    name: BlockchainNameSchema,
  })
  .describe('BlockchainSchema');
export type Blockchain = z.infer<typeof BlockchainSchema>;
