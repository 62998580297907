import { Game } from '@stardust-monorepo/types/sd-private';
import {
  RemoveModal,
  SdPrivateApi,
  track,
  useToast,
} from '@stardust-monorepo/web-sdk-apps-shared';
import React from 'react';

export interface DeleteGameModalProps {
  game: Game;
  open: boolean;
  onClose: () => void;
  onDelete: () => void;
}

export const DeleteGameModal = ({
  game,
  open,
  onClose,
  onDelete,
}: DeleteGameModalProps) => {
  const toaster = useToast();
  const deleteGame = SdPrivateApi.useDeleteGame();

  const doDeleteGame = async () => {
    if (game) {
      await deleteGame
        .mutateAsync({
          gameId: game.id,
        })
        .then(({ id }) => {
          track('Delete Game', 'Success', {
            gameId: game.id,
          });
          toaster('Game deleted successfully');
          onDelete();
        })
        .catch((e) => {
          track('Delete Game', 'Failure', {
            gameId: game.id,
          });
          toaster(
            `An error occurred while deleting the game: ${e.message}`,
            'error'
          );
        });
    }
  };

  return (
    <RemoveModal
      body={`Are you sure you want to remove "${game.name}"? This action cannot be undone.`}
      label="Game"
      loading={deleteGame.isLoading}
      onClose={() => {
        track('Delete Game Close', 'Clicked', {
          gameId: game.id,
        });
        onClose();
      }}
      onConfirm={doDeleteGame}
      open={open}
    />
  );
};
