import {
  Box,
  Link as MuiLink,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  ArrowSquareOut,
  Icon as PhosphorIcon,
  PencilSimple,
} from '@phosphor-icons/react';
import {
  Collection,
  CollectionResults,
} from '@stardust-monorepo/types/sd-core';
import {
  CollapsibleTable,
  formatAddress,
  formatBlockchainExplorerUrl,
  MoreActions,
  track,
  UnlimitedTemplateCap,
  ValidatedImage,
} from '@stardust-monorepo/web-sdk-apps-shared';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { EditCollectionModal } from './EditCollectionModal';

type AddressColumnProps = {
  address: string | null;
  blockchainId: number | null;
};

const AddressColumn = ({ address, blockchainId }: AddressColumnProps) => {
  const [hover, setHover] = useState(false);
  return (
    <Stack spacing={0.25} sx={{ flex: '1 1 0' }}>
      <Typography variant="caption" color="text.hint">
        Contract
      </Typography>
      {address ? (
        <Stack
          direction="row"
          spacing={0.5}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          sx={{ alignItems: 'center' }}
        >
          <Tooltip title="Collection Contract" placement="top">
            <MuiLink
              target="_blank"
              rel="noopener"
              href={formatBlockchainExplorerUrl(blockchainId, address)}
            >
              <Typography variant="body2">{formatAddress(address)}</Typography>
            </MuiLink>
          </Tooltip>
          <Box
            sx={{
              display: 'flex',
              color: 'text.hint',
              visibility: hover ? 'visible' : 'hidden',
            }}
          >
            <ArrowSquareOut size={20} />
          </Box>
        </Stack>
      ) : (
        <Tooltip title="Collection Contract" placement="top">
          <Typography variant="body2">Unavailable</Typography>
        </Tooltip>
      )}
    </Stack>
  );
};

type CollectionTableProps = {
  collectionResults: CollectionResults;
  Icon: PhosphorIcon;
  label: string;
  page: number;
  setPage: (page: number) => void;
};

export const CollectionTable = ({
  collectionResults,
  Icon,
  label,
  page,
  setPage,
}: CollectionTableProps) => {
  const [editingCollection, setEditingCollection] = useState<
    Collection | undefined
  >();

  const rows = collectionResults.results.map((collection) => {
    return [
      <ValidatedImage
        size="64px"
        image={collection.image}
        sx={{
          maxWidth: '64px',
        }}
      />,
      <Stack
        direction={'row'}
        gap={4}
        sx={{
          alignItems: 'center',
        }}
      >
        <Stack
          spacing={0.5}
          sx={{
            flex: '2 1 0',
          }}
        >
          <Link to={`${collection.id}`}>
            <Typography variant="subtitle1" color="primary.main">
              {collection.name}
            </Typography>
          </Link>
          <Typography
            variant="helperText"
            sx={{ mt: 0.5, color: 'text.secondary' }}
          >
            {collection.id}
          </Typography>
        </Stack>
        <AddressColumn
          address={collection.address}
          blockchainId={collection.blockchainId}
        />
        <Typography
          variant="body2"
          sx={{
            flex: '1 1 0',
            alignSelf: 'center',
          }}
        >
          {`${
            !collection.totalSupply ||
            collection.totalSupply === UnlimitedTemplateCap
              ? 'Unlimited'
              : collection.totalSupply
          } Supply`}
        </Typography>
      </Stack>,
      <MoreActions
        actions={[
          {
            icon: <PencilSimple weight="duotone" />,
            label: 'Edit',
            onClick: () => {
              track('Collections Edit Collection', 'Clicked', {
                gameId: collection.gameId,
                collectionId: collection.id,
              });
              setEditingCollection(collection);
            },
          },
        ]}
      />,
    ];
  });
  return (
    <>
      {editingCollection && (
        <EditCollectionModal
          collection={editingCollection}
          gameId={editingCollection.gameId}
          open={!!editingCollection}
          onClose={() => setEditingCollection(undefined)}
          sourcePage="Collections"
        />
      )}
      <CollapsibleTable
        page={page}
        setPage={setPage}
        headings={[
          {
            label: '',
            width: '64px',
          },
          undefined,
          {
            label: '',
            width: '24px',
          },
        ]}
        rows={rows}
        label={label}
        count={collectionResults.total}
        Icon={Icon}
      />
    </>
  );
};
