import { datadogRum } from '@datadog/browser-rum';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import {
  configureAmplify,
  developerAmplifyConfig,
  developerAmplifyConfigDev,
  Spinner,
} from '@stardust-monorepo/web-sdk-apps-shared';
import { StrictMode, Suspense } from 'react';
import * as ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { RecoilRoot } from 'recoil';

import App from './app/app';
import './main.css';

configureAmplify(
  process.env.NX_TARGET_ENVIRONMENT === 'dev'
    ? developerAmplifyConfigDev
    : developerAmplifyConfig
);

Sentry.init({
  environment: process.env.NX_TARGET_ENVIRONMENT || 'prod',
  dsn: 'https://0974ca99bdcb4656bb06584f48b12268@o1283597.ingest.sentry.io/4504577480982528',
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: process.env.NX_TARGET_ENVIRONMENT === 'prod' ? 0.75 : 1.0,
  release: process.env.NX_SENTRY_RELEASE_NAME || undefined,
});

//only initialize RUM if there's a release set to avoid capturing local sessions
if (process.env.NX_SENTRY_RELEASE_NAME) {
  datadogRum.init({
    applicationId: 'd0dea318-5bfb-4e85-8637-fa09601cebbf',
    clientToken: 'pub1d39b265350b3d9daebd6059c9879fd3',
    site: 'datadoghq.com',
    service: 'game-developer-dashboard',

    env:
      process.env.NX_TARGET_ENVIRONMENT === 'prod'
        ? 'PRD'
        : process.env.NX_TARGET_ENVIRONMENT?.toUpperCase(),
    version: process.env.NX_SENTRY_RELEASE_NAME,
    sampleRate: 100,
    sessionReplaySampleRate: 25,
    trackInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask',
  });

  datadogRum.startSessionReplayRecording();
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <StrictMode>
    <Sentry.ErrorBoundary>
      <RecoilRoot>
        <Suspense fallback={<Spinner />}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </Suspense>
      </RecoilRoot>
    </Sentry.ErrorBoundary>
  </StrictMode>
);
