import { paginatedResultsSchema } from '@stardust-monorepo/types/common';
import { z } from 'zod';

export const GeneratorSchema = z
  .object({
    id: z.string().uuid(),
    gameId: z.number(),
    name: z.string(),
    blockchainId: z.nullable(z.number()),
    address: z.nullable(z.string()),
    cap: z.number(),
    totalSupply: z.number(),
    createdAt: z.number(),
    updatedAt: z.number(),
  })
  .describe('GeneratorSchema');
export type Generator = z.infer<typeof GeneratorSchema>;

export const GeneratorResultsSchema = paginatedResultsSchema(GeneratorSchema);
export type GeneratorResults = z.infer<typeof GeneratorResultsSchema>;
