import { paginatedResultsWithFilterSchema } from '@stardust-monorepo/types/common';
import { z } from 'zod';

import { unixTimestampToDateTransformation } from './base';

export const RequestLogSchema = z
  .object({
    id: z.string(),
    rootUserId: z.string(),
    applicationId: z.string(),
    clientTimestamp: unixTimestampToDateTransformation(),
    serverTimestamp: unixTimestampToDateTransformation(),
    httpMethod: z.string(),
    endpointName: z.string(),
    endpoint: z.string(),
    requestParams: z.record(z.string(), z.any()),
    responseStatus: z.number(),
    responseTime: z.number(),
    response: z.string(),
  })
  .describe('RequestLogSchema');
export type RequestLog = z.infer<typeof RequestLogSchema>;

export const RequestLogResultsSchema = paginatedResultsWithFilterSchema(
  RequestLogSchema
).describe('ProfileResultsSchema');
export type RequestLogResults = z.infer<typeof RequestLogResultsSchema>;
