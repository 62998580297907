import {
  Box,
  debounce,
  InputAdornment,
  Stack,
  Typography,
} from '@mui/material';
import { MagnifyingGlass } from '@phosphor-icons/react';
import { Input } from '@stardust-monorepo/web-sdk-apps-shared';
import { ChangeEvent, ReactNode } from 'react';

const searchAdornment = (
  <InputAdornment position="start" sx={{ pl: 1, color: 'text.secondary' }}>
    <MagnifyingGlass size={16} />
  </InputAdornment>
);

export interface SearchBarProps {
  actions?: ReactNode;
  additionalInfo?: ReactNode;
  count?: number;
  placeholder?: string;
  setFilter?: (filter: string) => void;
  type?: string;
  renderActions?: () => ReactNode;
}

export function SearchBar({
  actions,
  additionalInfo,
  count = 0,
  placeholder,
  setFilter,
  type,
}: SearchBarProps) {
  const dbSetFilter = setFilter ? debounce(setFilter, 500) : null;
  const onChange = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    dbSetFilter && dbSetFilter(event.target.value);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        pb: 2,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
        }}
      >
        {type && (
          <Stack
            sx={{
              alignItems: 'center',
              gap: 1,
            }}
            direction="row"
          >
            <Typography variant="h6">{type}</Typography>
            <Typography
              variant="h6"
              color="text.secondary"
              sx={{
                fontWeight: 400,
              }}
            >
              ({count < 10 ? `0${count}` : count.toLocaleString()})
            </Typography>
          </Stack>
        )}
        {additionalInfo}
        {setFilter && !type && (
          <Input
            sx={{ width: '250px', borderRadius: 2 }}
            onChange={onChange}
            placeholder={placeholder}
            startAdornment={searchAdornment}
          />
        )}
      </Box>
      <Stack direction="row" spacing={1.5}>
        {setFilter && type && (
          <Input
            sx={{ width: '250px', borderRadius: 2 }}
            onChange={onChange}
            placeholder={placeholder}
            startAdornment={searchAdornment}
          />
        )}
        {actions}
      </Stack>
    </Box>
  );
}
