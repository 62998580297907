import {
  Properties,
  Purchasable,
  PurchasableItem,
  PurchasableItemSchema,
  PurchasableSale,
  PurchasableSaleSchema,
  PurchasableSaleSummary,
  PurchasableSaleSummarySchema,
  PurchasableSchema,
} from '@stardust-monorepo/types/sd-private';
import { z } from 'zod';

import { authenticatedRequest, validatedRequestWithAuth } from './api-utils';
import { createAsset, persistFile } from './asset-api';

export async function getPurchasables(
  gameId: number,
  templateId?: number
): Promise<Purchasable[]> {
  return validatedRequestWithAuth(
    PurchasableSchema.array(),
    'purchasable/get-all',
    {
      params: {
        gameId,
        templateId,
      },
    }
  );
}

export async function getPurchasableSaleHistorySummary(
  gameId: number
): Promise<PurchasableSaleSummary[]> {
  return validatedRequestWithAuth(
    PurchasableSaleSummarySchema.array(),
    'purchasable-sale-history/summary-get-all',
    {
      params: {
        gameId,
      },
    }
  );
}

export interface PurchasableSaleHistoryArgs {
  gameId: number;
  purchasableId?: number;
  start?: number;
  limit?: number;
}

export async function getPurchasableSaleHistory(
  args: PurchasableSaleHistoryArgs
): Promise<PurchasableSale[]> {
  return validatedRequestWithAuth(
    PurchasableSaleSchema.array(),
    'purchasable-sale-history/get-all',
    {
      params: {
        ...args,
      },
    }
  );
}

export interface TogglePurchasableActiveArgs {
  gameId: number;
  purchasableId: number;
}

export async function togglePurchasableActive(
  args: TogglePurchasableActiveArgs
): Promise<Record<string, never>> {
  return authenticatedRequest('purchasable/active-listing', {
    method: 'PUT',
    data: args,
  });
}

export interface DeletePurchasableArgs {
  gameId: number;
  purchasableId: number;
}

export async function deletePurchasable(
  args: DeletePurchasableArgs
): Promise<Record<string, never>> {
  return authenticatedRequest('purchasable/remove', {
    method: 'DELETE',
    params: args,
  });
}

export async function getPurchasableItems(
  gameId: number,
  purchasableId: number
): Promise<PurchasableItem[]> {
  return validatedRequestWithAuth(
    PurchasableItemSchema.array(),
    'purchasable-item/get-all',
    {
      params: {
        gameId,
        purchasableId,
      },
    }
  );
}

export interface CreatePurchasableArgs {
  cap: string;
  gameId: number;
  image?: File | null;
  name: string;
  price: string;
  purchasableItems: {
    amount: string;
    props: {
      mutable: Properties;
      immutable: Properties;
    };
    templateId: number;
  }[];
}

export async function createPurchasable({
  image,
  ...data
}: CreatePurchasableArgs) {
  const { id: purchasableId } = (await validatedRequestWithAuth(
    z.object({
      id: z.number(),
    }),
    'purchasable/create',
    {
      method: 'POST',
      data: {
        currencyISOCode: 'SC',
        image: '',
        ...data,
      },
    }
  )) as { id: number };

  if (image) {
    const { uploadUrl } = await createAsset({
      gameId: data.gameId,
      fileType: image.type,
      path: `purchasables/${purchasableId}`,
    });

    await persistFile(uploadUrl, image);
  }

  return purchasableId;
}

export interface MutatePurchasableArgs {
  cap: string;
  gameId: number;
  image?: string;
  name: string;
  newImage?: File | null;
  price: string;
  purchasableId: number;
}

export async function mutatePurchasable({
  newImage,
  ...data
}: MutatePurchasableArgs) {
  await authenticatedRequest('purchasable/mutate', {
    method: 'PUT',
    data: {
      ...data,
    },
  });

  if (newImage) {
    const { uploadUrl } = await createAsset({
      gameId: data.gameId,
      fileType: newImage.type,
      path: `purchasables/${data.purchasableId}`,
    });

    await persistFile(uploadUrl, newImage);
  }

  return data.purchasableId;
}

export interface CreatePurchasableItemArgs {
  amount: string;
  gameId: number;
  props: {
    mutable: Properties;
    immutable: Properties;
  };
  purchasableId: number;
  templateId: number;
}

export async function createPurchasableItem(
  args: CreatePurchasableItemArgs
): Promise<{ id: number }> {
  return validatedRequestWithAuth(
    z.object({
      id: z.number(),
    }),
    'purchasable-item/create',
    {
      method: 'POST',
      data: {
        ...args,
      },
    }
  );
}

export interface MutatePurchasableItemArgs {
  amount: string;
  gameId: number;
  props: {
    mutable: Properties;
    immutable: Properties;
  };
  purchasableId: number;
  purchasableItemId: number;
}

export async function mutatePurchasableItem(
  args: MutatePurchasableItemArgs
): Promise<Record<string, never>> {
  return authenticatedRequest('purchasable-item/mutate', {
    method: 'PUT',
    data: {
      ...args,
    },
  });
}

export interface DeletePurchasableItemArgs {
  gameId: number;
  purchasableId: number;
  purchasableItemId: number;
}

export async function deletePurchasableItem(
  args: DeletePurchasableItemArgs
): Promise<Record<string, never>> {
  return authenticatedRequest('purchasable-item/remove', {
    method: 'DELETE',
    params: args,
  });
}
