import { useTheme } from '@mui/material';
import { Stats } from '@stardust-monorepo/types/sd-private';
import {
  LineChart,
  SdPrivateApi,
} from '@stardust-monorepo/web-sdk-apps-shared';
import { UseQueryResult } from '@tanstack/react-query';
import {
  addDays,
  format,
  startOfQuarter,
  subMonths,
  subQuarters,
  subWeeks,
} from 'date-fns';
import { useMemo } from 'react';

import { TimeFrame } from '../../../types/timeframe';

export const PlayerCountChart = ({
  gameId,
  timeFrame,
}: {
  gameId: number;
  timeFrame: TimeFrame;
}) => {
  const theme = useTheme();
  const now = useMemo(() => new Date(), []);

  const startDate = addDays(
    timeFrame === 'week'
      ? subWeeks(now, 1)
      : timeFrame === 'month'
      ? subMonths(now, 1)
      : subQuarters(startOfQuarter(now), 1),
    1
  );

  const queryResult: UseQueryResult<Stats>[] = SdPrivateApi.useAllStats({
    games: [gameId],
    startDate: startDate.getTime(),
    endDate: now.getTime(),
  });
  const { data } = queryResult[0];

  const statsData = useMemo(() => {
    return data
      ? Object.keys(data.dailyActivePlayerCounts).map((day: string) => ({
          period: format(new Date(Number(day)), 'MMM d'),
          activePlayers: data.dailyActivePlayerCounts[day],
          newPlayers: data.dailyNewPlayerCounts[day],
        }))
      : [];
  }, [data]);

  return (
    <LineChart
      data={statsData}
      config={{
        lines: {
          activePlayers: {
            color: theme.palette.error.main,
          },
          newPlayers: {
            color: theme.palette.warning.main,
          },
        },
        xAxisKey: 'period',
      }}
    />
  );
};
