export const mockToken = {
  templateId: 83,
  id: 32712,
  name: 'Moto Ape',
  props: {
    inherited: {
      test: 'value',
      image: 'moto-ape.jpeg',
      description: 'An ape that likes bikes',
    },
    immutable: { another: 'test' },
    mutable: { steph: 30 },
  },
};

export const mockTokenMint = {
  playerId: '781c014c-596b-438b-ab6e-92dab67fcf05',
  tokenId: 32712,
  amount: '1',
};
