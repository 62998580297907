import { Blockchain } from '@stardust-monorepo/types/sd-private';

let v2Blockchains: number[] = [];
if (process.env['NX_V2_BLOCKCHAIN_LIST']) {
  v2Blockchains = process.env['NX_V2_BLOCKCHAIN_LIST'].split(',').map(Number);
}

export function isV2Blockchain(blockchainId: Blockchain) {
  return v2Blockchains.includes(blockchainId);
}
