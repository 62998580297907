import { Box, Divider, IconButton, Stack, Typography } from '@mui/material';
import { SignOut } from '@phosphor-icons/react';
import { userState } from '@stardust-monorepo/web-sdk-apps-shared';
import { useQueryClient } from '@tanstack/react-query';
import { Auth } from 'aws-amplify';
import { PropsWithChildren, ReactNode } from 'react';
import { useRecoilState } from 'recoil';

export type PageHeaderBaseProps = {
  renderActions?: () => ReactNode;
  label?: string;
};

export const PageHeaderBase = ({
  label,
  renderActions,
  children,
}: PropsWithChildren<PageHeaderBaseProps>) => {
  const [, setUser] = useRecoilState(userState);
  const queryClient = useQueryClient();
  return (
    <Box
      sx={{
        px: 2,
        width: '100%',
        backgroundColor: 'background.default',
        borderBottom: '1px solid',
        borderColor: 'divider',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          height: '73px',
          width: '100%',
          px: 0.5,
          backgroundColor: 'background.default',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="subtitle1" color="text.primary">
            {label}
          </Typography>
          {children}
        </Box>
        <Stack direction="row" spacing={1.5} sx={{ alignItems: 'center' }}>
          {renderActions?.()}
          <Divider orientation="vertical" flexItem />
          <IconButton
            sx={{ color: 'text.secondary' }}
            onClick={() => {
              Auth.signOut().then(() =>
                setUser({
                  user: null,
                  unacceptedPolicies: null,
                  loading: false,
                  error: null,
                })
              );
              queryClient.clear();
            }}
          >
            <SignOut size="20" weight="duotone" />
          </IconButton>
        </Stack>
      </Box>
    </Box>
  );
};
