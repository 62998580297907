import { CollectionType } from '@stardust-monorepo/types/sd-core';

export const collectionTypeToDisplayString = (type: CollectionType) => {
  return type === 'FT'
    ? 'Standalone Currency'
    : type === 'NFT'
    ? 'Standalone Asset'
    : 'Game Collection';
};

export const collectionTypeToTechnicalDisplayString = (
  type: CollectionType
) => {
  return type === 'FT'
    ? 'Fungible Token'
    : type === 'NFT'
    ? 'Non-Fungible Token'
    : 'Fungible Assets';
};
