import { Typography } from '@mui/material';
import { MagnifyingGlass } from '@phosphor-icons/react';
import React from 'react';

import { EmptyContainer } from '../empty';

export const NoSearchResults = () => (
  <EmptyContainer
    sx={{
      background: 'no-repeat url(/assets/emptyStateBackground.png)',
      height: '312px',
      display: 'flex',
      flexDirection: 'column',
      px: 15,
      py: 6.25,
      alignItems: 'center',
      textAlign: 'center',
    }}
  >
    <MagnifyingGlass size={64} />
    <Typography
      variant="subtitle1"
      sx={{ color: 'text.secondary', mt: 6, mb: 1 }}
    >
      No results found
    </Typography>
  </EmptyContainer>
);
