import Auth from '@aws-amplify/auth';
import { zodResolver } from '@hookform/resolvers/zod';
import { ArrowBackRounded } from '@mui/icons-material';
import { Button, FormHelperText, Link, Stack, Typography } from '@mui/material';
import { color, FormField } from '@stardust-monorepo/web-sdk-apps-shared';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { NavLink as RouterLink } from 'react-router-dom';
import { z } from 'zod';

const emailControl = 'email';

const emailFormSchema = z
  .object({
    [emailControl]: z.string().email(),
  })
  .required();

export const ReceiveResetCodeForm = ({
  onSubmitSuccess,
}: {
  onSubmitSuccess: (email: string) => void;
}) => {
  const [externalPlatformError, setExternalPlatformError] =
    useState<boolean>(false);
  const [notFoundError, setNotFoundError] = useState<boolean>(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      [emailControl]: '',
    },
    resolver: zodResolver(emailFormSchema),
  });

  const resetPassword = async (email: string) => {
    try {
      await Auth.forgotPassword(email);
      onSubmitSuccess(email);
      /* eslint-disable @typescript-eslint/no-explicit-any */
    } catch (error: any) {
      //TODO proper error types?
      if (error && error.name?.includes('NotAuthorizedException')) {
        setExternalPlatformError(true);
      } else if (error && error.name?.includes('UserNotFoundException')) {
        setNotFoundError(true);
      }
    }
  };
  return (
    <Stack
      sx={(theme) => ({
        background: theme.palette.background.cool,
        border: `1px solid ${color.neutral[100]}`,
        borderRadius: 4,
        padding: 4,
      })}
    >
      <Stack
        component="form"
        onSubmit={handleSubmit((data) => {
          resetPassword(data[emailControl]);
        })}
        sx={{
          maxWidth: '360px',
        }}
      >
        <Typography variant={'h4'}>Reset Password</Typography>
        <Typography variant={'body1'} marginBottom={3} marginTop={1}>
          We'll email a code that you can use to reset your password.
        </Typography>
        <FormField
          control={control}
          label="Email Address"
          autoComplete="username"
          formControlName={emailControl}
          hasError={
            !!(errors[emailControl] || externalPlatformError || notFoundError)
          }
          renderHelpText={() =>
            (errors[emailControl] && (
              <FormHelperText>{errors[emailControl]?.message}</FormHelperText>
            )) ||
            (externalPlatformError && (
              <FormHelperText>
                This account was created with an external platform.
              </FormHelperText>
            )) ||
            (notFoundError && <FormHelperText>User not found.</FormHelperText>)
          }
        />
        <Button
          type={'submit'}
          variant="contained"
          size="large"
          sx={{
            marginTop: 3,
            marginBottom: 4,
          }}
        >
          Send code
        </Button>
        <Link
          component={RouterLink}
          to="/login"
          variant={'caption'}
          underline={'none'}
          sx={(theme) => ({
            color: theme.palette.text.secondary,
            display: 'flex',
            alignItems: 'center',
          })}
        >
          <ArrowBackRounded
            sx={{
              marginRight: 0.5,
              fontSize: '1rem',
            }}
          />
          Back to login screen
        </Link>
      </Stack>
    </Stack>
  );
};
