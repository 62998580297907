import { z } from 'zod';

export const PolicySchema = z
  .object({
    gameId: z.string().nullable(),
    url: z.string().url(),
    startDate: z.string().datetime(),
    endDate: z.string().datetime().nullable(),
    label: z.string(),
    id: z.number(),
  })
  .describe('PolicySchema');
export type Policy = z.infer<typeof PolicySchema>;

export const PolicyHistorySchema = z
  .object({
    policyId: z.number(),
    acceptorId: z.string(),
    acceptorType: z.string(),
    createdAt: z.string().datetime(),
  })
  .describe('PolicyHistorySchema');
export type PolicyHistory = z.infer<typeof PolicyHistorySchema>;
