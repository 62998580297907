import { Box, FormHelperText, IconButton, SxProps } from '@mui/material';
import { XCircle } from '@phosphor-icons/react';
import { FormField, FormSelect } from '@stardust-monorepo/web-sdk-apps-shared';
import get from 'lodash.get';
import { useEffect } from 'react';
import {
  FieldErrors,
  FieldPath,
  FieldValues,
  UseFormWatch,
} from 'react-hook-form';
import { Control } from 'react-hook-form/dist/types/form';

export interface Property {
  name: string;
  propType: string;
  value: string | number;
}

interface PropertyRowProps<F extends FieldValues> {
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any>;
  errors?: FieldErrors<Property>;
  fieldName: FieldPath<F>;
  removeProperty: () => void;
  sx?: SxProps;
  updateProperty: (property: Property) => void;
  watch: UseFormWatch<F>;
}

const propTypeOptions = [
  { label: 'string', value: 'string' },
  { label: 'number', value: 'number' },
  { label: 'boolean', value: 'boolean' },
];

const boolOptions = [
  { label: 'true', value: 'true' },
  { label: 'false', value: 'false' },
];

export const PropertyRow = <F extends FieldValues>({
  control,
  errors,
  fieldName,
  removeProperty,
  sx = {},
  updateProperty,
  watch,
}: PropertyRowProps<F>) => {
  const nameError = errors?.name;
  const propTypeError = errors?.propType;
  const valueError = errors?.value;

  const propType = watch(`${fieldName}.propType` as FieldPath<F>);

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === `${fieldName}.propType` && type === 'change') {
        updateProperty({
          name: get(value, `${fieldName}.name`, ''),
          propType: get(value, `${fieldName}.propType`, ''),
          value: '',
        });
      }
    });
    return () => subscription.unsubscribe();
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [watch]);

  return (
    <Box
      sx={{
        padding: 1.5,
        display: 'flex',
        gap: 1,
        borderTop: '1px solid',
        borderColor: 'divider',
        alignItems: 'flex-start',
        ...sx,
      }}
    >
      <FormField
        sx={{ flex: '1 1 0' }}
        formControlName={`${fieldName}.name`}
        control={control}
        placeholder="Name..."
        type="text"
        hasError={!!nameError}
        renderHelpText={() =>
          nameError && <FormHelperText>{nameError?.message}</FormHelperText>
        }
        margin="none"
      />
      <FormSelect
        control={control}
        formControlName={`${fieldName}.propType`}
        hasError={!!propTypeError}
        options={propTypeOptions}
        placeholder="Type..."
        renderHelpText={() =>
          propTypeError && (
            <FormHelperText>{propTypeError?.message}</FormHelperText>
          )
        }
        sx={{ flex: '1 1 0' }}
        margin="none"
      />
      {propType === 'boolean' ? (
        <FormSelect
          control={control}
          formControlName={`${fieldName}.value`}
          hasError={!!valueError}
          options={boolOptions}
          placeholder="Value"
          renderHelpText={() =>
            valueError && <FormHelperText>{valueError?.message}</FormHelperText>
          }
          sx={{ flex: '1 1 0' }}
          margin="none"
        />
      ) : (
        <FormField
          sx={{ flex: '1 1 0' }}
          margin="none"
          formControlName={`${fieldName}.value`}
          control={control}
          placeholder="Value..."
          type={propType === 'number' ? 'number' : 'text'}
          hasError={!!valueError}
          renderHelpText={() =>
            valueError && <FormHelperText>{valueError?.message}</FormHelperText>
          }
        />
      )}
      <IconButton
        aria-label="remove-property"
        onClick={removeProperty}
        sx={{ color: 'text.hint', mt: -0.25 }}
      >
        <XCircle size={20} weight="duotone" />
      </IconButton>
    </Box>
  );
};
