import { Link, Stack, SxProps, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';

export interface DocumentationProps {
  title?: string;
  description?: string;
  links?: { href: string; description: string }[];
  sx?: SxProps<Theme>;
}

export const Documentation = ({
  title,
  description,
  links,
  sx = {},
}: DocumentationProps) => {
  return (
    <Stack
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore TODO why is this unhappy?
      sx={(theme) => ({
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: 3,
        padding: 2,
        height: 'fit-content',
        ...sx,
      })}
    >
      <Typography variant={'overline'} color={'text.secondary'}>
        Learn More
      </Typography>
      <Typography
        variant={'h5'}
        sx={{
          mt: 1,
          mb: 2,
        }}
      >
        {title}
      </Typography>
      <Stack
        gap={2}
        sx={(theme) => ({
          gap: 2,
          background: theme.palette.background.cool,
          borderRadius: 2,
          padding: 2,
        })}
      >
        <Typography variant={'body2'} color={'text.secondary'}>
          {description}
        </Typography>
        {links?.map((link) => (
          <Link
            target={'_blank'}
            rel="noopener"
            variant={'subtitle2'}
            sx={(theme) => ({
              color: theme.palette.text.link,
            })}
            key={link.description}
            href={link.href}
          >
            {link.description}
          </Link>
        ))}
      </Stack>
    </Stack>
  );
};
