import { zodResolver } from '@hookform/resolvers/zod';
import { FormHelperText, Stack, Typography } from '@mui/material';
import {
  Collection,
  CollectionType,
  FungibleAsset,
  NonFungibleToken,
} from '@stardust-monorepo/types/sd-core';
import {
  FormField,
  FormSectionContainer,
  CreateDocumentation,
  FormButtonBar,
  UnlimitedTemplateCap,
} from '@stardust-monorepo/web-sdk-apps-shared';
import React, { MutableRefObject } from 'react';
import { useForm } from 'react-hook-form';

import {
  BaseCollectionFormValue,
  baseCollectionFormValue,
  formSchemaWithMetadata,
} from './constants/base-collection-form';
import {
  metadataDefaultFormValue,
  metadataSchemaExtension,
  MetadataFormValue,
  standaloneTokenDefaultFormValue,
  standaloneTokenSchemaExtension,
} from './constants/inventory-standalone-token-metadata';
import {
  collectionTypeToDisplayString,
  collectionTypeToTechnicalDisplayString,
} from './util';

export type CollectionFormValue = BaseCollectionFormValue & MetadataFormValue;

const collectionToFormValue = (
  collection: Collection
): CollectionFormValue => ({
  name: collection.name,
  image: collection.image,
  symbol: collection.symbol ?? '',
  description: collection.description,
  externalLink: String(collection.publicMetadata.externalLink ?? ''),
  supplyType:
    collection.totalSupply === UnlimitedTemplateCap ? 'Unlimited' : 'Limited',
  totalSupply: collection.totalSupply ?? undefined,
  promoImage: String(collection.publicMetadata.promoImage ?? ''),
  bannerImage: String(collection.publicMetadata.bannerImage ?? ''),
});

const emptyFormValue = (
  collectionType: CollectionType
): CollectionFormValue => ({
  ...baseCollectionFormValue,
  ...metadataDefaultFormValue,
  ...(collectionType === FungibleAsset ? {} : standaloneTokenDefaultFormValue),
});

export const CollectionForm = ({
  collection,
  collectionType,
  submitFormRef,
  onSave,
}: {
  collection?: Collection;
  collectionType: CollectionType;
  submitFormRef: MutableRefObject<HTMLButtonElement | null>;
  onSave: (collection: CollectionFormValue) => void;
}) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<CollectionFormValue>({
    defaultValues: collection
      ? collectionToFormValue(collection)
      : emptyFormValue(collectionType),
    resolver: zodResolver(
      formSchemaWithMetadata({
        ...metadataSchemaExtension,
        ...(collectionType === FungibleAsset
          ? {}
          : standaloneTokenSchemaExtension),
      }).refine(
        collectionType === FungibleAsset
          ? () => true
          : (data) =>
              ((data as CollectionFormValue).supplyType === 'Limited' &&
                ((data as CollectionFormValue).totalSupply || 0) >= 1) ||
              (data as CollectionFormValue).supplyType === 'Unlimited',
        {
          path: ['totalSupply'],
          message: 'Limit must be at least 1',
        }
      )
    ),
  });

  const capTypeOptions = [
    {
      label: 'Fixed Quantity',
      value: 'Limited',
      description: 'Define max number of tokens that can be minted.',
      renderSubField: () => (
        <FormField
          disabled={!!collection}
          formControlName="totalSupply"
          control={control}
          sx={{ mt: 2 }}
          placeholder="Limit"
          type="number"
          hasError={!!errors['totalSupply']}
          renderHelpText={() =>
            errors['totalSupply'] && (
              <FormHelperText>{errors['totalSupply']?.message}</FormHelperText>
            )
          }
        />
      ),
    },
    {
      label: 'Unlimited',
      value: 'Unlimited',
      description: 'No defined max number of tokens that can be minted.',
    },
  ];

  return (
    <Stack
      component="form"
      onSubmit={handleSubmit((data) => {
        onSave(data);
      })}
    >
      <button
        data-testid="collection-form-submit-button"
        ref={submitFormRef}
        style={{ display: 'none' }}
      />
      <Typography variant="h4" mt={7}>
        {collection
          ? `Edit ${collection.name}`
          : `New ${collectionTypeToDisplayString(collectionType)}`}
      </Typography>
      <Typography variant="body1" color="text.secondary" mt={1}>
        {collectionTypeToTechnicalDisplayString(collectionType)}
      </Typography>
      <Stack
        gap={4}
        direction={'row'}
        sx={{
          mt: 5,
        }}
      >
        <Stack gap={4}>
          <FormSectionContainer>
            <Stack>
              <Stack>
                <Typography variant="h6">Collection Metadata</Typography>
                <Typography
                  variant="body2"
                  sx={{ color: 'text.secondary', mt: 0.5, mb: 3 }}
                >
                  Collection Metadata describes your collection and will be used
                  by external marketplaces.
                </Typography>
              </Stack>
              <Stack direction={'row'} gap={4}>
                <Stack
                  sx={{
                    flexGrow: 1,
                  }}
                >
                  <FormField
                    formControlName={'name'}
                    control={control}
                    subtitle="The name that will appear on marketplaces. This name is written into your smart contract and  cannot be changed."
                    label="Name your Collection"
                    type="text"
                    hasError={!!errors['name']}
                    renderHelpText={() =>
                      errors['name'] && (
                        <FormHelperText>
                          {errors['name']?.message}
                        </FormHelperText>
                      )
                    }
                  />
                  <FormField
                    formControlName={'image'}
                    control={control}
                    label="Collection Image"
                    subtitle="Main image that appears on marketplaces."
                    type="text"
                    hasError={!!errors['image']}
                    renderHelpText={() =>
                      errors['image'] && (
                        <FormHelperText>
                          {errors['image']?.message}
                        </FormHelperText>
                      )
                    }
                  />
                  <FormField
                    formControlName={'description'}
                    control={control}
                    rows={3}
                    label="Collection Description"
                    subtitle="Short description of your collection that will appear on marketplaces."
                    type="text"
                    hasError={!!errors['description']}
                    renderHelpText={() =>
                      errors['description'] && (
                        <FormHelperText>
                          {errors['description']?.message}
                        </FormHelperText>
                      )
                    }
                  />
                  <FormField
                    formControlName={'symbol'}
                    control={control}
                    label="Symbol"
                    fullWidth={false}
                    maxInputWidth="small"
                    subtitle="Shorthand abbreviation of your currency."
                    type="text"
                    hasError={!!errors['symbol']}
                    renderHelpText={() =>
                      errors['symbol'] && (
                        <FormHelperText>
                          {errors['symbol']?.message}
                        </FormHelperText>
                      )
                    }
                  />
                  {collectionType === NonFungibleToken && (
                    <>
                      <FormField
                        formControlName={'externalLink'}
                        control={control}
                        label="Custom URL (optional)"
                        subtitle="This link will appear on marketplaces, typically your company website."
                        type="text"
                        hasError={!!errors['externalLink']}
                        renderHelpText={() =>
                          errors['externalLink'] && (
                            <FormHelperText>
                              {errors['externalLink']?.message}
                            </FormHelperText>
                          )
                        }
                      />
                      <FormButtonBar
                        disabled={!!collection}
                        width={'512px'}
                        control={control}
                        formControlName={'supplyType'}
                        hasError={!!errors['supplyType']}
                        label="Total Supply Type"
                        description="Max number of items that can be created for this collection."
                        options={capTypeOptions}
                        renderHelpText={() =>
                          errors['supplyType'] && (
                            <FormHelperText>
                              {errors['supplyType']?.message}
                            </FormHelperText>
                          )
                        }
                      />
                    </>
                  )}
                </Stack>
              </Stack>
            </Stack>
          </FormSectionContainer>
          <FormSectionContainer>
            <Stack>
              <Typography variant="h6">Additional Images</Typography>
              <Typography
                variant="body2"
                sx={{ color: 'text.secondary', mt: 0.5, mb: 3 }}
              >
                Other images used by marketplaces.
              </Typography>
              <FormField
                formControlName={'bannerImage'}
                control={control}
                subtitle="Wide image used by marketplaces."
                label="Banner Image (optional)"
                type="text"
                hasError={!!errors['bannerImage']}
                renderHelpText={() =>
                  errors['bannerImage'] && (
                    <FormHelperText>
                      {errors['bannerImage']?.message}
                    </FormHelperText>
                  )
                }
              />
              <FormField
                formControlName={'promoImage'}
                control={control}
                label="Promo Image (optional)"
                subtitle="Used for small thumbnail, spotlight, or search results."
                type="text"
                hasError={!!errors['promoImage']}
                renderHelpText={() =>
                  errors['promoImage'] && (
                    <FormHelperText>
                      {errors['promoImage']?.message}
                    </FormHelperText>
                  )
                }
              />
            </Stack>
          </FormSectionContainer>
        </Stack>
        <CreateDocumentation
          title="Collections"
          description={
            collectionType === NonFungibleToken
              ? 'Standalone Asset Collections are useful for Limited Edition Items, where the specific Item a player owns is important and differs from all of the others.'
              : "The main collection type. A common use case is for most of a game's items: they don't need to be unique, but they still have metadata associated with them."
          }
        />
      </Stack>
    </Stack>
  );
};
