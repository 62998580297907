import { BaseEmptyView } from '@stardust-monorepo/web-sdk-apps-shared';

import sword from '../../../assets/sword.png';

interface EmptyTemplatesProps {
  onClick: () => void;
}

export const EmptyTemplates = ({ onClick }: EmptyTemplatesProps) => (
  <BaseEmptyView
    image={sword}
    title="Add a Template for items in your game."
    body="Templates define the blockchain assets, FT or NFT, that can be minted for your game."
    buttonText="Create Template"
    onClick={onClick}
  />
);
