import { Add } from '@mui/icons-material';
import { Box, Button, Stack, Typography } from '@mui/material';
import { Game } from '@stardust-monorepo/types/sd-private';
import { useTrack } from '@stardust-monorepo/web-sdk-apps-shared';
import React, { useState } from 'react';
import { useRecoilState } from 'recoil';

import {
  CreateEditGameModal,
  DeleteGameModal,
  GameCard,
} from '../components/game';
import { DeprecationTooltip } from '../components/rip/DeprecationTooltip';
import { gameState } from '../state/game-state';

export const GamesPage = () => {
  const [games] = useRecoilState(gameState);
  const [selectedGame, setSelectedGame] = useState<Game | undefined>();
  const [gameModalOpen, setGameModalOpen] = useState(false);
  const [deleteGameModalOpen, setDeleteGameModalOpen] = useState(false);

  useTrack('Games');

  return (
    <Box sx={{ padding: 2 }}>
      <CreateEditGameModal
        game={selectedGame}
        open={gameModalOpen}
        onClose={() => {
          setGameModalOpen(false);
          setSelectedGame(undefined);
        }}
        onDelete={() => {
          setGameModalOpen(false);
          setSelectedGame(undefined);
        }}
      />
      {selectedGame && (
        <DeleteGameModal
          game={selectedGame}
          open={deleteGameModalOpen}
          onClose={() => setDeleteGameModalOpen(false)}
          onDelete={() => setDeleteGameModalOpen(false)}
        />
      )}
      <Box
        sx={{
          pb: 2,
          borderBottom: '1px solid',
          borderColor: 'divider',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <Typography variant="subtitle1" color="text.secondary">
          Showing ({games.length}) Games
        </Typography>
        <DeprecationTooltip>
          <Button variant="contained" disabled startIcon={<Add />}>
            Create New
          </Button>
        </DeprecationTooltip>
      </Box>
      {games.length > 0 ? (
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
            gap: 3,
            px: 1,
            py: 2,
          }}
        >
          {games.map((game) => (
            <GameCard
              key={game.id}
              game={game}
              openEditModal={(game) => {
                setSelectedGame(game);
                setGameModalOpen(true);
              }}
              openDeleteModal={(game) => {
                setSelectedGame(game);
                setDeleteGameModalOpen(true);
              }}
            />
          ))}
        </Box>
      ) : (
        <Stack>
          <Typography color={'text.secondary'}>No games to display.</Typography>
        </Stack>
      )}
    </Box>
  );
};
