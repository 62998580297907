import { paginatedResultsWithFilterSchema } from '@stardust-monorepo/types/common';
import { z } from 'zod';

import { legacyPaginatedResultsSchema } from './base';

const ChainTypeSchema = z
  .enum(['evm', 'sol', 'sui', 'aptos'])
  .describe('ChainTypeSchema');
export type ChainType = z.infer<typeof ChainTypeSchema>;

export const WalletSchema = z
  .object({
    id: z.string(),
    profileId: z.string().optional(),
    applicationId: z.string(),
    lastUsedAt: z.string().datetime().nullable().optional(),
    createdAt: z.string().datetime(),
    addresses: z.record(ChainTypeSchema, z.string()).optional(),
  })
  .describe('WalletSchema');
export type Wallet = z.infer<typeof WalletSchema>;

export const WalletResultsSchema = paginatedResultsWithFilterSchema(
  WalletSchema
).describe('WalletResultsSchema');
export type WalletResults = z.infer<typeof WalletResultsSchema>;

export const WalletAddressSchema = z
  .object({
    address: z.string(),
    id: z.string(),
  })
  .describe('WalletAddressSchema');
export type WalletAddress = z.infer<typeof WalletAddressSchema>;

export const WalletHistorySchema = z
  .object({
    apiKey: z.string(),
    body: z.record(z.string(), z.string()),
    createdAt: z.string().datetime(),
    id: z.number(),
    method: z.string(),
    url: z.string(),
  })
  .describe('WalletHistorySchema');
export type WalletHistory = z.infer<typeof WalletHistorySchema>;

export const WalletHistoryResultsSchema = legacyPaginatedResultsSchema(
  WalletHistorySchema
).describe('WalletHistoryResultsSchema');
export type WalletHistoryResults = z.infer<typeof WalletHistoryResultsSchema>;
