import { Heartbeat, Wallet } from '@phosphor-icons/react';
import { color, SmallIconStat } from '@stardust-monorepo/web-sdk-apps-shared';

import { StatTotals } from '../../hooks/useGameStats';

export const PerformanceCards = ({
  currentTotals,
  priorTotals,
}: {
  currentTotals: StatTotals;
  priorTotals: StatTotals;
}) => {
  return (
    <>
      <SmallIconStat
        Icon={Wallet}
        iconColor={color.accent.orange[900]}
        iconBackgroundColor={color.accent.orange[50]}
        statValue={currentTotals.totalNewPlayers}
        priorValue={priorTotals.totalNewPlayers}
        title="Wallets: Total"
      />
      <SmallIconStat
        Icon={Heartbeat}
        iconColor={color.accent.orange[900]}
        iconBackgroundColor={color.accent.orange[50]}
        statValue={currentTotals.totalActivePlayers}
        priorValue={priorTotals.totalActivePlayers}
        title="Wallets: Active"
      />
    </>
  );
};
