import { Amplify, Auth, Hub } from 'aws-amplify';
import { AxiosError } from 'axios';

import { login } from '../api';
import { amplifyConfig as prodConfig } from './config/amplify-config';
import { DetailedCognitoUser } from './types/detailed-cognito-user';

/*
 * Amazon does not provide a type for the config shape. For available options, refer to
 * https://docs.amplify.aws/lib/client-configuration/configuring-amplify-categories/q/platform/js/
 */
export const configureAmplify = (amplifyConfig = prodConfig) => {
  const config = {
    ...amplifyConfig,
    Auth: { oauth: { responseType: 'code' } },
    oauth: {
      ...amplifyConfig.oauth,
      responseType: 'code',
      redirectSignIn: origin, //TODO why not take the values from the custom config?
      redirectSignOut: origin,
    },
  };
  Amplify.configure(config);
};

export const onAuthError = (callback: (message: string) => void) => {
  //how do we clean up the listener? it's only possible per-channel and `listen` doesn't return a handler
  Hub.listen('auth', ({ payload }) => {
    if (payload.event === 'signIn_failure') {
      //TODO the message in the payload isn't useful so this is standing in
      callback('An unknown error occurred.');
    }
  });
};

export const setCognitoUserGameId = async (
  gameId: string
): Promise<DetailedCognitoUser> => {
  await Auth.currentAuthenticatedUser();
  Auth.configure({
    clientMetadata: { 'custom:gameId': gameId },
    Auth: { oauth: { responseType: 'code' } },
  });
  const cognitoUser: DetailedCognitoUser =
    await Auth.currentAuthenticatedUser();
  // https://aws.amazon.com/blogs/mobile/aws-amplify-adds-support-for-custom-attributes-in-amazon-cognito-user-pools/
  await Auth.updateUserAttributes(cognitoUser, {
    'custom:gameId': gameId,
  });
  return cognitoUser;
};

export const signUp = (email: string, gameId: string) => {
  const array = new Uint32Array(5);
  crypto.getRandomValues(array);
  return Auth.signUp({
    username: email,
    password: array.join('-'),
    attributes: {
      email,
      'custom:gameId': gameId,
    },
  });
};

export const loginOrSignUpIfNoAcountExists = async (
  email: string,
  gameId: string,
  redirect?: string
) => {
  try {
    login(email, redirect);
  } catch (e: unknown) {
    if (e instanceof AxiosError && e?.response?.status === 403) {
      await signUp(email, gameId);
      await login(email, redirect);
    } else {
      throw e;
    }
  }
};

export async function getAuthToken() {
  const user = await Auth.currentAuthenticatedUser();
  return user.signInUserSession.idToken.jwtToken;
}
