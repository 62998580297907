import { AddCircle, RemoveCircle } from '@mui/icons-material';
import {
  Box,
  Collapse,
  IconButton as MuiIconButton,
  TableCell,
  TableRow,
  useTheme,
} from '@mui/material';
import { ReactNode, SyntheticEvent, useState } from 'react';

import { copyButtonClassName } from '../layout/clipboard-copy';
import { Heading } from './base-table';

export interface RowProps {
  cells: ReactNode[];
  hasSubRow?: boolean;
  headings?: Heading[];
  onRowClick?: (event: SyntheticEvent, rowIndex: number) => void;
  rowBorder?: boolean;
  rowIndex: number;
  totalRows: number;
}

// If hasSubRow is true, the last element in a row will be used as
// the sub-row content. We also add an expand/contract icon to the end of the row.
// TODO storing the 'open' state in the Row isn't an ideal solution because a refetch of
// the data will cause the row to collapse when the Row is re-mounted
export const Row = ({
  cells,
  hasSubRow,
  headings,
  onRowClick,
  rowBorder,
  rowIndex,
  totalRows,
}: RowProps) => {
  const [open, setOpen] = useState(false);
  const theme = useTheme();

  return (
    <>
      <TableRow
        hover
        onClick={onRowClick ? (e) => onRowClick(e, rowIndex) : undefined}
        sx={(theme) => ({
          ...(open ? { background: theme.palette.background.cool } : {}),
          [` .${copyButtonClassName}`]: {
            opacity: 0,
          },
          [`:hover .${copyButtonClassName}`]: {
            opacity: 1,
          },
        })}
      >
        {(hasSubRow ? cells.slice(0, -1) : cells).map((cell, index) => {
          //TODO sx should be encapsulated in Heading to avoid needing to filter non-sx props here
          const { label, onSortChange, sortDirection, ...sx } = headings
            ? headings[index] || {
                label: '',
                onSortChange: undefined,
                sortDirection: undefined,
              }
            : { label: '', onSortChange: undefined, sortDirection: undefined };
          return (
            <TableCell
              key={index}
              sx={{
                ...sx,
                borderBottom:
                  rowBorder && rowIndex < totalRows - 1 && !open
                    ? `1px solid ${theme.palette.divider}`
                    : 'none',
              }}
            >
              {cell}
            </TableCell>
          );
        })}
        {hasSubRow ? (
          <TableCell
            sx={{
              borderBottom:
                rowBorder && rowIndex < totalRows - 1 && !open
                  ? `1px solid ${theme.palette.divider}`
                  : 'none',
            }}
          >
            <MuiIconButton
              aria-label="Expand or contract table row"
              onClick={() => setOpen(!open)}
              sx={{
                padding: 0,
                color: 'primary.main',
                fontSize: '20px',
              }}
            >
              {open ? (
                <RemoveCircle fontSize="inherit" />
              ) : (
                <AddCircle fontSize="inherit" />
              )}
            </MuiIconButton>
          </TableCell>
        ) : null}
      </TableRow>
      {hasSubRow ? (
        <TableRow>
          <TableCell
            sx={(theme) => ({
              py: 0,
              borderBottom:
                rowBorder && rowIndex < totalRows - 1 && open
                  ? `1px solid ${theme.palette.divider}`
                  : 'none',
              background: theme.palette.background.cool,
            })}
            colSpan={cells.length}
          >
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box
                sx={{
                  py: 2,
                }}
              >
                {cells[cells.length - 1]}
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      ) : null}
    </>
  );
};
