import {
  Properties,
  Token,
  TokenMint,
  TokenMintSchema,
  TokenSchema,
} from '@stardust-monorepo/types/sd-private';
import { z } from 'zod';

import { authenticatedRequest, validatedRequestWithAuth } from './api-utils';

export interface GetTokenArgs {
  gameId: number;
  tokenIds: number[];
  returnExplorerLinks?: boolean;
}
export async function getTokens({
  gameId,
  tokenIds,
  returnExplorerLinks,
}: GetTokenArgs): Promise<Token[]> {
  return validatedRequestWithAuth(TokenSchema.array(), 'token/get', {
    params: {
      gameId,
      tokenIds: JSON.stringify(tokenIds),
      returnExplorerLinks,
    },
  });
}

export interface GetTokenMintsArgs {
  gameId: number;
  templateId: number;
}

export async function getTokenMints(
  args: GetTokenMintsArgs
): Promise<TokenMint[]> {
  return validatedRequestWithAuth(
    TokenMintSchema.array(),
    'token/mint/get-all',
    {
      params: {
        ...args,
      },
    }
  );
}

export interface MintTokenArgs {
  gameId: number;
  playerId: string;
  amount: number | string;
  templateId: number;
  tokenType: string;
  props: Properties;
}

export function mintToken({
  gameId,
  playerId,
  amount,
  templateId,
  tokenType,
  props,
}: MintTokenArgs): Promise<number[]> {
  return validatedRequestWithAuth(z.array(z.number()), 'token/mint-bulk', {
    method: 'POST',
    data: {
      gameId,
      playerId,
      tokenObjects: [
        {
          amount: tokenType === 'FT' ? String(amount) : '1',
          templateId,
          props:
            tokenType === 'FT'
              ? undefined
              : {
                  mutable: props,
                  immutable: {},
                },
        },
      ],
    },
  });
}

export interface BurnTokenArgs {
  gameId: number;
  playerId: string;
  amount: string;
  templateId: number;
  tokenId: number;
}

export function burnToken({
  gameId,
  playerId,
  amount,
  tokenId,
}: BurnTokenArgs): Promise<Record<string, never>> {
  return authenticatedRequest('token/burn', {
    method: 'POST',
    data: {
      gameId,
      playerId,
      tokenObjects: [
        {
          amount,
          tokenId,
        },
      ],
    },
  });
}

export interface TransferTokenArgs {
  gameId: number;
  fromPlayerId: string;
  toPlayerId: string;
  amount: string;
  templateId: number;
  tokenId: number;
}

export function transferToken({
  gameId,
  fromPlayerId,
  toPlayerId,
  amount,
  tokenId,
}: TransferTokenArgs): Promise<Record<string, never>> {
  return authenticatedRequest('token/transfer', {
    method: 'POST',
    data: {
      gameId,
      fromPlayerId,
      toPlayerId,
      tokenObjects: [
        {
          amount,
          tokenId,
        },
      ],
    },
  });
}
