import { Typography } from '@mui/material';
import { Item } from '@stardust-monorepo/types/sd-core';
import {
  Spinner,
  track,
  useHandleError,
  useItems,
  useToast,
  useTransferItemBalance,
} from '@stardust-monorepo/web-sdk-apps-shared';
import React, { useRef } from 'react';

import { BaseBalanceModal } from './BaseBalanceModal';
import { TransferItemForm, TransferItemFormValue } from './TransferItemForm';

export const TransferItemBalance = ({
  gameId,
  onClose,
  collectionId,
  ...props
}: {
  gameId: number;
  collectionId?: string;
  item?: Item;
  playerId?: string;
  onClose: () => void;
  open: boolean;
  lockItems?: boolean;
}) => {
  const submitFormButtonRef = useRef<HTMLButtonElement | null>(null);
  const {
    data: items,
    error: itemsError,
    isLoading: itemsLoading,
  } = useItems({
    gameId,
    collectionId,
  });

  const transferItem = useTransferItemBalance();
  const toaster = useToast();
  const handleError = useHandleError();

  const doTransferItem = async (formValues: TransferItemFormValue) => {
    const trackingData = {
      gameId,
      destinationPlayerId: formValues.destinationPlayerId,
      sourcePlayerId: formValues.sourcePlayerId,
      itemId: formValues.item.id,
    };
    try {
      await transferItem.mutateAsync({
        destinationPlayerId: formValues.destinationPlayerId,
        sourcePlayerId: formValues.sourcePlayerId,
        amount: String(formValues.quantity),
        itemId: formValues.item.id,
      });
      track('Item Transfer', 'Success', trackingData);
      toaster(
        `Balance transferred successfully for ${formValues.item.name}`,
        'success'
      );
      onClose();
    } catch (e) {
      handleError(
        e,
        'An error occurred while transferring items',
        'Item Transfer',
        trackingData
      );
    }
  };
  return (
    <BaseBalanceModal
      {...props}
      onClose={onClose}
      isLoading={transferItem.isLoading}
      gameId={gameId}
      dialogLabel="Transfer Balance"
      dialogSublabel="Transfer a balance of an item to another player."
      submitFormRef={submitFormButtonRef}
    >
      {itemsLoading ? (
        <Spinner />
      ) : itemsError ? (
        <Typography>An error occurred while loading Items</Typography>
      ) : (
        <TransferItemForm
          {...props}
          itemList={items?.results || []}
          submitFormRef={submitFormButtonRef}
          onSave={doTransferItem}
        />
      )}
    </BaseBalanceModal>
  );
};
