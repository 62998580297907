import { zodResolver } from '@hookform/resolvers/zod';
import { FormHelperText, Stack } from '@mui/material';
import { Item, ItemSchema } from '@stardust-monorepo/types/sd-core';
import {
  AutocompleteField,
  FormField,
} from '@stardust-monorepo/web-sdk-apps-shared';
import React, { MutableRefObject, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

const formControls = {
  item: 'item',
  recipient: 'recipient',
  quantity: 'quantity',
} as const;

const formSchema = z
  .object({
    [formControls.item]: ItemSchema.required(),
    [formControls.recipient]: z
      .string()
      .min(1, 'Recipient Player ID is required'),
    [formControls.quantity]: z.string().min(1, 'Quantity is required'),
  })
  .required()
  .refine((data) => (data.quantity || 0) >= 1, {
    path: [formControls.quantity],
    message: 'Quantity must be at least 1',
  })
  .refine(
    (data) =>
      Number(data.item.totalSupply) - Number(data.item.currentSupply) >=
      Number(data.quantity),
    (data) => ({
      path: [formControls.quantity],
      message: `Quantity cannot exceed available supply (${
        Number(data.item.totalSupply) - Number(data.item.currentSupply)
      })`,
    })
  );
export type ItemBalanceFormValue = z.infer<typeof formSchema>;

interface InternalItemBalanceFormValue {
  [formControls.recipient]: string;
  [formControls.quantity]: string;
  [formControls.item]: Item | null;
}

export const ItemBalanceForm = ({
  item,
  itemList,
  playerId,
  lockItems = false,
  submitFormRef,
  onSave,
  quantitySubtitle,
}: {
  item?: Item;
  itemList: Item[];
  playerId?: string;
  lockItems?: boolean;
  submitFormRef: MutableRefObject<HTMLButtonElement | null>;
  onSave: (value: ItemBalanceFormValue) => void;
  quantitySubtitle: string;
}) => {
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<InternalItemBalanceFormValue>({
    defaultValues: {
      [formControls.item]: item || null,
      [formControls.recipient]: playerId || '',
      [formControls.quantity]: '1',
    },
    resolver: zodResolver(formSchema),
  });

  const itemOptions = useMemo(
    () =>
      itemList.map((item) => ({
        label: item.name,
        value: item,
      })),
    [itemList]
  );

  return (
    <Stack>
      <Stack
        component="form"
        onSubmit={handleSubmit((data) => {
          onSave(data as ItemBalanceFormValue); //item has to be defined to pass the schema validation
        })}
        sx={(theme) => ({
          border: `1px solid ${theme.palette.divider}`,
          paddingY: 0.5,
          paddingX: 2,
          borderRadius: 2,
        })}
      >
        <button
          data-testid="item-balance-form-submit-button"
          ref={submitFormRef}
          style={{ display: 'none' }}
        />
        <AutocompleteField
          formControlName={formControls.item}
          control={control}
          label="Item"
          lockField={lockItems}
          options={itemOptions}
          isOptionEqualToValue={(option, value) =>
            option.value?.id === value?.id
          }
        />
        <FormField
          fullWidth={false}
          formControlName={formControls.recipient}
          control={control}
          maxInputWidth="normal"
          label="Player ID"
          subtitle="The target Player ID of this operation"
          hasError={!!errors[formControls.recipient]}
          renderHelpText={() =>
            errors[formControls.recipient] && (
              <FormHelperText>
                {errors[formControls.recipient]?.message}
              </FormHelperText>
            )
          }
        />
        <FormField
          formControlName={formControls.quantity}
          control={control}
          maxInputWidth="small"
          label="Quantity"
          subtitle={quantitySubtitle}
          hasError={!!errors[formControls.quantity]}
          renderHelpText={() =>
            errors[formControls.quantity] && (
              <FormHelperText>
                {errors[formControls.quantity]?.message}
              </FormHelperText>
            )
          }
        />
      </Stack>
    </Stack>
  );
};
