import { SdPrivateApi } from '@stardust-monorepo/web-sdk-apps-shared';
import {
  useMutation,
  useQueries,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';

import { GetPlayerInventoryArgs, PlayerArgs } from '../player-api';

export const playerKeys = {
  players: (
    gameId: number,
    start?: number,
    limit?: number,
    filter?: string,
    includeWallet?: boolean
  ) =>
    [
      'players',
      {
        gameId,
      },
      ...(start || limit || filter || includeWallet
        ? [{ start, limit, filter, includeWallet }]
        : []),
    ] as const,
  playerCount: (gameId: number, filter?: string) =>
    ['players-count', { gameId }, ...(filter ? [{ filter }] : [])] as const,
  player: (args: PlayerArgs) => ['player', { ...args }] as const,
  playerInventory: ({
    playerId,
    gameId,
    start,
    limit,
    returnExplorerLinks,
  }: GetPlayerInventoryArgs) =>
    [
      'playerInventory',
      { playerId },
      { gameId },
      ...(start || limit || returnExplorerLinks !== undefined
        ? [{ start, limit, returnExplorerLinks }]
        : []),
    ] as const,
  playerWallet: (args: PlayerArgs) => ['playerWallet', { ...args }] as const,
};

export function usePlayers(
  args: {
    gameId: number | undefined;
    start?: number;
    limit?: number;
    filter?: string;
    includeWallet?: boolean;
  },
  enabled = true
) {
  return useQuery({
    queryKey: playerKeys.players(
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      args.gameId!,
      args.start,
      args.limit,
      args.filter,
      args.includeWallet
    ),
    queryFn: () =>
      SdPrivateApi.getPlayers(
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        args.gameId!,
        args.start,
        args.limit,
        args.filter,
        args.includeWallet
      ),
    enabled: enabled && args.gameId !== undefined,
  });
}

export function usePlayerCount(
  args: {
    gameId: number | undefined;
    filter?: string;
  },
  enabled = true
) {
  return useQuery({
    queryKey: playerKeys.playerCount(
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      args.gameId!,
      args.filter
    ),
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    queryFn: () => SdPrivateApi.getPlayerCount(args.gameId!, args.filter),
    enabled: enabled && args.gameId !== undefined,
  });
}

export function usePlayer(args: PlayerArgs) {
  return useQuery({
    queryKey: playerKeys.player(args),
    queryFn: () => SdPrivateApi.getPlayer(args),
    enabled: args.gameId !== undefined && args.playerId !== undefined,
  });
}

export function usePlayerInventory(args: GetPlayerInventoryArgs) {
  return useQuery({
    queryKey: playerKeys.playerInventory(args),
    queryFn: () => SdPrivateApi.getPlayerInventory(args),
    enabled: args.gameId !== undefined && args.playerId !== undefined,
  });
}

export function useDeletePlayer() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: SdPrivateApi.deletePlayer,
    onSuccess: (data, variables) => {
      // Refresh the players list
      queryClient.invalidateQueries({
        queryKey: playerKeys.players(variables.gameId),
      });
      queryClient.invalidateQueries({
        queryKey: playerKeys.playerCount(variables.gameId),
      });
    },
  });
}

export function usePlayerWallet(args: { playerId?: string; gameId: number }) {
  return useQuery({
    queryKey: playerKeys.playerWallet({
      gameId: args.gameId,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      playerId: args.playerId!,
    }),
    queryFn: () =>
      SdPrivateApi.getPlayerWallet({
        gameId: args.gameId,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        playerId: args.playerId!,
      }),
    enabled: args.gameId !== undefined && args.playerId !== undefined,
  });
}

export function usePlayerWallets(args: {
  gameId: number;
  playerIds: string[];
}) {
  return useQueries({
    queries: args.playerIds.map((playerId) => ({
      queryKey: playerKeys.playerWallet({ gameId: args.gameId, playerId }),
      queryFn: () =>
        SdPrivateApi.getPlayerWallet({ gameId: args.gameId, playerId }),
      enabled: args.gameId !== undefined,
    })),
  });
}

export function useCreatePlayer() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: SdPrivateApi.createPlayer,
    onSuccess: (data, variables) => {
      // Refresh the players list
      queryClient.invalidateQueries({
        queryKey: playerKeys.players(variables.gameId),
      });
      queryClient.invalidateQueries({
        queryKey: playerKeys.playerCount(variables.gameId),
      });
    },
  });
}

export function useMutatePlayer() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: SdPrivateApi.mutatePlayer,
    onSuccess: (data, variables) => {
      // Refresh the players list
      queryClient.invalidateQueries({
        queryKey: playerKeys.players(variables.gameId),
      });
      queryClient.invalidateQueries({
        queryKey: playerKeys.player(variables),
      });
    },
  });
}

// No onSuccess for this call since it will be followed by a call to
// mutatePlayer which does the cache invalidation. If we did cache
// invalidation here, we end up with extra calls.
export function useDeletePlayerProperty() {
  return useMutation({
    mutationFn: SdPrivateApi.deletePlayerProperty,
  });
}
