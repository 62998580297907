import {
  Breadcrumbs,
  Button,
  CircularProgress,
  Stack,
  Typography,
} from '@mui/material';
import { Game } from '@stardust-monorepo/types/sd-private';
import {
  CreateDocumentation,
  HeavyDialog,
  HeavyDialogActions,
  HeavyDialogContent,
  HeavyDialogTitle,
  track,
  useHandleError,
  useToast,
} from '@stardust-monorepo/web-sdk-apps-shared';
import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { GameForm } from './GameForm';

interface CreateGameModalProps {
  open: boolean;
  onClose: () => void;
  onDelete?: () => void;
  game?: Game;
}

export const CreateEditGameModal = ({
  open,
  onClose,
  onDelete,
  game,
}: CreateGameModalProps) => {
  const submitFormButtonRef = useRef<HTMLButtonElement | null>(null);
  const [loading, setLoading] = useState(false);
  const toaster = useToast();
  const handleError = useHandleError();
  const navigate = useNavigate();

  const doOnClose = () => {
    track(`${game ? 'Edit' : 'Create'} Game Modal Close`, 'Clicked', {
      gameId: game?.id,
    });
    onClose();
  };

  const onSave = (result: { gameId: number } | Error) => {
    const eventName = `${game ? 'Edit' : 'Create'} Game`;
    const trackingData = {
      gameId: game?.id,
    };
    if (result instanceof Error) {
      handleError(
        result,
        `An error occurred while ${game ? 'editing' : 'creating'} the game`,
        eventName,
        trackingData
      );
    } else {
      track(eventName, 'Success', trackingData);
      toaster(`Game ${game ? 'saved' : 'created'} successfully`);
      if (!game) {
        navigate(`/games/${result.gameId}`);
      }
      onClose();
    }
  };

  return (
    <HeavyDialog open={open} onClose={doOnClose} scroll="body">
      <HeavyDialogTitle>
        <Breadcrumbs>
          <Typography variant="body2">Game</Typography>
          <Typography variant="body2" color="text.primary">
            New Game
          </Typography>
        </Breadcrumbs>
      </HeavyDialogTitle>
      <HeavyDialogContent>
        <Stack gap={5}>
          <Typography variant="h3" mt={7}>
            {game ? 'Edit Game' : 'Create New Game'}
          </Typography>
          <Stack direction={'row'} gap={5.5}>
            <GameForm
              submitFormRef={submitFormButtonRef}
              onLoading={(loading) => setLoading(loading)}
              onSave={onSave}
              onDelete={onDelete}
              game={game}
            />
            <CreateDocumentation
              title="Creating a Game"
              description="Follow the steps presented to create your game. You may reference the documentation pages below for more information."
              links={[
                {
                  description: 'Creating Your First Game',
                  href: 'https://docs.stardust.gg/docs/creating-your-first-game',
                },
                {
                  description: 'Selecting a Blockchain',
                  href: 'https://docs.stardust.gg/docs/feature-support-by-blockchain',
                },
                {
                  description: 'Getting an API Key',
                  href: 'https://docs.stardust.gg/docs/creating-your-first-game#getting-your-api-key',
                },
                {
                  description: 'Viewing API Calls & Logs',
                  href: 'https://docs.stardust.gg/docs/creating-your-first-game',
                },
              ]}
            />
          </Stack>
        </Stack>
      </HeavyDialogContent>
      <HeavyDialogActions>
        <Button
          onClick={doOnClose}
          size="large"
          variant="outlined"
          color="secondary"
        >
          Cancel
        </Button>
        <Button
          disabled={loading}
          onClick={() => {
            if (game) {
              track('Modal Edit Game', 'Clicked', { gameId: game.id });
            } else {
              track('Modal Create Game', 'Clicked');
            }
            submitFormButtonRef.current?.click();
          }}
          size="large"
          variant="contained"
        >
          {loading ? (
            <CircularProgress size="18px" color="primary" />
          ) : game ? (
            'Save Changes'
          ) : (
            'Create Game'
          )}
        </Button>
      </HeavyDialogActions>
    </HeavyDialog>
  );
};
