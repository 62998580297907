/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

export const developerAmplifyConfig = {
  aws_project_region: 'us-east-1',
  aws_cognito_identity_pool_id:
    'us-east-1:101bed52-b136-4802-a206-6286405d3929',
  aws_cognito_region: 'us-east-1',
  aws_user_pools_id: 'us-east-1_IKcq9nHnY',
  aws_user_pools_web_client_id: '3kcjncrl4jssmmffe6vvok1alo',
  oauth: {
    domain: 'stardust.auth.us-east-1.amazoncognito.com',
    scope: ['email', 'openid', 'profile', 'aws.cognito.signin.user.admin'],
    responseType: 'code',
    redirectSignIn: 'http://localhost:3000',
    redirectSignOut: 'http://localhost:3000',
  },
  federationTarget: 'COGNITO_USER_POOLS',
  aws_content_delivery_bucket:
    'tardustdminashboa-20201117155748-hostingbucket-amplify',
  aws_content_delivery_bucket_region: 'us-east-1',
  aws_content_delivery_url: 'https://d2guq3aixyussq.cloudfront.net',
};
