import { Typography } from '@mui/material';
import {
  BlockchainNames,
  BlockchainNetworkNames,
  Game,
} from '@stardust-monorepo/types/sd-private';
import { BlockchainIcon } from '@stardust-monorepo/web-sdk-apps-shared';

import { PageHeader } from './PageHeader';

export interface GameTopHeaderProps {
  game?: Game;
}

export const GameTopHeader = ({ game }: GameTopHeaderProps) => {
  return (
    <PageHeader>
      <Typography variant="subtitle1" color="text.hint">
        Games
      </Typography>
      {game ? (
        <>
          <Typography variant="subtitle1" sx={{ color: 'text.hint', px: 1.5 }}>
            /
          </Typography>
          <Typography variant="subtitle1">{game.name}</Typography>
          <Typography variant="body2" sx={{ color: 'text.hint', ml: 1 }}>
            Game ID
          </Typography>
          <Typography
            variant="body2"
            sx={{ color: 'text.secondary', ml: 0.25 }}
          >
            {game.id}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.hint', mx: 1 }}>
            •
          </Typography>
          <BlockchainIcon blockchain={game.blockchain} />
          <Typography variant="body2" sx={{ color: 'text.secondary', mx: 1 }}>
            {BlockchainNames[game.blockchain]}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.hint' }}>
            •
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary', mx: 1 }}>
            {BlockchainNetworkNames[game.blockchain]}
          </Typography>
        </>
      ) : null}
    </PageHeader>
  );
};
