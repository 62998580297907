import {
  Box,
  CircularProgress,
  Paper,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { track } from '@stardust-monorepo/web-sdk-apps-shared';
import React, { useMemo, useState } from 'react';
import { useRecoilState } from 'recoil';

import { useApiCallCount } from '../../hooks/useApiCallCount';
import { useGameStats } from '../../hooks/useGameStats';
import { gameState } from '../../state/game-state';
import { TimeFrame } from '../../types/timeframe';
import { OverviewApiCallsCard } from './OverviewApiCallsCard';
import { PerformanceCards } from './PerformanceCards';
import { TitleSection } from './TitleSection';

export interface OverviewCardProps {
  setShowCreateGame: (show: boolean) => void;
}

export const OverviewCard = ({ setShowCreateGame }: OverviewCardProps) => {
  const [timeFrame, setTimeFrame] = useState<TimeFrame>('week');
  const [games] = useRecoilState(gameState);
  const gameIds = useMemo(() => games.map((game) => game.id), [games]);
  const {
    currentApiCalls,
    priorApiCalls,
    isLoading: isLoadingApiCalls,
  } = useApiCallCount(timeFrame, gameIds, true);
  const {
    currentTotals,
    priorTotals,
    isLoading: isLoadingStats,
  } = useGameStats(timeFrame, gameIds, true);

  return (
    <Paper
      elevation={1}
      sx={{
        padding: 2,
        backgroundColor: 'background.white',
        width: '100%',
        borderRadius: 3,
      }}
    >
      <TitleSection setShowCreateGame={setShowCreateGame} />
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Typography variant="subtitle2" sx={{ pt: 3, pb: 2.5 }}>
          Account Stats
        </Typography>
        <Stack direction="row" spacing={1.5} sx={{ alignItems: 'center' }}>
          {isLoadingStats || isLoadingApiCalls ? (
            <CircularProgress size="18px" color="primary" />
          ) : null}
          <ToggleButtonGroup
            sx={(theme) => ({
              backgroundColor: theme.palette.background.white,
            })}
            exclusive
            value={timeFrame}
            onChange={(e, value) => {
              track(`Home Toggle Button Group ${value}`, 'Clicked');
              setTimeFrame(value);
            }}
          >
            <ToggleButton value="week">Week</ToggleButton>
            <ToggleButton value="month">Month</ToggleButton>
            <ToggleButton value="quarter">Quarter</ToggleButton>
          </ToggleButtonGroup>
        </Stack>
      </Box>
      <Box sx={{ display: 'flex', gap: 1.5 }}>
        <PerformanceCards
          currentTotals={currentTotals}
          priorTotals={priorTotals}
        />
        <OverviewApiCallsCard
          currentApiCalls={currentApiCalls}
          priorApiCalls={priorApiCalls}
        />
      </Box>
    </Paper>
  );
};
