import { Autocomplete, CircularProgress, TextField } from '@mui/material';
import { Player } from '@stardust-monorepo/types/sd-private';
import { SdPrivateApi } from '@stardust-monorepo/web-sdk-apps-shared';
import React, { useState } from 'react';

export interface PlayerSearchProps {
  disabled?: boolean;
  gameId: number;
  value: Player | null;
  onChange: (t: Player | null) => void;
}

export const PlayerSearch = ({
  disabled,
  gameId,
  value,
  onChange,
}: PlayerSearchProps) => {
  const [playerFilter, setPlayerFilter] = useState('');
  const { data: players, isLoading } = SdPrivateApi.usePlayers(
    {
      gameId: gameId,
      start: 0,
      limit: 20,
      filter: playerFilter,
    },
    !!gameId
  );

  return (
    <Autocomplete
      id="player-search"
      disabled={disabled}
      loading={isLoading}
      value={value ? { label: value.uniqueId, player: value } : null}
      onChange={(event, newValue) => {
        if (newValue) {
          setPlayerFilter('');
        }
        onChange(newValue?.player || null);
      }}
      inputValue={playerFilter}
      onInputChange={(event, newInputValue) => {
        setPlayerFilter(newInputValue);
      }}
      isOptionEqualToValue={(o, t) => o.player.uniqueId === t.player.uniqueId}
      options={(players || []).map((p) => ({
        label: p.uniqueId,
        player: p,
      }))}
      renderInput={(params) => (
        <TextField
          {...params}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isLoading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      size="small"
      sx={{
        width: '400px',
        borderRadius: 2,
        backgroundColor: 'white',
        '.MuiAutocomplete-inputRoot': { borderRadius: 2 },
      }}
    />
  );
};
