import Auth from '@aws-amplify/auth';
import { zodResolver } from '@hookform/resolvers/zod';
import { ArrowForwardRounded } from '@mui/icons-material';
import {
  Button,
  Divider,
  FormHelperText,
  Link,
  Stack,
  Typography,
  FormControl,
  Alert,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import {
  DetailedCognitoUser,
  Input,
  InputLabel,
  FederatedLoginButtons,
} from '@stardust-monorepo/web-sdk-apps-shared';
import { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
  Link as RouterLink,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { z } from 'zod';

import { userState, selectUserStateLoginError } from '../../state/user-state';
import { passwordResetSuccessParam } from './forgot-password/ForgotPassword';

export const loginFormMaxWidth = '396px';

const formControls = {
  email: 'email',
  password: 'password',
} as const;

const formSchema = z
  .object({
    [formControls.email]: z.string().email(),
    [formControls.password]: z.string().min(1, 'Password is required'),
  })
  .required();

export const LoginForm = ({
  onLogin,
  onSocialLogin,
}: {
  onLogin?: () => void;
  onSocialLogin?: () => void;
}) => {
  const [user, setUser] = useRecoilState(userState);
  const loginError = useRecoilValue(selectUserStateLoginError);

  const [params] = useSearchParams();
  const [invalidSubmissionError, setInvalidSubmissionError] =
    useState<boolean>(false);
  const [signingIn, setSigningIn] = useState(false);
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      [formControls.email]: '',
      [formControls.password]: '',
    },
    resolver: zodResolver(formSchema),
  });
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const subscription = watch(() => setInvalidSubmissionError(false));
    return () => subscription.unsubscribe();
  }, [watch]);

  const signIn = async (email: string, password: string) => {
    setSigningIn(true);
    return await Auth.signIn(email, password)
      .then((cognitoUser: DetailedCognitoUser) => {
        setUser({
          ...user,
          user: cognitoUser,
        });
        onLogin?.();
        setSigningIn(false);
        navigate('..');
      })
      .catch((error) => {
        setSigningIn(false);
        if (
          error.name?.includes('NotAuthorizedException') ||
          error.name?.includes('UserNotFoundException')
        ) {
          setInvalidSubmissionError(true);
          setUser({
            ...user,
            user: null,
          });
        }
      });
  };

  return (
    <Stack
      component="form"
      data-testid="login-form"
      sx={{
        maxWidth: loginFormMaxWidth,
        width: '100%',
      }}
      onSubmit={handleSubmit((data) => {
        signIn(data[formControls.email], data[formControls.password]);
      })}
    >
      {params.get(passwordResetSuccessParam) && (
        <Alert
          severity="success"
          sx={{
            marginBottom: 3,
          }}
        >
          Password reset successfully.
        </Alert>
      )}
      {!isMobile && (
        <>
          <Typography variant={'h4'}>Welcome back to Stardust!</Typography>
          <Typography
            variant={'body1'}
            marginBottom={4}
            marginTop={1}
            sx={(theme) => ({
              color: theme.palette.text.secondary,
            })}
          >
            Sign in to get started.
          </Typography>
        </>
      )}
      <FederatedLoginButtons onSocialLogin={onSocialLogin} />
      <Divider
        sx={{
          mt: 4,
          mb: 2.5,
        }}
      >
        <Typography
          variant={'overline'}
          sx={(theme) => ({
            color: theme.palette.text.hint,
          })}
        >
          OR
        </Typography>
      </Divider>
      <Controller
        control={control}
        name={formControls.email}
        render={({ field: { ref, ...fieldProps } }) => (
          <FormControl
            margin={'normal'}
            error={invalidSubmissionError || !!errors[formControls.email]}
          >
            <>
              <InputLabel htmlFor={formControls.email}>
                Email Address
              </InputLabel>
              <Input
                {...fieldProps}
                autoComplete="username"
                inputRef={ref}
                id={formControls.email}
                fullWidth
              />
              {errors[formControls.email] && (
                <FormHelperText>
                  {errors[formControls.email]?.message}
                </FormHelperText>
              )}
            </>
          </FormControl>
        )}
      />
      <Controller
        control={control}
        name={formControls.password}
        render={({ field: { ref, ...fieldProps } }) => (
          <FormControl
            margin={'normal'}
            error={invalidSubmissionError || !!errors[formControls.password]}
          >
            <>
              <InputLabel htmlFor={formControls.password}>Password</InputLabel>
              <Input
                {...fieldProps}
                autoComplete="current-password"
                type="password"
                inputRef={ref}
                id={formControls.password}
                fullWidth
              />
              {errors[formControls.password] && (
                <FormHelperText>
                  {errors[formControls.password]?.message}
                </FormHelperText>
              )}
              {invalidSubmissionError ? (
                <FormHelperText>
                  Invalid password and email combination
                </FormHelperText>
              ) : null}
            </>
          </FormControl>
        )}
      />
      <Button
        type={'submit'}
        disabled={signingIn}
        variant="contained"
        size="large"
        endIcon={<ArrowForwardRounded />}
        sx={{
          marginTop: 4,
        }}
      >
        Sign in
      </Button>
      {loginError && (
        <FormHelperText error>
          An unknown error occurred while signing in. Please try again or use a
          different method.
        </FormHelperText>
      )}
      <Stack
        direction={'row'}
        sx={{
          alignItems: 'center',
          justifyContent: 'space-between',
          marginTop: 4,
        }}
      >
        <Link component={RouterLink} to="./forgot-password" variant={'caption'}>
          Forgot password?
        </Link>
        <Stack direction={'row'} gap={0.5} sx={{ alignItems: 'center' }}>
          <Typography
            variant={'helperText'}
            sx={(theme) => ({
              color: theme.palette.text.secondary,
            })}
          >
            New to Stardust?
          </Typography>
          <Link
            component={RouterLink}
            to="./register"
            variant={'caption'}
            sx={{
              color: theme.palette.text.link,
            }}
          >
            Sign up.
          </Link>
        </Stack>
      </Stack>
    </Stack>
  );
};
