import { Box, Button, Link as MuiLink, Stack, Typography } from '@mui/material';
import {
  Icon as PhosphorIcon,
  ArrowSquareOut,
  Sparkle,
  ArrowSquareRight,
} from '@phosphor-icons/react';
import { Collection, CollectionType } from '@stardust-monorepo/types/sd-core';
import {
  formatAddress,
  formatBlockchainExplorerUrl,
  InfoItem,
} from '@stardust-monorepo/web-sdk-apps-shared';
import React, { PropsWithChildren, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { CreateItemModal } from './items/CreateItemModal';
import { collectionTypeToDisplayString } from './util';

interface SuccessItem {
  link: string;
  title: string;
  Icon: PhosphorIcon;
}

const SuccessRowLinkWrapper = ({
  children,
  link,
}: PropsWithChildren<{ link: string }>) => {
  return (
    <MuiLink
      target="_blank"
      rel="noopener"
      href={link}
      sx={{ '&:hover': { textDecoration: 'none' } }}
    >
      {children}
    </MuiLink>
  );
};

const SuccessRow = ({
  title,
  Icon,
  onClick,
}: Omit<SuccessItem, 'link'> & { onClick?: () => void }) => {
  const [hover, setHover] = useState(false);

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        py: 3,
        borderTop: '1px solid',
        borderColor: 'divider',
        color: 'text.primary',
        cursor: 'pointer',
      }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={() => onClick?.()}
    >
      <Stack spacing={0.5}>
        <Typography
          variant="subtitle1"
          color={hover ? 'primary.main' : 'text.primary'}
        >
          {title}
        </Typography>
      </Stack>
      <Icon size={24} />
    </Box>
  );
};

export interface CreateCollectionSuccessProps {
  collectionType: CollectionType | null;
  collection: Collection;
  gameId: number;
}

export const CreateCollectionSuccess = ({
  collectionType,
  collection,
  gameId,
}: CreateCollectionSuccessProps) => {
  const [createItem, setCreateItem] = useState<boolean>(false);
  const collectionDetailPath = `/games/${gameId}/collections/${collection?.id}`;
  const navigate = useNavigate();

  return (
    <>
      <CreateItemModal
        gameId={gameId}
        collection={collection}
        open={createItem}
        onClose={() => navigate(collectionDetailPath)}
        sourcePage="Create Collection Success"
      />

      <Stack sx={{ width: '100%', alignItems: 'center' }}>
        <Stack sx={{ maxWidth: '575px', mt: 7 }}>
          <Box sx={{ color: 'primary.main' }}>
            <Sparkle size={44} weight="duotone" />
          </Box>
          <Typography variant="h3" sx={{ my: 2 }}>
            Your Collection has been successfully created.
          </Typography>
          <Stack direction="row" spacing={2}>
            <InfoItem
              label="Type"
              value={`${
                collectionType
                  ? collectionTypeToDisplayString(collectionType)
                  : ''
              }`}
            />
            {collection?.address && (
              <InfoItem
                label="Contract Address"
                value={formatAddress(collection.address)}
              />
            )}
          </Stack>
          <Typography
            variant="body1"
            sx={{ color: 'text.secondary', mt: 5, mb: 2 }}
          >
            What would you like to do next?
          </Typography>
          <SuccessRow
            title="Start creating items for this collection."
            Icon={ArrowSquareRight}
            onClick={() => setCreateItem(true)}
          />
          <SuccessRowLinkWrapper
            link={formatBlockchainExplorerUrl(
              collection.blockchainId,
              collection.address
            )}
          >
            <SuccessRow
              title="View collection contract on blockchain explorer."
              Icon={ArrowSquareOut}
            />
          </SuccessRowLinkWrapper>
          <SuccessRowLinkWrapper link="https://docs.stardust.gg/docs/erc721">
            <SuccessRow
              title="View Stardust documentation on Collections."
              Icon={ArrowSquareOut}
            />
          </SuccessRowLinkWrapper>
          <Box sx={{ borderTop: '1px solid', borderColor: 'divider', pt: 5 }}>
            <Link to={collectionDetailPath}>
              <Button
                size="large"
                variant="outlined"
                color="secondary"
                onClick={() => null}
              >
                Exit to Collection Detail
              </Button>
            </Link>
          </Box>
        </Stack>
      </Stack>
    </>
  );
};
