import { paginatedResultsWithFilterSchema } from '@stardust-monorepo/types/common';
import { z } from 'zod';

export const UsageSchema = z
  .object({
    rootUserId: z.string(),
    key: z.enum([
      'wallet_creates',
      'message_signatures',
      'transaction_signatures',
    ]),
    value: z.string(), //int64
  })
  .describe('UsageSchema');
export type Usage = z.infer<typeof UsageSchema>;

export const UsageResultsSchema =
  paginatedResultsWithFilterSchema(UsageSchema).describe('UsageResultsSchema');
export type UsageResults = z.infer<typeof UsageResultsSchema>;
