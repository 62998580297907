import { SdPrivateApi } from '@stardust-monorepo/web-sdk-apps-shared';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import {
  gameKeys,
  useGame as baseUseGame,
  useGames as baseUseGames,
} from '../../hooks/useGame';
import { getGame, getGames } from '../game-api';

export function useGame(
  gameId: string | number | undefined,
  enabled?: boolean
) {
  return baseUseGame(gameId, getGame, enabled);
}

export function useGames(enabled?: boolean) {
  return baseUseGames(getGames, enabled);
}

export function useCreateGame() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: SdPrivateApi.createGame,
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({
        queryKey: gameKeys.games(),
      });
    },
  });
}

export function useMutateGame() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: SdPrivateApi.mutateGame,
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({
        queryKey: gameKeys.games(),
      });
      queryClient.invalidateQueries({
        queryKey: gameKeys.game(variables.gameId),
      });
    },
  });
}

export function useDeleteGame() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: SdPrivateApi.deleteGame,
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({
        queryKey: gameKeys.games(),
      });
      queryClient.invalidateQueries({
        queryKey: gameKeys.game(variables.gameId),
      });
    },
  });
}
