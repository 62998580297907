import { Box, Card, Link, Paper, Stack, Typography } from '@mui/material';
import { ArrowSquareOut } from '@phosphor-icons/react';
import React, { useState } from 'react';

interface BlogItem {
  link: string;
  image: string;
  title: string;
}

const featuredPost: BlogItem = {
  link: 'https://www.stardust.gg/blog/stardust-adds-avalanche',
  image:
    'https://cms.stardust.gg/wp-content/uploads/2023/01/stardust-avax.webp',
  title:
    'Stardust Adds Avalanche, Providing Powerful New Scaling Solutions for Game Developers',
};

const blogItems: BlogItem[] = [
  {
    link: 'https://www.stardust.gg/blog/5-best-client-experience-tactics-web3-can-learn-from-web2',
    image:
      'https://cms.stardust.gg/wp-content/uploads/2023/01/Customer-Experience-Tactics.png',
    title: '5 Best Client Experience Tactics Web3 can learn from Web2',
  },
  {
    link: 'https://www.stardust.gg/blog/how-pwrup-is-creating-community',
    image:
      'https://cms.stardust.gg/wp-content/uploads/2023/01/How-Power-Up-is-Creating-Community.png',
    title: 'How PWRUP is Creating Community',
  },
  {
    link: 'https://www.stardust.gg/blog/creating-strong-candidate-interview-experiences-in-web3',
    image:
      'https://cms.stardust.gg/wp-content/uploads/2023/01/candidate-interview-experience-web3.png',
    title: 'Creating Strong Candidate Interview Experiences in Web3',
  },
  {
    link: 'https://www.stardust.gg/blog/how-to-scale-with-stardust-through-frictionless-user-onboarding',
    image:
      'https://cms.stardust.gg/wp-content/uploads/2023/01/Stardust-Use-Case.png',
    title: 'How to Scale with Stardust through Frictionless User Onboarding',
  },
  {
    link: 'https://www.stardust.gg/blog/wallet-guide-from-onboarding-to-ownership',
    image:
      'https://cms.stardust.gg/wp-content/uploads/2022/10/wallet-transfer-guide.png',
    title: 'Wallet Guide: From Onboarding to Ownership',
  },
];

interface BlogRowProps {
  item: BlogItem;
}

const BlogRow = ({ item }: BlogRowProps) => {
  const [hover, setHover] = useState(false);

  return (
    <Link
      target="_blank"
      rel="noopener"
      href={item.link}
      sx={{ '&:hover': { textDecoration: 'none' } }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          py: 2,
        }}
      >
        <Box
          sx={{
            height: '40px',
            width: '60px',
            backgroundImage: `url(${item.image})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            borderRadius: 2,
            mr: 1.5,
            flexShrink: 0,
          }}
        />
        <Typography
          variant="subtitle2"
          color={hover ? 'primary.main' : 'text.primary'}
        >
          {item.title}
        </Typography>
      </Box>
    </Link>
  );
};

export const BlogCard = () => (
  <Paper
    elevation={1}
    sx={{
      padding: 2,
      backgroundColor: 'background.white',
      width: '40%',
      borderRadius: 3,
    }}
  >
    <Stack direction="row" spacing={0.5}>
      <Typography variant="subtitle2">Stardust Blog</Typography>
      <Link
        target="_blank"
        rel="noopener"
        href="https://stardust.gg/blog"
        sx={{ color: 'text.hint' }}
      >
        <ArrowSquareOut size={18} />
      </Link>
    </Stack>
    <Card
      variant="outlined"
      sx={{
        borderRadius: 2,
        mt: 1,
      }}
    >
      <Link
        target="_blank"
        rel="noopener"
        href={featuredPost.link}
        sx={{ '&:hover': { textDecoration: 'none' } }}
      >
        <Stack
          spacing={1.5}
          sx={{
            py: 2,
            px: 2,
            backgroundColor: 'background.cool',
            borderBottom: '1px solid',
            borderColor: 'divider',
          }}
        >
          <Typography variant="overline" color="secondary.main">
            Featured Post
          </Typography>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Box
              sx={{
                height: '80px',
                width: '120px',
                backgroundImage: `url(${featuredPost.image})`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                borderRadius: 2,
                mr: 1.5,
                flexShrink: 0,
              }}
            />
            <Typography variant="subtitle2" color="text.primary">
              {featuredPost.title}
            </Typography>
          </Box>
        </Stack>
      </Link>
      <Box sx={{ px: 2 }}>
        {blogItems.map((blogItem, index) => (
          <BlogRow item={blogItem} key={index} />
        ))}
      </Box>
    </Card>
  </Paper>
);
