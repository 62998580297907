import { zodResolver } from '@hookform/resolvers/zod';
import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  Stack,
  Typography,
} from '@mui/material';
import { TokenType } from '@stardust-monorepo/types/marketplace';
import { PlayerInventoryItem } from '@stardust-monorepo/types/sd-private';
import {
  BasicDialog,
  FormField,
  SdPrivateApi,
  track,
  useHandleError,
  useToast,
} from '@stardust-monorepo/web-sdk-apps-shared';
import { useRef } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

export const formControls = {
  amount: 'amount',
} as const;

const formSchema = (existingAmount: number) =>
  z
    .object({
      [formControls.amount]: z.preprocess(
        (amount) =>
          typeof amount === 'string'
            ? parseInt(z.string().parse(amount || '0'))
            : amount,
        z
          .number()
          .gte(1, 'Amount must be at least 1')
          .and(
            z
              .number()
              .lte(
                existingAmount,
                `Amount cannot be more than ${existingAmount}`
              )
          )
      ),
    })
    .required();

interface FormValues {
  amount: number | string;
}

export interface TokenBurnModalProps {
  gameId: number;
  open: boolean;
  onClose: () => void;
  playerId: string;
  item: PlayerInventoryItem;
  tokenType: TokenType;
}

export const TokenBurnModal = ({
  gameId,
  open,
  onClose,
  playerId,
  item,
  tokenType,
}: TokenBurnModalProps) => {
  const submitFormButtonRef = useRef<HTMLButtonElement | null>(null);
  const toaster = useToast();
  const handleError = useHandleError();
  const burnToken = SdPrivateApi.useBurnToken();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      [formControls.amount]: 1,
    },
    resolver: zodResolver(formSchema(Number(item.amount))),
  });

  const doBurnToken = async (formValues: FormValues) => {
    const trackingData = {
      gameId,
      playerId,
      tokenId: item.tokenId,
    };
    try {
      await burnToken.mutateAsync({
        gameId,
        playerId,
        amount: String(formValues.amount),
        tokenId: item.tokenId,
        templateId: item.templateId,
      });
      track('Burn Token', 'Success', trackingData);
      toaster(`${item.name} successfully burned`);
      onClose();
    } catch (e) {
      handleError(
        e,
        'An error occurred while burning',
        'Burn Token',
        trackingData
      );
    }
  };

  return (
    <BasicDialog open={open} onClose={onClose}>
      <DialogTitle>Burn Token </DialogTitle>
      <DialogContent sx={{ width: '400px' }}>
        <Stack
          component="form"
          sx={{ flexGrow: 1 }}
          onSubmit={handleSubmit((data) => {
            doBurnToken(data);
          })}
        >
          <Typography variant="body1" color="text.secondary">
            {tokenType === 'NFT'
              ? `Are you sure you wish to burn token ${item.name} (ID ${item.tokenId})? This action can not be undone.`
              : `Enter the number of tokens you wish to burn for ${item.name} (ID ${item.tokenId}). This action cannot be undone.`}
          </Typography>
          {tokenType === 'FT' ? (
            <FormField
              formControlName={formControls.amount}
              control={control}
              inputSx={{ maxWidth: '120px' }}
              label="Amount"
              type="number"
              hasError={!!errors[formControls.amount]}
              renderHelpText={() =>
                errors[formControls.amount] && (
                  <FormHelperText>
                    {errors[formControls.amount]?.message}
                  </FormHelperText>
                )
              }
            />
          ) : null}
          <button ref={submitFormButtonRef} style={{ display: 'none' }} />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={burnToken.isLoading}
          onClick={onClose}
          variant="outlined"
          color="secondary"
        >
          Cancel
        </Button>
        <Button
          type="submit"
          disabled={burnToken.isLoading}
          autoFocus
          color="error"
          variant="contained"
          onClick={() => {
            track('Confirm Burn Token ', 'Clicked', {
              gameId,
              playerId,
              tokenId: item.tokenId,
            });
            submitFormButtonRef.current?.click();
          }}
        >
          {burnToken.isLoading ? (
            <CircularProgress size="18px" color="primary" />
          ) : (
            'Burn'
          )}
        </Button>
      </DialogActions>
    </BasicDialog>
  );
};
