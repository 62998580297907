import { Stack } from '@mui/material';
import { LoggedInResetPassword } from '@stardust-monorepo/web-sdk-apps-shared';

export const SettingsPage = () => {
  return (
    <Stack sx={{ p: 2 }}>
      <LoggedInResetPassword />
    </Stack>
  );
};
