import { Box, Button, Checkbox, Stack, Typography } from '@mui/material';
import { Policy } from '@stardust-monorepo/types/sd-private';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  SdPrivateApi,
  userState,
  useTrack,
} from '@stardust-monorepo/web-sdk-apps-shared';
import { Auth } from 'aws-amplify';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';

import { PrivacyPolicy } from '../components/sd-policies/PrivacyPolicy';
import { TermsOfService } from '../components/sd-policies/TermsOfService';

const contentWidth = '768px' as const;

export const SdPolicies = ({ policies }: { policies: Policy[] }) => {
  const [user, setUser] = useRecoilState(userState);
  const [acceptChecked, setAcceptChecked] = useState(false);
  const [error, setError] = useState<unknown>();
  const navigate = useNavigate();

  if (!user) {
    navigate('../login');
  }

  useTrack('Policies');

  const submitAcceptance = async () => {
    if (user) {
      return await SdPrivateApi.createPolicyHistory(
        //this should always be defined
        //eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        user.user!.attributes.sub,
        policies.map((policy) => policy.id)
      )
        .then(() => {
          setUser({
            ...user,
            unacceptedPolicies: [],
          });
          navigate('../');
        })
        .catch((err) => {
          setError(err);
        });
    }
  };

  return (
    <Stack
      sx={{
        height: '100%',
        width: '100%',
        overflow: 'hidden',
      }}
    >
      <Stack
        sx={{
          paddingLeft: 10,
          paddingBottom: 8,
          height: '100%',
          overflow: 'auto',
        }}
      >
        <Box
          sx={{
            my: 5,
          }}
        >
          <img alt="Stardust Logo" width={200} src="/assets/logo.svg" />
        </Box>
        <Typography
          variant={'h2'}
          sx={{
            my: 3,
            maxWidth: contentWidth,
          }}
        >
          Updates to Terms of Service and Privacy Policy
        </Typography>
        <Typography
          variant={'h6'}
          sx={(theme) => ({
            my: 3,
            color: theme.palette.text.secondary,
          })}
        >
          Updated October 13, 2022
        </Typography>
        <Stack gap={1}>
          <Accordion sx={{ maxWidth: contentWidth }}>
            <AccordionSummary
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography variant="subtitle2">Terms of Service</Typography>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                padding: 4,
              }}
            >
              <TermsOfService />
            </AccordionDetails>
          </Accordion>
          <Accordion sx={{ maxWidth: contentWidth }}>
            <AccordionSummary
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography variant="subtitle2">Privacy Policy</Typography>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                padding: 4,
              }}
            >
              <PrivacyPolicy />
            </AccordionDetails>
          </Accordion>
        </Stack>
      </Stack>
      <Box
        sx={(theme) => ({
          zIndex: 999,
          boxShadow: theme.shadows[3],
          backgroundColor: theme.palette.background.default,
          width: '100%',
          paddingLeft: 10,
        })}
      >
        <Stack
          gap={3}
          sx={(theme) => ({
            paddingTop: 3,
            paddingBottom: 4,
            width: '100%',
            maxWidth: contentWidth,
          })}
        >
          <>
            {error && (
              <Typography color={'error'} variant={'body2'}>
                An error has occurred while submitting policy acceptance.
              </Typography>
            )}
            <Stack direction={'row'} gap={1.5}>
              <Checkbox
                sx={{
                  padding: '0',
                }}
                onChange={(event) => {
                  setAcceptChecked(event.target.checked);
                }}
                inputProps={{
                  'aria-label':
                    'Stardust Terms of Service and Privacy Policy Confirmation',
                }}
              />
              <Typography
                variant={'body2'}
                sx={{
                  maxWidth: '548px',
                }}
              >
                I confirm that I am at least 13 years of age and have read and
                accept the Stardust Terms of Service and Privacy Policy
              </Typography>
            </Stack>
            <Stack
              direction="row"
              sx={{
                justifyContent: 'space-between',
                maxWidth: contentWidth, //copied from stardust.gg to align with the text
              }}
            >
              <Button
                variant={'outlined'}
                color={'secondary'}
                size={'large'}
                onClick={() => {
                  Auth.signOut();
                  setUser({
                    ...user,
                    user: null,
                  });
                  window.location.href = 'https://www.stardust.gg';
                }}
              >
                Cancel
              </Button>
              <Button
                color={'primary'}
                size={'large'}
                variant={'contained'}
                disabled={!acceptChecked}
                onClick={() => submitAcceptance()}
                sx={{
                  minWidth: '200px',
                }}
              >
                Accept
              </Button>
            </Stack>
          </>
        </Stack>
      </Box>
    </Stack>
  );
};
