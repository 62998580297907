import { Box, Button, Link, Typography } from '@mui/material';
import { HandCoins, PaperPlaneTilt, PencilSimple } from '@phosphor-icons/react';
import { Collection, Item } from '@stardust-monorepo/types/sd-core';
import {
  ContainedTable,
  EmptyContainer,
  MoreActions,
  Spinner,
  track,
  UnlimitedTemplateCap,
  ValidatedImage,
} from '@stardust-monorepo/web-sdk-apps-shared';
import React, { useMemo, useState } from 'react';

import { AddItemBalance } from '../item-balance/AddItemBalance';
import { TransferItemBalance } from '../item-balance/TransferItemBalance';
import { CreateItemModal } from './CreateItemModal';
import { ItemDetailsModal } from './ItemDetailsModal';

const headings = [
  { width: '72px', label: '' },
  { label: 'Name' },
  { label: 'Item ID' },
  { label: 'Description' },
  { label: 'Current Supply' },
  { label: 'Total Supply' },
  { width: '40px', label: '' },
];

export const ItemTable = ({
  items,
  collection,
  error,
  isLoading,
}: {
  items?: Item[];
  collection: Collection;
  isLoading: boolean;
  error?: Error;
}) => {
  const [currentItemDetails, setCurrentItemDetails] = useState<Item | null>(
    null
  );
  const [creatingItem, setCreatingItem] = useState<boolean>(false);
  const [editingItem, setEditingItem] = useState<Item | null>(null);
  const [addingItemBalance, setAddingItemBalance] = useState<Item | null>(null);
  const [transferringItemBalance, setTransferringItemBalance] =
    useState<Item | null>(null);

  const rows = useMemo(
    () =>
      (items || []).map((item) => {
        return [
          <ValidatedImage size="40px" image={item.image} />,
          <Link
            variant="subtitle2"
            onClick={() => setCurrentItemDetails(item)}
            sx={{
              cursor: 'pointer',
            }}
          >
            {item.name}
          </Link>, //should this be routed in some way?
          item.id,
          <Typography sx={{ overflowWrap: 'anywhere' }} variant="body2">
            {item.description}
          </Typography>,
          item.currentSupply,
          item.totalSupply === UnlimitedTemplateCap
            ? 'Unlimited'
            : item.totalSupply,
          <MoreActions
            actions={[
              {
                icon: <PencilSimple weight="duotone" />,
                label: 'Edit',
                onClick: () => {
                  track('ItemTable Edit Item', 'Clicked', {
                    gameId: collection.gameId,
                    collectionId: collection.id,
                    itemId: item.id,
                  });
                  setEditingItem(item);
                },
              },
              {
                icon: <HandCoins weight="duotone" />,
                label: 'Add Balance',
                onClick: () => {
                  track('ItemTable Add Balance', 'Clicked', {
                    gameId: collection.gameId,
                    collectionId: collection.id,
                    itemId: item.id,
                  });
                  setAddingItemBalance(item);
                },
                disabledTooltip:
                  item.currentSupply === item.totalSupply
                    ? 'Total supply reached'
                    : '',
              },
              {
                icon: <PaperPlaneTilt weight="duotone" />,
                label: 'Transfer Balance',
                onClick: () => {
                  track('ItemTable Transfer Balance', 'Clicked', {
                    gameId: collection.gameId,
                    collectionId: collection.id,
                    itemId: item.id,
                  });
                  setTransferringItemBalance(item);
                },
                disabledTooltip:
                  item.currentSupply === '0' ? 'No item owners' : '',
              },
            ]}
          />,
        ];
      }),
    [items]
  );

  if (!items) {
    return error ? (
      <Typography sx={{ color: 'text.error' }}>
        <>
          An error occurred while loading the items list:
          {(error as Error).message}
        </>
      </Typography>
    ) : (
      <Spinner />
    );
  }

  return (
    <>
      {currentItemDetails && (
        <ItemDetailsModal
          itemId={currentItemDetails.id}
          open={!!currentItemDetails}
          onClose={() => setCurrentItemDetails(null)}
          sourcePage={collection.name}
        />
      )}
      {editingItem && (
        <ItemDetailsModal
          itemId={editingItem.id}
          open={!!editingItem}
          onClose={() => setEditingItem(null)}
          sourcePage={collection.name}
          edit={true}
        />
      )}
      {addingItemBalance && (
        <AddItemBalance
          collectionId={collection.id}
          gameId={collection.gameId}
          item={addingItemBalance}
          open={!!addingItemBalance}
          lockItems={true}
          onClose={() => setAddingItemBalance(null)}
        />
      )}
      {transferringItemBalance && (
        <TransferItemBalance
          collectionId={collection.id}
          gameId={collection.gameId}
          item={transferringItemBalance}
          open={!!transferringItemBalance}
          lockItems={true}
          onClose={() => setTransferringItemBalance(null)}
        />
      )}
      <CreateItemModal
        gameId={collection.gameId}
        collection={collection}
        open={creatingItem}
        onClose={() => setCreatingItem(false)}
        sourcePage={collection.name}
      />
      <ContainedTable
        count={rows.length}
        page={0}
        type="Items"
        headings={headings}
        rows={rows}
        rowBorder={true}
        isLoading={isLoading}
        emptyScreen={
          <EmptyContainer
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              pt: 6.25,
            }}
          >
            <Box sx={{ color: 'primary.main', mb: 2, display: 'flex' }}>
              <HandCoins size={32} weight="duotone" />
            </Box>
            <Typography variant="subtitle1">No Items to Display</Typography>
            <Typography
              variant="body2"
              sx={{ color: 'text.secondary', mt: 1, mb: 2 }}
            >
              Items will be listed here after they are minted.
            </Typography>
            <Button
              variant="contained"
              onClick={() => {
                setCreatingItem(true);
                track('Empty Items Create New', 'Clicked', {
                  gameId: collection.gameId,
                  collectionId: collection.id,
                });
              }}
            >
              Define Item
            </Button>
          </EmptyContainer>
        }
      />
    </>
  );
};
