import { Purchasable } from '@stardust-monorepo/types/sd-private';
import { SdPrivateApi } from '@stardust-monorepo/web-sdk-apps-shared';
import {
  useMutation,
  useQueries,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';

import { PurchasableSaleHistoryArgs } from '../purchasable-api';

export const purchasableKeys = {
  purchasables: (gameId: number) => ['purchasables', { gameId }],
  purchasableSaleHistory: (
    gameId: number,
    start?: number,
    limit?: number,
    puchasableId?: number
  ) => [
    'purchasableSaleHistory',
    { puchasableId },
    {
      gameId,
    },
    ...(start || limit ? [{ start, limit }] : []),
  ],
  purchasableSaleHistorySummary: (gameId: number) => [
    'purchasableSaleHistorySummary',
    {
      gameId,
    },
  ],
  purchasableItems: (gameId: number, purchasableId: number) => [
    'purchasables',
    {
      gameId,
      purchasableId,
    },
  ],
};

export function usePurchasables(
  args: {
    gameId: number | undefined;
  },
  enabled = true
) {
  return useQuery({
    queryKey: purchasableKeys.purchasables(
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      args.gameId!
    ),
    queryFn: () =>
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      SdPrivateApi.getPurchasables(args.gameId!),
    enabled: enabled && args.gameId !== undefined,
  });
}

export function usePurchasableSaleHistorySummary(
  args: {
    gameId: number | undefined;
  },
  enabled = true
) {
  return useQuery({
    queryKey: purchasableKeys.purchasableSaleHistorySummary(
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      args.gameId!
    ),
    queryFn: () =>
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      SdPrivateApi.getPurchasableSaleHistorySummary(args.gameId!),
    enabled: enabled && args.gameId !== undefined,
  });
}

export function usePurchasableSaleHistory(
  args: PurchasableSaleHistoryArgs,
  enabled = true
) {
  return useQuery({
    queryKey: purchasableKeys.purchasableSaleHistory(
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      args.gameId!,
      args.start,
      args.limit,
      args.purchasableId
    ),
    queryFn: () => SdPrivateApi.getPurchasableSaleHistory(args),
    enabled: enabled && args.gameId !== undefined,
  });
}

export function useTogglePurchasableActive() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: SdPrivateApi.togglePurchasableActive,
    onSuccess: (data, variables) => {
      // Refresh the purchasables so we can get the new activeListing status
      queryClient.invalidateQueries({
        queryKey: purchasableKeys.purchasables(variables.gameId),
      });
    },
  });
}

export function useDeletePurchasable() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: SdPrivateApi.deletePurchasable,
    onSuccess: (data, variables) => {
      // Refresh the purchasables
      queryClient.invalidateQueries({
        queryKey: purchasableKeys.purchasables(variables.gameId),
      });
    },
  });
}

export function usePurchasablesItems(args: {
  gameId: number | undefined;
  purchasables: Purchasable[];
}) {
  return useQueries({
    queries: args.purchasables.map((p) => ({
      queryKey: purchasableKeys.purchasableItems(
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        args.gameId!,
        p.id
      ),
      queryFn: () =>
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        SdPrivateApi.getPurchasableItems(args.gameId!, p.id),
      enabled: args.gameId !== undefined,
    })),
  });
}

export function useCreatePurchasable() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: SdPrivateApi.createPurchasable,
    onSuccess: (data, variables) => {
      // Refresh the purchasables
      queryClient.invalidateQueries({
        queryKey: purchasableKeys.purchasables(variables.gameId),
      });
    },
  });
}

export function useMutatePurchasable() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: SdPrivateApi.mutatePurchasable,
    onSuccess: (data, variables) => {
      // Refresh the purchasables
      queryClient.invalidateQueries({
        queryKey: purchasableKeys.purchasables(variables.gameId),
      });
    },
  });
}

// No onSuccess for these calls since they will be followed by a call to
// mutatePurchasable which does the cache invalidation. If we did cache
// invalidation for all, we end up with many calls.
export function useCreatePurchasableItem() {
  return useMutation({
    mutationFn: SdPrivateApi.createPurchasableItem,
  });
}

export function useMutatePurchasableItem() {
  return useMutation({
    mutationFn: SdPrivateApi.mutatePurchasableItem,
  });
}

export function useDeletePurchasableItem() {
  return useMutation({
    mutationFn: SdPrivateApi.deletePurchasableItem,
  });
}
