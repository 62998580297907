import { Stack, Typography } from '@mui/material';

export const PrivacyPolicy = () => {
  return (
    <Stack gap={2}>
      <Typography variant={'body1'}>
        This Privacy Policy describes how your personal information is
        collected, used, and shared through the Stardust website, API, and
        marketplace (collectively, “the Services”).
      </Typography>
      <Typography variant={'body1'}>
        Stardust offers an easy-to-use platform for game developers to build
        their games in the metaverse. To use our platform, game developers – our
        clients – share information to us about their end users– i.e., their
        players – including those users’ account information and transaction
        information. We process that information on our clients’ behalf to
        facilitate our clients’ use of our platform.
      </Typography>
      <Typography variant={'body1'}>
        We may also collect personal information for our own business purposes,
        such as information that individuals submit to us directly through the
        Stardust marketplace and business contact details of potential clients
        who visit our website.
      </Typography>
      <Typography variant={'body1'}>This Privacy Policy applies to:</Typography>
      <ul>
        <li>
          The Stardust.gg website and any other websites or online services
          controlled by us and which display this Privacy Policy, and
        </li>
        <li>
          Game development services that we provide to our clients through our
          API.
        </li>
      </ul>
      <Typography variant={'body1'}>
        We provide a supplementary{' '}
        <b>
          <i>notice for California residents</i>
        </b>{' '}
        and{' '}
        <b>
          <i>notice to European users below.</i>
        </b>
      </Typography>
      <Typography variant={'body1'}>
        <b>INFORMATION WE COLLECT</b>
      </Typography>
      <Typography variant={'body1'}>
        <b>Information We Collect for Stardust’s Own Business Purposes</b>
      </Typography>
      <Typography variant={'body1'}>
        We may collect information about individuals who interact with Stardust
        when using our Services, such as players who use our marketplace,
        employees of our clients, job applicants, and other individuals.
      </Typography>
      <Typography variant={'body1'}>
        <b>Information collected when you visit our website includes:</b>
      </Typography>
      <ul>
        <li>
          <b>Account details,</b> such as your first and last name, email and
          mailing addresses, and phone number.
        </li>
        <li>
          <b>Payment details,</b> such as your USDC, Bitcoin, Ethereum, or
          Solana payment, or your credit card details and billing address. We
          use third-party payment processors, Circle and Fireblocks, to process
          this payment information. Stardust does not have access to this
          information.
        </li>
        <li>
          <b>Social media data,</b> such as your handle and other information
          that social media platforms may share when you log-in to the Stardust
          marketplace using your social media accounts. The types and amount of
          data you share depend on the privacy settings in the social media
          account you connect to our platform.
        </li>
        <li>
          <b>Marketing data,</b> such as your preferences for receiving our
          marketing communications, and details about your engagement with them.
        </li>
        <li>
          <b>Device data,</b> such as the manufacturer and model, operating
          system, IP address, location data, and unique identifiers associated
          with the device you use to access the platform.
        </li>
      </ul>
      <Typography variant={'body1'}>
        <b>Cookies and similar technologies</b>. Our website may use the
        following technologies:
      </Typography>
      <ul>
        <li>
          <b>Cookies,</b> which are text files that websites store on a
          visitor‘s device to uniquely identify the visitor’s browser or to
          store information or settings in the browser for the purpose of
          helping you navigate between pages efficiently, remembering your
          preferences, enabling functionality, helping us understand user
          activity and patterns, and facilitating online advertising.
        </li>
        <li>
          <b>Local storage technologies,</b> like HTML5, that provide
          cookie-equivalent functionality but can store larger amounts of data,
          including on your device outside of your browser in connection with
          specific applications.
        </li>
        <li>
          <b>Web beacons,</b> also known as pixel tags or clear GIFs, which are
          used to demonstrate that a webpage or email was accessed or opened, or
          that certain content was viewed or clicked.{' '}
        </li>
      </ul>
      <Typography variant={'body1'}>
        {' '}
        <b>Information We Collect From or On Behalf of Our Clients</b>
      </Typography>
      <Typography variant={'body1'}>
        We may collect information about end users – players who play our
        clients’ games – from our clients or, at clients’ requests, from their
        service providers. Our clients determine the scope of the information
        transferred to us, and the information we receive may vary by client.
        Typically, we may collect end users’ account and login information,
        in-game purchase history, user inventory and gaming settings, and other
        data produced by game developers using our API.
      </Typography>
      <Typography variant={'body1'}>
        Stardust processes and stores end users’ payment information using a
        payment widget integrated with Circle and Coinbase Commerce, our third
        party payment processors. Stardust does not have access to this payment
        information, and this information is subject to these payment
        processors’ privacy policies.
      </Typography>
      <Typography variant={'body1'}>
        <b>OUR USE OF PERSONAL INFORMATION</b>
      </Typography>
      <Typography variant={'body1'}>
        <b>
          Our Use of Personal Information for Stardust’s Own Business Purposes
        </b>
      </Typography>
      <Typography variant={'body1'}>
        We use personal information for the following purposes or as otherwise
        described at the time of collection:
      </Typography>
      <Typography variant={'body1'}>
        <b>Services delivery,</b> including to:
      </Typography>
      <ul>
        <li>Operate the Services and our business;</li>
        <li>Maintain and improve the Services and the Services’ features;</li>
        <li>Create and maintain your account;</li>
        <li>Facilitate user connections on our Services;</li>
        <li>Recommend content that might be of interest to you;</li>
        <li>Process your transactions;</li>
        <li>
          Communicate with you about the Services, including by sending
          announcements, updates, security alerts, and support and
          administrative messages; and
        </li>
        <li>
          Provide support for the Services, and respond to your requests,
          questions, and feedback.
        </li>
      </ul>
      <Typography variant={'body1'}>
        <b>Research and development.</b> We may create and use de-identified
        information for our business purposes, including to analyze the
        effectiveness of our Services, to improve and add features to our
        Services, and to analyze the general behavior and characteristics of
        users of our Services. We may use this anonymous, aggregated, or
        de-identified data and share it with third parties for our lawful
        business purposes.
      </Typography>
      <Typography variant={'body1'}>
        <b>Compliance and protection</b>. We may use personal information to:
      </Typography>
      <ul>
        <li>
          Protect our, your, or others’ rights, privacy, safety, or property
          (including by making and defending legal claims);
        </li>
        <li>
          Audit our internal processes for compliance with legal and contractual
          requirements and internal policies
        </li>
        <li>
          Prevent, identify, investigate, and deter fraudulent, harmful,
          unauthorized, unethical or illegal activity, including cyberattacks
          and identity theft; and
        </li>
        <li>
          Comply with applicable laws, lawful requests, and legal process, such
          as to respond to subpoenas or requests from government authorities.
        </li>
      </ul>
      <Typography variant={'body1'}>
        <b>Marketing and advertising:</b> We may send you marketing
        communications via email as permitted by law. You will have the ability
        to opt-out of our marketing and promotional communications as described
        in the “Opt out of marketing communications” section below.
      </Typography>
      <Typography variant={'body1'}>
        <b>Our Use of Personal Information on Behalf of our Clients</b>
      </Typography>
      <Typography variant={'body1'}>
        We use personal information we collect from or on behalf of our clients
        to provide services to our clients at their direction. We do not use
        this information for Stardust’s own purposes. We use personal
        information only as directed or authorized by our client. Typically, we
        are directed or authorized to use personal information collected on
        behalf of the client to:
      </Typography>
      <ul>
        <li>
          Provide, operate, maintain, secure and improve the API, including by
          creating new features;{' '}
        </li>
        <li>Create, maintain, and authenticate your account; </li>
        <li>
          Deliver advertising and marketing content to players on behalf of our
          clients;
        </li>
        <li>
          Process transactions through our third party payment processors; and
        </li>
        <li>
          Provide support for the API, and respond to your requests, questions
          and feedback.
        </li>
      </ul>
      <Typography variant={'body1'}>
        <b>PERSONAL INFORMATION SHARING</b>
      </Typography>
      <Typography variant={'body1'}>
        We may share personal information for the purposes set out in this
        Privacy Policy with:
      </Typography>
      <ul>
        <li>
          <b>Game developers</b>. We may share your personal information with
          the game developer from whom or on whose behalf we collected the
          information.{' '}
        </li>
        <li>
          <b>Service providers</b>. Companies and individuals that provide
          services on our behalf or help us operate the Services or our business
          (such as hosting, analytics, customer support, email delivery, payment
          services, and identity verification services).{' '}
        </li>
        <li>
          <b>Professional advisors</b>. Professional advisors, such as lawyers,
          auditors, bankers, and insurers, where necessary in the course of the
          professional services that they render to us.{' '}
        </li>
        <li>
          <b>Authorities and others</b>. Law enforcement, government
          authorities, and private parties as we believe necessary to: (a)
          protect our, your, or others’ rights, privacy, safety, or property
          (including by making and defending legal claims);(b) enforce the terms
          and conditions that govern the Services; and (c)protect, investigate,
          and deter against fraudulent, harmful, unauthorized, unethical, or
          illegal activity.{' '}
        </li>
        <li>
          <b>Business transferees</b> Acquirers and other relevant participants
          in business transactions (or negotiations for such transactions)
          involving a corporate divestiture, merger, consolidation, acquisition,
          reorganization, sale, or other disposition of all or any portion of
          the business or assets of, or equity interests in, Nifty’s or our
          affiliates (including, in connection with a bankruptcy or similar
          proceedings).{' '}
        </li>
        <li>
          <b>Third-party platforms and social media networks</b>. If you have
          enabled features or functionality that connect the Services to a
          third-party platform or social media network (such as by logging into
          Stardust using your account with a third-party), we may disclose the
          information that you authorized us to share. We do not control the
          third-party platforms’ use of your personal information, which is
          governed by that third party’s privacy policy and terms and
          conditions.{' '}
        </li>
      </ul>
      <Typography variant={'body1'}>
        Players can also share their personal information through the API with:
      </Typography>
      <ul>
        <li>
          <b>Other players and the public,</b> when players create a public
          profile, post content, and engage in public transactions in the API;
        </li>
        <li>
          <b>Other businesses,</b> when players participate in offers,
          promotions and other features available via the API or marketed to
          players; and
        </li>
        <li>
          <b>Social media platforms,</b> when players choose to share content on
          social media.
        </li>
      </ul>
      <Typography variant={'body1'}>
        <b>PRIVACY CHOICES</b>
      </Typography>
      <ul>
        <li>
          <b>Access to information.</b> To keep your information accurate,
          current, and complete, please contact us as specified below. We will
          take reasonable steps to update or correct information in our
          possession that you have previously submitted via the Service. Please
          also feel free to contact us if you have any questions about our
          Privacy Policy or the information practices of the Services at
          support@stardust.gg.
        </li>
        <li>
          <b>Disconnect social media companies.</b> When you connect your social
          media accounts to Stardust, we notify those platforms. You may
          disconnect your social media accounts directly on the Services at any
          time.
        </li>
        <li>
          <b>Opt out of marketing communications.</b> You may opt out of
          marketing-related communications by following the opt out or
          unsubscribe instructions contained in the marketing communication we
          send you.{' '}
        </li>
        <li>
          <b>Online tracking opt out.</b> There are a number of ways to limit
          online tracking, which we have summarized below:
        </li>
      </ul>
      <Typography variant={'body1'}>
        <b>Blocking cookies in your browser</b>. Most browsers let you remove or
        reject cookies. To do this, follow the instructions in your browser
        settings. Many browsers accept cookies by default until you change your
        settings. For more information about cookies, including how to see what
        cookies have been set on your device and how to manage and delete them,
        visit{' '}
        <a
          target="_blank"
          href="https://www.allaboutcookies.org"
          rel="noreferrer noopener"
        >
          www.allaboutcookies.org
        </a>
        .{' '}
      </Typography>
      <Typography variant={'body1'}>
        <b>Using privacy plug-ins or browsers</b>. You can block our websites
        from setting cookies used for interest-based ads by using a browser with
        privacy features, like{' '}
        <a target="_blank" href="https://brave.com/" rel="noreferrer noopener">
          Brave
        </a>
        , or installing browser plugins like{' '}
        <a
          target="_blank"
          href="https://privacybadger.org/"
          rel="noreferrer noopener"
        >
          Privacy Badger
        </a>
        ,{' '}
        <a
          target="_blank"
          href="https://www.ghostery.com/"
          rel="noreferrer noopener"
        >
          Ghostery
        </a>
        , or{' '}
        <a target="_blank" href="https://ublock.org/" rel="noreferrer noopener">
          uBlock Origin
        </a>
        , and configuring them to block third party cookies/trackers.{' '}
      </Typography>
      <Typography variant={'body1'}>
        Note that because these opt out mechanisms are specific to the device or
        browser on which they are exercised, you will need to opt out on every
        browser and device that you use.
      </Typography>
      <Typography variant={'body1'}>
        <b>Do Not Track</b>. Some Internet browsers may be configured to send
        &quot;Do Not Track&quot; signals to the online services that you visit.
        We currently do not respond to &quot;Do Not Track&quot; or similar
        signals. To find out more about &quot;Do Not Track&quot;, please visit
        <a href="http://www.allaboutdnt.com/">http://www.allaboutdnt.com</a>.
      </Typography>
      <Typography variant={'body1'}>
        <b>HOW WE PROTECT INFORMATION</b>
      </Typography>
      <Typography variant={'body1'}>
        We maintain administrative, technical, and physical safeguards designed
        to protect the personal information we maintain against accidental,
        unlawful, or unauthorized access, disclosure, alteration, use, loss, or
        destruction. However, we cannot guarantee that the safeguards we
        maintain will ensure the security of the personal information.
      </Typography>
      <Typography variant={'body1'}>
        <b>LINKS TO OTHER WEBSITES AND THIRD-PARTY CONTENT</b>
      </Typography>
      <Typography variant={'body1'}>
        The Services may contain links to websites and other online services
        operated by third parties. In addition, our content may be integrated
        into web pages or other online services that are not associated with us.
        These links and integrations are not an endorsement of, or
        representation that we are affiliated with, any third party. We do not
        control websites or online services operated by third parties, and we
        are not responsible for their actions.
      </Typography>
      <Typography variant={'body1'}>
        <b>PROCESSING PERSONAL INFORMATION IN THE US</b>
      </Typography>
      <Typography variant={'body1'}>
        We are headquartered in the United States. To provide our services and
        operate our website, it is necessary for us to process personal
        information in the United States.
      </Typography>
      <Typography variant={'body1'}>
        If we transfer personal information across borders such that we are
        required to apply appropriate safeguards to personal information under
        applicable data protection laws, we will do so. Please contact us for
        further information about any such transfers or the specific safeguards
        applied.
      </Typography>
      <Typography variant={'body1'}>
        <b>CHILDREN</b>
      </Typography>
      <Typography variant={'body1'}>
        Our website and services are not intended for use by children under 13
        years of age. If we learn that we have collected personal information
        through our website or services from a child under 13 without the
        consent of the child’s parent or guardian as required by law, we will
        delete it.
      </Typography>
      <Typography variant={'body1'}>
        <b>CHANGES TO OUR PRIVACY POLICY</b>
      </Typography>
      <Typography variant={'body1'}>
        We reserve the right to modify this Privacy Policy at any time. If we
        make material changes to this Privacy Policy, we will notify you by
        updating the date of this Privacy Policy and posting it on the website.
      </Typography>
      <Typography variant={'body1'}>
        <b>HOW TO CONTACT US</b>
      </Typography>
      <Typography variant={'body1'}>
        You may contact us with questions, comments, or complaints about this
        Privacy Policy by email at support@stardust.gg or at the following
        mailing address:
      </Typography>
      <Typography variant={'body1'}>555 Bryant Street #120</Typography>
      <Typography variant={'body1'}>Palo Alto, Ca, 94301</Typography>
      <Typography variant={'body1'}>
        <b>NOTICE FOR CALIFORNIA RESIDENTS</b>
      </Typography>
      <Typography variant={'body1'}>
        The California Consumer Privacy Act of 2018(“CCPA”) requires us to
        provide this supplemental privacy notice to our consumers in California.
      </Typography>
      <Typography variant={'body1'}>
        Please note that we do not sell personal information.
      </Typography>
      <Typography variant={'body1'}>
        <b>CCPA Notice Scope</b>
      </Typography>
      <Typography variant={'body1'}>
        This CCPA Notice and the rights it sets out apply to the personal
        information we collect, use or disclose about consumers who submit
        personal information to us directly through the Stardust marketplace.
      </Typography>
      <Typography variant={'body1'}>
        This CCPA Notice does not apply to the personal information we collect,
        use or disclose about:
      </Typography>
      <ul>
        <li>
          Our game developer clients’ end-users – i.e., their players – whose
          information we process on our clients’ behalf to facilitate our
          clients’ use of our platform.
        </li>
        <li>
          Representatives of businesses who visit our website to seek to obtain
          our products or services.
        </li>
      </ul>
      <Typography variant={'body1'}>
        For the California residents to whom this CCPA notice does apply, the
        CCPA grants the following rights.
      </Typography>
      <ul>
        <li>
          <b>Information</b>. This Privacy Policy explains how we use and share
          your personal information through the Services, during the past 12
          months. We describe the sources through which we collect personal
          information and the types of personal information collected in the
          “Information We Collect” section above. We describe the purposes for
          which we use and share this information in the “Our Use of Personal
          Information ”above.
        </li>
        <li>
          <b>Access</b> You can request a copy of the personal information that
          we maintain about you.
        </li>
        <li>
          Deletion . You can ask to delete the personal information that we
          maintain about you.
        </li>
      </ul>
      <Typography variant={'body1'}>
        Please note that the CCPA limits these rights by, for example,
        prohibiting businesses from providing certain sensitive information in
        response to an access request and limiting the circumstances in which
        they must comply with a deletion request.
      </Typography>
      <Typography variant={'body1'}>
        You are entitled to exercise the rights described above free from
        discrimination.
      </Typography>
      <Typography variant={'body1'}>
        Here is how you can submit requests:
      </Typography>
      <ul>
        <li>
          <b>
            To request access to or deletion of personal information collected
            via the Services
          </b>
          , please email us atsupport@stardust.gg or call us at (833) 242 - 2002{' '}
        </li>
        <li>
          <b>To verify your identity prior to responding to your requests</b> ,
          we may ask you to confirm information that we have on file about you
          or your interactions with us. Where we ask for additional personal
          information to verify your identity, we will only use it to verify
          your identity or your authority to make the request on behalf of
          another consumer.
        </li>
        <li>
          <b>Authorized agents</b>. California residents can empower an
          “authorized agent” to submit requests on their behalf.
        </li>
      </ul>
      <Typography variant={'body1'}>NOTICE TO EUROPEAN USERS</Typography>
      <Typography variant={'body1'}>
        VeraSafe has been appointed as Stardust Cards Corporation representative
        in the European Union for data protection matters, pursuant to Article
        27 of the General Data Protection Regulation of the European Union. If
        you are in the European Economic Area, VeraSafe can be contacted in
        addition to support@stardust.gg, only on matters related to the
        processing of personal data. To make such an inquiry, please contact
        VeraSafe using this contact form:
        https://verasafe.com/public-resources/contact-data-protection-representative
        or via telephone at: +420 228 881 031.
      </Typography>
      <Typography variant={'body1'}>
        <b>Alternatively, VeraSafe can be contacted at:</b>
      </Typography>
      <Typography variant={'body1'}>VeraSafe Ireland Ltd.</Typography>
      <Typography variant={'body1'}>Unit 3D North Point House</Typography>
      <Typography variant={'body1'}>North Point Business Park</Typography>
      <Typography variant={'body1'}>New Mallow Road</Typography>
      <Typography variant={'body1'}>Cork T23AT2P</Typography>
      <Typography variant={'body1'}>Ireland</Typography>
      <Typography variant={'body1'}>
        <b>Legal basis for processing</b> . We use your personal information
        only as permitted by law. Our legal bases for processing the personal
        information described in this Privacy Policy are described in the table
        below.
      </Typography>
      <Typography variant={'body1'}>Processing Purpose</Typography>
      <Typography variant={'body1'}>Legal Basis</Typography>
      <Typography variant={'body1'}>Service delivery</Typography>
      <Typography variant={'body1'}>
        Processing is necessary to perform the contract governing our provision
        of services in connection with the Services, or to take steps that you
        request prior to signing up for the Services. If we have not entered
        into a contract with you, we process your personal information based on
        our legitimate interest in providing the services you access and request
        in connection with the Services.
      </Typography>
      <Typography variant={'body1'}>Research and development</Typography>
      <Typography variant={'body1'}>
        These activities constitute our legitimate interests. We do not use your
        personal information for these activities where our interests are
        overridden by the impact on you (unless we have your consent or are
        otherwise required or permitted to by law).
      </Typography>
      <Typography variant={'body1'}>Marketing and advertising</Typography>
      <Typography variant={'body1'}>
        These activities constitute our legitimate interests. We do not use your
        personal information for these activities where our interests are
        overridden by the impact on you (unless we have your consent or are
        otherwise required or permitted to by law).
      </Typography>
      <Typography variant={'body1'}>Compliance and protection</Typography>
      <Typography variant={'body1'}>
        These activities constitute our legitimate interests. We do not use your
        personal information for these activities where our interests are
        overridden by the impact on you (unless we have your consent or are
        otherwise required or permitted to by law).
      </Typography>
      <Typography variant={'body1'}>
        Processing is also necessary to comply with our legal obligations.
      </Typography>
      <Typography variant={'body1'}>With your consent</Typography>
      <Typography variant={'body1'}>
        Processing is based on your consent. Where we rely on your consent you
        have the right to withdraw it any time in the manner indicated when you
        consent or in the Services.
      </Typography>
      <Typography variant={'body1'}>
        <b>Use for new purposes</b> . We may use your personal information for
        reasons not described in this Privacy Policy where permitted by law and
        the reason is compatible with the purpose for which we collected it. If
        we need to use your personal information for an unrelated purpose, we
        will notify you and explain the applicable legal basis.
      </Typography>
      <Typography variant={'body1'}>
        <b>Sensitive personal information</b>. We ask that you not provide us
        with any sensitive personal information (e.g., information related to
        racial or ethnic origin, political opinions, religion or other beliefs,
        health, biometrics or genetic characteristics, criminal background or
        trade union membership) on or through the Services, or otherwise to us.
      </Typography>
      <Typography variant={'body1'}>
        If you provide us with any sensitive personal information when you use
        our Services, you must consent to our processing and use of such
        sensitive personal information in accordance with this Privacy Policy.
        If you do not consent to our processing and use of such sensitive
        personal information, you must not submit such sensitive personal
        information through our services.
      </Typography>
      <Typography variant={'body1'}>
        <b>Retention</b>. We retain personal information for as long as
        necessary to fulfill the purposes for which we collected it, including
        for the purposes of satisfying any legal, accounting, or reporting
        requirements, to establish or defend legal claims, or for fraud
        prevention purposes.
      </Typography>
      <Typography variant={'body1'}>
        To determine the appropriate retention period for personal information,
        we consider the amount, nature, and sensitivity of the personal
        information, the potential risk of harm from unauthorized use or
        disclosure of your personal information, the purposes for which we
        process your personal information and whether we can achieve those
        purposes through other means, and the applicable legal requirements.
      </Typography>
      <Typography variant={'body1'}>
        <b>Cross-border Data Transfer</b>. If we transfer your personal
        information from Europe to another country such that we are required to
        apply appropriate safeguards to your personal information under European
        data protection laws, we will do so. Please contact us for further
        information about any such transfers or the specific safeguards applied.
      </Typography>
      <Typography variant={'body1'}>
        <b>Your rights</b>. European data protection laws may give you certain
        rights regarding your personal information. If you are located in
        Europe, you may ask us to take the following actions in relation to your
        personal information that we hold:
      </Typography>
      <ul>
        <li>
          <b>Access</b> Provide you with information about our processing of
          your personal information and give you access to your personal
          information.
        </li>
        <li>
          <b>Correct</b> Update or correct inaccuracies in your personal
          information.
        </li>
        <li>
          <b>Delete</b> Delete your personal information.
        </li>
        <li>
          <b>Transfer</b> Transfer a machine-readable copy of your personal
          information to you or a third party of your choice.
        </li>
        <li>
          <b>Restrict</b> Restrict the processing of your personal information.
        </li>
        <li>
          <b>Object</b> Object to our reliance on our legitimate interests as
          the basis of our processing of your personal information that impacts
          your rights.
        </li>
      </ul>
      <Typography variant={'body1'}>
        You may submit these requests by email to{' '}
        <a href="mailto:support@stardust.gg">support@stardust.gg</a> or our
        postal address provided above. We may request specific information from
        you to help us confirm your identity and process your request.
        Applicable law may require or permit us to decline your request. If we
        decline your request, we will tell you why, subject to legal
        restrictions. If you would like to submit a complaint about our use of
        your personal information or our response to your requests regarding
        your personal information, you may contact us or submit a complaint to
        the data protection regulator in your jurisdiction.{' '}
        <a href="https://edpb.europa.eu/about-edpb/about-edpb/members_en">
          You can find your data protection regulator here.
        </a>
      </Typography>
    </Stack>
  );
};
