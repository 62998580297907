import { Box, Button, Typography } from '@mui/material';
import { Eye, HandCoins, PaperPlaneTilt, Trash } from '@phosphor-icons/react';
import { Item } from '@stardust-monorepo/types/sd-core';
import {
  ContainedTable,
  EmptyContainer,
  ErrorAlert,
  MoreActions,
  track,
  useInventory,
  ValidatedImage,
} from '@stardust-monorepo/web-sdk-apps-shared';
import React, { useState } from 'react';

import { AddItemBalance } from '../collections/item-balance/AddItemBalance';
import { RemoveItemBalance } from '../collections/item-balance/RemoveItemBalance';
import { TransferItemBalance } from '../collections/item-balance/TransferItemBalance';
import { ItemDetailsModal } from '../collections/items/ItemDetailsModal';
const headings = [
  { width: '40px', label: '' },
  { label: 'Item' },
  { label: 'Item ID' },
  { label: 'Amount' },
  { width: '40px', label: '' },
];
export const Inventory = ({
  gameId,
  playerId,
}: {
  gameId: number;
  playerId: string;
}) => {
  const [viewingItemDetails, setViewingItemDetails] = useState<Item | null>(
    null
  );
  const [addingItemBalance, setAddingItemBalance] = useState<Item | null>(null);
  const [addingGenericItemBalance, setAddingGenericItemBalance] =
    useState<boolean>(false);
  const [removingItemBalance, setRemovingItemBalance] = useState<Item | null>(
    null
  );
  const [transferringItemBalance, setTransferringItemBalance] =
    useState<Item | null>(null);
  const { data, error, isLoading } = useInventory({ gameId, playerId });

  const rows =
    data?.map((inventoryEntry) => {
      const item = inventoryEntry.item;
      const balance = inventoryEntry.balance;
      return [
        <ValidatedImage
          size="40px"
          image={item.image}
          testId="template-image"
        />,
        <Typography variant="subtitle2">{inventoryEntry.item.name}</Typography>,
        item.id,
        balance.amount,
        <MoreActions
          actions={[
            {
              icon: <Eye weight="duotone" />,
              label: 'Item Details',
              onClick: () => {
                track('Inventory Item View Properties', 'Clicked', {
                  gameId,
                  playerId: playerId,
                  itemId: item.id,
                });
                setViewingItemDetails(item);
              },
            },
            {
              icon: <HandCoins weight="duotone" />,
              label: 'Add Balance',
              onClick: () => {
                track('Inventory Item Add Balance', 'Clicked', {
                  gameId,
                  playerId: playerId,
                  itemId: item.id,
                });
                setAddingItemBalance(item);
              },
            },
            {
              icon: <PaperPlaneTilt weight="duotone" />,
              label: 'Transfer Balance',
              onClick: () => {
                track('Inventory Item Transfer Balance', 'Clicked', {
                  gameId,
                  playerId: playerId,
                  itemId: item.id,
                });
                setTransferringItemBalance(item);
              },
            },
            {
              icon: <Trash weight="duotone" />,
              label: 'Deduct Balance',
              onClick: () => {
                track('Inventory Item Deduct Balance', 'Clicked', {
                  gameId,
                  playerId: playerId,
                  itemId: item.id,
                });
                setRemovingItemBalance(item);
              },
              type: 'error',
            },
          ]}
        />,
      ];
    }) ?? [];

  return (
    <>
      {viewingItemDetails && (
        <ItemDetailsModal
          itemId={viewingItemDetails.id}
          open={!!viewingItemDetails}
          onClose={() => setViewingItemDetails(null)}
          sourcePage={'Player Inventory'}
        />
      )}
      <AddItemBalance
        item={addingItemBalance ?? undefined}
        playerId={playerId}
        gameId={gameId}
        lockItems={!!addingItemBalance}
        open={!!(addingGenericItemBalance || addingItemBalance)}
        onClose={() => {
          setAddingItemBalance(null);
          setAddingGenericItemBalance(false);
        }}
      />
      {removingItemBalance && (
        <RemoveItemBalance
          item={removingItemBalance}
          gameId={gameId}
          playerId={playerId}
          lockItems={true}
          open={!!removingItemBalance}
          onClose={() => {
            setRemovingItemBalance(null);
          }}
        />
      )}
      {transferringItemBalance && (
        <TransferItemBalance
          item={transferringItemBalance}
          gameId={gameId}
          playerId={playerId}
          lockItems={true}
          open={!!transferringItemBalance}
          onClose={() => {
            setTransferringItemBalance(null);
          }}
        />
      )}

      {error && <ErrorAlert error={error as Error} />}
      <ContainedTable
        count={rows.length}
        page={0}
        type="Items"
        headings={headings}
        rows={rows}
        isLoading={isLoading}
        emptyScreen={
          <EmptyContainer
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              pt: 6.25,
            }}
          >
            <Box sx={{ color: 'primary.main', mb: 2, display: 'flex' }}>
              <HandCoins size={32} weight="duotone" />
            </Box>
            <Typography variant="subtitle1">No Items in Inventory</Typography>
            <Typography
              variant="body2"
              sx={{ color: 'text.secondary', mt: 1, mb: 2 }}
            >
              Items will be listed here after an item balance is added for this
              player.
            </Typography>
            <Button
              variant="contained"
              onClick={() => {
                track('Empty Inventory Add Balance', 'Clicked', {
                  gameId,
                  playerId,
                });
                setAddingGenericItemBalance(true);
              }}
            >
              Add Balance
            </Button>
          </EmptyContainer>
        }
      />
    </>
  );
};
