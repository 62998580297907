import { Breadcrumbs, Typography } from '@mui/material';
import { Collection, Item } from '@stardust-monorepo/types/sd-core';
import {
  DrawerDialog,
  DrawerDialogContent,
  DrawerDialogTitle,
  Spinner,
  useCollection,
  useItem,
} from '@stardust-monorepo/web-sdk-apps-shared';
import React, { PropsWithChildren, ReactElement, useState } from 'react';

import { AddItemBalance } from '../item-balance/AddItemBalance';
import { RemoveItemBalance } from '../item-balance/RemoveItemBalance';
import { TransferItemBalance } from '../item-balance/TransferItemBalance';
import { EditItem } from './EditItem';
import { ItemDetails, ItemDetailsTitle } from './ItemDetails';

type ItemDetailsModalContentProps = PropsWithChildren<{
  item: Item | undefined;
  collection: Collection | undefined;
  itemError: Error | undefined;
  collectionError: Error | undefined;
}>;
export const ItemDetailsModalContent = ({
  item,
  collection,
  itemError,
  collectionError,
  children,
}: ItemDetailsModalContentProps): ReactElement => {
  return !item || !collection ? (
    <Spinner />
  ) : itemError || collectionError ? (
    <Typography sx={{ color: 'text.error' }}>
      <>
        An error occurred while loading the Item:{' '}
        {(itemError as Error)?.message ||
          itemError ||
          (collectionError as Error)?.message ||
          collectionError}
      </>
    </Typography>
  ) : (
    // sorry it's mad about the type of ReactNode
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>{children}</>
  );
};

export const ItemDetailsModal = ({
  itemId,
  open,
  onClose,
  sourcePage,
  edit,
}: {
  itemId: string;
  open: boolean;
  onClose: () => void;
  sourcePage: string;
  edit?: boolean;
}) => {
  const [dialogState, setDialogState] = useState<'details' | 'edit'>(
    edit ? 'edit' : 'details'
  );
  const [addingBalance, setAddingBalance] = useState<boolean>(false);
  const [removingBalance, setRemovingBalance] = useState<boolean>(false);
  const [transferringBalance, setTransferringBalance] =
    useState<boolean>(false);

  const { data: item, error } = useItem({ id: itemId });
  const { data: collection, error: collectionError } = useCollection(
    {
      id: item?.collectionId,
    },
    !!item
  );

  return (
    <>
      {item && (
        <>
          <AddItemBalance
            collectionId={item.collectionId}
            gameId={item.gameId}
            item={item}
            open={addingBalance}
            lockItems={true}
            onClose={() => setAddingBalance(false)}
          />
          <RemoveItemBalance
            collectionId={item.collectionId}
            gameId={item.gameId}
            item={item}
            open={removingBalance}
            lockItems={true}
            onClose={() => setRemovingBalance(false)}
          />
          <TransferItemBalance
            collectionId={item.collectionId}
            gameId={item.gameId}
            item={item}
            open={transferringBalance}
            lockItems={true}
            onClose={() => setTransferringBalance(false)}
          />
        </>
      )}
      <DrawerDialog open={open} onClose={onClose}>
        {dialogState === 'details' ? (
          <>
            <DrawerDialogTitle paddingY={1.5}>
              {item ? (
                <ItemDetailsTitle
                  onEditClick={() => setDialogState('edit')}
                  onAddBalanceClick={() => setAddingBalance(true)}
                  onRemoveBalanceClick={() => setRemovingBalance(true)}
                  onTransferBalanceClick={() => setTransferringBalance(true)}
                  sourcePage={sourcePage}
                  onClose={onClose}
                  item={item}
                />
              ) : (
                'Loading item...'
              )}
            </DrawerDialogTitle>
            <DrawerDialogContent>
              <ItemDetailsModalContent
                item={item}
                collection={collection}
                itemError={error as Error | undefined}
                collectionError={collectionError as Error | undefined}
              >
                {item && collection && (
                  <ItemDetails
                    item={item}
                    collectionAddress={collection?.address}
                    open={open}
                    onClose={onClose}
                    sourcePage={sourcePage}
                  />
                )}
              </ItemDetailsModalContent>
            </DrawerDialogContent>
          </>
        ) : (
          <>
            <DrawerDialogTitle>
              <Breadcrumbs>
                {sourcePage && (
                  <Typography variant="body2">{sourcePage}</Typography>
                )}
                <Typography variant="body2" color="text.primary">
                  Edit Item
                </Typography>
              </Breadcrumbs>
            </DrawerDialogTitle>
            <DrawerDialogContent>
              <ItemDetailsModalContent
                item={item}
                collection={collection}
                itemError={error as Error | undefined}
                collectionError={collectionError as Error | undefined}
              >
                {item && (
                  <EditItem
                    item={item}
                    onSave={() => setDialogState('details')}
                    onCancel={() => setDialogState('details')}
                  />
                )}
              </ItemDetailsModalContent>
            </DrawerDialogContent>
          </>
        )}
      </DrawerDialog>
    </>
  );
};
