import {
  Box,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  useTheme,
} from '@mui/material';
import { Purchasable } from '@stardust-monorepo/types/sd-private';
import {
  BasicDialog,
  ValidatedImage,
} from '@stardust-monorepo/web-sdk-apps-shared';
import React from 'react';

export interface DeactivateWarningModalProps {
  isLoading: boolean;
  onConfirm: () => void;
  open: boolean;
  onClose: () => void;
  purchasables: Purchasable[];
}

export const DeactivateWarningModal = ({
  isLoading,
  onConfirm,
  open,
  onClose,
  purchasables,
}: DeactivateWarningModalProps) => {
  const theme = useTheme();
  return (
    <BasicDialog open={open} onClose={onClose}>
      <DialogTitle
        variant="h5"
        sx={{
          padding: `${theme.spacing(3)} ${theme.spacing(3)} ${theme.spacing(
            1
          )}!important`,
        }}
      >
        Active Purchasables with Template
      </DialogTitle>
      <DialogContent sx={{ maxWidth: '640px' }}>
        <Typography variant="body2" sx={{ color: 'text.secondary', pb: 4 }}>
          The following purchasables will be deactivated if you deactivate this
          template
        </Typography>
        {purchasables.map((p) => (
          <Box
            key={p.id}
            sx={{
              display: 'flex',
              width: '100%',
              alignItems: 'center',
              borderRadius: 3,
              border: '1px solid',
              borderColor: 'divider',
              py: 1.5,
              mb: 0.5,
            }}
          >
            <Box sx={{ display: 'flex', width: '70%', alignItems: 'center' }}>
              <ValidatedImage
                size="40px"
                width="60px"
                image={p.image}
                sx={{
                  mr: 3,
                  ml: 1.5,
                }}
              />
              <Typography variant="subtitle1">{p.name}</Typography>
            </Box>
            <Typography
              variant="body1"
              sx={{ color: 'text.secondary', width: '15%' }}
            >
              {p.totalSupply}
            </Typography>
            <Typography
              variant="body1"
              sx={{ color: 'text.secondary', width: '15%' }}
            >
              ${Number(p.price).toFixed(2)}
            </Typography>
          </Box>
        ))}
      </DialogContent>
      <DialogActions
        sx={{ borderTop: '1px solid', borderColor: 'divider', mt: 2.5 }}
      >
        <Button
          disabled={isLoading}
          onClick={onClose}
          variant="outlined"
          color="secondary"
          sx={{ mt: 1.5 }}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          disabled={isLoading}
          autoFocus
          variant="contained"
          onClick={onConfirm}
          sx={{ mt: 1.5 }}
        >
          {isLoading ? (
            <CircularProgress size="18px" color="primary" />
          ) : (
            'Deactivate'
          )}
        </Button>
      </DialogActions>
    </BasicDialog>
  );
};
