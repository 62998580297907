import {
  Box,
  Breadcrumbs,
  Button,
  Divider,
  Stack,
  styled,
  Typography,
} from '@mui/material';
import { Flame, PaperPlaneTilt, PencilSimple, X } from '@phosphor-icons/react';
import { Collection, Item } from '@stardust-monorepo/types/sd-core';
import { BlockchainExplorerBaseUrls } from '@stardust-monorepo/types/sd-private';
import {
  Collapsible,
  formatAddress,
  IconButton,
  InfoItem,
} from '@stardust-monorepo/web-sdk-apps-shared';
import { format } from 'date-fns';
import React from 'react';

const StyledImage = styled('img')(({ theme }) => ({
  borderRadius: 10,
  objectFit: 'cover',
  zIndex: 999,
  marginTop: '32px',
  border: '2px solid white',
  boxShadow: theme.shadows[3],
}));

const Property = ({
  name,
  value,
}: {
  name: string;
  value: string | boolean | number;
}) => {
  return (
    <Stack
      gap={0.5}
      sx={(theme) => ({
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: 2,
        padding: '11px 16px',
        maxWidth: 'calc(calc(100% - 16px) / 3)',
        minWidth: 'calc(calc(100% - 16px) / 3)',
        flex: '1 1 0',
      })}
    >
      <Stack
        direction="row"
        sx={{
          justifyContent: 'space-between',
        }}
      >
        <Typography variant="caption">{name}</Typography>
        <Typography
          variant="caption"
          sx={{
            fontWeight: 400,
            textTransform: 'capitalize',
          }}
        >
          {typeof value}
        </Typography>
      </Stack>
      <Typography variant="subtitle1" sx={{ overflowWrap: 'break-word' }}>
        {String(value)}
      </Typography>
    </Stack>
  );
};

export const ItemDetailsTitle = ({
  sourcePage,
  onClose,
  onEditClick,
  onAddBalanceClick,
  onRemoveBalanceClick,
  onTransferBalanceClick,
  item,
}: {
  sourcePage: string;
  onClose: () => void;
  onEditClick: () => void;
  onAddBalanceClick: () => void;
  onRemoveBalanceClick: () => void;
  onTransferBalanceClick: () => void;
  item: Item;
}) => (
  <Stack
    direction="row"
    sx={{
      justifyContent: 'space-between',
      alignItems: 'center',
    }}
  >
    <Breadcrumbs>
      {sourcePage && <Typography variant="body2">{sourcePage}</Typography>}
      <Typography variant="body2" color="text.primary">
        {item.name} Details
      </Typography>
    </Breadcrumbs>
    <Stack direction="row" gap={1}>
      <IconButton
        onClick={onEditClick}
        data-testid={'item-details-edit-button'}
      >
        <PencilSimple weight="duotone" size={20} />
      </IconButton>
      <Divider flexItem variant="middle" orientation="vertical" />
      <Button variant="outlined" color="secondary" onClick={onAddBalanceClick}>
        Add Balance
      </Button>
      <IconButton onClick={onTransferBalanceClick}>
        <PaperPlaneTilt weight="duotone" size={20} />
      </IconButton>
      <IconButton onClick={onRemoveBalanceClick}>
        <Flame weight="duotone" size={20} />
      </IconButton>
      <Divider flexItem variant="middle" orientation="vertical" />
      <IconButton onClick={() => onClose()}>
        <X size={20} />
      </IconButton>
    </Stack>
  </Stack>
);

export const ItemDetails = ({
  item,
  collectionAddress,
  open,
  onClose,
  sourcePage,
}: {
  item: Item;
  collectionAddress: Collection['address'];
  open: boolean;
  onClose: () => void;
  sourcePage: string;
}) => {
  return (
    <Stack
      gap={2}
      sx={{
        width: '100%',
      }}
    >
      <Stack
        sx={{
          position: 'relative',
          width: '100%',
        }}
      >
        <Box
          sx={(theme) => ({
            position: 'absolute',
            background: theme.palette.background.cool,
            height: '64px',
            width: 'calc(100% + 32px)',
            right: '-16px',
            top: '8px',
            borderRadius: 2,
          })}
        />
        <StyledImage
          alt={`${item.name} Icon`}
          src={item.image}
          width="64px"
          height="64px"
        />
        <Stack
          direction="row"
          gap={1}
          mt={2}
          sx={{
            alignItems: 'baseline',
          }}
        >
          <Typography variant="h5">{item.name}</Typography>
          <Typography variant="subtitle2" color="text.secondary">
            {item.id}
          </Typography>
        </Stack>
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{ maxWidth: '65%', overflowWrap: 'break-word' }}
        >
          {item.description}
        </Typography>
      </Stack>
      <Stack
        direction="row"
        gap={2}
        sx={(theme) => ({
          border: `1px solid ${theme.palette.divider}`,
          borderRadius: 3,
          padding: 2,
        })}
      >
        <InfoItem
          label="Created"
          value={format(new Date(item.createdAt), 'MM/dd/yyyy')}
        />
        {item.blockchainId && (
          <InfoItem
            label="Contract Address"
            value={formatAddress(item.address || 'N/A')}
            link={`${
              BlockchainExplorerBaseUrls[item.blockchainId]
            }/token/${collectionAddress}?a=${item.address}`}
          />
        )}
      </Stack>
      <Box
        sx={(theme) => ({
          border: `1px solid ${theme.palette.divider}`,
          borderRadius: 2,
          padding: 2,
        })}
      >
        <Collapsible
          titleContent={
            <Typography variant="subtitle2">
              Attributes ({Object.keys(item.publicMetadata).length})
            </Typography>
          }
        >
          <Stack
            direction="row"
            gap={1}
            sx={{
              flexWrap: 'wrap',
              marginTop: 1.25,
            }}
          >
            {Object.keys(item.publicMetadata).map((prop) => (
              <Property
                key={prop}
                name={prop}
                value={item.publicMetadata[prop]}
              />
            ))}
          </Stack>
        </Collapsible>
      </Box>
    </Stack>
  );
};
