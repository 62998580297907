import {
  FormControl,
  FormControlProps,
  SxProps,
  Typography,
} from '@mui/material';
import { Theme, useTheme } from '@mui/material/styles';
import { Input, InputLabel } from '@stardust-monorepo/web-sdk-apps-shared';
import { ReactNode } from 'react';
import { Controller } from 'react-hook-form';
import { Control } from 'react-hook-form/dist/types/form';

export const formFieldInputWidth = {
  small: '96px',
  normal: '512px',
} as const;

export interface FormFieldProps {
  //TODO ideally this type would narrow formControlName to keys of "control"
  formControlName: string;
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any>;
  label?: string;
  subtitle?: string;
  sx?: SxProps<Theme>;
  inputSx?: SxProps<Theme>;
  fullWidth?: boolean;
  startAdornment?: ReactNode;
  endAdornment?: ReactNode;
  type?: string;
  renderHelpText?: () => ReactNode;
  autoComplete?: string;
  margin?: 'dense' | 'normal' | 'none';
  maxInputWidth?: keyof typeof formFieldInputWidth | 'unset';
  /**
   * //there are cases where we want the border but don't necessarily have a message for this field. in these cases,
   * error styling can be applied with the `hasError` prop
   */
  hasError?: boolean;
  id?: string;
  //applies readonly styling to the field. does not disable
  readonly?: boolean;
  disabled?: boolean;
  placeholder?: string;
  rows?: number;
  color?: Extract<'success', FormControlProps['color']> | undefined;
}

//TODO support an uncontrolled input
//TODO this is a WIP - composition by rendering the input via children would probably be more flexible
export const FormField = ({
  formControlName,
  control,
  label,
  subtitle,
  sx = {},
  inputSx = {},
  margin = 'normal',
  maxInputWidth = 'normal',
  fullWidth = true,
  startAdornment,
  endAdornment,
  type,
  renderHelpText,
  autoComplete,
  hasError,
  id,
  disabled = false,
  placeholder,
  rows,
  readonly = false,
  color,
}: FormFieldProps) => {
  const inputId = id || formControlName; //TODO auto-incrementing id?
  const helpTextNode = renderHelpText?.();
  const theme = useTheme();
  return (
    <Controller
      control={control}
      name={formControlName}
      render={({ field: { ref, ...fieldProps } }) => (
        <FormControl
          sx={{ ...sx }}
          margin={margin}
          error={hasError}
          color={color}
          disabled={disabled}
        >
          <>
            {label && <InputLabel htmlFor={inputId}>{label}</InputLabel>}
            {subtitle && (
              <Typography
                variant="helperText"
                color={'text.secondary'}
                sx={{ marginBottom: 1 }}
              >
                {subtitle}
              </Typography>
            )}
            <Input
              {...fieldProps}
              sx={{
                borderRadius: 2,
                '& .MuiInputBase-input': {
                  borderRadius: 2,
                  ...(readonly
                    ? {
                        backgroundColor: theme.palette.background.cool,
                        '& .MuiInputBase-input': {
                          color: `${theme.palette.text.primary} !important`,
                          WebkitTextFillColor: `${theme.palette.text.primary} !important`,
                        },
                      }
                    : {}),
                },
                ...(maxInputWidth === 'normal'
                  ? { maxWidth: formFieldInputWidth.normal }
                  : maxInputWidth === 'small'
                  ? { maxWidth: formFieldInputWidth.small }
                  : {}),
                ...inputSx,
              }}
              startAdornment={startAdornment}
              endAdornment={endAdornment}
              type={type}
              autoComplete={autoComplete}
              inputRef={ref}
              id={inputId}
              fullWidth={fullWidth}
              disabled={disabled}
              placeholder={placeholder}
              multiline={!!rows}
              rows={rows}
              onWheel={(event) => {
                // For number types, scrolling in the input changes the
                // value, but it has been leading to unintended changes
                // when staying focused and scrolling the page.
                event.currentTarget.querySelector('input')?.blur();
              }}
            />
            {helpTextNode}
          </>
        </FormControl>
      )}
    />
  );
};
