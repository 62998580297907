import { Alert, AlertColor, AlertProps } from '@mui/material';

import { color } from '../../theming/color';

const filledColorBorderColorMap: Record<AlertColor, string> = {
  success: color?.intent.success.dark,
  warning: color?.intent.warning.dark,
  info: color?.brand.primary[900],
  error: color.intent.error.dark,
};

export const Banner = (props: AlertProps) => {
  return (
    <Alert
      {...props}
      icon={false}
      sx={{
        justifyContent: 'center',
        borderRadius: 0,
        borderTop: 0,
        borderRight: 0,
        borderLeft: 0,
        '& .MuiAlert-message': {
          margin: 'auto',
        },
        '& .MuiAlert-action': {
          marginLeft: 'unset',
        },
        ...(props.variant === 'filled'
          ? {
              borderBottom: `1px solid ${
                filledColorBorderColorMap[props?.color || 'info']
              }`,
            }
          : {}),
        ...(props.sx || {}),
      }}
    />
  );
};
