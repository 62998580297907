import { Typography } from '@mui/material';
import { Item } from '@stardust-monorepo/types/sd-core';
import {
  Spinner,
  track,
  useHandleError,
  useItems,
  useAddItemBalance,
  useToast,
} from '@stardust-monorepo/web-sdk-apps-shared';
import React, { useRef } from 'react';

import { BaseBalanceModal } from './BaseBalanceModal';
import { ItemBalanceForm, ItemBalanceFormValue } from './ItemBalanceForm';

export const AddItemBalance = ({
  gameId,
  onClose,
  collectionId,
  ...props
}: {
  gameId: number;
  collectionId?: string;
  item?: Item;
  playerId?: string;
  onClose: () => void;
  open: boolean;
  lockItems?: boolean;
}) => {
  const handleError = useHandleError();
  const submitFormButtonRef = useRef<HTMLButtonElement | null>(null);
  const {
    data: items,
    error: itemsError,
    isLoading: itemsLoading,
  } = useItems({
    gameId,
    collectionId,
  });

  const mintItem = useAddItemBalance();
  const toaster = useToast();

  const doMintItem = async (formValues: ItemBalanceFormValue) => {
    const trackingData = {
      gameId,
      playerId: formValues.recipient,
      itemId: formValues.item.id,
    };
    try {
      await mintItem.mutateAsync({
        playerId: formValues.recipient,
        amount: String(formValues.quantity),
        itemId: formValues.item.id,
      });
      track('Item Mint', 'Success', trackingData);
      toaster(
        `Balance added successfully for ${formValues.item.name}`,
        'success'
      );
      onClose();
    } catch (e) {
      handleError(
        e,
        `An error occurred while adding balance for ${formValues.item.name}`,
        'Item Mint',
        trackingData
      );
    }
  };
  return (
    <BaseBalanceModal
      {...props}
      onClose={onClose}
      isLoading={mintItem.isLoading}
      gameId={gameId}
      dialogLabel="Add Balance"
      dialogSublabel="Create and award a balance of an item to a player or other wallet. This step mints a token to the player."
      submitFormRef={submitFormButtonRef}
    >
      {itemsLoading ? (
        <Spinner />
      ) : itemsError ? (
        <Typography>An error occurred while loading Items</Typography>
      ) : (
        <ItemBalanceForm
          {...props}
          itemList={items?.results || []}
          submitFormRef={submitFormButtonRef}
          onSave={doMintItem}
          quantitySubtitle="The amount of items to mint"
        />
      )}
    </BaseBalanceModal>
  );
};
