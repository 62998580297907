import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { NewPasswordForm } from './NewPasswordForm';
import { ReceiveResetCodeForm } from './ReceiveResetCodeForm';

export const passwordResetSuccessParam = 'reset';

export const ForgotPassword = () => {
  const [email, setEmail] = useState<string>();
  const navigate = useNavigate();

  return email ? (
    <NewPasswordForm
      email={email}
      onResetSuccess={() => {
        navigate(`..?${passwordResetSuccessParam}=true`);
      }}
    />
  ) : (
    <ReceiveResetCodeForm
      onSubmitSuccess={(email) => {
        setEmail(email);
      }}
    />
  );
};
