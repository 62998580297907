import { z } from 'zod';

import { TokenPropertyRecordSchema } from './token';
import { TokenTypeSchema } from './token-type';

export const TemplateSchema = z
  .object({
    activeListing: z.boolean(),
    gameId: z.optional(z.number()),
    id: z.number(),
    cap: z.string(),
    totalSupply: z.string(),
    name: z.string(),
    royalty: z.nullable(z.number()),
    type: TokenTypeSchema,
    props: z.object({
      immutable: TokenPropertyRecordSchema,
      mutable: TokenPropertyRecordSchema.and(
        z.object({
          description: z.optional(z.string()),
          image: z.string(),
        })
      ),
      $mutable: TokenPropertyRecordSchema,
    }),
    image: z.optional(z.string().url()),
  })
  .describe('TemplateSchema');
export type Template = z.infer<typeof TemplateSchema>;
