import {
  paginatedResultsWithFilterSchema,
  transformRecordToPaginatedResult,
} from '@stardust-monorepo/types/common';
import { IdentityIdSchema } from '@stardust-monorepo/types/iam';
import { z } from 'zod';

export const ApplicationSchema = z
  .object({
    id: z.string(),
    name: z.string(),
    email: z.string(),
    description: z.optional(z.string()),
    identityId: IdentityIdSchema,
    rootUserId: IdentityIdSchema,
    apiKey: z.optional(z.string()), //TODO we could extract a type for a NewApplication where this is not optional but the usage becomes more complicated when editing
  })
  .describe('ApplicationSchema');
export type Application = z.infer<typeof ApplicationSchema>;

export const ApplicationResultsSchema = paginatedResultsWithFilterSchema(
  ApplicationSchema
)
  .or(ApplicationSchema.transform(transformRecordToPaginatedResult))
  .describe('ApplicationResultsSchema');
export type ApplicationResults = z.infer<typeof ApplicationResultsSchema>;
