import { Box, Stack, Typography } from '@mui/material';

export interface ChartLegendProps {
  items: {
    name: string;
    color: string;
  }[];
}

export const ChartLegend = ({ items }: ChartLegendProps) => (
  <Stack direction="row" gap={2} sx={{ justifyContent: 'center' }}>
    {items.map((item, index) => (
      <Stack key={index} direction="row" gap={1} sx={{ alignItems: 'center' }}>
        <Box
          sx={{
            width: 12,
            height: 12,
            border: '1px solid',
            borderColor: item.color,
            borderRadius: 0.75,
            clear: 'both',
            backgroundColor: item.color,
            flexGrow: 0,
          }}
        >
          &nbsp;
        </Box>
        <Typography variant="body2" color="text.secondary">
          {item.name}
        </Typography>
      </Stack>
    ))}
  </Stack>
);
