import { SdPrivateApi } from '@stardust-monorepo/web-sdk-apps-shared';
import {
  useMutation,
  useQueries,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';

export const templateKeys = {
  templates: (
    gameId: number,
    start?: number,
    limit?: number,
    filter?: string
  ) =>
    [
      'templates',
      {
        gameId,
      },
      ...(start || limit || filter ? [{ start, limit, filter }] : []),
    ] as const,
  template: (gameId: number, templateId: number) =>
    ['template', { gameId, templateId }] as const,
  templateCount: (gameId: number, filter?: string) =>
    ['templates-count', { gameId }, ...(filter ? [{ filter }] : [])] as const,
};

export function useTemplates(
  args: {
    gameId: number | undefined;
    start?: number;
    limit?: number;
    filter?: string;
  },
  enabled = true
) {
  return useQuery({
    queryKey: templateKeys.templates(
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      args.gameId!,
      args.start,
      args.limit,
      args.filter
    ),
    queryFn: () =>
      SdPrivateApi.getTemplates(
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        args.gameId!,
        args.start,
        args.limit,
        args.filter
      ),
    enabled: enabled && args.gameId !== undefined,
  });
}

export function useTemplate(args: {
  gameId: number | undefined;
  templateId: number | undefined;
}) {
  return useQuery({
    queryKey: templateKeys.template(
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      args.gameId!,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      args.templateId!
    ),
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    queryFn: () => SdPrivateApi.getTemplate(args.gameId!, args.templateId!),
    enabled: args.gameId !== undefined && args.templateId !== undefined,
  });
}

export function useSelectedTemplates(args: {
  gameId: number | undefined;
  templateIds: number[];
}) {
  return useQueries({
    queries: args.templateIds.map((templateId) => ({
      queryKey: templateKeys.template(
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        args.gameId!,
        templateId
      ),
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      queryFn: () => SdPrivateApi.getTemplate(args.gameId!, templateId),
      enabled: args.gameId !== undefined,
    })),
  });
}

export function useTemplateCount(
  args: {
    gameId: number | undefined;
    filter?: string;
  },
  enabled = true
) {
  return useQuery({
    queryKey: templateKeys.templateCount(
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      args.gameId!,
      args.filter
    ),
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    queryFn: () => SdPrivateApi.getTemplateCount(args.gameId!, args.filter),
    enabled: enabled && args.gameId !== undefined,
  });
}

export function useDeleteTemplate() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: SdPrivateApi.deleteTemplate,
    onSuccess: (data, variables) => {
      // Refresh the template list
      queryClient.invalidateQueries({
        queryKey: templateKeys.templates(variables.gameId),
      });
      queryClient.invalidateQueries({
        queryKey: templateKeys.templateCount(variables.gameId),
      });
    },
  });
}

export function useCreateTemplate() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: SdPrivateApi.createTemplate,
    onSuccess: (data, variables) => {
      // Refresh the template list
      queryClient.invalidateQueries({
        queryKey: templateKeys.templates(variables.gameId),
      });
      queryClient.invalidateQueries({
        queryKey: templateKeys.templateCount(variables.gameId),
      });
    },
  });
}

export function useMutateTemplate() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: SdPrivateApi.mutateTemplate,
    onSuccess: (data, variables) => {
      // Refresh the template list
      queryClient.invalidateQueries({
        queryKey: templateKeys.templates(variables.gameId),
      });
      queryClient.invalidateQueries({
        queryKey: templateKeys.template(variables.gameId, variables.templateId),
      });
    },
  });
}

export function useDeleteTemplateProperty() {
  return useMutation({
    mutationFn: SdPrivateApi.deleteTemplateProperty,
  });
}

export function useToggleTemplateActive() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: SdPrivateApi.toggleTemplateActive,
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({
        queryKey: templateKeys.templates(variables.gameId),
      });
      queryClient.invalidateQueries({
        queryKey: templateKeys.template(variables.gameId, variables.templateId),
      });
    },
  });
}
