import { DotsThree } from '@phosphor-icons/react';

import { MenuAction } from './basic-menu';
import { IconMenu } from './icon-menu';

export interface MoreActionsProps {
  actions: MenuAction[];
  size?: 'small' | 'large';
}

export const MoreActions = ({ actions, size = 'large' }: MoreActionsProps) => (
  <IconMenu
    actions={actions}
    icon={<DotsThree weight="regular" size={size === 'small' ? 20 : 24} />}
    label="more"
    size={size}
  />
);

export const moreActionsTableHeader = {
  small: { label: '', width: '52px' },
  large: { label: '', width: '60px' },
};
