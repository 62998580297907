import { useForkRef } from '@mui/material';
import { cloneElement, CSSProperties, forwardRef, useRef } from 'react';
import { Transition as RtsTransition } from 'react-transition-group';

const defaultStyle = {
  transition: `opacity 150ms ease, transform 300ms ease, -webkit-transform 300ms ease`,
  transitionDelay: '100ms',
  opacity: 0,
};

type TransitionState =
  | 'unmounted'
  | 'entering'
  | 'entered'
  | 'exiting'
  | 'exited';

type TransitionDirection = 'up' | 'right';

const getTransitionStyles: (
  direction: TransitionDirection
) => Record<TransitionState, CSSProperties> = (
  direction: TransitionDirection
) => ({
  entering: {
    transform: 'translateY(0) scale(1)',
    opacity: 1,
  },
  entered: {
    transform: 'translateY(0) scale(1)',
    opacity: 1,
  },
  exiting: {
    transform:
      direction === 'right'
        ? 'translateX(15%) scale(0.98)'
        : 'translateY(15%) scale(0.98)',
    opacity: 0,
  },
  exited: {
    transform:
      direction === 'right'
        ? 'translateX(15%) scale(0.98)'
        : 'translateY(15%) scale(0.98)',
    opacity: 0,
  },
  unmounted: {},
});

export const SoftSlide = forwardRef(function SoftSlide(
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  props: any & { direction: TransitionDirection | undefined }, //sorry but the RTG types are giving me too much trouble and im not using this component directly
  ref
) {
  const {
    children,
    in: inProp,
    style,
    TransitionComponent = RtsTransition,
    direction = 'up',
    ...other
  } = props;

  const childrenRef = useRef(null);
  const handleRef = useForkRef(children.ref, childrenRef, ref);

  return (
    <TransitionComponent nodeRef={childrenRef} in={inProp} {...other}>
      {(state: TransitionState, childProps: Iterable<unknown>) => {
        return cloneElement(children, {
          ref: handleRef,
          style: {
            visibility: state === 'exited' && !inProp ? 'hidden' : undefined,
            ...children.props.style,
            ...defaultStyle,
            ...getTransitionStyles(direction)[state],
          },
          ...childProps,
        });
      }}
    </TransitionComponent>
  );
});
