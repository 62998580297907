import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import {
  createApiKey,
  deleteIdentifier,
  getIdentifiers,
  GetIdentifiersArgs,
} from '../identifier-api';

export const identifierRootKey = 'identifier' as const;
export const identifierKeys = {
  identifiers: ({
    rootUserId,
    identityId,
    type,
    value,
    start,
    limit,
  }: GetIdentifiersArgs) => [
    identifierRootKey,
    ...(rootUserId ? [{ rootUserId: rootUserId }] : []),
    ...(identityId ? [{ identityId: identityId }] : []),
    ...(type || value ? [{ type, value }] : []),
    ...(start || limit ? [{ start, limit }] : []),
  ],
};

export const useIdentifiers = (args: GetIdentifiersArgs, enabled = true) => {
  return useQuery({
    queryKey: identifierKeys.identifiers(args),
    queryFn: () => getIdentifiers(args),
    enabled,
  });
};

export const useDeleteIdentifier = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: deleteIdentifier,
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries(
        identifierKeys.identifiers({
          rootUserId: variables.rootUserId,
        })
      );
    },
  });
};

//exported for convenience
export const useDeleteApiKey = useDeleteIdentifier;

export const useCreateApiKey = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: createApiKey,
    onSuccess: (data) => {
      queryClient.invalidateQueries(
        identifierKeys.identifiers({
          rootUserId: data.rootUserId,
        })
      );
      queryClient.invalidateQueries(
        identifierKeys.identifiers({
          identityId: data.identityId,
        })
      );
    },
  });
};
