import { Alert } from '@mui/material';
import React from 'react';

export interface ErrorAlertProps {
  error?: Error;
}

export const ErrorAlert = ({ error }: ErrorAlertProps) => {
  return error ? (
    <Alert severity="error">
      {error.message
        ? `Unexpected error: ${error.message}`
        : 'Unexpected error. Please try again.'}
    </Alert>
  ) : null;
};
