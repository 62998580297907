import { z } from 'zod';

import { BlockchainNameSchema } from './blockchain';
import { CurrencySchema } from './currency';
import { getSearchResultSchemaWithDataType } from './search';
import { TokenTypeSchema } from './token-type';

const ListingStatusSchema = z
  .enum(['open', 'pending', 'cancelled', 'completed'])
  .describe('ListingStatusSchema');
export type ListingStatus = z.infer<typeof ListingStatusSchema>;

const ListingTemplateSchema = z
  .object({
    id: z.number(),
    name: z.string(),
    type: TokenTypeSchema,
    image: z.string(),
    description: z.string(),
  })
  .describe('ListingTemplateSchema');

const ListingGameSchema = z
  .object({
    id: z.number(),
    name: z.string(),
    image: z.string(),
    blockchain: BlockchainNameSchema, //game/get-all uses the full Blockchain object
  })
  .describe('ListingGameSchema');
export type ListingGame = z.infer<typeof ListingGameSchema>;

export const ListingSchema = z
  .object({
    id: z.number(),
    status: ListingStatusSchema, //TODO this is state in other order endpoints
    price: z.string(),
    currency: z.string(), //TODO this is currencyISOCode in other order endpoints
    token: z.object({
      id: z.number(),
      amount: z.string(),
    }),
    game: ListingGameSchema,
    template: ListingTemplateSchema,
    seller: z.object({
      id: z.string(),
      walletAddress: z.string(),
    }),
  })
  .describe('ListingSchema');
export type Listing = z.infer<typeof ListingSchema>;

export const BlockchainCurrenciesSchema = z
  .object({
    blockchain: BlockchainNameSchema,
    currencies: z.array(CurrencySchema),
  })
  .describe('BlockchainCurrenciesSchema');
export type BlockchainCurrencies = z.infer<typeof BlockchainCurrenciesSchema>;

export const ListingResultSchema = getSearchResultSchemaWithDataType(
  ListingSchema
)
  .extend({
    currencies: BlockchainCurrenciesSchema.array(),
  })
  .describe('ListingResultSchema');
export type ListingResult = z.infer<typeof ListingResultSchema>;
