import { Plugs } from '@phosphor-icons/react';
import { color, SmallIconStat } from '@stardust-monorepo/web-sdk-apps-shared';

export const OverviewApiCallsCard = ({
  currentApiCalls,
  priorApiCalls,
}: {
  currentApiCalls: number;
  priorApiCalls: number;
}) => {
  return (
    <SmallIconStat
      Icon={Plugs}
      iconColor={color.accent.blue[900]}
      iconBackgroundColor={color.accent.blue[50]}
      statValue={currentApiCalls}
      priorValue={priorApiCalls}
      title="API Calls: Total"
    />
  );
};
