export const getGameImageUrl = (
  gameBucketName: string,
  imageName: string,
  width = 100,
  height = 100
): string => {
  const url = process.env['NX_CLOUDFRONT_URL'];
  const request = {
    bucket: process.env['NX_SD_BUCKET_NAME'],
    key: `${gameBucketName}/${imageName}`,
    edits: { resize: { width, height, fit: 'inside' } },
  };
  const strRequest = JSON.stringify(request);
  const encRequest = btoa(strRequest);
  return `${url}/${encRequest}`;
};

export const validateImage = (url?: string) => {
  return !(!url || !url.match(/^(https|ipfs):\/\/.+\/.+$/));
};
