import {
  Checkout,
  CheckoutWithClientSecret,
  PaymentMethod,
} from '@stardust-monorepo/types/marketplace';

import { mockPurchasable } from './purchasable-mock';

export const mockPaymentMethod: PaymentMethod = {
  id: 'b29af3b6-4876-4bfd-a68a-0505d91aed09',
  billing_details: {
    address: {
      city: null,
      country: 'US',
      line1: null,
      line2: null,
      postal_code: '97214',
      state: null,
    },
  },
  card: {
    brand: 'visa',
    exp_month: 5,
    exp_year: 2030,
    last4: '9999',
  },
};

export const mockCheckout: Checkout = {
  uuid: 'b29af3b6-4876-4bfd-a68a-0505d91aed09',
  playerId: 'b3cf05e1-925f-4d72-9406-004b2f400abe',
  items: {
    purchasables: [
      {
        id: mockPurchasable.id,
        amount: '1',
      },
    ],
  },
  price: '2000',
  finished: false,
  createdAt: '2022-09-26T19:55:45.398Z',
  externalServiceData: {},
};

export const mockCreateCheckoutResponse: CheckoutWithClientSecret = {
  checkout: mockCheckout,
  stripePaymentIntent: {
    client_secret: '123456',
  },
};

export const mockCreateCheckoutResponseWithPaymentMethod: CheckoutWithClientSecret =
  {
    ...mockCreateCheckoutResponse,
    paymentMethods: {
      data: [mockPaymentMethod],
    },
  };

export const mockFinishedCheckout: Checkout = {
  uuid: 'b29af3b6-4876-4bfd-a68a-0505d91aed09',
  playerId: 'b3cf05e1-925f-4d72-9406-004b2f400abe',
  items: {
    purchasables: [
      {
        id: mockPurchasable.id,
        amount: '1',
      },
    ],
  },
  price: '2000',
  finished: true,
  createdAt: '2022-09-26T19:55:45.398Z',
  externalServiceData: {},
};
