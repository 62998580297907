import { z } from 'zod';

import { TokenDetailsWithQuantity } from './token';

export const PlayerSchema = z.object({
  id: z.string(),
  image: z.string(),
});
export type Player = z.infer<typeof PlayerSchema>;

export interface PlayerGameInventory {
  gameId: number;
  gameName: string;
  gameIcon: string;
  userItems: TokenDetailsWithQuantity[];
}
