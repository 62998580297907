import { StyledComponent } from '@emotion/styled';
import {
  styled,
  InputLabel as MuiInputLabel,
  InputLabelProps,
} from '@mui/material';

//TODO remove and replace usages with MuiInputLabel
export const InputLabel: StyledComponent<InputLabelProps> = styled(
  (props: InputLabelProps) => <MuiInputLabel {...props} />
)(({ theme }) => ({}));
