import CircleIcon from '@mui/icons-material/Circle';
import {
  createTheme,
  linearProgressClasses,
  Theme,
  ThemeOptions,
} from '@mui/material';
import {
  CheckCircle,
  Info,
  Warning,
  WarningCircle,
} from '@phosphor-icons/react';
import { CSSProperties } from 'react';

import { color } from './color';

const themeOptions: ThemeOptions = {
  typography: {
    fontFamily: [
      'DM Sans',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    h1: {
      fontSize: 64,
      fontWeight: 700,
      lineHeight: '83px',
    },
    h2: {
      fontSize: 48,
      fontWeight: 500,
      lineHeight: '60px',
    },
    h3: {
      fontSize: 36,
      fontWeight: 700,
      lineHeight: '44px',
    },
    h4: {
      fontSize: 24,
      fontWeight: 700,
      lineHeight: '28px',
    },
    h5: {
      fontWeight: 700,
      fontSize: 20,
      lineHeight: '24px',
    },
    h6: {
      fontWeight: 700,
      fontSize: 18,
      lineHeight: '23px',
    },
    subtitle1: {
      fontWeight: 500,
      fontSize: 16,
      lineHeight: '21px',
    },
    subtitle2: {
      fontWeight: 500,
      fontSize: 14,
      lineHeight: '18px',
    },
    body1: {
      fontWeight: 400,
      fontSize: 16,
      lineHeight: '21px',
    },
    body2: {
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '18px',
    },
    button: {
      fontWeight: 500,
      fontSize: 14,
      lineHeight: '18px',
    },
    overline: {
      fontWeight: 700,
      fontSize: 12,
      lineHeight: '16px',
      textTransform: 'uppercase',
    },
    caption: {
      fontWeight: 500,
      fontSize: 12,
      lineHeight: '16px',
    },
    inputLabel: {
      fontWeight: 500,
      fontSize: 12,
      lineHeight: '16px',
    },
    inputText: {
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '18px',
    },
    helperText: {
      fontWeight: 400,
      fontSize: 12,
      lineHeight: '14px',
    },
    tooltip: {
      fontWeight: 500,
      fontSize: 14,
      lineHeight: '21px',
    },
  },
  components: {
    MuiCardActions: {
      defaultProps: {
        disableSpacing: true,
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          height: '20px',
          lineHeight: '18px',
        },
        root: ({ theme }) => ({
          '&.MuiInputBase-colorSuccess': {
            borderColor: theme.palette.success.dark,
          },
        }),
      },
    },
    MuiInputLabel: {
      defaultProps: {
        size: 'normal',
        shrink: true,
      },
      styleOverrides: {
        root: ({ theme }) => ({
          fontWeight: theme.typography.inputLabel.fontWeight,
          fontSize: theme.typography.inputLabel.fontSize,
          lineHeight: theme.typography.inputLabel.lineHeight,
          transform: 'none',
          position: 'relative',
          display: 'flex',
          color: theme.palette.text.primary,
          //TODO why cant i define 'disabled' as a member of styleOverrides instead????????
          '+.MuiInputBase-root': {
            marginTop: 8,
          },
          '&.Mui-disabled': {
            color: theme.palette.text.primary,
          },
        }),
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          border: `1px solid ${color.neutral[300]}`,
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          fontSize: '50rem',
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        //TODO we should be able to use the error rule instead of root for applying error colors but it doesn't seem to be working
        root: ({ ownerState, theme }) => ({
          marginLeft: '0',
          ...(ownerState.error
            ? { color: `${theme.palette.text.error} !important` }
            : {}),
        }),
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          display: 'flex',
          alignItems: 'center',
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: ({ theme }) => ({
          width: '48px',
          height: '24px',
          padding: 0,
          '& .MuiSwitch-switchBase': {
            padding: 0,
            margin: 4,
            transitionDuration: '300ms',
            '&.Mui-checked': {
              transform: 'translateX(24px)',
              '& .MuiSwitch-thumb': {
                backgroundColor: theme.palette.background.default,
              },
              '& + .MuiSwitch-track': {
                backgroundColor: theme.palette.primary.main,
                border: '1px solid #4422DE',
                opacity: 1,
              },
              '&.Mui-disabled + .MuiSwitch-track': {
                backgroundColor: color.neutral[300],
                border: `1px solid ${color.neutral[400]}`,
                opacity: 1,
              },
            },
            '&.Mui-disabled .MuiSwitch-thumb': {
              backgroundColor: theme.palette.background.default,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
              backgroundColor: color.neutral[300],
              border: `1px solid ${color.neutral[400]}`,
              opacity: 1,
            },
            '&:hover': {
              boxShadow: theme.shadows[2],
            },
          },
          '& .MuiSwitch-thumb': {
            boxSizing: 'border-box',
            width: '16px',
            height: '16px',
            boxShadow: 'none',
          },
          '& .MuiSwitch-track': {
            borderRadius: '100px',
            height: 'auto',
            backgroundColor: color.neutral[400],
            border: `1px solid ${color.neutral[500]}`,
            opacity: 1,
            transition: theme.transitions.create(['background-color'], {
              duration: 500,
            }),
          },
        }),
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        root: {
          overflow: 'unset',
        },
      },
    },
    MuiLink: {
      defaultProps: {
        underline: 'hover',
      },
      styleOverrides: {
        //TODO should this be a separate component or typography variant?
        underlineNone: ({ theme }) => ({
          transition: 'color 0.3s ease',
          color: theme.palette.text.secondary,
          '&:hover': {
            color: theme.palette.text.link,
          },
        }),
      },
    },
    MuiBreadcrumbs: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.text.primary,
        }),
        separator: ({ theme }) => ({
          color: theme.palette.text.secondary,
        }),
      },
    },
    MuiAlert: {
      defaultProps: {
        iconMapping: {
          error: (
            <WarningCircle
              size={20}
              weight="duotone"
              color={color.intent.error.dark}
            />
          ),
          warning: (
            <Warning
              size={20}
              weight="duotone"
              color={color.intent.warning.dark}
            />
          ),
          success: (
            <CheckCircle
              size={20}
              weight="duotone"
              color={color.intent.success.dark}
            />
          ),
          info: (
            <Info size={20} weight="duotone" color={color.intent.info.dark} />
          ),
        },
      },
      styleOverrides: {
        message: {
          display: 'flex',
          alignItems: 'center',
        },
        root: ({ theme }: { theme: Theme }) => ({
          paddingTop: theme.spacing(0.5),
          paddingBottom: theme.spacing(0.5),
        }),
        outlinedWarning: ({ theme }: { theme: Theme }) => ({
          border: `1px solid ${theme.palette.warning.main}`,
          borderRadius: theme.shape.borderRadius * 3,
          backgroundColor: theme.palette.background.white,
          color: theme.palette.text.warning,
        }),
        filledWarning: ({ theme }: { theme: Theme }) => ({
          backgroundColor: theme.palette.warning.main,
          color: color.intent.warning.dark,
        }),
        standardWarning: ({ theme }: { theme: Theme }) => ({
          border: `1px solid ${theme.palette.warning.main}`,
          borderRadius: theme.shape.borderRadius * 3,
          backgroundColor: theme.palette.warning.light,
          color: theme.palette.text.warning,
          '& .MuiAlert-icon': {
            color: theme.palette.text.warning,
          },
        }),
        outlinedInfo: ({ theme }: { theme: Theme }) => ({
          border: `1px solid ${theme.palette.info.main}`,
          borderRadius: theme.shape.borderRadius * 3,
          backgroundColor: theme.palette.background.white,
          color: theme.palette.text.info,
        }),
        filledInfo: ({ theme }: { theme: Theme }) => ({
          backgroundColor: color.brand.primary[800],
          color: theme.palette.text.white,
        }),
        standardInfo: ({ theme }: { theme: Theme }) => ({
          border: `1px solid ${theme.palette.info.main}`,
          borderRadius: theme.shape.borderRadius * 3,
          backgroundColor: theme.palette.info.light,
          color: theme.palette.text.info,
          '& .MuiAlert-icon': {
            color: theme.palette.text.info,
          },
        }),
        outlinedSuccess: ({ theme }: { theme: Theme }) => ({
          border: `1px solid ${theme.palette.success.main}`,
          borderRadius: theme.shape.borderRadius * 3,
          backgroundColor: theme.palette.background.white,
          color: theme.palette.text.success,
        }),
        filledSuccess: ({ theme }: { theme: Theme }) => ({
          backgroundColor: theme.palette.success.main,
          color: theme.palette.text.white,
        }),
        standardSuccess: ({ theme }: { theme: Theme }) => ({
          border: `1px solid ${theme.palette.success.main}`,
          borderRadius: theme.shape.borderRadius * 3,
          backgroundColor: theme.palette.success.light,
          color: theme.palette.text.success,
          '& .MuiAlert-icon': {
            color: theme.palette.text.success,
          },
        }),
        outlinedError: ({ theme }: { theme: Theme }) => ({
          border: `1px solid ${theme.palette.error.main}`,
          borderRadius: theme.shape.borderRadius * 3,
          backgroundColor: theme.palette.background.white,
          color: theme.palette.text.error,
        }),
        filledError: ({ theme }: { theme: Theme }) => ({
          backgroundColor: theme.palette.error.main,
          color: theme.palette.text.white,
        }),
        standardError: ({ theme }: { theme: Theme }) => ({
          border: `1px solid ${theme.palette.error.main}`,
          borderRadius: theme.shape.borderRadius * 3,
          backgroundColor: theme.palette.error.light,
          color: theme.palette.text.error,
          '& .MuiAlert-icon': {
            color: theme.palette.text.error,
          },
        }),
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        a: {
          textDecoration: 'none',
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&.MuiTableRow-hover': {
            '&:hover': {
              backgroundColor: color.accent.blue[50],
            },
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: ({ theme }: { theme: Theme }) => ({
          padding: theme.spacing(2),
          borderBottom: 'none',
          fontSize: 14,
          lineHeight: '18px',
        }),
        head: ({ theme }: { theme: Theme }) => ({
          backgroundColor: color.neutral[100],
          padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
          fontWeight: 500,
          fontSize: 12,
          lineHeight: '16px',
        }),
      },
    },
    MuiTabs: {
      styleOverrides: {
        flexContainer: {
          justifyContent: 'space-between',
        },
        root: ({ theme }: { theme: Theme }) => ({
          width: 'fit-content',
          borderWidth: '1px',
          borderStyle: 'solid',
          borderColor: theme.palette.primary.light,
          borderRadius: theme.shape.borderRadius * 2.5,
        }),
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            color: '#fff',
          },
          borderRadius: '0.5rem',
          textTransform: 'none',
          color: '#9b77ff',
          background: 'transparent',
          padding: '5px 30px',
          margin: `10px 10px`,
          fontWeight: 'bold',
          minWidth: 0,
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableTouchRipple: true,
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: ({ theme }: { theme: Theme }) => ({
          textTransform: 'none',
          transition: 'all 0.3s ease',
          '&.Mui-disabled': {
            backgroundColor: color.neutral[100],
            border: `1px solid ${color.neutral[200]}`,
            color: theme.palette.text.disabled,
          },
        }),
        containedSuccess: ({ theme }: { theme: Theme }) => ({
          backgroundColor: theme.palette.success.main,
          border: `1px solid rgba(25, 108, 52, 0.25)`,
          color: theme.palette.text.white,
          '&:hover, &:focus': {
            backgroundColor: theme.palette.success.main,
            border: `1px solid rgba(25, 108, 52, 0.5)`,
            boxShadow: '0px 2px 0px #309F5F',
            transform: 'translateY(-2px)',
          },
          '&:active': {
            borderColor: '#309F5F',
            backgroundColor: '#3CB974',
            boxShadow: 'none',
            transform: 'translateY(0)',
          },
        }),
        containedInfo: ({ theme }: { theme: Theme }) => ({
          backgroundColor: theme.palette.info.main,
          border: '1px solid rgba(0, 86, 146, 0.25)',
          color: theme.palette.text.white,
          '&:hover, &:focus': {
            backgroundColor: theme.palette.info.main,
            border: '1px solid rgba(0, 86, 146, 0.5)',
            boxShadow: '0px 2px 0px #0079B5',
            transform: 'translateY(-1px)',
          },
          '&:active': {
            backgroundColor: '#008AC6',
            border: '1px solid #0079B5',
            boxShadow: 'none',
            transform: 'translateY(0)',
          },
        }),
        containedWarning: ({ theme }: { theme: Theme }) => ({
          backgroundColor: theme.palette.warning.main,
          border: '1px solid rgba(152, 93, 39, 0.25)',
          color: 'rgba(0, 0, 0, 0.75)',
          '&:hover, &:focus': {
            backgroundColor: theme.palette.warning.main,
            border: '1px solid rgba(152, 93, 39, 0.5)',
            boxShadow: '0px 2px 0px #C1932E',
            transform: 'translateY(-1px)',
          },
          '&:active': {
            backgroundColor: '#D6AE31',
            border: '1px solid #C1932E',
            boxShadow: 'none',
            transform: 'translateY(0)',
          },
        }),
        containedError: ({ theme }: { theme: Theme }) => ({
          backgroundColor: theme.palette.error.main,
          border: '1px solid rgba(135, 33, 34, 0.25)',
          color: theme.palette.text.white,
          '&:hover, &:focus': {
            backgroundColor: theme.palette.error.main,
            border: '1px solid rgba(135, 33, 34, 0.5)',
            boxShadow: ' 0px 2px 0px #BD3C3D',
            transform: 'translateY(-1px)',
          },
          '&:active': {
            backgroundColor: '#D7494A',
            border: '1px solid #BD3C3D',
            boxShadow: 'none',
            transform: 'translateY(0)',
          },
        }),
        sizeLarge: {
          fontSize: '16px',
          borderRadius: 12,
          padding: '11px 24px',
        },
        sizeMedium: {
          fontSize: '14px',
          borderRadius: 10,
          padding: '6px 16px',
        },
        sizeSmall: {
          fontSize: '12px',
          borderRadius: 8,
          padding: '3px 12px',
        },
        contained: {
          backgroundColor: color.brand.primary[500],
          border: `1px solid ${color.brand.primary[600]}`,
          '&:hover, &:focus': {
            backgroundColor: color.brand.primary[500],
            border: `1px solid ${color.brand.primary[700]}`,
            boxShadow: '0px 2px 0px #362197',
            transform: 'translateY(-2px)',
          },
          '&:active': {
            backgroundColor: color.brand.primary[600],
            boxShadow: 'none',
            transform: 'translateY(0)',
          },
        },
        outlined: {
          color: color.neutral[900],
          border: `1px solid ${color.neutral[300]}`,
          backgroundColor: color.neutral.white,
          '&:hover, &:focus': {
            border: `1px solid ${color.neutral[400]}`,
            backgroundColor: color.neutral[100],
          },
          '&:active': {
            backgroundColor: color.neutral[200],
          },
        },
        outlinedPrimary: {
          color: color.brand.primary[500],
          border: `1px solid ${color.brand.primary[500]}`,
          backgroundColor: color.neutral.white,
          '&:hover, &:focus': {
            borderColor: color.brand.primary[500],
            backgroundColor: color.brand.primary[50],
          },
          '&:active': {
            backgroundColor: color.brand.primary[50],
          },
        },
        text: {
          backgroundColor: 'transparent',
          border: 'none',
          color: color.neutral[600],
          '&:hover, &:focus': {
            backgroundColor: color.neutral[100],
            color: color.neutral[900],
          },
          '&:active': {
            backgroundColor: color.neutral[300],
            color: color.neutral[900],
          },
        },
        //TODO these styles should probably be primary but they were added retroactively and shouldn't necessarily be the default
        textSecondary: {
          backgroundColor: 'transparent',
          border: 'none',
          color: color.brand.primary[500],
          '&:hover, &:focus': {
            backgroundColor: color.brand.primary[50],
            color: color.brand.primary[800],
          },
          '&:active': {
            backgroundColor: color.brand.primary[100],
            color: color.brand.primary[800],
          },
        },
        containedSecondary: () => ({
          fontWeight: 'bold',
          textTransform: 'none',
          borderRadius: '0.75rem',
        }),
        containedPrimary: {
          fontWeight: 500,
          textTransform: 'none',
          borderRadius: '0.75rem',
        },
      },
    },
    MuiCheckbox: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          color: '#D8DCE4',
          padding: 0,
        },
      },
    },
    MuiRadio: {
      defaultProps: {
        disableRipple: true,
        checkedIcon: <CircleIcon />,
      },
      styleOverrides: {
        root: ({ theme }: { theme: Theme }) => ({
          backgroundColor: theme.palette.background.white,
          border: `1px solid ${color.brand.primary[600]}`,
          padding: 0,
          '&:hover': {
            '&::before': {
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              content: '""',
              zIndex: -1,
              borderRadius: '50%',
              backgroundColor: '#DAD1F9',
            },
          },
          '&.Mui-disabled': {
            border: `1px solid ${color.neutral[200]}`,
            backgroundColor: color.neutral[100],
            '&.Mui-checked': {
              backgroundColor: color.neutral[100],
              '& .MuiSvgIcon-root': {
                color: color.neutral[300],
              },
            },
          },
          '& .MuiSvgIcon-root': {
            fontSize: 0,
          },
          '&.MuiRadio-root': {
            color: theme.palette.primary.main,
            transition: 'all 300ms',
          },
          '&.Mui-checked': {
            backgroundColor: theme.palette.primary.main,
            position: 'relative',
            '& .MuiSvgIcon-root': {
              color: theme.palette.background.white,
              transition: 'all 300ms',
            },
          },
        }),
      },
    },
    MuiFormControl: {
      styleOverrides: {
        marginNormal: ({ theme }: { theme: Theme }) => ({
          marginTop: theme.spacing(1.5),
          marginBottom: theme.spacing(1.5),
        }),
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: color.neutral[200],
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: ({ theme }: { theme: Theme }) => ({
          borderRadius: theme.shape.borderRadius * 2,
        }),
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: ({ theme }: { theme: Theme }) => ({
          minWidth: '20px',
          marginRight: theme.spacing(1.5),
        }),
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: ({ theme }: { theme: Theme }) => ({
          borderRadius: `${theme.shape.borderRadius * 2}px!important`,
        }),
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: ({ theme }: { theme: Theme }) => ({
          margin: `0 ${theme.spacing(0.5)}`,
          padding: `${theme.spacing(1)} ${theme.spacing(1.5)}`,
          borderRadius: theme.shape.borderRadius * 2,
          minWidth: '160px',
          '&:hover': {
            boxShadow: color.neutral['100'],
          },
          '.MuiListItemIcon-root': {
            fontSize: '16px',
            minWidth: '16px',
            marginRight: '10px',
          },
        }),
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          fontWeight: 500,
          fontSize: 11,
          lineHeight: '16px',
          height: '20px',
        },
        colorSuccess: ({ theme }) => ({
          backgroundColor: theme.palette.success.light,
          color: theme.palette.text.success,
        }),
        colorError: ({ theme }) => ({
          backgroundColor: theme.palette.error.light,
          color: theme.palette.text.error,
        }),
      },
    },
    MuiTooltip: {
      defaultProps: {
        arrow: true,
      },
      styleOverrides: {
        tooltip: ({ theme }: { theme: Theme }) => ({
          background: theme.palette.background.dark,
          padding: '10px 12px 12px',
          borderRadius: theme.shape.borderRadius * 3,
          boxShadow: theme.shadows[20],
          ...theme.typography.tooltip,
        }),
        arrow: ({ theme }: { theme: Theme }) => ({
          color: theme.palette.background.dark,
        }),
      },
    },
    MuiToggleButtonGroup: {
      styleOverrides: {
        root: ({ theme }: { theme: Theme }) => ({
          border: `1px solid ${color.neutral[300]}`,
          borderRadius: theme.shape.borderRadius * 2.5,
          padding: theme.spacing(0.5),
        }),
      },
    },
    //please ensure that these largely match MuiToggleButton
    MuiButtonGroup: {
      styleOverrides: {
        outlined: ({ theme }) => ({
          border: `1px solid ${color.neutral[300]}`,
          borderRadius: theme.shape.borderRadius * 2.5,
          padding: theme.spacing(0.5),
          '.MuiButton-sizeSmall': {
            fontSize: 12,
            lineHeight: '14px',
            padding: `${theme.spacing(0.5)} ${theme.spacing(1.5)}`,
            borderRadius: `${theme.shape.borderRadius * 2}px !important`,
          },
          '.MuiButton-sizeMedium': {
            padding: `${theme.spacing(0.25)} ${theme.spacing(2)}`,
            borderRadius: `${theme.shape.borderRadius * 1.5}px !important`,
          },
          '.MuiButtonRoot': ({ theme }: { theme: Theme }) => ({
            fontWeight: 400,
            color: theme.palette.text.secondary,
            border: 'none',
            borderRadius: `${theme.shape.borderRadius * 1.5}px`,
            borderTopLeftRadius: 'unset',
            textTransform: 'none',
            '&.Mui-selected': {
              backgroundColor: color.neutral[100],
              '&.Mui-disabled': {
                color: theme.palette.text.disabled,
                backgroundColor: theme.palette.background.cool,
              },
            },
          }),
        }),
      },
    },
    //please ensure that these largely match MuiToggleButtonGroup Buttons
    MuiToggleButton: {
      styleOverrides: {
        sizeSmall: ({ theme }) => ({
          fontSize: 12,
          lineHeight: '14px',
          padding: `${theme.spacing(0.5)} ${theme.spacing(1.5)}`,
          borderRadius: `${theme.shape.borderRadius * 2}px !important`,
        }),
        sizeMedium: ({ theme }) => ({
          padding: `${theme.spacing(0.25)} ${theme.spacing(2)}`,
          borderRadius: `${theme.shape.borderRadius * 1.5}px !important`,
        }),
        root: ({ theme }: { theme: Theme }) => ({
          fontWeight: 400,
          color: theme.palette.text.secondary,
          border: 'none',
          borderRadius: `${theme.shape.borderRadius * 1.5}px`,
          borderTopLeftRadius: 'unset',
          textTransform: 'none',
          '&.Mui-selected': {
            backgroundColor: color.neutral[100],
            '&.Mui-disabled': {
              color: theme.palette.text.disabled,
              backgroundColor: theme.palette.background.cool,
            },
          },
        }),
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: ({ theme, ownerState }) => ({
          height: 12,
          borderRadius: '999px',
          border: '2px solid #FFF',
          [`&.${linearProgressClasses.root}`]: {
            backgroundColor: theme.palette.grey[100],
          },
          [`& .${linearProgressClasses.bar}`]: {
            border: '2px solid #FFF',
            height: 12,
            borderRadius: '999px',
            top: '-2px',
            background:
              ownerState.color === 'success'
                ? `repeating-linear-gradient(
                -45deg,
                ${theme.palette.success.main},
                ${theme.palette.success.main}, 18px,
                #6FDBA2, 18px,
                #6FDBA2 36px
              )`
                : ownerState.color === 'warning'
                ? `repeating-linear-gradient(
                -45deg,
                ${theme.palette.warning.main},
                ${theme.palette.warning.main}, 18px,
                #EFD458, 18px,
                #EFD458 36px
              )`
                : ownerState.color === 'error'
                ? `repeating-linear-gradient(
                -45deg,
                ${theme.palette.error.main},
                ${theme.palette.error.main}, 18px,
                #F5797B, 18px,
                #F5797B 36px
              )`
                : `repeating-linear-gradient(
                -45deg,
                ${theme.palette.info.main},
                ${theme.palette.info.main}, 18px,
                #03b5ff, 18px,
                #03b5ff 36px
              )`,
          },
        }),
      },
    },
  },
  palette: {
    primary: {
      light: color.brand.primary[100],
      main: color.brand.primary[500],
      dark: color.brand.primary[900],
    },
    secondary: {
      light: color.brand.secondary[50],
      main: color.brand.secondary[300],
      dark: color.brand.secondary[800],
    },
    text: {
      primary: color.neutral[900],
      secondary: color.neutral[600],
      white: color.neutral.white,
      link: color.brand.primary[500],
      hint: color.neutral[500],
      disabled: color.neutral[400],
      error: color.intent.error.dark,
      warning: color.intent.warning.dark,
      success: color.intent.success.dark,
      info: color.intent.info.dark,
    },
    background: {
      default: color.neutral.white,
      white: color.neutral.white,
      cool: color.neutral[50],
      warm: '#FBF9FD',
      dark: '#2B2D31',
    },
    success: {
      light: color.intent.success.light,
      main: color.intent.success.main,
      dark: color.intent.success.dark,
    },
    warning: {
      light: color.intent.warning.light,
      main: color.intent.warning.main,
      dark: color.intent.warning.dark,
    },
    error: {
      light: color.intent.error.light,
      main: color.intent.error.main,
      dark: color.intent.error.dark,
    },
    info: {
      light: color.intent.info.light,
      main: color.intent.info.main,
      dark: color.intent.info.dark,
    },
    divider: color.neutral[200],
  },
};

declare module '@mui/material/styles' {
  interface TypeBackground {
    white: string;
    cool: string;
    warm: string;
    dark: string;
  }

  interface TypeText {
    primary: string;
    secondary: string;
    white: string;
    link: string;
    hint: string;
    disabled: string;
    error: string;
    warning: string;
    success: string;
    info: string;
  }
}

declare module '@mui/material/styles' {
  interface TypographyVariants {
    inputLabel: CSSProperties;
    inputText: CSSProperties;
    helperText: CSSProperties;
    tooltip: CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    inputLabel?: CSSProperties;
    inputText?: CSSProperties;
    helperText?: CSSProperties;
    tooltip?: CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    inputLabel: true;
    inputText: true;
    helperText: true;
    tooltip: true;
  }
}

export const sdTheme = createTheme(themeOptions);
