import { z, ZodTypeAny } from 'zod';

export type SearchParams<T> = T & PaginationMetadata & OrderMetadata;

export interface SearchResult<T> {
  results: T[];
  pagination: {
    resultCount: number;
    totalCount?: number;
    start: number;
    limit: number;
  };
}

export type PaginationMetadata = {
  start?: number;
  limit?: number;
};

export type OrderMetadata = {
  orderBy?: string;
  orderDirection?: OrderDirection;
};

export type OrderDirection = 'asc' | 'desc';

export const getSearchResultSchemaWithDataType = <SDataType extends ZodTypeAny>(
  dataType: SDataType
) =>
  z
    .object({
      results: dataType.array(),
      pagination: z.object({
        resultCount: z.number(),
        totalCount: z
          .optional(z.string().or(z.number()))
          .transform((value) => (value ? Number(value) : undefined)),
        start: z
          .string()
          .or(z.number())
          .transform((value) => Number(value)),
        limit: z
          .string()
          .or(z.number())
          .transform((value) => Number(value)),
      }),
    })
    .describe(`SearchResultSchemaWithDataType: ${dataType.description}`);
