import { Policy } from '@stardust-monorepo/types/sd-private';
import {
  configureAmplify,
  DetailedCognitoUser,
  developerAmplifyConfig,
  developerAmplifyConfigDev,
  onAuthError,
  SdPrivateApi,
} from '@stardust-monorepo/web-sdk-apps-shared';
import { Auth } from 'aws-amplify';
import { atom, selector } from 'recoil';

// i would prefer that this live in the UserState atom and be evaluated before the call to Auth.currentAuthenticatedUser(), but
// configureAmplify is a sync operation that does not throw errors. listening to the errors requires providing a callback. if we attempt
// to set an error property in UserState within the atom effect, recoil will bomb out if an effect on the same atom is pending (such as via the resolution of Auth.currentAuthenticatedUser()).
let userStateLoginError: string | null = null;

/*
 * The CognitoUser type that ships with amazon-cognito-identity-js doesn't include all the properties on the type (unless
 * you consider the type CognitoUser | any as in their contracts) DetailedCognitoUser provides some props missing on the type.
 */
export interface UserState {
  user: DetailedCognitoUser | null;
  unacceptedPolicies: Policy[] | null;
  loading: boolean;
  error: string | null;
}

const emptyUserState = {
  user: null,
  unacceptedPolicies: null,
  loading: true,
  error: null,
} as const;

const clearPylon = () => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  if (window.pylon) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    window.pylon.chat_settings = {};
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    window.Pylon('hide');
  }
};

//TODO the user-state should probably just live in a context. recoil is not really meant to be used as a redux replacement
export const userState = atom<UserState>({
  key: 'UserState',
  default: {
    ...emptyUserState,
  },
  dangerouslyAllowMutability: true, //can't freeze a cognito user so this is required unless we change the shape
  effects: [
    ({ setSelf }) => {
      onAuthError((err) => {
        userStateLoginError = err;
      });
    },
    ({ onSet, setSelf, node }) => {
      setSelf(
        new Promise((resolve) => {
          configureAmplify(
            process.env.NX_TARGET_ENVIRONMENT === 'dev'
              ? developerAmplifyConfigDev
              : developerAmplifyConfig
          );

          resolve(
            Auth.currentAuthenticatedUser()
              .then((user) => {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                //@ts-ignore - need to monkeypatch pylon config
                window.pylon = {
                  chat_settings: {
                    app_id: process.env.NX_PYLON_APP_ID,
                    email: user.attributes.email,
                    name: user.attributes.email,
                  },
                };

                return SdPrivateApi.getUnacceptedPolicies(user.attributes.sub)
                  .then((unacceptedPolicies) => ({
                    ...emptyUserState,
                    user,
                    unacceptedPolicies,
                    loading: false,
                    error: userStateLoginError,
                  }))
                  .catch(() => ({
                    ...emptyUserState,
                    user,
                    loading: false,
                    error: userStateLoginError,
                  }));
              })
              .catch(() => {
                clearPylon();
                return {
                  ...emptyUserState,
                  loading: false,
                  error: userStateLoginError,
                };
              })
          );
        })
      );

      onSet(async (newUserState) => {
        if (newUserState.user && newUserState.unacceptedPolicies === null) {
          setSelf({
            ...newUserState,
            unacceptedPolicies: await SdPrivateApi.getUnacceptedPolicies(
              newUserState.user.attributes.sub
            ),
          });
        }

        if (!newUserState.user) {
          clearPylon();
        }
      });
    },
  ],
});

export const selectUser = selector<DetailedCognitoUser | null>({
  key: 'SelectUser',
  get: ({ get }) => get(userState).user,
  dangerouslyAllowMutability: true, //can't freeze a cognito user so this is required unless we change the shape
});

export const selectUserId = selector<string | null>({
  key: 'SelectUserId',
  dangerouslyAllowMutability: true, //can't freeze a cognito user so this is required unless we change the shape
  get: ({ get }) => get(userState).user?.attributes.sub || null,
});

export const selectUnacceptedPolicies = selector({
  key: 'SelectRequiresTOSAcceptance',
  dangerouslyAllowMutability: true, //can't freeze a cognito user so this is required unless we change the shape
  get: ({ get }) => get(userState).unacceptedPolicies,
});

export const selectIsSsoUser = selector({
  key: 'SelectIsSsoUser',
  dangerouslyAllowMutability: true, //can't freeze a cognito user so this is required unless we change the shape
  get: ({ get }) => Boolean(get(userState).user?.attributes.identities),
});

export const selectUserStateLoginError = selector({
  key: 'SelectUserStateLoginError',
  get: ({ get }) => get(userState).error,
});
