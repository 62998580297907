import Auth from '@aws-amplify/auth';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Button,
  FormHelperText,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  FormField,
  formFieldInputWidth,
  FormSectionContainer,
  selectIsSsoUser,
  track,
  userState,
  useToast,
  useTrack,
} from '@stardust-monorepo/web-sdk-apps-shared';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useRecoilState, useRecoilValue } from 'recoil';
import { z } from 'zod';

const newPasswordFormControls = {
  oldPassword: 'oldPassword',
  password: 'password',
  confirmPassword: 'confirmPassword',
} as const;

const newPasswordFormSchema = z.object({
  [newPasswordFormControls.oldPassword]: z
    .string()
    .min(1, 'Old Password is required'),
  [newPasswordFormControls.password]: z.string().min(1, 'Password is required'),
  [newPasswordFormControls.confirmPassword]: z
    .string()
    .min(1, 'Password confirmation is required'),
});

export const LoggedInResetPassword = () => {
  const [passwordMismatch, setPasswordMismatch] = useState<boolean>(false);
  const toaster = useToast();
  const [user] = useRecoilState(userState);
  useTrack('Settings');

  const isSsoUser = useRecoilValue(selectIsSsoUser);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      [newPasswordFormControls.oldPassword]: '',
      [newPasswordFormControls.password]: '',
      [newPasswordFormControls.confirmPassword]: '',
    },
    resolver: zodResolver(newPasswordFormSchema),
  });

  const confirmNewPassword = async (oldPassword: string, password: string) => {
    try {
      await Auth.changePassword(user.user, oldPassword, password);
      track('Password Update', 'Success');
      toaster('Password updated successfully');
      reset();
    } catch (e) {
      track('Password Update', 'Failure');
      if (e instanceof Error && e.message) {
        if (e.message === 'Incorrect username or password.') {
          toaster('Incorrect old password', 'error');
        } else {
          toaster(e.message, 'error');
        }
      } else {
        toaster(`Unable to change password, please try again`, 'error');
      }
    }
  };

  return (
    <FormSectionContainer
      density="compact"
      sx={(theme) => ({
        background: theme.palette.background.default,
      })}
    >
      <Typography variant="subtitle1">Update Password</Typography>
      <Typography variant="body2" color="text.secondary">
        Enter the information below to update your password.
      </Typography>

      <FormSectionContainer
        density="compact"
        sx={{
          mt: 2,
          maxWidth: formFieldInputWidth.normal,
        }}
      >
        <Stack
          sx={{
            alignItems: 'flex-start',
          }}
          component="form"
          spacing={3}
          onSubmit={handleSubmit((data) => {
            if (
              data[newPasswordFormControls.password] ===
              data[newPasswordFormControls.confirmPassword]
            ) {
              setPasswordMismatch(false);
              confirmNewPassword(
                data[newPasswordFormControls.oldPassword],
                data[newPasswordFormControls.password]
              );
            } else {
              setPasswordMismatch(true);
            }
          })}
        >
          <FormField
            control={control}
            label="Old Password"
            type="password"
            autoComplete="old-password"
            sx={{ width: '100%' }}
            formControlName={newPasswordFormControls.oldPassword}
            hasError={!!errors[newPasswordFormControls.oldPassword]}
            renderHelpText={() =>
              errors[newPasswordFormControls.oldPassword] && (
                <FormHelperText>
                  {errors[newPasswordFormControls.oldPassword]?.message}
                </FormHelperText>
              )
            }
          />
          <FormField
            control={control}
            label="New Password"
            type="password"
            autoComplete="new-password"
            sx={{ width: '100%' }}
            formControlName={newPasswordFormControls.password}
            hasError={!!errors[newPasswordFormControls.password]}
            renderHelpText={() =>
              errors[newPasswordFormControls.password] && (
                <FormHelperText>
                  {errors[newPasswordFormControls.password]?.message}
                </FormHelperText>
              )
            }
          />
          <FormField
            control={control}
            label="Confirm New Password"
            type="password"
            autoComplete="new-password"
            sx={{ width: '100%' }}
            formControlName={newPasswordFormControls.confirmPassword}
            hasError={
              !!(
                errors[newPasswordFormControls.confirmPassword] ||
                passwordMismatch
              )
            }
            renderHelpText={() =>
              (errors[newPasswordFormControls.confirmPassword] ||
                passwordMismatch) && (
                <FormHelperText>
                  {errors[newPasswordFormControls.confirmPassword]?.message ||
                    (passwordMismatch &&
                      'Password confirmation does not match')}
                </FormHelperText>
              )
            }
          />
          <Tooltip
            title={isSsoUser ? 'Cannot change password for SSO user' : ''}
          >
            <Button type="submit" variant="contained" disabled={isSsoUser}>
              Update
            </Button>
          </Tooltip>
        </Stack>
      </FormSectionContainer>
    </FormSectionContainer>
  );
};
