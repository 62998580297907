import { Alert, Box, Stack, Typography } from '@mui/material';
import {
  localStorageKey,
  useTrack,
} from '@stardust-monorepo/web-sdk-apps-shared';
import React, { useState } from 'react';

import { CreateEditGameModal } from '../components/game';
import {
  BlogCard,
  DocumentationCard,
  MyGamesCard,
  OverviewCard,
} from '../components/home';

export const HomePage = () => {
  const [showCreateGame, setShowCreateGame] = useState(false);
  const [showStatsWarning, setShowStatsWarning] = useState(
    localStorage.getItem(localStorageKey.statsWarning) !== 'dismissed'
  );

  useTrack('Home');

  return (
    <>
      {showStatsWarning && (
        <Alert
          severity="warning"
          variant="filled"
          onClose={() => {
            localStorage.setItem(localStorageKey.statsWarning, 'dismissed');
            setShowStatsWarning(false);
          }}
        >
          <Stack spacing={1}>
            <Typography variant="subtitle2">
              Account Statistics Service Interruption
            </Typography>
            <Typography variant="body2">
              07/31/2023 - Stardust is currently working on making scalability
              improvements to our stats feature and functionality will be
              restored soon. Stats other than API Calls will remain at zero
              until further notice. If you have any questions or need access to
              account stats please reach out to your Client Success Manager.
            </Typography>
          </Stack>
        </Alert>
      )}
      <Box sx={{ padding: 2 }}>
        <CreateEditGameModal
          open={showCreateGame}
          onClose={() => setShowCreateGame(false)}
        />
        <OverviewCard setShowCreateGame={setShowCreateGame} />
        <Box
          sx={{
            mt: 2,
            width: '100%',
            display: 'flex',
            gap: 2,
            alignItems: 'flex-start',
          }}
        >
          <Stack spacing={2} sx={{ width: '60%' }}>
            <MyGamesCard setShowCreateGame={setShowCreateGame} />
            <DocumentationCard />
          </Stack>
          <BlogCard />
        </Box>
      </Box>
    </>
  );
};
