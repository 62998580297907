import { Check } from '@mui/icons-material';
import { Box, CircularProgress } from '@mui/material';
import { useEffect, useState } from 'react';

import { Switch } from './switch';

export interface ActiveToggleProps {
  checked: boolean;
  onToggle: (checked: boolean) => Promise<unknown>;
  onToggleError: (checked: boolean, e: Error) => void;
  onToggleSuccess: (checked: boolean) => void;
}

export const ActiveToggle = ({
  checked,
  onToggle,
  onToggleError,
  onToggleSuccess,
}: ActiveToggleProps) => {
  const [toggling, setToggling] = useState(false);
  const [currentActiveStatus, setCurrentActiveStatus] = useState(checked);

  useEffect(() => {
    if (toggling && checked !== currentActiveStatus) {
      setToggling(false);
      setCurrentActiveStatus(checked);
    }
  }, [checked, toggling, currentActiveStatus]);

  return toggling ? (
    <Box
      sx={{
        width: '48px',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <CircularProgress size="24px" color="primary" />
    </Box>
  ) : (
    <Switch
      checked={checked}
      checkedIcon={<Check />}
      onClick={(e) => e.stopPropagation()}
      onChange={async (e, newVal: boolean) => {
        try {
          setToggling(true);
          await onToggle(newVal);
          onToggleSuccess(newVal);
        } catch (e) {
          onToggleError(newVal, e as Error);
          setToggling(false);
        }
      }}
    />
  );
};
