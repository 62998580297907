import { Chip, Stack } from '@mui/material';
import { PurchasableItem } from '@stardust-monorepo/types/sd-private';

interface TokenNumberColumnProps {
  items?: PurchasableItem[];
}

export const TokenNumberColumn = ({ items }: TokenNumberColumnProps) => {
  return !items ? null : (
    <Stack direction="row" spacing={0.5}>
      <Chip
        label={items.length < 10 ? `0${items.length}` : items.length}
        color="primary"
        variant="outlined"
      />
      {items.length > 1 ? (
        <Chip label="BUNDLE" color="primary" variant="outlined" />
      ) : null}
    </Stack>
  );
};
