import { Alert, Chip, Stack, Typography } from '@mui/material';
import { Eye, PaperPlaneTilt, Trash } from '@phosphor-icons/react';
import { Template } from '@stardust-monorepo/types/marketplace';
import {
  Player,
  PlayerInventoryItem,
} from '@stardust-monorepo/types/sd-private';
import {
  BaseEmptyView,
  ContainedTable,
  extractData,
  isDoneLoading,
  MoreActions,
  SdPrivateApi,
  track,
  ValidatedImage,
} from '@stardust-monorepo/web-sdk-apps-shared';
import React, { useMemo, useState } from 'react';

import sword from '../../../assets/sword.png';
import { ViewPropertiesModal } from '../properties';
import { BlockchainLink } from './BlockchainLink';
import { TokenBurnModal } from './TokenBurnModal';
import { TokenTransferModal } from './TokenTransferModal';

const headings = [
  { width: '40px', label: '' },
  { label: 'Token' },
  { label: 'Token ID' },
  { label: 'Amount' },
  { label: 'Blockchain Link' },
  { width: '40px', label: '' },
];

export interface InventoryProps {
  gameId: number;
  player: Player;
  setMintTokenOpen: (open: boolean) => void;
}

export const InventoryV1 = ({
  gameId,
  player,
  setMintTokenOpen,
}: InventoryProps) => {
  const [selectedInventoryItem, setSelectedInventoryItem] = useState<
    PlayerInventoryItem | undefined
  >();
  const [viewPropertiesOpen, setViewPropertiesOpen] = useState(false);
  const [tokenBurnOpen, setTokenBurnOpen] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState<Template>();
  const [tokenTransferOpen, setTokenTransferOpen] = useState(false);

  const {
    data: playerInventory,
    error: playerInventoryError,
    isLoading: playerInventoryLoading,
  } = SdPrivateApi.usePlayerInventory({
    gameId: Number(gameId),
    playerId: player.playerId,
    returnExplorerLinks: true,
  });

  const { data: game } = SdPrivateApi.useGame(gameId, !!gameId);

  const allTemplateQueries = SdPrivateApi.useSelectedTemplates({
    gameId,
    templateIds: Array.from(
      (playerInventory || []).reduce<Set<number>>((acc, item) => {
        acc.add(item.templateId);
        return acc;
      }, new Set())
    ),
  });
  const doneLoadingTemplates = isDoneLoading(allTemplateQueries);

  const templates = useMemo(() => {
    if (doneLoadingTemplates) {
      return extractData(allTemplateQueries);
    }
    return [];
  }, [doneLoadingTemplates]);

  const rows = Array.isArray(playerInventory)
    ? playerInventory.map((item) => {
        const template = templates.find(
          (template) => template.id === item.templateId
        );
        return [
          <ValidatedImage
            size="40px"
            image={template?.image}
            testId="template-image"
          />,
          <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
            <Typography variant="subtitle2">{item.name}</Typography>
            {template && <Chip label={String(template.type)} />}
          </Stack>,
          item.tokenId,
          item.amount,
          <BlockchainLink
            blockchain={game?.blockchain}
            blockchainExplorerLink={item.blockchainExplorerLink}
            tokenId={item.tokenId}
          />,
          <MoreActions
            actions={[
              {
                icon: <Eye weight="duotone" />,
                label: 'View Properties',
                onClick: () => {
                  track('Token View Properties', 'Clicked', {
                    gameId,
                    playerId: player.playerId,
                    tokenId: item.tokenId,
                  });
                  setSelectedInventoryItem(item);
                  setViewPropertiesOpen(true);
                },
              },
              {
                icon: <PaperPlaneTilt weight="duotone" />,
                label: 'Transfer Token',
                onClick: () => {
                  track('Transfer Token', 'Clicked', {
                    gameId,
                    playerId: player.playerId,
                    tokenId: item.tokenId,
                  });
                  setSelectedInventoryItem(item);
                  setSelectedTemplate(template);
                  setTokenTransferOpen(true);
                },
              },
              {
                icon: <Trash weight="duotone" />,
                label: 'Burn Token',
                onClick: () => {
                  track('Burn Token', 'Clicked', {
                    gameId,
                    playerId: player.playerId,
                    tokenId: item.tokenId,
                  });
                  setSelectedInventoryItem(item);
                  setSelectedTemplate(template);
                  setTokenBurnOpen(true);
                },
                type: 'error',
              },
            ]}
          />,
        ];
      })
    : [];

  return (
    <>
      {playerInventoryError ? (
        <Alert severity="error">Unexpected error. Please try again.</Alert>
      ) : null}
      {selectedInventoryItem ? (
        <ViewPropertiesModal
          allProperties={[
            { Inherited: selectedInventoryItem.props.inherited },
            { Mutable: selectedInventoryItem.props.mutable },
            { Immutable: selectedInventoryItem.props.immutable },
          ]}
          open={viewPropertiesOpen}
          onClose={() => {
            track('Token View Properties Close', 'Clicked', {
              gameId,
              playerId: player.playerId,
              tokenId: selectedInventoryItem.tokenId,
            });
            setSelectedInventoryItem(undefined);
            setViewPropertiesOpen(false);
          }}
        />
      ) : null}
      {selectedInventoryItem && selectedTemplate ? (
        <TokenBurnModal
          gameId={Number(gameId)}
          open={tokenBurnOpen}
          onClose={() => {
            track('Token Burn Modal Close', 'Clicked', {
              gameId,
              playerId: player.playerId,
              tokenId: selectedInventoryItem.tokenId,
            });
            setSelectedInventoryItem(undefined);
            setSelectedTemplate(undefined);
            setTokenBurnOpen(false);
          }}
          playerId={player.playerId}
          item={selectedInventoryItem}
          tokenType={selectedTemplate.type}
        />
      ) : null}
      {selectedInventoryItem && selectedTemplate ? (
        <TokenTransferModal
          gameId={Number(gameId)}
          open={tokenTransferOpen}
          onClose={() => {
            track('Token Transfer Modal Close', 'Clicked', {
              gameId,
              playerId: player.playerId,
              tokenId: selectedInventoryItem.tokenId,
            });
            setSelectedInventoryItem(undefined);
            setSelectedTemplate(undefined);
            setTokenTransferOpen(false);
          }}
          fromPlayer={player}
          item={selectedInventoryItem}
          tokenType={selectedTemplate.type}
        />
      ) : null}
      <ContainedTable
        count={rows.length}
        page={0}
        type="Tokens"
        headings={headings}
        rows={rows}
        isLoading={
          playerInventoryLoading ||
          (rows.length === 0 &&
            Array.isArray(playerInventory) &&
            playerInventory.length > 0)
        }
        emptyScreen={
          <BaseEmptyView
            image={sword}
            title="Add an item to your player's inventory"
            body="Mint tokens to players from templates you've created."
            buttonText="Mint Token"
            onClick={() => {
              track('Empty Player Mint Token', 'Clicked', {
                gameId,
                playerId: player.playerId,
              });
              setMintTokenOpen(true);
            }}
          />
        }
      />
    </>
  );
};
