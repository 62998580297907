import { z } from 'zod';

import { unauthenticatedRequest, validatedRequestWithAuth } from './api-utils';

export const CreateAssetResponseSchema = z.object({
  uploadUrl: z.string().url(),
});

export interface AssetMetadata {
  gameId: number;
  path: string;
  fileType: string;
  usageType?: string;
}

export const createAsset = ({
  gameId,
  path,
  fileType,
  usageType,
}: AssetMetadata) => {
  return validatedRequestWithAuth(CreateAssetResponseSchema, 'asset/create', {
    method: 'POST',
    data: {
      gameId,
      path,
      fileType,
      usageType,
    },
  });
};
export const persistFile = (uploadUrl: string, image: File) => {
  return unauthenticatedRequest(uploadUrl, {
    method: 'PUT',
    headers: {
      'Content-Type': image.type,
    },
    data: image,
  }).catch(() => {
    // eslint-disable-next-line no-console
    console.error('An error occurred while persisting the file'); //TODO
  });
};
