import { Box, useTheme } from '@mui/material';
import {
  NoSearchResults,
  PAGE_SIZE,
  Spinner,
} from '@stardust-monorepo/web-sdk-apps-shared';
import { ReactNode } from 'react';

import { BaseTable, BaseTableProps } from './base-table';
import { Pagination, PaginationProps } from './pagination';
import { SearchBar, SearchBarProps } from './search-bar';

export const showPagination = (
  setPage?: (page: number) => void,
  count?: number
) => setPage && (count || 0) / PAGE_SIZE > 1;

export type SearchTableProps = Omit<PaginationProps, 'setPage'> &
  SearchBarProps &
  BaseTableProps & {
    isLoading: boolean;
    setPage?: (page: number) => void;
    emptyScreen?: ReactNode;
    filter?: string;
    showSearchBar?: boolean;
    showHeader?: boolean;
  };

// If hasSubRow is true, the last element in a row will be used as
// the sub-row content
/**
 * This table component has inflated: its broad support of different use-cases via props has caused it to become difficult to maintain.
 *
 * @deprecated use web-sdk-apps-shared/Table instead
 */
export function SearchTable({
  actions,
  additionalInfo,
  count,
  emptyScreen,
  filter,
  hasSubRow,
  headings,
  isLoading,
  onRowClick,
  page,
  placeholder,
  setPage,
  setFilter,
  showHeadings,
  showSearchBar = true,
  rows,
  rowBorder,
  type,
  fillHeight,
  tableLayout,
}: SearchTableProps) {
  const theme = useTheme();

  return (
    <>
      {showSearchBar && (
        <SearchBar
          actions={actions}
          additionalInfo={additionalInfo}
          count={count}
          placeholder={placeholder}
          setFilter={setFilter}
          type={type}
        />
      )}
      {isLoading ? (
        <Box
          sx={(theme) => ({
            border: '1px solid',
            borderColor: 'divider',
            borderRadius: 3,
            borderBottomLeftRadius: theme.shape.borderRadius * 3,
            borderBottomRightRadius: theme.shape.borderRadius * 3,
            backgroundColor: theme.palette.background.white,
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            py: 4,
          })}
        >
          <Spinner grow={true} />
        </Box>
      ) : rows.length === 0 && filter ? (
        <NoSearchResults />
      ) : rows.length === 0 && emptyScreen ? (
        emptyScreen
      ) : (
        <>
          <BaseTable
            hasSubRow={hasSubRow}
            headings={headings}
            rows={rows}
            onRowClick={onRowClick}
            rowBorder={rowBorder}
            showHeadings={showHeadings}
            fillHeight={fillHeight}
            tableLayout={tableLayout}
            tableContainerProps={{
              sx: {
                border: `1px solid ${theme.palette.divider}`,
                borderRadius: 3,
                overflowX: 'auto',
              },
            }}
          />
          {showPagination(setPage, count) && setPage ? (
            <Pagination count={count} page={page} setPage={setPage} />
          ) : null}
        </>
      )}
    </>
  );
}
