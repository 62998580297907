import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { UseFormWatch } from 'react-hook-form/dist/types/form';

export const useManualValidationError = <T,>(
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  watch: UseFormWatch<any>,
  initialValue: T | null = null
): [T | null, Dispatch<SetStateAction<T | null>>] => {
  const [validationError, setValidationError] = useState<T | null>(
    initialValue
  );
  useEffect(() => {
    const subscription = watch(() => setValidationError(null));
    return () => subscription.unsubscribe();
  }, [watch]);
  return [validationError, setValidationError];
};
