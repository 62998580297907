import { Alert, AlertColor, Snackbar } from '@mui/material';
import { createContext, useContext, useId } from 'react';

export const ToastContext = createContext<{
  setToast: (msg: string, severity?: AlertColor, duration?: number) => void;
}>({
  setToast: () => null,
});

export const useToast = () => {
  const { setToast } = useContext(ToastContext);
  return setToast;
};

export interface ToastProps {
  msg: string;
  setMsg: (msg: string) => void;
  severity: AlertColor;
  duration?: number;
}

export const Toast = ({
  msg,
  setMsg,
  severity,
  duration = 5000,
}: ToastProps) => {
  const onClose = () => setMsg('');
  const id = useId();
  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      key={id}
      onClose={onClose}
      open={Boolean(msg)}
      autoHideDuration={duration}
    >
      <Alert severity={severity} onClose={onClose}>
        {msg}
      </Alert>
    </Snackbar>
  );
};
