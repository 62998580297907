import { zodResolver } from '@hookform/resolvers/zod';
import { PercentRounded } from '@mui/icons-material';
import { Divider, FormHelperText, Stack, Typography } from '@mui/material';
import { Parallelogram, Stack as StackIcon } from '@phosphor-icons/react';
import { Template } from '@stardust-monorepo/types/marketplace';
import {
  color,
  FormButtonBar,
  FormField,
  FormSectionContainer,
  SdPrivateApi,
  UnlimitedTemplateCap,
  UploadImage,
} from '@stardust-monorepo/web-sdk-apps-shared';
import React, { MutableRefObject } from 'react';
import { Controller, FieldErrors, useForm } from 'react-hook-form';
import { z, ZodIssueCode } from 'zod';

import {
  AddProperties,
  mapToProperties,
  ZodPropertyValidation,
} from '../properties';
import { FormValues } from './types';

const tokenTypeOptions = [
  {
    label: 'NFT',
    value: 'NFT',
    description:
      'Individual and distinct, each can have its own distinguishable value and properties, making NFTs ideal for representing dynamic items, collectibles, or unique collections',
    icon: <Parallelogram size={21} color={color.brand.primary[500]} />,
  },
  {
    label: 'FT',
    value: 'FT',
    description:
      'Fungible tokens are identical and are commonly used as assets that have a standardized value. (e.g. - gold, rocks, etc)',
    icon: <StackIcon size={21} color={color.brand.primary[500]} />,
  },
];

const capTypeOptions = [
  {
    label: 'Limited',
    value: 'Limited',
    description: 'Collection has a max number of tokens that can be minted.',
  },
  {
    label: 'Unlimited',
    value: 'Unlimited',
    description:
      'Collection has no defined max number of tokens that can be minted.',
  },
];

export const formControls = {
  name: 'name',
  description: 'description',
  royalty: 'royalty',
  tokenType: 'tokenType',
  capType: 'capType',
  capLimit: 'capLimit',
  image: 'image',
  props: 'props',
} as const;

const formSchema = z
  .object({
    [formControls.name]: z.string().min(1, 'Name is required'),
    [formControls.image]: z.instanceof(File).or(z.null()),
    [formControls.description]: z.string(),
    [formControls.royalty]: z.preprocess(
      (royalty) =>
        typeof royalty === 'string'
          ? parseFloat(z.string().parse(royalty) || '0.0')
          : royalty,
      z
        .number({
          //blank cases are covered by the help text message
          errorMap: (err) => ({
            message:
              err.code === ZodIssueCode.invalid_type ? '' : err.message || '',
          }),
        })
        .nonnegative('')
        .and(z.number().lte(15, ''))
    ),
    [formControls.tokenType]: z.string().min(1, 'Token Type is required'),
    [formControls.capType]: z.string().min(1, 'Cap (Supply) is required'),
    [formControls.capLimit]: z.preprocess(
      (cap) =>
        typeof cap === 'string' ? parseInt(z.string().parse(cap || '0')) : cap,
      z.number()
    ),
    [formControls.props]: ZodPropertyValidation,
  })
  .required()
  .refine(
    (data) =>
      (data.capType === 'Limited' && data.capLimit >= 1) ||
      data.capType === 'Unlimited',
    {
      path: [formControls.capLimit],
      message: 'Limit must be at least 1',
    }
  );

interface TemplateFormProps {
  defaultValues: FormValues;
  gameId: number;
  submitFormRef: MutableRefObject<HTMLButtonElement | null>;
  template?: Template | null;
  onLoading: (loading: boolean) => void;
  onSave: (result: number | Error) => void;
}

export const TemplateForm = ({
  defaultValues,
  gameId,
  submitFormRef,
  template,
  onLoading,
  onSave,
}: TemplateFormProps) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<FormValues>({
    defaultValues,
    resolver: zodResolver(formSchema),
  });

  const capType = watch(formControls.capType);

  const createTemplate = SdPrivateApi.useCreateTemplate();
  const mutateTemplate = SdPrivateApi.useMutateTemplate();
  const deleteTemplateProperty = SdPrivateApi.useDeleteTemplateProperty();

  const submitTemplate = async (formValues: FormValues) => {
    if (template) {
      try {
        const deletePromises = defaultValues.props.reduce<
          Promise<Record<string, never>>[]
        >((acc, prop) => {
          if (
            !formValues.props.find((formProp) => formProp.name === prop.name)
          ) {
            acc.push(
              deleteTemplateProperty.mutateAsync({
                gameId,
                templateId: template.id,
                name: prop.name,
              })
            );
          }
          return acc;
        }, []);
        await Promise.all(deletePromises);
        const updatedTemplate = {
          gameId,
          templateId: template.id,
          description: formValues.description,
          royalty:
            formValues.royalty !== defaultValues.royalty
              ? formValues.royalty
              : undefined,
          image: formValues.image,
          properties: mapToProperties(formValues.props),
        };
        const templateId = await mutateTemplate.mutateAsync(updatedTemplate);
        onSave(templateId);
      } catch (e) {
        if (e instanceof Error) {
          onSave(e);
        }
      }
    } else {
      try {
        const newTemplate = {
          name: formValues.name,
          description: formValues.description,
          royalty: formValues.royalty,
          type: formValues.tokenType,
          cap:
            formValues.capType === 'Limited'
              ? String(formValues.capLimit)
              : UnlimitedTemplateCap,
          image: formValues.image,
          gameId,
          properties: mapToProperties(formValues.props),
        };
        const templateId = await createTemplate.mutateAsync(newTemplate);
        onSave(templateId);
      } catch (e) {
        if (e instanceof Error) {
          onSave(e);
        }
      }
    }
    onLoading(false);
  };

  return (
    <Stack
      component="form"
      sx={{
        flexGrow: 1,
      }}
      onSubmit={handleSubmit((data) => {
        onLoading(true);
        submitTemplate(data);
      })}
    >
      <FormSectionContainer>
        <Typography variant="h6" sx={{ mb: 2 }}>
          Template Details
        </Typography>
        <Typography
          variant="body2"
          sx={{ color: 'text.secondary', mt: 1, mb: 4 }}
        >
          Templates are used to define the NFT’s you want to create in this
          collection.
        </Typography>
        <FormField
          formControlName={formControls.name}
          control={control}
          disabled={!!template}
          label="*Name"
          type="text"
          hasError={!!errors[formControls.name]}
          renderHelpText={() =>
            errors[formControls.name] && (
              <FormHelperText>
                {errors[formControls.name]?.message}
              </FormHelperText>
            )
          }
        />
        <FormField
          formControlName={formControls.description}
          control={control}
          label="Description"
          type="text"
          rows={3}
          hasError={!!errors[formControls.description]}
          renderHelpText={() =>
            errors[formControls.description] && (
              <FormHelperText>
                {errors[formControls.description]?.message}
              </FormHelperText>
            )
          }
        />
        {process.env.NX_FEATURE_ROYALTY === 'enabled' ? (
          <FormField
            formControlName={formControls.royalty}
            control={control}
            label="Royalty"
            type="number"
            fullWidth={false}
            inputSx={{
              maxWidth: '96px',
            }}
            endAdornment={
              <PercentRounded
                sx={(theme) => ({
                  fontSize: 16,
                  color: theme.palette.text.secondary,
                  marginRight: 1,
                })}
              />
            }
            hasError={!!errors[formControls.royalty]}
            renderHelpText={() => (
              <>
                <FormHelperText>
                  Royalty must be a percentage between 0% and 15%
                </FormHelperText>
                {errors[formControls.royalty] && (
                  <FormHelperText>
                    {errors[formControls.royalty]?.message}
                  </FormHelperText>
                )}
              </>
            )}
          />
        ) : null}
        <FormButtonBar
          width={'512px'}
          control={control}
          disabled={!!template}
          formControlName={formControls.tokenType}
          hasError={!!errors[formControls.tokenType]}
          label="Token Type"
          options={tokenTypeOptions}
          renderHelpText={() =>
            errors[formControls.tokenType] && (
              <FormHelperText>
                {errors[formControls.tokenType]?.message}
              </FormHelperText>
            )
          }
        />
        <FormButtonBar
          width={'512px'}
          control={control}
          disabled={!!template}
          formControlName={formControls.capType}
          hasError={!!errors[formControls.capType]}
          label="Cap (Supply)"
          options={capTypeOptions}
          renderHelpText={() =>
            errors[formControls.capType] && (
              <FormHelperText>
                {errors[formControls.capType]?.message}
              </FormHelperText>
            )
          }
        />
        {capType === 'Limited' ? (
          <FormField
            disabled={!!template}
            formControlName={formControls.capLimit}
            control={control}
            inputSx={{ maxWidth: '96px' }}
            sx={{ mt: 2 }}
            placeholder="Limit"
            type="number"
            hasError={!!errors[formControls.capLimit]}
            renderHelpText={() =>
              errors[formControls.capLimit] && (
                <FormHelperText>
                  {errors[formControls.capLimit]?.message}
                </FormHelperText>
              )
            }
          />
        ) : null}
        <Divider sx={{ my: 4 }} />
        <Typography variant="h6">Image</Typography>
        <Typography
          variant={'body2'}
          color={'text.secondary'}
          sx={{
            mt: 0.5,
          }}
        >
          Upload an icon image for this template. This icon will be the display
          image for the template.
        </Typography>
        <Controller
          control={control}
          name={formControls.image}
          render={({ field: { ref, ...fieldProps } }) => (
            <Stack marginTop={4} gap={1}>
              <UploadImage
                {...fieldProps}
                inputRef={ref}
                title="Upload a Template Image"
                subtitle="SVG, PNG, JPG or GIF. 60x60px recommended"
                existingImageHref={template?.image}
              />
            </Stack>
          )}
        />
        <Divider sx={{ my: 4 }} />
        <Typography variant="h6">Properties</Typography>
        <Typography
          variant={'body2'}
          color={'text.secondary'}
          sx={{
            mt: 0.5,
            mb: 3,
          }}
        >
          Define the custom properties that all tokens minted from this template
          will inherit. These properties will be in the Metadata for each token
          minted. Properties can be changed later by calling token/mutate from
          the Stardust API.
        </Typography>
        <AddProperties
          control={control}
          name="template"
          errors={errors[formControls.props] as FieldErrors[]}
          watch={watch}
        />
      </FormSectionContainer>
      <button ref={submitFormRef} style={{ display: 'none' }} />
    </Stack>
  );
};
