import { AlertColor, Box, Stack } from '@mui/material';
import {
  LeftNav,
  LeftNavProps,
  Toast,
  ToastContext,
} from '@stardust-monorepo/web-sdk-apps-shared';
import React, { ReactNode, useState } from 'react';
import { Outlet } from 'react-router-dom';

export interface DefaultLayout extends LeftNavProps {
  sidebarContent?: ReactNode;
  children?: ReactNode;
}

export const DefaultLayout = ({
  children,
  sidebarContent,
  ...leftNavProps
}: DefaultLayout) => {
  const [toastMsg, setToastMsg] = useState('');
  const [toastSeverity, setToastSeverity] = useState<AlertColor>('success');
  const setToast = (msg: string, severity: AlertColor = 'success') => {
    setToastSeverity(severity);
    setToastMsg(msg);
  };

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        backgroundColor: 'background.cool',
        overflow: 'auto',
        height: '100%',
      }}
    >
      <ToastContext.Provider value={{ setToast }}>
        <Toast msg={toastMsg} setMsg={setToastMsg} severity={toastSeverity} />
        <Stack
          sx={(theme) => ({
            backgroundColor: theme.palette.background.default,
            minWidth: '240px',
            maxWidth: '240px',
            flexGrow: 1,
            borderRight: `1px solid ${theme.palette.divider}`,
            overflow: 'auto',
          })}
        >
          <LeftNav {...leftNavProps} />
          {sidebarContent}
        </Stack>
        <Box
          sx={{
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            overflow: 'auto',
          }}
        >
          <Outlet />
          {children}
        </Box>
      </ToastContext.Provider>
    </Box>
  );
};
