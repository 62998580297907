import { Add } from '@mui/icons-material';
import {
  Box,
  Button,
  CircularProgress,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { ArrowUpRight, NotePencil, Trash } from '@phosphor-icons/react';
import {
  Purchasable,
  PurchasableItem,
} from '@stardust-monorepo/types/sd-private';
import {
  BaseEmptyView,
  ContainedTable,
  ErrorAlert,
  isDoneLoading,
  MoreActions,
  PageTitle,
  RemoveModal,
  SdPrivateApi,
  SlashedLine,
  track,
  useHandleError,
  useToast,
  useTrack,
  ValidatedImage,
} from '@stardust-monorepo/web-sdk-apps-shared';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import gameStoreIcon from '../../assets/game-store-icon.png';
import {
  CreatePurchasableModal,
  launchOnboarding,
  PaymentsOnboarding,
  PurchasableToggle,
  StatRow,
  TokenNumberColumn,
} from '../components/game-store';
import { useGuardOnPaymentsAllowed } from '../hooks/useGuardOnPaymentsAllowed';

const headings = [
  { width: '96px', label: '' },
  { label: 'Name' },
  { label: 'Price' },
  {
    label: <SlashedLine left="Sales" right="Supply" px={0.5} />,
  },
  { label: 'Tokens (#)' },
  { label: 'Active' },
  { width: '40px', label: '' },
];

type ViewModeOptions = 'view-all' | 'single-item' | 'bundle';

export const GameStorePage = () => {
  const { gameId } = useParams<{
    gameId: string;
  }>();
  useGuardOnPaymentsAllowed(Number(gameId));
  const [filteredPurchasables, setFilteredPurchasables] = useState<
    Purchasable[]
  >([]);
  const [filter, setFilter] = useState('');
  const [viewMode, setViewMode] = useState<ViewModeOptions>('view-all');
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [createPurchasableOpen, setCreatePurchasableOpen] = useState(false);
  const [selectedPurchasable, setSelectedPurchasable] =
    useState<Purchasable | null>();
  const [purchasableItemMap, setPurchasableItemMap] = useState<
    Record<number, PurchasableItem[]>
  >({});
  const [gettingLink, setGettingLink] = useState(false);
  const toaster = useToast();
  const handleError = useHandleError();

  const deletePurchasable = SdPrivateApi.useDeletePurchasable();

  useTrack('Game Store', { gameId });

  const {
    data: paymentProvider,
    error: paymentProviderError,
    isLoading: paymentProviderLoading,
  } = SdPrivateApi.usePaymentProvider(
    {
      gameId: Number(gameId),
      provider: 'STRIPE',
    },
    !!gameId
  );

  const {
    data: purchasables,
    error: purchasablesError,
    isLoading: purchasablesLoading,
  } = SdPrivateApi.usePurchasables(
    {
      gameId: Number(gameId),
    },
    !!gameId && paymentProvider?.status === 'ACTIVE'
  );

  useEffect(() => {
    if (Array.isArray(purchasables)) {
      let fp = purchasables;
      if (filter) {
        fp = purchasables.reduce<Purchasable[]>((acc, p) => {
          if (p.name.toLowerCase().includes(filter.toLowerCase())) {
            acc.push(p);
          }
          return acc;
        }, []);
      }
      if (viewMode === 'single-item' || viewMode === 'bundle') {
        fp = fp.reduce<Purchasable[]>((acc, p) => {
          if (
            p.id in purchasableItemMap &&
            ((purchasableItemMap[p.id].length === 1 &&
              viewMode === 'single-item') ||
              (purchasableItemMap[p.id].length > 1 && viewMode === 'bundle'))
          ) {
            acc.push(p);
          }
          return acc;
        }, []);
      }
      setFilteredPurchasables(fp);
    }
  }, [filter, purchasables, viewMode, purchasableItemMap]);

  const allPurchasableItemsQueries = SdPrivateApi.usePurchasablesItems({
    gameId: Number(gameId),
    purchasables: Array.isArray(purchasables) ? purchasables : [],
  });
  const doneLoading = isDoneLoading(allPurchasableItemsQueries);

  useEffect(() => {
    if (doneLoading) {
      const itemMap = allPurchasableItemsQueries.reduce<
        Record<string, PurchasableItem[]>
      >((acc, pi) => {
        if (pi.data && pi.data.length > 0) {
          acc[pi.data[0].purchasableId] = pi.data;
        }
        return acc;
      }, {});
      setPurchasableItemMap(itemMap);
    }
  }, [doneLoading]);

  const onDeletePurchasable = async () => {
    const trackingData = { gameId, purchasableId: selectedPurchasable?.id };
    track('Modal Remove Purchasable', 'Clicked', trackingData);
    try {
      if (gameId && selectedPurchasable) {
        await deletePurchasable.mutateAsync({
          gameId: Number(gameId),
          purchasableId: selectedPurchasable.id,
        });
        track('Remove Purchasable', 'Success', trackingData);
        toaster(`${selectedPurchasable.name} successfully removed`);
      }
    } catch (e) {
      handleError(
        e,
        `Unable to remove ${selectedPurchasable?.name}`,
        'Remove Purchasable',
        trackingData
      );
    }
    setSelectedPurchasable(null);
    setDeleteConfirmationOpen(false);
  };

  const rows = filteredPurchasables.map((purchasable) => {
    return [
      <ValidatedImage size="64px" width="96px" image={purchasable.image} />,
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Typography variant="subtitle1">{purchasable.name}</Typography>
        <Typography
          variant="helperText"
          sx={{ mt: 0.5, color: 'text.secondary' }}
        >
          ID: {purchasable.id}
        </Typography>
      </Box>,
      `$${Number(purchasable.price).toFixed(2)}`,
      <SlashedLine
        left={purchasable.totalSupply}
        right={purchasable.cap || '0'}
      />,
      <TokenNumberColumn items={purchasableItemMap[purchasable.id]} />,
      <PurchasableToggle gameId={Number(gameId)} purchasable={purchasable} />,
      <MoreActions
        actions={[
          {
            icon: <NotePencil weight="duotone" />,
            label: 'Edit Purchasable',
            onClick: () => {
              track('Edit Purchasable', 'Clicked', {
                gameId,
                purchasableId: purchasable.id,
              });
              setSelectedPurchasable(purchasable);
              setCreatePurchasableOpen(true);
            },
          },
          {
            icon: <Trash weight="duotone" />,
            label: 'Remove Purchasable',
            onClick: () => {
              track('Remove Purchasable', 'Clicked', {
                gameId,
                purchasableId: purchasable.id,
              });
              setSelectedPurchasable(purchasable);
              setDeleteConfirmationOpen(true);
            },
            type: 'error',
          },
        ]}
      />,
    ];
  });

  return (
    <>
      <RemoveModal
        body={`Are you sure you want to remove ${selectedPurchasable?.name}?`}
        label="Purchasable"
        loading={deletePurchasable.isLoading}
        onClose={() => {
          track('Remove Purchasable Close', 'Clicked', {
            gameId,
            purchasableId: selectedPurchasable?.id,
          });
          setDeleteConfirmationOpen(false);
          setSelectedPurchasable(null);
        }}
        onConfirm={onDeletePurchasable}
        open={deleteConfirmationOpen}
      />
      {createPurchasableOpen ? (
        <CreatePurchasableModal
          gameId={Number(gameId)}
          open={createPurchasableOpen}
          purchasable={selectedPurchasable}
          purchasableItems={
            selectedPurchasable
              ? purchasableItemMap[selectedPurchasable.id]
              : undefined
          }
          onClose={() => {
            setCreatePurchasableOpen(false);
            setSelectedPurchasable(null);
          }}
        />
      ) : null}
      <ErrorAlert error={purchasablesError as Error} />
      <PageTitle title="Game Store">
        <Stack direction="row" spacing={1}>
          <Button
            disabled={
              !!paymentProviderError ||
              paymentProvider?.status !== 'ACTIVE' ||
              paymentProviderLoading
            }
            variant="contained"
            onClick={() => {
              track('Purchasables Create New', 'Clicked', { gameId });
              setCreatePurchasableOpen(true);
            }}
            startIcon={<Add />}
          >
            Create New
          </Button>
          {paymentProvider?.status === 'ACTIVE' ? (
            <Button
              variant="outlined"
              color="secondary"
              endIcon={<ArrowUpRight />}
              onClick={() => {
                track('Purchasables Payment Settings', 'Clicked', { gameId });
                launchOnboarding(Number(gameId), setGettingLink, toaster);
              }}
            >
              {gettingLink ? (
                <CircularProgress size="18px" color="primary" />
              ) : (
                'Payment Settings'
              )}
            </Button>
          ) : null}
        </Stack>
      </PageTitle>
      <StatRow gameId={gameId} />
      <ContainedTable
        count={rows.length}
        page={0}
        type="Purchasables"
        headings={headings}
        rows={rows}
        isLoading={
          (purchasablesLoading && paymentProvider?.status === 'ACTIVE') ||
          paymentProviderLoading
        }
        placeholder="Filter by name"
        filter={
          rows.length === 0 && purchasables && purchasables.length > 0
            ? ' '
            : ''
        }
        setFilter={setFilter}
        actions={
          <ToggleButtonGroup
            disabled={purchasablesLoading}
            value={viewMode}
            exclusive
            onChange={(event, newViewMode) => {
              track(
                `Puchasables Toggle Button Group ${newViewMode}`,
                'Clicked',
                {
                  gameId,
                }
              );
              setViewMode(newViewMode);
            }}
          >
            <ToggleButton value="view-all">View All</ToggleButton>
            <ToggleButton value="single-item">Single-Item</ToggleButton>
            <ToggleButton value="bundle">Bundle</ToggleButton>
          </ToggleButtonGroup>
        }
        emptyScreen={
          paymentProvider?.status !== 'ACTIVE' ? (
            <PaymentsOnboarding
              gameId={Number(gameId)}
              isLoading={gettingLink}
              setIsLoading={setGettingLink}
              name="Game Store"
            />
          ) : (
            <BaseEmptyView
              image={gameStoreIcon}
              title="This game has no Purchasables"
              body="Use this page to create and manage in-app purchasables for your game."
              buttonText="Create Purchasable"
              onClick={() => {
                track('Empty Purchasables Create New', 'Clicked', { gameId });
                setCreatePurchasableOpen(true);
              }}
            />
          )
        }
      />
    </>
  );
};
