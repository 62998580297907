import { AssetMetadata } from '@stardust-monorepo/types/common';
import {
  Item,
  ItemResults,
  ItemResultsSchema,
  ItemSchema,
} from '@stardust-monorepo/types/sd-core';

import { authenticatedRequest, validatedRequestWithAuth } from './api-utils';

export interface GetItemArgs {
  id: Item['id'];
}
export const getItem = ({ id }: GetItemArgs): Promise<Item> => {
  return validatedRequestWithAuth(ItemSchema, `item/${id}`);
};

export interface GetItemsArgs {
  gameId: number;
  collectionId?: string;
  generatorId?: string;
  blockchainId?: number;
  start?: number;
  limit?: number;
}

export const getItems = (args: GetItemsArgs): Promise<ItemResults> => {
  return validatedRequestWithAuth(ItemResultsSchema, 'item', {
    params: args,
  });
};

export interface CreateItemArgs {
  collectionId: string;
  gameId: number;
  totalSupply: string;
  blockchainId: number;
  name?: string;
  description?: string;
  image?: string;
  generatorId?: string;
  publicMetadata?: AssetMetadata;
  address?: string;
}

export const createItem = (args: CreateItemArgs): Promise<Item> => {
  return validatedRequestWithAuth(ItemSchema, 'item', {
    data: args,
    method: 'POST',
  });
};

export const updateItem = ({
  id,
  name,
  description,
  image,
  publicMetadata,
}: {
  id: Item['id'];
  name?: string;
  description?: string;
  image?: string;
  publicMetadata?: AssetMetadata;
}) => {
  return validatedRequestWithAuth(ItemSchema, `item/${id}`, {
    data: {
      name,
      description,
      image,
      publicMetadata,
    },
    method: 'PUT',
  });
};

export const deleteItem = ({ id }: { id: Item['id'] }) => {
  return authenticatedRequest(`item/${id}`, {
    method: 'DELETE',
  });
};
