import { Box, styled, Typography } from '@mui/material';
import { PurchasableItem } from '@stardust-monorepo/types/marketplace';

import { Accordion, AccordionSummary, AccordionDetails } from '../accordion';

const PurchasableItemImage = styled('img')(({ theme }) => ({
  width: '40px',
  height: '40px',
  borderRadius: 2 * theme.shape.borderRadius,
}));

const TestPurchaseItem = ({ item }: { item: PurchasableItem }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <PurchasableItemImage src={item.image} alt={item.template.name} />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'baseline',
            ml: 1.5,
          }}
        >
          <Typography variant="subtitle2">{item.template.name}</Typography>
          <Typography variant="caption" color="secondary">
            {item.template.id}
          </Typography>
        </Box>
      </Box>
      <Typography variant="subtitle1">x{item.amount}</Typography>
    </Box>
  );
};

export const TestPurchaseDetails = ({
  purchasableItems,
}: {
  purchasableItems: PurchasableItem[];
}) => {
  return (
    <Accordion data-testid="test-purchase-details" sx={{ width: '400px' }}>
      <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
        <Typography variant="subtitle2">Test Purchase Details</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
          }}
        >
          {purchasableItems.map((item) => (
            <TestPurchaseItem item={item} key={item.id} />
          ))}
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};
