import { z } from 'zod';

import { TemplateSchema } from './template';

export const PurchasableItemSchema = z
  .object({
    purchasableId: z.number(),
    templateId: z.number(),
    id: z.number(),
    amount: z.string(),
    image: z.string().url(),
    template: TemplateSchema,
  })
  .describe('PurchasableItemSchema');
export type PurchasableItem = z.infer<typeof PurchasableItemSchema>;

export const PurchasableSchema = z
  .object({
    gameId: z.number(),
    name: z.string(),
    image: z.string().url(),
    currencyISOCode: z.string(),
    id: z.number(),
    activeListing: z.boolean(),
    totalSupply: z.string(),
    cap: z.string(),
    price: z.string(),
    purchasableItemsCount: z.number(),
  })
  .describe('PurchasableSchema');
export type Purchasable = z.infer<typeof PurchasableSchema>;
