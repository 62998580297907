import { z } from 'zod';

export const IdentityIdSchema = z.string().uuid().describe('IdentityIdSchema');
export type IdentityId = z.infer<typeof IdentityIdSchema>;

export const IdentityTypeSchema = z
  .enum(['user', 'project', 'role', 'team', 'organization'])
  .describe('IdentityTypeSchema');
export type IdentityType = z.infer<typeof IdentityTypeSchema>;

export const IdentitySchema = z
  .object({
    id: IdentityIdSchema,
    parentId: z.string().nullable(),
    rootUserId: z.string(),
    type: IdentityTypeSchema,
    name: z.string(),
  })
  .describe('IdentitySchema');
export type Identity = z.infer<typeof IdentitySchema>;
