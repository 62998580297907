import { Properties } from '@stardust-monorepo/types/sd-private';
import { z } from 'zod';

import { Property } from './PropertyRow';

export const DefaultProperty: Property = { name: '', propType: '', value: '' };

export const ZodPropertyValidation = z.array(
  z.object({
    name: z.string().min(1, 'Name is required'),
    propType: z.string().min(1, 'Type is required'),
    value: z.string().min(1, 'Value is required').or(z.number()),
  })
);

export const mapToProperties = (properties: Property[]): Properties => {
  return properties.reduce<Properties>((acc, prop) => {
    acc[prop.name] =
      prop.propType === 'string'
        ? String(prop.value)
        : prop.propType === 'number'
        ? Number(prop.value)
        : prop.value === 'true';
    return acc;
  }, {});
};

export const mapFromProperties = (
  properties?: Record<string, string | number | boolean> | null
): Property[] => {
  const props = properties || {};
  return Object.keys(props).map((keyName) => ({
    name: keyName,
    propType: typeof props[keyName],
    value: String(props[keyName]),
  }));
};
