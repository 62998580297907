import { zodResolver } from '@hookform/resolvers/zod';
import { Button, FormHelperText, Stack, Typography } from '@mui/material';
import { PencilSimple } from '@phosphor-icons/react';
import { Item } from '@stardust-monorepo/types/sd-core';
import {
  FormField,
  FormSectionContainer,
  track,
  useHandleError,
  useToast,
  useUpdateItem,
} from '@stardust-monorepo/web-sdk-apps-shared';
import React from 'react';
import { FieldErrors, useForm } from 'react-hook-form';
import { z } from 'zod';

import {
  AddProperties,
  mapFromProperties,
  mapToProperties,
  Property,
  ZodPropertyValidation,
} from '../../properties';

export interface ItemFormValue {
  name: string;
  image: string;
  description: string;
  publicMetadata: Property[];
}

const formSchema = z
  .object({
    name: z.string().min(1, 'Name is required'),
    image: z
      .string()
      .url()
      .or(z.string({ coerce: true })),
    description: z.string(),
    publicMetadata: ZodPropertyValidation,
  })
  .required();

export const EditItem = ({
  item,
  onSave,
  onCancel,
}: {
  item: Item;
  onSave: () => void;
  onCancel: () => void;
}) => {
  const handleError = useHandleError();
  const { mutate } = useUpdateItem();
  const toaster = useToast();
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<ItemFormValue>({
    defaultValues: {
      ...(item
        ? {
            name: item.name,
            description: item.description,
            image: item.image,
            publicMetadata: mapFromProperties(item.publicMetadata),
          }
        : {
            name: '',
            description: '',
            image: '',
            publicMetadata: [],
          }),
    },
    resolver: zodResolver(formSchema),
  });

  return (
    <Stack
      gap={3}
      sx={{
        flexGrow: 1,
      }}
      component={'form'}
      onSubmit={handleSubmit((data) => {
        const trackingData = {
          gameId: item.gameId,
          itemId: item.id,
        };
        try {
          mutate({
            id: item.id,
            ...data,
            publicMetadata: mapToProperties(data.publicMetadata),
          });
          track('Item Edit', 'Success', trackingData);
          toaster(`${data.name} edited successfully`, 'success');
          onSave();
        } catch (e) {
          handleError(
            e,
            `Unable to edit ${item.name}`,
            'Item Edit',
            trackingData
          );
        }
      })}
    >
      <Stack
        direction="row"
        sx={{
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
        mt={3}
      >
        <Stack
          direction="row"
          sx={{
            alignItems: 'center',
          }}
          gap={1}
        >
          <PencilSimple weight="duotone" size={20} />
          <Typography variant="h6">Edit {item.name} Item</Typography>
        </Stack>
        <Stack direction="row" gap={1}>
          <Button
            type="button"
            variant="outlined"
            color="secondary"
            onClick={() => onCancel()}
          >
            Cancel
          </Button>
          <Button type="submit" variant="contained">
            Save Changes
          </Button>
        </Stack>
      </Stack>
      <FormSectionContainer density="compact">
        <FormField
          formControlName={'name'}
          control={control}
          maxInputWidth="normal"
          subtitle="Used externally by marketplaces."
          label="Name your Item"
          type="text"
          hasError={!!errors['name']}
          renderHelpText={() =>
            errors['name'] && (
              <FormHelperText>{errors['name']?.message}</FormHelperText>
            )
          }
        />
        <FormField
          formControlName={'description'}
          control={control}
          maxInputWidth="normal"
          subtitle="Short item description that will appear on marketplaces."
          label="Description"
          type="text"
          rows={3}
          hasError={!!errors['description']}
          renderHelpText={() =>
            errors['description'] && (
              <FormHelperText>{errors['description']?.message}</FormHelperText>
            )
          }
        />
        <FormField
          formControlName={'image'}
          control={control}
          maxInputWidth="normal"
          label="Item Image"
          subtitle="Used externally by marketplaces. Image is hosted by you."
          type="text"
          hasError={!!errors['image']}
          renderHelpText={() =>
            errors['image'] && (
              <FormHelperText>{errors['image']?.message}</FormHelperText>
            )
          }
        />
      </FormSectionContainer>
      <FormSectionContainer density="compact">
        <AddProperties
          formControlName={'publicMetadata'}
          control={control}
          name={item.name || 'this Token.'}
          errors={errors['publicMetadata'] as unknown as FieldErrors[]} //TODO right type?
          watch={watch}
        />
      </FormSectionContainer>
    </Stack>
  );
};
