import { StyledComponent } from '@emotion/styled';
import { DialogActions, DialogActionsProps, styled } from '@mui/material';

export const BorderedDialogActions: StyledComponent<DialogActionsProps> =
  styled((props: DialogActionsProps) => <DialogActions {...props} />)(
    ({ theme }) => ({
      padding: `${theme.spacing(2)} ${theme.spacing(3)} !important`,
      borderTop: `1px solid ${theme.palette.divider}`,
    })
  );
