import { SdPrivateApi } from '@stardust-monorepo/web-sdk-apps-shared';
import { useQuery } from '@tanstack/react-query';

import { GetPaymentProviderArgs } from '../payment-provider-api';

const paymentProviderKeys = {
  paymentProvider: (args: GetPaymentProviderArgs) => [
    'paymentProvider',
    { ...args },
  ],
};

export function usePaymentProvider(
  args: GetPaymentProviderArgs,
  enabled = true
) {
  return useQuery({
    queryKey: paymentProviderKeys.paymentProvider(args),
    queryFn: () => SdPrivateApi.getPaymentProvider(args),
    enabled,
    retry: false,
  });
}
