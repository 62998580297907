import { Box, Link, Stack, useMediaQuery, useTheme } from '@mui/material';
import { PropsWithChildren } from 'react';

import { loginFormMaxWidth } from './LoginForm';

export const LoginFormContainer = ({
  children,
}: PropsWithChildren<unknown>) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Stack
      sx={(theme) => ({
        justifyContent: 'space-between',
        paddingX: 4,
        paddingTop: isMobile ? 10 : 3,
        paddingBottom: 3,
        flexGrow: 1,
        backgroundColor: theme.palette.background.cool,
        maxWidth: '100%',
        overflow: 'auto',
        gap: isMobile ? 8 : 0,
      })}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
        }}
      >
        {children}
      </Box>
      <Stack
        sx={{
          alignItems: 'center',
        }}
      >
        <Stack
          direction={'row'}
          gap={3}
          sx={{
            width: loginFormMaxWidth,
            maxWidth: '100%',
            alignItems: 'center',
            justifyContent: 'end',
          }}
        >
          <Link
            underline={'none'}
            variant={'caption'}
            target="_blank"
            rel="noopener"
            href="https://www.stardust.gg/terms-of-service"
          >
            Terms of Service
          </Link>

          <Link
            underline={'none'}
            variant={'caption'}
            sx={{
              textDecoration: 'none',
            }}
            target="_blank"
            rel="noopener"
            href="https://www.stardust.gg/privacy-policy"
          >
            Privacy Policy
          </Link>
        </Stack>
      </Stack>
    </Stack>
  );
};
