import { BlockchainExplorerBaseUrls } from '@stardust-monorepo/types/sd-private';

export const formatAddress = (address: string) =>
  address.length > 10
    ? `${address.substring(0, 6)}...${address.substring(address.length - 4)}`
    : address;

export const formatBlockchainExplorerUrl = (
  blockchainId?: number | null,
  address?: string | null
) =>
  blockchainId && address
    ? `${BlockchainExplorerBaseUrls[blockchainId]}/address/${address}`
    : '';
