import { Box, SxProps } from '@mui/material';
import { ReactNode } from 'react';

export interface EmptyContainerProps {
  children: ReactNode;
  sx: SxProps;
  tableStyle?: boolean;
}

export const EmptyContainer = ({
  children,
  sx,
  tableStyle = true,
}: EmptyContainerProps) => {
  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        flexGrow: 1,
        alignItems: 'center',
        ...(tableStyle
          ? {
              backgroundColor: 'background.white',
            }
          : {}),
      }}
    >
      <Box
        sx={{
          ...sx,
        }}
      >
        {children}
      </Box>
    </Box>
  );
};
