import { StyledComponent } from '@emotion/styled';
import {
  alpha,
  InputBase as MuiInputBase,
  InputProps,
  styled,
} from '@mui/material';
import { color } from '@stardust-monorepo/web-sdk-apps-shared';

export const Input: StyledComponent<InputProps> = styled(
  (props: InputProps) => <MuiInputBase {...props} />
)(({ theme }) => ({
  borderRadius: 4,
  backgroundColor: theme.palette.background.white,
  border: `1px solid ${color.neutral[300]}`,
  fontSize: 14,
  lineHeight: '18px',
  fontWeight: 400,
  transition: theme.transitions.create([
    'border-color',
    'background-color',
    'box-shadow',
  ]),
  ':focus-within': {
    boxShadow: `${alpha(theme.palette.primary.main, 0.1)} 0 0 0 2px`,
    borderColor: theme.palette.primary.main,
  },
  'label + &': {
    marginTop: theme.spacing(0.5),
  },
  '&.Mui-error': {
    borderColor: theme.palette.error.main,
  },
  '& .MuiInputBase-input': {
    padding: '6px 8px',
    position: 'relative',
  },
  '& .MuiInputBase-input[type="number"]': {
    MozAppearance: 'textfield',
  },
  '& .MuiInputBase-input::-webkit-outer-spin-button': {
    WebkitAppearance: 'none',
    margin: 0,
  },
  '& .MuiInputBase-input::-webkit-inner-spin-button': {
    WebkitAppearance: 'none',
    margin: 0,
  },
  '& .MuiAutocomplete-endAdornment': {
    right: 8,
  },
}));
