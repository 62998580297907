import { Add } from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';
import { useEffect } from 'react';
import {
  FieldErrors,
  FieldPath,
  FieldValues,
  useFieldArray,
  UseFormWatch,
} from 'react-hook-form';
import { Control } from 'react-hook-form/dist/types/form';

import { Property, PropertyRow } from './PropertyRow';
import { DefaultProperty } from './utils';

export interface AddPropertiesProps<F extends FieldValues> {
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any>;
  errors?: FieldErrors<Property>[];
  name: string;
  formControlName?: string;
  removeAll?: boolean;
  setRemoveAll?: (removeAll: boolean) => void;
  watch: UseFormWatch<F>;
}

export const AddProperties = <F extends FieldValues>({
  control,
  errors,
  name,
  removeAll,
  setRemoveAll,
  watch,
  formControlName = 'props',
}: AddPropertiesProps<F>) => {
  const {
    fields: propertyFields,
    append: appendProperty,
    remove: removeProperty,
    update: updateProperty,
  } = useFieldArray({
    control,
    name: formControlName,
  });

  useEffect(() => {
    if (removeAll) {
      const totalProps = propertyFields.length;
      for (let i = 0; i < totalProps; i++) {
        removeProperty(0);
      }
      setRemoveAll && setRemoveAll(false);
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [removeAll]);

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          pb: 2,
        }}
      >
        <Typography variant="subtitle2">Attributes</Typography>
        <Button
          size="small"
          variant="outlined"
          color="secondary"
          startIcon={<Add />}
          onClick={() => appendProperty({ ...DefaultProperty })}
        >
          New
        </Button>
      </Box>
      {propertyFields.length === 0 ? (
        <Box
          sx={{
            py: 1.5,
            border: '1px solid',
            borderColor: 'divider',
            borderRadius: 2,
            width: '100%',
            textAlign: 'center',
            backgroundColor: 'background.white',
          }}
        >
          <Typography variant="body2" color="text.hint">
            Add custom properties to {name}.
          </Typography>
        </Box>
      ) : (
        <Box
          sx={{
            p: 2,
            border: '1px solid',
            borderColor: 'divider',
            borderRadius: 2,
          }}
        >
          <Box
            sx={{
              px: 0.75,
              pb: 0.25,
              display: 'flex',
              gap: 1,
              alignItems: 'center',
            }}
          >
            <Typography
              variant="caption"
              sx={{ flex: '1 1 0', pl: 1 }}
              color="text.secondary"
            >
              Name
            </Typography>
            <Typography
              variant="caption"
              sx={{ flex: '1 1 0', pl: 1 }}
              color="text.secondary"
            >
              Type
            </Typography>
            <Typography
              variant="caption"
              sx={{ flex: '1 1 0', pl: 1 }}
              color="text.secondary"
            >
              Value
            </Typography>
            <Box sx={{ width: 36 }} />
          </Box>
          {propertyFields.map((prop, propIndex) => (
            <PropertyRow
              key={prop.id}
              control={control}
              errors={errors?.[propIndex]}
              fieldName={`${formControlName}.${propIndex}` as FieldPath<F>}
              sx={{ borderTop: 'none', padding: 0.75 }}
              updateProperty={(property: Property) =>
                updateProperty(propIndex, property)
              }
              removeProperty={() => removeProperty(propIndex)}
              watch={watch}
            />
          ))}
        </Box>
      )}
    </Box>
  );
};
