import { Box, SxProps, useTheme } from '@mui/material';
import { color } from '@stardust-monorepo/web-sdk-apps-shared';
import { PropsWithChildren } from 'react';

export const SelectButton = ({
  checked,
  onClick,
  children,
  height,
  disabled,
  sx = {},
}: PropsWithChildren<{
  checked: boolean;
  height?: number | string;
  onClick: () => void;
  disabled?: boolean;
  sx?: SxProps;
}>) => {
  const theme = useTheme();
  const styleModifiers =
    checked && disabled
      ? {
          boxShadow: `0px 3px 0px ${theme.palette.primary.main}`,
          borderColor: theme.palette.primary.main,
          backgroundColor: theme.palette.background.white,
          cursor: 'default',
        }
      : checked
      ? {
          boxShadow: `0px 3px 0px ${theme.palette.primary.main}`,
          borderColor: theme.palette.primary.main,
          backgroundColor: theme.palette.background.white,
          color: theme.palette.text.primary,
        }
      : disabled
      ? {
          backgroundColor: theme.palette.background.cool,
          color: theme.palette.text.secondary,
          cursor: 'default',
        }
      : {
          '&:hover': {
            boxShadow: `0px 3px 0px ${color.neutral[400]}`,
            borderColor: color.neutral[400],
          },
        };

  return (
    <Box
      onClick={() => {
        if (!disabled) {
          onClick();
        }
      }}
      sx={{
        paddingX: 2.5,
        paddingY: 2,
        boxShadow: `0px 3px 0px ${color.neutral[200]}`,
        transition: 'all 300ms ease',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        flex: 1,
        border: '1px solid',
        borderRadius: 3,
        borderColor: color.neutral[200],
        ...(height ? { height } : {}),
        ...styleModifiers,
        ...sx,
      }}
    >
      {children}
    </Box>
  );
};
