import { zodResolver } from '@hookform/resolvers/zod';
import { FormHelperText, Stack, Typography } from '@mui/material';
import {
  CollectionType,
  FungibleToken,
  NonFungibleToken,
} from '@stardust-monorepo/types/sd-core';
import {
  CreateDocumentation,
  FormButtonBar,
  FormField,
  FormSectionContainer,
} from '@stardust-monorepo/web-sdk-apps-shared';
import React, { MutableRefObject } from 'react';
import { FieldErrors, useForm } from 'react-hook-form';
import { z } from 'zod';

import {
  AddProperties,
  Property,
  ZodPropertyValidation,
} from '../../properties';

export interface ItemFormValue {
  name: string;
  description: string;
  image: string;
  publicMetadata: Property[];
  supplyType: 'Limited' | 'Unlimited' | '';
  totalSupply: string;
}

export const defaultItemFormValue: (
  collectionType: CollectionType
) => ItemFormValue = (collectionType: CollectionType) => ({
  name: '',
  description: '',
  image: '',
  publicMetadata: [],
  supplyType: collectionType === NonFungibleToken ? 'Limited' : '',
  totalSupply: collectionType === NonFungibleToken ? '1' : '0', //256 bit int
});

export const formSchema = z
  .object({
    name: z.string().min(1, 'Name is required'),
    description: z.string().min(1, 'Description is required'),
    image: z
      .string()
      .url()
      .or(z.string({ coerce: true })),
    publicMetadata: ZodPropertyValidation,
    supplyType: z.string().min(1, 'Total Supply is required'),
    totalSupply: z.string(),
  })
  .refine(
    (data) =>
      (data.supplyType === 'Limited' && (data.totalSupply || 0) >= 1) ||
      data.supplyType === 'Unlimited',
    {
      path: ['totalSupply'],
      message: 'Limit must be at least 1',
    }
  );

export const CreateItemForm = ({
  collectionType,
  submitFormRef,
  onSave,
}: {
  collectionType: CollectionType;
  submitFormRef: MutableRefObject<HTMLButtonElement | null>;
  onSave: (item: ItemFormValue) => void;
}) => {
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<ItemFormValue>({
    defaultValues: {
      ...defaultItemFormValue(collectionType),
      ...{
        tokenType: collectionType === FungibleToken ? 'NFT' : undefined,
      },
    },
    resolver: zodResolver(formSchema),
  });

  const supplyTypeOptions = [
    {
      label: 'Fixed Quantity',
      value: 'Limited',
      description: 'Define max number of items that can be minted.',
      renderSubField: () => (
        <FormField
          formControlName="totalSupply"
          disabled={collectionType === NonFungibleToken}
          control={control}
          sx={{ mt: 2 }}
          placeholder="Limit"
          type="number"
          hasError={!!errors['totalSupply']}
          renderHelpText={() =>
            errors['totalSupply'] && (
              <FormHelperText>{errors['totalSupply']?.message}</FormHelperText>
            )
          }
        />
      ),
    },
    {
      label: 'Unlimited',
      value: 'Unlimited',
      description: 'No defined max number of items can be minted.',
    },
  ];

  return (
    <Stack
      component="form"
      onSubmit={handleSubmit((data) => {
        onSave({
          ...data,
        });
      })}
    >
      <button
        data-testid="item-form-submit-button"
        ref={submitFormRef}
        style={{ display: 'none' }}
      />
      <Typography variant="h4" mt={7}>
        Create a New Item
      </Typography>
      <Typography variant="body2" sx={{ color: 'text.secondary', mt: 1 }}>
        Define how you want your new item to appear.
      </Typography>
      <Stack direction={'row'} gap={4} sx={{ mt: 5, flexGrow: 1 }}>
        <Stack gap={2}>
          <FormSectionContainer sx={{ flexGrow: 1 }}>
            <Stack>
              <Typography variant="h6">Details</Typography>
              <Typography
                variant="body2"
                sx={{ color: 'text.secondary', mt: 0.5, mb: 3 }}
              >
                Metadata describing the item. This can be changed after
                creation.
              </Typography>
            </Stack>
            <FormField
              formControlName={'name'}
              control={control}
              subtitle="Used externally by marketplaces."
              label="Name your Item"
              type="text"
              hasError={!!errors['name']}
              renderHelpText={() =>
                errors['name'] && (
                  <FormHelperText>{errors['name']?.message}</FormHelperText>
                )
              }
            />
            <FormField
              formControlName={'image'}
              control={control}
              label="Item Image"
              subtitle="Used externally by marketplaces. Image is hosted by you."
              type="text"
              hasError={!!errors['image']}
              renderHelpText={() =>
                errors['image'] && (
                  <FormHelperText>{errors['image']?.message}</FormHelperText>
                )
              }
            />
            <FormField
              formControlName={'description'}
              control={control}
              subtitle="Short item description that will appear on marketplaces."
              label="Description"
              rows={3}
              type="text"
              hasError={!!errors['description']}
              renderHelpText={() =>
                errors['description'] && (
                  <FormHelperText>
                    {errors['description']?.message}
                  </FormHelperText>
                )
              }
            />
            <FormButtonBar
              disabled={collectionType === NonFungibleToken}
              width={'512px'}
              control={control}
              formControlName={'supplyType'}
              hasError={!!errors['supplyType']}
              label="Total Supply Capacity"
              description="Max number of items, or supply, that can be created for this collection."
              options={supplyTypeOptions}
              renderHelpText={() =>
                errors['supplyType'] && (
                  <FormHelperText>
                    {errors['supplyType']?.message}
                  </FormHelperText>
                )
              }
            />
          </FormSectionContainer>
          <FormSectionContainer>
            <Typography variant="h6">Attributes</Typography>
            <Typography
              variant={'body2'}
              color={'text.secondary'}
              sx={{
                mt: 0.5,
                mb: 3,
              }}
            >
              Define the custom attributes that all items of this type will
              inherit. These attributes will be set on the metadata for each
              item.
            </Typography>
            <AddProperties
              formControlName={'publicMetadata'}
              control={control}
              name="Item"
              errors={errors['publicMetadata'] as unknown as FieldErrors[]} //TODO right type?
              watch={watch}
            />
          </FormSectionContainer>
        </Stack>
        <CreateDocumentation
          title="Items"
          description="An Item is where the metadata is defined, describing what is being created. After an Item is created, it can be awarded (minted) to a player by creating an Item Balance. On the blockchain, an Item is similar to a token. Creating an Item in Stardust is like defining a token, and creating an Item Balance is minting the token to a given wallet (player)."
        />
      </Stack>
    </Stack>
  );
};
