import { z } from 'zod';

export const ApiKeySchema = z
  .object({
    apiKey: z.string(),
    apiKeyId: z.string(),
    apiKeyName: z.string(),
    enabled: z.boolean(),
    gameId: z.number(),
    ownerId: z.string().uuid(),
    primary: z.boolean(),
    createdAt: z.string().datetime(),
  })
  .describe('ApiKeySchema');
export type ApiKey = z.infer<typeof ApiKeySchema>;

export const ApiLogCountSchema = z
  .object({
    totalApiLogCount: z.number(),
    apiLogs: z.number(),
  })
  .describe('ApiLogCountSchema');
export type ApiLogCount = z.infer<typeof ApiLogCountSchema>;

export const ApiLogSchema = z
  .object({
    totalApiLogCount: z.number(),
    apiLogs: z.array(
      z.object({
        id: z.number(),
        api_key_id: z.string(),
        created_at: z.string().datetime(),
        endpoint: z.string(),
        operation: z.string(),
        payload: z.record(z.string(), z.any()),
        error_exception: z.record(z.string(), z.any()),
      })
    ),
  })
  .describe('ApiLogSchema');
export type ApiLog = z.infer<typeof ApiLogSchema>;
