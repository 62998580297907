import { Box, Link, styled, Typography } from '@mui/material';
import { Purchasable } from '@stardust-monorepo/types/marketplace';
import {
  color,
  purchasableToTrackable,
  track,
} from '@stardust-monorepo/web-sdk-apps-shared';

interface Props {
  purchasable: Purchasable;
  navigationUrl: string;
}

export const suggestedPurchasableHeight = '80px';

const PurchasableImage = styled('img')((mui) => ({
  width: '80px',
  height: suggestedPurchasableHeight,
  boxShadow: mui.theme.shadows[2],
  border: `2px solid ${color.neutral.white}`,
  borderRadius: mui.theme.shape.borderRadius * 2,
}));

const PriceBadge = ({ price }: { price: string }) => {
  return (
    <Box
      sx={{
        paddingY: 0.25,
        paddingX: 0.5,
        display: 'flex',
        backgroundColor: color.neutral.white,
        opacity: 0.9,
        borderRadius: '999px',
        position: 'absolute',
        bottom: '6px',
        left: '6px',
      }}
    >
      <Typography variant="caption">${price}</Typography>
    </Box>
  );
};

export const SuggestedPurchasable = ({ purchasable, navigationUrl }: Props) => {
  return (
    <Link
      href={navigationUrl}
      onClick={() => {
        track(
          'Suggested Purchasable Card',
          'Clicked',
          purchasableToTrackable(purchasable)
        );
      }}
      underline="none"
      color="inherit"
      data-testid="purchasable"
      key={purchasable.id}
      sx={{
        display: 'flex',
        flexDirection: 'row',
        position: 'relative',
        transition: 'all 0.2s ease-in-out',
        '&:hover': {
          cursor: 'pointer',
          opacity: 0.7,
          transition: 'all 0.2s ease-in-out',
        },
      }}
    >
      <PurchasableImage src={purchasable.image} alt={purchasable.name} />
      <PriceBadge price={Number(purchasable.price).toFixed(2)} />
      <Typography
        textOverflow="ellipsis"
        variant="caption"
        sx={(theme) => ({
          color: theme.palette.text.link,
          maxWidth: '100px', //TODO use a grid instead
        })}
        textAlign="center"
        ml={1.25}
        mt={0.25}
      >
        {purchasable.name}
      </Typography>
    </Link>
  );
};
