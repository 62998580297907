import { IconButton } from '@mui/material';
import { MouseEvent, ReactNode, useState } from 'react';

import { BasicMenu, MenuAction } from './basic-menu';

export interface IconMenuProps {
  actions: MenuAction[];
  icon: ReactNode;
  label: string;
  secondaryStyle?: boolean;
  size?: 'small' | 'large';
}

export const IconMenu = ({
  actions,
  icon,
  label,
  secondaryStyle,
  size = 'large',
}: IconMenuProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        aria-label={label}
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        sx={{
          ...(secondaryStyle
            ? { color: 'text.secondary' }
            : { color: 'text.hint', '&:hover': { color: 'text.primary' } }),
          ...(size === 'small' ? { padding: '0 !important' } : {}),
        }}
      >
        {icon}
      </IconButton>
      <BasicMenu
        actions={actions}
        anchorEl={anchorEl}
        onClose={handleClose}
        open={open}
      />
    </>
  );
};
