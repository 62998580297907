import { z } from 'zod';

import { FeeSchema } from './fee';

export const GameSchema = z
  .object({
    id: z.number(),
    name: z.string(),
    image: z.string(),
    desc: z.string(),
    blockchain: z.number(),
    royalty: z.optional(z.number()),
    stardustFee: z.optional(z.number()),
    banner: z.optional(z.string()),
    news: z.optional(z.nullable(z.string())),
    bucketName: z.string(),
    escrow: z.string(),
    ownerId: z.string(),
    fees: z.optional(z.array(FeeSchema)),
    testMode: z.boolean(),
    createdAt: z.string().datetime(),
    props: z.object({
      version: z.optional(z.number()),
      socialMediaLinks: z.optional(
        z
          .object({
            name: z.enum([
              'FACEBOOK',
              'INSTAGRAM',
              'LINKEDIN',
              'REDDIT',
              'TWITTER',
              'WEBSITE',
              'YOUTUBE',
            ]),
            url: z.string().url(),
          })
          .array()
      ),
    }),
  })
  .describe('GameSchema');
export type Game = z.infer<typeof GameSchema>;
