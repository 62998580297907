import { useQuery } from '@tanstack/react-query';

export const gameKeys = {
  game: (gameId: number | string) => ['game', { gameId }] as const,
  games: () => ['games'] as const,
};

export function useGame<T>(
  gameId: string | number | undefined,
  getGame: (args: { gameId: string | number }) => Promise<T>,
  enabled?: boolean
) {
  return useQuery({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    queryKey: gameKeys.game(gameId!),
    queryFn: () =>
      getGame({
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        gameId: gameId!,
      }),
    enabled: enabled && gameId !== undefined,
  });
}

export function useGames<T>(getGames: () => Promise<T>, enabled?: boolean) {
  return useQuery({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    queryKey: gameKeys.games(),
    queryFn: () => getGames(),
    enabled,
  });
}
