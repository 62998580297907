export const centsFormatter = new Intl.NumberFormat('en-US', {
  minimumIntegerDigits: 2,
});
export const dollarsFormatter = new Intl.NumberFormat('en-US');

export const formatUsd = (amount: string) => {
  return `$${dollarsFormatter.format(
    BigInt(amount) / BigInt('100')
  )}.${centsFormatter.format(BigInt(amount) % BigInt('100'))}`;
};
