import { z } from 'zod';

export function paginatedResultsSchema<RecordSchema extends z.ZodTypeAny>(
  recordSchema: RecordSchema
) {
  return z.object({
    total: z.number(),
    results: z.array(recordSchema),
  });
}

export function paginatedResultsWithFilterSchema<
  RecordSchema extends z.ZodTypeAny
>(recordSchema: RecordSchema) {
  return z
    .object({
      start: z.number().optional(),
      limit: z.number().optional(),
      filter: z.any().optional(),
    })
    .merge(paginatedResultsSchema(recordSchema));
}

export function transformRecordToPaginatedResult<RecordType>(
  record: RecordType
) {
  return {
    total: 1,
    results: [record],
  };
}
