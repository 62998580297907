import { Box, Paper, Stack, Typography, useTheme } from '@mui/material';
import { ArrowRight, Coins, Package, Star } from '@phosphor-icons/react';
import {
  CollectionType,
  FungibleAsset,
  FungibleToken,
  NonFungibleToken,
} from '@stardust-monorepo/types/sd-core';
import { color } from '@stardust-monorepo/web-sdk-apps-shared';
import { ReactNode, useState } from 'react';

import {
  collectionTypeToDisplayString,
  collectionTypeToTechnicalDisplayString,
} from './util';

interface CollectionTypeOptionProps {
  title: string;
  description: string;
  icon: ReactNode;
  contractDescription: string;
  selected: () => void;
  details1: string;
  details2?: string;
}

const CollectionTypeOption = ({
  title,
  description,
  icon,
  contractDescription,
  selected,
  details1,
  details2,
}: CollectionTypeOptionProps) => {
  const [hovering, setHovering] = useState<boolean>(false);
  const theme = useTheme();

  const HoverWrapper = () =>
    hovering ? (
      <Stack
        sx={(theme) => ({
          position: 'absolute',
          top: 0,
          width: '100%',
          height: '100%',
          flexDirection: 'column-reverse',
          zIndex: 2,
          border: `2px solid ${theme.palette.primary.main}`,
          borderRadius: 3,
        })}
      >
        <Stack
          direction={'row'}
          gap={1}
          sx={{
            paddingY: 1.5,
            alignItems: 'center',
            backgroundColor: 'primary.main',
            justifyContent: 'center',
          }}
        >
          <Typography variant="subtitle1" color="text.white">
            Continue
          </Typography>
          <ArrowRight size={18} color={theme.palette.text.white} />
        </Stack>
      </Stack>
    ) : null;

  return (
    <Stack
      direction="column"
      gap={4}
      sx={{
        flex: '1 1 0',
        height: '100%',
      }}
    >
      <Paper
        onClick={() => selected()}
        onMouseEnter={() => {
          setHovering(true);
        }}
        onMouseLeave={() => {
          setHovering(false);
        }}
        elevation={hovering ? 12 : 2}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: 'min-content',
          borderRadius: 3,
          overflow: 'hidden',
          position: 'relative',
          ...(hovering
            ? {
                cursor: 'pointer',
                '&:after': {
                  //'haze' over the text above the button
                  content: '""',
                  position: 'absolute',
                  width: '100%',
                  height: '68px',
                  bottom: 4,
                  display: 'block',
                  zIndex: 1,
                  borderRadius: '0 0 12px 12px',
                  background:
                    'linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 50%)',
                },
              }
            : {
                '&:after': {
                  //accent line at the bottom of the option
                  content: '""',
                  height: '4px',
                  position: 'absolute',
                  bottom: 0,
                  width: '100%',
                  background:
                    'linear-gradient(180deg, #B170FE 0%, #642FED 100%)',
                  display: 'block',
                },
              }),
        }}
      >
        <HoverWrapper />
        <Stack
          sx={(theme) => ({
            padding: 2.875,
          })}
        >
          <Box
            sx={(theme) => ({
              width: 'min-content',
              border: hovering
                ? `1px solid ${color.brand.primary[500]}`
                : `1px dashed ${color.neutral[300]}`,
              borderRadius: 2,
              display: 'flex',
              padding: 1.5,
              transition: 'fill ease 150ms',
              fill: hovering
                ? theme.palette.primary.main
                : theme.palette.text.primary,
            })}
          >
            {icon}
          </Box>
          <Stack
            gap={0.5}
            direction={'column'}
            sx={{
              marginTop: 3,
              marginBottom: 1,
            }}
          >
            <Typography variant="h6">{title}</Typography>
            <Typography variant="subtitle2" color="text.secondary">
              ({contractDescription})
            </Typography>
          </Stack>
          <Typography variant="body2" color="text.secondary">
            {description}
          </Typography>
        </Stack>
      </Paper>
      <Stack
        gap={1}
        sx={{
          opacity: hovering ? 1 : 0,
          transition: 'opacity ease 300ms',
          paddingX: 3,
        }}
      >
        <Typography variant="h6">Details</Typography>
        <Typography variant="body2" color="text.secondary">
          {details1}
        </Typography>
        {details2 && (
          <Typography variant="body2" color="text.secondary">
            {details2}
          </Typography>
        )}
      </Stack>
    </Stack>
  );
};

export interface SelectCollectionTypeProps {
  collectionTypeSelected: (collectionType: CollectionType) => unknown;
}

export const SelectCollectionType = ({
  collectionTypeSelected,
}: SelectCollectionTypeProps) => {
  return (
    <Stack direction={'row'} gap={4}>
      <CollectionTypeOption
        title={collectionTypeToDisplayString(FungibleAsset)}
        contractDescription={collectionTypeToTechnicalDisplayString(
          FungibleAsset
        )}
        description="A flexible collection of common items, made up of Fungible Assets (FA)."
        icon={<Package weight="duotone" size={56} color="inherit" />}
        selected={() => collectionTypeSelected(FungibleAsset)}
        details1="The main collection type. The use case for Game Collections is for most of a game's items: they don't need to be unique, but they still have metadata associated with them."
        details2="An example could be having a Game Collection called Weapons, with Items such as Swords, Axes, and Maces. The Sword item is different than the Axe, but all Swords are identical."
      />
      <CollectionTypeOption
        title={collectionTypeToDisplayString(NonFungibleToken)}
        contractDescription={collectionTypeToTechnicalDisplayString(
          NonFungibleToken
        )}
        description="A collection of unique items, made up of Non-fungible Tokens (NFT)."
        icon={<Star weight="duotone" size={56} color="inherit" />}
        selected={() => collectionTypeSelected(NonFungibleToken)}
        details1="Certain use cases require every single Item in a Collection to be unique. Standalone Asset Collections are useful for Limited Edition Items, where the specific Item a player owns is important and differs from all of the others."
      />
      <CollectionTypeOption
        title={collectionTypeToDisplayString(FungibleToken)}
        contractDescription={collectionTypeToTechnicalDisplayString(
          FungibleToken
        )}
        description="A single-item collection, or currency, made up of a Fungible Token (FT)."
        icon={<Coins weight="duotone" size={56} color="inherit" />}
        selected={() => collectionTypeSelected(FungibleToken)}
        details1="A Standalone Currency Collection will only have one Item, since there is no differentiation between Items within this Collection. This Collection is meant for currencies or resources inside of your game: gold, wood, etc."
      />
    </Stack>
  );
};
