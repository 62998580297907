import { paginatedResultsSchema } from '@stardust-monorepo/types/common';
import { z } from 'zod';

import { HasPublicMetadata } from './has-public-metadata';

export const ItemSchema = z
  .object({
    id: z.string(),
    name: z.string(),
    image: z.string().url().or(z.literal('')),
    description: z.string(),
    generatorId: z.optional(z.string().nullable()),
    collectionId: z.string(),
    blockchainId: z.nullable(z.number()),
    gameId: z.number(),
    address: z.nullable(z.string()),
    currentSupply: z.string(),
    totalSupply: z.string(),
    createdAt: z.number(),
    updatedAt: z.number(),
  })
  .merge(HasPublicMetadata)
  .describe('ItemSchema');
export type Item = z.infer<typeof ItemSchema>;

export const ItemResultsSchema = paginatedResultsSchema(ItemSchema);
export type ItemResults = z.infer<typeof ItemResultsSchema>;
