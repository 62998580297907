import { z } from 'zod';

export const PaymentMethodSchema = z.object({
  //TODO props coming from stripe should be camelcase
  id: z.string(),
  billing_details: z.object({
    address: z.object({
      city: z.string().nullable(),
      country: z.string().nullable(),
      line1: z.string().nullable(),
      line2: z.string().nullable(),
      postal_code: z.string().nullable(),
      state: z.string().nullable(),
    }),
  }),
  card: z.object({
    brand: z.string(),
    exp_month: z.number(),
    exp_year: z.number(),
    last4: z.string(),
  }),
});
export type PaymentMethod = z.infer<typeof PaymentMethodSchema>;
