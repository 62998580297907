import { FormControl, Stack, Typography, useTheme } from '@mui/material';
import { LockKey } from '@phosphor-icons/react';
import React, { cloneElement, ReactElement, ReactNode } from 'react';
import { Controller } from 'react-hook-form';
import { Control } from 'react-hook-form/dist/types/form';

import { InputLabel } from '../input';
import { SelectButton } from '../select-button';

export interface FormButtonBarProps {
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any>;
  disabled?: boolean;
  formControlName: string;
  hasError?: boolean;
  label?: string;
  description?: string;
  options: {
    icon?: ReactElement;
    label: string;
    description?: string;
    value: string | number;
    renderSubField?: () => ReactNode;
  }[];
  renderHelpText?: () => ReactNode;
  width?: string | number;
  virtualOptionCount?: number;
}

export const FormButtonBar = ({
  control,
  disabled,
  formControlName,
  hasError,
  label,
  description,
  options,
  renderHelpText,
  width,
  virtualOptionCount,
}: FormButtonBarProps) => {
  const helpTextNode = renderHelpText?.();
  const theme = useTheme();
  const optionGapSize = 12; //px
  return (
    <Controller
      control={control}
      name={formControlName}
      render={({ field: { onChange, value } }) => (
        <FormControl error={hasError} disabled={disabled} margin="normal">
          <>
            <InputLabel sx={{ mb: 0.5 }}>{label}</InputLabel>
            {description && (
              <Typography
                variant="helperText"
                sx={{
                  color: 'text.secondary',
                  mb: 1.5,
                }}
              >
                {description}
              </Typography>
            )}
            <Stack
              direction="row"
              gap={`${optionGapSize}px`}
              sx={width ? { width } : {}}
            >
              {options.map((option) => (
                <SelectButton
                  sx={{
                    alignItems: 'baseline',
                    ...(virtualOptionCount
                      ? {
                          //css grid would be much better for this
                          maxWidth: `calc((100% - ${
                            (virtualOptionCount - 1) * optionGapSize
                          }px) / ${virtualOptionCount})`,
                        }
                      : {}),
                  }}
                  key={option.label}
                  checked={value === option.value}
                  disabled={disabled}
                  onClick={() => onChange(option.value)}
                >
                  <Stack>
                    {option.icon && (
                      <Stack
                        sx={{
                          marginBottom: 3,
                        }}
                      >
                        {disabled && option.value !== value
                          ? cloneElement(option.icon, {
                              ...option.icon.props,
                              color: theme.palette.text.secondary,
                            })
                          : option.icon}
                      </Stack>
                    )}
                    <Stack direction={'row'} gap={0.5} alignItems={'center'}>
                      <Typography variant="subtitle1">
                        {option.label}
                      </Typography>
                      {disabled && option.value === value && (
                        <LockKey size={16} color={theme.palette.text.hint} />
                      )}
                    </Stack>
                    <Typography
                      variant="helperText"
                      sx={{
                        color: 'text.secondary',
                        marginTop: 0.5,
                      }}
                    >
                      {option.description ?? ''}
                    </Typography>
                    {value === option.value && option.renderSubField
                      ? option.renderSubField()
                      : null}
                  </Stack>
                </SelectButton>
              ))}
            </Stack>
            {helpTextNode}
          </>
        </FormControl>
      )}
    />
  );
};
