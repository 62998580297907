import {
  TeamMember,
  TeamMemberSchema,
} from '@stardust-monorepo/types/sd-private';

import { authenticatedRequest, validatedRequestWithAuth } from './api-utils';

export async function getTeamMembers(
  gameId: number,
  start?: number,
  limit?: number
): Promise<TeamMember[]> {
  return validatedRequestWithAuth(
    TeamMemberSchema.array(),
    'team-member/get-all',
    {
      params: {
        gameId,
        start,
        limit,
      },
    }
  );
}

export interface TeamMemberArgs {
  gameId: number;
  teamMemberEmail: string;
}

export async function deleteTeamMember(
  args: TeamMemberArgs
): Promise<Record<string, never>> {
  return authenticatedRequest('team-member/remove', {
    method: 'DELETE',
    params: args,
  });
}

export async function createTeamMember(
  args: TeamMemberArgs
): Promise<Record<string, never>> {
  return authenticatedRequest('team-member/create', {
    method: 'POST',
    data: args,
  });
}
