import { useTheme } from '@mui/material';
import { ApiLogCount } from '@stardust-monorepo/types/sd-private';
import {
  isDoneLoading,
  LineChart,
  SdPrivateApi,
} from '@stardust-monorepo/web-sdk-apps-shared';
import { useQueries, UseQueryResult } from '@tanstack/react-query';
import {
  eachDayOfInterval,
  eachWeekOfInterval,
  endOfDay,
  endOfWeek,
  format,
  subDays,
  subMonths,
  subQuarters,
} from 'date-fns';
import { useMemo } from 'react';

import { TimeFrame } from '../../../types/timeframe';

export const ApiCallsChart = ({
  gameId,
  timeFrame,
}: {
  gameId: number;
  timeFrame: TimeFrame;
}) => {
  const theme = useTheme();
  const now = useMemo(() => new Date(), []);

  let statIntervals: { startDate: Date; endDate: Date; period: string }[] = [];
  if (timeFrame === 'week') {
    statIntervals = eachDayOfInterval({
      start: subDays(now, 6),
      end: now,
    }).map((startOfDay) => ({
      startDate: startOfDay,
      endDate: endOfDay(startOfDay),
      period: format(startOfDay, 'MMM d'),
    }));
  } else if (timeFrame === 'month') {
    statIntervals = eachWeekOfInterval({
      start: subMonths(now, 1),
      end: now,
    }).map((startOfWeek) => ({
      startDate: startOfWeek,
      endDate: endOfWeek(startOfWeek),
      period: `${format(startOfWeek, 'MMM d')} - ${format(
        endOfWeek(startOfWeek),
        'MMM d'
      )}`,
    }));
  } else if (timeFrame === 'quarter') {
    statIntervals = eachWeekOfInterval({
      start: subQuarters(now, 2),
      end: now,
    }).map((startOfWeek) => ({
      startDate: startOfWeek,
      endDate: endOfWeek(startOfWeek),
      period: `${format(startOfWeek, 'MMM d')} - ${format(
        endOfWeek(startOfWeek),
        'MMM d'
      )}`,
    }));
  }
  const apiCallResults: UseQueryResult<ApiLogCount>[] = useQueries({
    queries: statIntervals.map((interval) => ({
      queryKey: SdPrivateApi.apiKeys.apiLogCounts({
        gameId,
        startTimeISO: interval.startDate.toISOString(),
        endTimeISO: interval.endDate.toISOString(),
      }),
      queryFn: () =>
        SdPrivateApi.getApiKeyLogCount({
          gameId,
          startTimeISO: interval.startDate.toISOString(),
          endTimeISO: interval.endDate.toISOString(),
        }),
    })),
  });
  const doneLoading = isDoneLoading(apiCallResults);

  const statsData = useMemo(() => {
    return apiCallResults && doneLoading
      ? apiCallResults.map((result, index) => {
          return {
            ...result.data,
            period: statIntervals[index].period,
          };
        })
      : [];
  }, [apiCallResults, doneLoading]);

  return (
    <LineChart
      data={statsData}
      config={{
        lines: {
          apiLogs: {
            color: theme.palette.error.main,
          },
        },
        xAxisKey: 'period',
      }}
    />
  );
};
