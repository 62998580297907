import Auth from '@aws-amplify/auth';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button, FormHelperText, Stack, Typography } from '@mui/material';
import { FormField } from '@stardust-monorepo/web-sdk-apps-shared';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

const newPasswordFormControls = {
  code: 'code',
  password: 'password',
  confirmPassword: 'confirmPassword',
} as const;

const newPasswordFormSchema = z.object({
  [newPasswordFormControls.code]: z.string().min(1, 'Code is required'),
  [newPasswordFormControls.password]: z.string().min(1, 'Password is required'),
  [newPasswordFormControls.confirmPassword]: z
    .string()
    .min(1, 'Password confirmation is required'),
});
export const NewPasswordForm = ({
  email,
  onResetSuccess,
}: {
  email: string;
  onResetSuccess: () => void;
}) => {
  const [passwordMismatch, setPasswordMismatch] = useState<boolean>(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      [newPasswordFormControls.code]: '',
      [newPasswordFormControls.password]: '',
      [newPasswordFormControls.confirmPassword]: '',
    },
    resolver: zodResolver(newPasswordFormSchema),
  });

  const confirmNewPassword = async (code: string, password: string) => {
    try {
      await Auth.forgotPasswordSubmit(email, code, password);
      onResetSuccess();
      // TODO handle error types?
      // eslint-disable-next-line no-empty
    } catch (error: unknown) {}
  };
  return (
    <Stack
      sx={{
        maxWidth: '360px',
      }}
      component="form"
      onSubmit={handleSubmit((data) => {
        if (
          data[newPasswordFormControls.password] ===
          data[newPasswordFormControls.confirmPassword]
        ) {
          setPasswordMismatch(false);
          confirmNewPassword(
            data[newPasswordFormControls.code],
            data[newPasswordFormControls.password]
          );
        } else {
          setPasswordMismatch(true);
        }
      })}
    >
      <Typography variant={'h4'}>Code Sent</Typography>
      <Typography variant={'body1'} marginBottom={3} marginTop={1}>
        Please check your email for your password reset code.
      </Typography>
      <FormField
        control={control}
        label="Code"
        autoComplete="one-time-code"
        formControlName={newPasswordFormControls.code}
        hasError={!!errors[newPasswordFormControls.code]}
        renderHelpText={() =>
          errors[newPasswordFormControls.code] && (
            <FormHelperText>
              {errors[newPasswordFormControls.code]?.message}
            </FormHelperText>
          )
        }
      />
      <FormField
        control={control}
        label="New Password"
        type="password"
        autoComplete="new-password"
        formControlName={newPasswordFormControls.password}
        hasError={!!errors[newPasswordFormControls.password]}
        renderHelpText={() =>
          errors[newPasswordFormControls.password] && (
            <FormHelperText>
              {errors[newPasswordFormControls.password]?.message}
            </FormHelperText>
          )
        }
      />
      <FormField
        control={control}
        label="Confirm New Password"
        type="password"
        autoComplete="new-password"
        formControlName={newPasswordFormControls.confirmPassword}
        hasError={
          !!(
            errors[newPasswordFormControls.confirmPassword] || passwordMismatch
          )
        }
        renderHelpText={() =>
          (errors[newPasswordFormControls.confirmPassword] ||
            passwordMismatch) && (
            <FormHelperText>
              {errors[newPasswordFormControls.confirmPassword]?.message ||
                (passwordMismatch && 'Password confirmation does not match')}
            </FormHelperText>
          )
        }
      />
      <Button
        type={'submit'}
        variant="contained"
        size="large"
        sx={{
          marginTop: 3,
          marginBottom: 4,
        }}
      >
        Reset my password
      </Button>
    </Stack>
  );
};
