import { Add } from '@mui/icons-material';
import {
  Box,
  Button,
  FormHelperText,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { Trash } from '@phosphor-icons/react';
import { Template } from '@stardust-monorepo/types/marketplace';
import {
  FormField,
  InfoIcon,
  ValidatedImage,
} from '@stardust-monorepo/web-sdk-apps-shared';
import React from 'react';
import { FieldErrors, useFieldArray, UseFormWatch } from 'react-hook-form';
import { Control } from 'react-hook-form/dist/types/form';

import { DefaultProperty, Property, PropertyRow } from '../properties';
import { FormValues, Token } from './types';

interface TokenRowProps {
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any>;
  errors?: FieldErrors<Token>;
  tokenFieldIndex: number;
  removeToken: () => void;
  template: Template;
  watch: UseFormWatch<FormValues>;
}

export const TokenRow = ({
  control,
  errors,
  tokenFieldIndex,
  removeToken,
  template,
  watch,
}: TokenRowProps) => {
  const error = errors?.amount;
  const {
    fields: propertyFields,
    append: appendProperty,
    remove: removeProperty,
    update: updateProperty,
  } = useFieldArray({
    control,
    name: `tokens[${tokenFieldIndex}].props`,
  });

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        mb: 0.5,
        backgroundColor: 'background.white',
        border: '1px solid',
        borderColor: 'divider',
        borderRadius: 2,
      }}
    >
      <Box sx={{ px: 1.5 }}>
        <Stack
          direction="row"
          sx={{
            width: '100%',
            alignItems: 'center',
            py: 0.5,
          }}
        >
          <Stack
            direction="row"
            sx={{ width: '50%', alignItems: 'center' }}
            gap={1.5}
          >
            <ValidatedImage
              size="34px"
              image={template.image}
              sx={{
                flexShrink: 0,
              }}
            />
            ,
            <Typography
              variant="subtitle2"
              sx={{
                paddingY: 1,
              }}
            >
              {template.name}
            </Typography>
          </Stack>
          <Box sx={{ width: '15%' }}>
            <Typography
              variant="body2"
              sx={{ lineHeight: '34px' }}
              color="text.secondary"
            >
              {template.type}
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              width: '35%',
              alignItems: 'flex-start',
              justifyContent: 'space-between',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <FormField
                formControlName={`tokens.${tokenFieldIndex}.pid`}
                control={control}
                type="hidden"
              />
              <FormField
                disabled={template.type === 'NFT'}
                formControlName={`tokens.${tokenFieldIndex}.amount`}
                control={control}
                type="number"
                hasError={!!error}
                placeholder="Amount"
                renderHelpText={() =>
                  error && <FormHelperText>{error?.message}</FormHelperText>
                }
                sx={{
                  width: template.type === 'NFT' ? '35px' : '100px',
                  mr: 1.5,
                }}
              />
              {template.type === 'NFT' ? (
                <InfoIcon info="NFTs are limited to an amount of 1" />
              ) : null}
            </Box>
            <IconButton
              aria-label="remove-token"
              onClick={removeToken}
              sx={{ color: 'text.hint', mt: 0.75 }}
            >
              <Trash size={20} weight="duotone" />
            </IconButton>
          </Box>
        </Stack>
      </Box>
      <Box>
        {propertyFields.map((prop, propIndex) => (
          <PropertyRow
            key={prop.id}
            control={control}
            errors={errors?.props?.[propIndex]}
            fieldName={`tokens.${tokenFieldIndex}.props.${propIndex}`}
            updateProperty={(property: Property) =>
              updateProperty(propIndex, property)
            }
            removeProperty={() => removeProperty(propIndex)}
            watch={watch}
          />
        ))}
      </Box>
      {template.type === 'NFT' ? (
        <Box
          sx={{
            px: 1.5,
            py: 1,
            borderTop: '1px solid',
            borderColor: 'divider',
          }}
        >
          <Button
            size="small"
            variant="outlined"
            color="secondary"
            startIcon={<Add />}
            onClick={() => appendProperty({ ...DefaultProperty })}
          >
            Custom Property
          </Button>
        </Box>
      ) : null}
    </Box>
  );
};
