import { useQuery } from '@tanstack/react-query';

import { getBlockchainNetworks } from '../blockchain-api';

export const blockchainKeys = {
  blockchainNetworks: () => ['blockchain-networks'],
} as const;

export const useBlockchainNetworks = () =>
  useQuery({
    queryKey: blockchainKeys.blockchainNetworks(),
    queryFn: () => getBlockchainNetworks(),
  });
