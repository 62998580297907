import {
  Item,
  ItemBalance,
  ItemBalanceResults,
  ItemBalanceResultsSchema,
  ItemBalanceSchema,
  TransferItemBalanceResult,
  TransferItemBalanceSchema,
} from '@stardust-monorepo/types/sd-core';

import { validatedRequestWithAuth } from './api-utils';
import { getItem } from './item-api';

export interface GetItemBalanceArgs {
  id: ItemBalance['id'];
}
export const getItemBalance = ({ id }: GetItemBalanceArgs): Promise<Item> => {
  return validatedRequestWithAuth(ItemBalanceSchema, `item/balance/${id}`);
};

export interface GetItemBalancesArgs {
  gameId: number;
  itemId?: string;
  playerId?: string;
  start?: number;
  limit?: number;
}

export const getItemBalances = (
  args: GetItemBalancesArgs
): Promise<ItemBalanceResults> => {
  return validatedRequestWithAuth(ItemBalanceResultsSchema, 'item/balance', {
    params: args,
  });
};

export interface MintBurnItemBalanceArgs {
  itemId: string;
  playerId: string;
  amount: string;
}

export const addItemBalance = (
  args: MintBurnItemBalanceArgs
): Promise<ItemBalance> => {
  return validatedRequestWithAuth(ItemBalanceSchema, 'item/balance/add', {
    data: args,
    method: 'POST',
  });
};

export const removeItemBalance = (
  args: MintBurnItemBalanceArgs
): Promise<ItemBalance> => {
  return validatedRequestWithAuth(ItemBalanceSchema, 'item/balance/remove', {
    data: args,
    method: 'POST',
  });
};

export interface TransferItemBalanceArgs {
  itemId: string;
  sourcePlayerId: string;
  destinationPlayerId: string;
  amount: string;
}
export const transferItemBalance = (
  args: TransferItemBalanceArgs
): Promise<TransferItemBalanceResult> => {
  return validatedRequestWithAuth(
    TransferItemBalanceSchema,
    'item/balance/transfer',
    {
      data: args,
      method: 'POST',
    }
  );
};

export interface GetInventoryArgs {
  gameId: number;
  playerId: string;
  start?: number;
  limit?: number;
}

export const getInventory = async (
  args: GetInventoryArgs
): Promise<
  {
    balance: ItemBalance;
    item: Item;
  }[]
> => {
  const balances = await getItemBalances(args);
  return Promise.all(
    balances.results.map((balance) =>
      getItem({ id: balance.itemId }).then((item) => ({
        item,
        balance,
      }))
    )
  );
};
