import {
  PaymentProvider,
  PaymentProviderOnboard,
  PaymentProviderOnboardSchema,
  PaymentProviders,
  PaymentProviderSchema,
} from '@stardust-monorepo/types/sd-private';

import { validatedRequestWithAuth } from './api-utils';

export interface GetPaymentProviderArgs {
  gameId: number;
  provider: PaymentProviders;
}

export async function getPaymentProvider(
  args: GetPaymentProviderArgs
): Promise<PaymentProvider> {
  return validatedRequestWithAuth(
    PaymentProviderSchema,
    'payment-provider/get',
    {
      params: { ...args },
    }
  );
}

export interface GetPaymentProviderOnboardingArgs
  extends GetPaymentProviderArgs {
  refreshUrl: string;
  returnUrl: string;
}

export async function getPaymentProviderOnboarding(
  args: GetPaymentProviderOnboardingArgs
): Promise<PaymentProviderOnboard> {
  return validatedRequestWithAuth(
    PaymentProviderOnboardSchema,
    'payment-provider/onboard',
    { method: 'POST', data: { ...args } }
  );
}
