import { mockGame } from './game-mock';

export const mockPurchasables = [
  {
    id: 2,
    gameId: mockGame.id,
    name: 'Monkey Starter Pack',
    image:
      'https://sd-game-assets-dev-lhv834fnkjsd.s3.amazonaws.com/game_70/purchasables/3',
    price: '45',
    currencyISOCode: 'USDC',
    activeListing: true,
    cap: '100',
    totalSupply: '1',
    purchasableItemsCount: 6,
  },
  {
    id: 3,
    gameId: mockGame.id,
    name: 'Monkey Starter Pack',
    image:
      'https://sd-game-assets-dev-lhv834fnkjsd.s3.amazonaws.com/game_70/purchasables/3',
    price: '45',
    currencyISOCode: 'USDC',
    activeListing: true,
    cap: '100',
    totalSupply: '1',
    purchasableItemsCount: 6,
  },
  {
    id: 4,
    gameId: mockGame.id,
    name: 'Monkey Starter Pack',
    image:
      'https://sd-game-assets-dev-lhv834fnkjsd.s3.amazonaws.com/game_70/purchasables/3',
    price: '45',
    currencyISOCode: 'USDC',
    activeListing: true,
    cap: '100',
    totalSupply: '1',
    purchasableItemsCount: 6,
  },
  {
    id: 5,
    gameId: mockGame.id,
    name: 'Monkey Starter Pack',
    image:
      'https://sd-game-assets-dev-lhv834fnkjsd.s3.amazonaws.com/game_70/purchasables/3',
    price: '45',
    currencyISOCode: 'USDC',
    activeListing: true,
    cap: '100',
    totalSupply: '1',
    purchasableItemsCount: 6,
  },
];

export const mockSdSalesHistoryPurchasable = {
  id: 3,
  gameId: mockGame.id,
  name: 'Monkey Starter Pack',
  image:
    'https://sd-game-assets-dev-lhv834fnkjsd.s3.amazonaws.com/game_70/purchasables/3',
  price: '45',
  currencyISOCode: 'USDC',
  cap: '100',
  totalSupply: '1',
};

export const mockSdPurchasableSaleSummary = {
  purchasable: mockSdSalesHistoryPurchasable,
  totalPrice: '555',
  totalPriceMoney: {
    amount: '55500',
    currency: 'SC',
  },
  totalSales: '1',
  totalSold: '1',
};

export const mockSdPurchasableSale = {
  purchasable: mockSdSalesHistoryPurchasable,
  playerId: '07a40aee-3796-473f-92b9-9ac4d69a97dc',
  price: '1',
  priceMoney: { amount: '100', currency: 'SC' },
  soldDate: '2023-02-16T19:10:29.257Z',
  amount: '1',
};

export const mockSdPurchasable = {
  ...mockSdSalesHistoryPurchasable,
  activeListing: true,
};

export const mockPurchasable = {
  ...mockSdPurchasable,
  purchasableItemsCount: 6,
};

export const mockNonBundlePurchasable = {
  id: 1,
  gameId: mockGame.id,
  name: 'Monkey Starter Pack',
  image:
    'https://sd-game-assets-dev-lhv834fnkjsd.s3.amazonaws.com/game_70/purchasables/3',
  price: '45',
  currencyISOCode: 'USDC',
  activeListing: true,
  cap: '100',
  totalSupply: '1',
  purchasableItemsCount: 1,
};

export const mockSdPurchasableItem = {
  id: 2,
  purchasableId: mockSdPurchasable.id,
  templateId: 80,
  template: {
    gameId: 70,
    cap: '39614081257132168796771975168',
    name: 'Moto Ape',
    type: 'FT',
    props: {
      mutable: {
        image: 'templates/80',
        popularity: 98,
        description: 'An ape that loves motorcyeles',
        intelligence: 25,
      },
      $mutable: {},
      immutable: {},
    },
    id: 80,
    totalSupply: '174',
    royalty: 200,
    activeListing: true,
  },
  amount: '10',
};

export const mockPurchasableItem = [
  {
    ...mockSdPurchasableItem,
    purchasableId: mockNonBundlePurchasable.id,
    image:
      'https://sd-game-assets-dev-lhv834fnkjsd.s3.amazonaws.com/game_70/templates/80',
  },
];

export const mockPurchasableItems = [
  {
    id: 2,
    purchasableId: mockPurchasable.id,
    templateId: 80,
    template: {
      gameId: 70,
      cap: '39614081257132168796771975168',
      name: 'Moto Ape',
      type: 'FT',
      props: {
        mutable: {
          image: 'templates/80',
          popularity: 98,
          description: 'An ape that loves motorcyeles',
          intelligence: 25,
        },
        $mutable: {},
        immutable: {},
      },
      id: 80,
      totalSupply: '174',
      royalty: 200,
      activeListing: true,
    },
    amount: '10',
    image:
      'https://sd-game-assets-dev-lhv834fnkjsd.s3.amazonaws.com/game_70/templates/80',
  },
  {
    id: 3,
    purchasableId: mockPurchasable.id,
    templateId: 69,
    template: {
      gameId: 70,
      cap: '1000',
      name: 'Punk Ape x KAWS',
      type: 'NFT',
      props: {
        mutable: {
          image: 'templates/69',
          speed: 10000,
          vision: 0,
          description:
            'A punk ape with the signature XX of KAWS in place of the eyes',
          intelligence: 65,
        },
        $mutable: {},
        immutable: {},
      },
      id: 69,
      totalSupply: '16',
      royalty: 200,
      activeListing: true,
    },
    props: {
      mutable: {},
      immutable: {},
    },
    amount: '1',
    image:
      'https://sd-game-assets-dev-lhv834fnkjsd.s3.amazonaws.com/game_70/templates/69',
  },
];
