import { Box, Link, Stack, TypographyProps } from '@mui/material';
import { Copy, Eye, EyeSlash } from '@phosphor-icons/react';
import { color, useClipboard } from '@stardust-monorepo/web-sdk-apps-shared';
import { useEffect, useState } from 'react';

export interface ClipboardCopyProps {
  className?: string;
  maskValue?: boolean;
  maxWidth?: string;
  onClick?: () => void;
  onCopy: () => void;
  text?: string;
  formatDisplayValue?: (value: string) => string;
  typographyVariant?: TypographyProps['variant'];
  color?: TypographyProps['color'];
}

export const copyButtonClassName = 'clipboard-copy-copy-button';

const getText = (
  text: string,
  maskValue: boolean,
  show: boolean,
  formatDisplayValue?: (value: string) => string
) => {
  const displayText = formatDisplayValue ? formatDisplayValue(text) : text;
  if (!maskValue || (maskValue && show)) {
    return displayText;
  }
  if (displayText.length <= 4) {
    return '****';
  } else {
    return `${displayText.slice(0, 2)}${'***'}${displayText.slice(
      displayText.length - 2,
      displayText.length
    )}`;
  }
};

export const ClipboardCopy = ({
  className,
  maskValue = false,
  maxWidth = '300px',
  onClick,
  onCopy,
  text = '',
  formatDisplayValue,
  typographyVariant,
  color: colorProp,
}: ClipboardCopyProps) => {
  const copyText = useClipboard(onCopy, text);
  const [showVal, setShowVal] = useState(false);
  const [displayText, setDisplayText] = useState(
    getText(text, maskValue, showVal, formatDisplayValue)
  );

  useEffect(() => {
    setDisplayText(getText(text, maskValue, showVal, formatDisplayValue));
  }, [showVal, text, formatDisplayValue]);

  return (
    <Stack
      direction="row"
      spacing={1}
      sx={{ alignItems: 'center', maxWidth, overflow: 'hidden' }}
    >
      <Box
        sx={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }}
        className={className}
        typography={typographyVariant}
        color={colorProp}
      >
        {onClick ? (
          <Link
            onClick={onClick}
            sx={{ '&:hover': { cursor: 'pointer', textDecoration: 'none' } }}
          >
            {displayText}
          </Link>
        ) : (
          displayText
        )}
      </Box>
      {text !== '' && (
        <Box
          onClick={(e) => {
            copyText(e);
          }}
          sx={{
            color: 'text.hint',
            cursor: 'pointer',
            display: 'flex',
            '&:hover': {
              color: color.neutral[700],
            },
          }}
          className={copyButtonClassName}
        >
          <Copy size={18} />
        </Box>
      )}
      {maskValue && (
        <Box
          onClick={(e) => {
            e.stopPropagation();
            setShowVal(!showVal);
          }}
          sx={{
            color: 'text.hint',
            cursor: 'pointer',
            display: 'flex',
            '&:hover': {
              color: color.neutral[700],
            },
          }}
        >
          {showVal ? <EyeSlash size={18} /> : <Eye size={18} />}
        </Box>
      )}
    </Stack>
  );
};
