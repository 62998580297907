import { StyledComponent } from '@emotion/styled';
import {
  Dialog,
  DialogActions,
  DialogActionsProps,
  DialogContent,
  DialogContentProps,
  DialogTitle,
  DialogTitleProps,
  Stack,
  styled,
  useTheme,
} from '@mui/material';
import { DialogProps } from '@mui/material/Dialog/Dialog';
import { TransitionProps } from '@mui/material/transitions';
import { forwardRef, ReactElement, Ref } from 'react';

import { SoftSlide } from '../transitions';
const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: ReactElement;
  },
  ref: Ref<unknown>
) {
  return <SoftSlide ref={ref} direction={'right'} {...props} />;
});

export const DrawerDialog: StyledComponent<DialogProps> = styled(
  (props: DialogProps) => (
    <Dialog
      maxWidth={'md'}
      fullWidth={true}
      {...props}
      TransitionComponent={Transition}
    />
  )
)(({ theme }) => ({
  '.MuiDialog-paper': {
    height: '100%',
    margin: 'unset',
    maxHeight: 'unset',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: `${theme.shape.borderRadius * 4}px 0 0 ${
      theme.shape.borderRadius * 4
    }px`,
  },
  '.MuiDialog-container': {
    display: 'flex',
    justifyContent: 'end',
  },
  '& .MuiDialogTitle-root': {
    width: '100%',
    display: 'block',
    padding: `0 ${theme.spacing(3)}`,
  },
  '& .MuiDialogContent-root': {
    width: '100%',
    padding: 0,
  },
  '& .MuiDialogActions-root': {
    borderTop: `1px solid ${theme.palette.divider}`,
    marginTop: 0,
    justifyContent: 'center',
    width: '100%',
    padding: 0,
  },
}));

export const DrawerDialogTitle = (
  props: DialogTitleProps & { paddingY?: string | number }
) => {
  const { children, paddingY, ...passThroughProps } = props;
  return (
    <Stack
      direction={'row'}
      gap={2}
      sx={(theme) => ({
        justifyContent: 'center',
        width: '100%',
        paddingY: paddingY !== undefined ? paddingY : `${theme.spacing(2)}`,
        borderBottom: `1px solid ${theme.palette.divider}`,
        flex: '0 0 auto',
      })}
    >
      <DialogTitle component={'div'} {...passThroughProps}>
        {children}
      </DialogTitle>
    </Stack>
  );
};

export const DrawerDialogContent = (props: DialogContentProps) => {
  const { children, ...passThroughProps } = props;
  const theme = useTheme();
  return (
    <DialogContent {...passThroughProps}>
      <Stack
        direction={'row'}
        gap={2}
        paddingX={theme.spacing(3)}
        paddingBottom={theme.spacing(5.5)} //TODO temporary bottom padding for dealing w/ chat iframe
        sx={{
          margin: 'auto',
          height: '100%',
        }}
      >
        {children}
      </Stack>
    </DialogContent>
  );
};

export const DrawerDialogActions = (props: DialogActionsProps) => {
  const { children, ...passThroughProps } = props;

  return (
    <DialogActions {...passThroughProps}>
      <Stack
        direction={'row'}
        gap={2}
        sx={(theme) => ({
          padding: `${theme.spacing(3)} ${theme.spacing(3)}`,
          justifyContent: 'space-between',
          flexGrow: 1,
        })}
      >
        {children}
      </Stack>
    </DialogActions>
  );
};

//dialog used within the drawer dialog
export const DrawerCenteredDialog: StyledComponent<DialogProps> = styled(
  (props: DialogProps) => <Dialog {...props} />
)(({ theme }) => ({
  '.MuiDialog-container': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    maxWidth: theme.breakpoints.values.md,
    marginLeft: 'auto',
    paddingTop: '150px',
  },
  '.MuiDialog-paper': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: theme.shape.borderRadius * 4,
  },
}));
