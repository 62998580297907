import {
  Box,
  Button,
  Card,
  Paper,
  Stack,
  styled,
  Typography,
} from '@mui/material';
import { ArrowCircleRight } from '@phosphor-icons/react';
import { Game } from '@stardust-monorepo/types/sd-private';
import { getGameImageUrl } from '@stardust-monorepo/web-sdk-apps-shared';
import React from 'react';
import { Link } from 'react-router-dom';
import { useRecoilState } from 'recoil';

import gameIcon from '../../../assets/game-icon.png';
import { gameState } from '../../state/game-state';
import { DeprecationTooltip } from '../rip/DeprecationTooltip';

const IconImage = styled('img')(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  height: '48px',
  width: '48px',
}));

interface MiniGameCardProps {
  game: Game;
}

const MiniGameCard = ({ game }: MiniGameCardProps) => (
  <Card
    variant="outlined"
    sx={{ padding: 1.5, borderRadius: 2.5, width: '25%' }}
  >
    <Link to={`/games/${game.id}`}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '100%',
        }}
      >
        <IconImage src={getGameImageUrl(game.bucketName, game.image)} />
        <Stack spacing={0.75} sx={{ mt: 3.5 }}>
          <Typography variant="subtitle2" color="text.primary">
            {game.name}
          </Typography>
          <Typography variant="helperText" color="text.secondary">
            Game ID {game.id}
          </Typography>
        </Stack>
      </Box>
    </Link>
  </Card>
);

export interface MyGameCardProps {
  setShowCreateGame: (show: boolean) => void;
}

export const MyGamesCard = ({ setShowCreateGame }: MyGameCardProps) => {
  const [games] = useRecoilState(gameState);

  return (
    <Paper
      elevation={1}
      sx={{
        padding: 2,
        backgroundColor: 'background.white',
        width: '100%',
        borderRadius: 3,
      }}
    >
      <Typography variant="subtitle2">My Games</Typography>
      {games.length > 0 ? (
        <Stack direction="row" spacing={1.25} sx={{ mt: 2 }}>
          {games.slice(0, 3).map((game) => (
            <MiniGameCard key={game.id} game={game} />
          ))}
          <Card
            variant="outlined"
            sx={{ padding: 3, borderRadius: 2.5, width: '25%' }}
          >
            <Link to="/games">
              <Stack
                sx={{
                  color: 'text.hint',
                  height: '100%',
                  alignItems: 'center',
                  justifyContent: 'center',
                  textAlign: 'center',
                }}
                spacing={1}
              >
                <ArrowCircleRight size={24} weight="duotone" />
                <Typography variant="caption">View All Games</Typography>
              </Stack>
            </Link>
          </Card>
        </Stack>
      ) : (
        <Card
          variant="outlined"
          sx={{
            borderRadius: 2.5,
            width: '100%',
            color: 'text.secondary',
            py: 4,
            mt: 3,
          }}
        >
          <Stack
            direction="row"
            spacing={4}
            sx={{
              width: '100%',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <img alt="game icon" src={gameIcon} width="48px" height="48px" />
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
              }}
            >
              <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
                Get Started!
              </Typography>
              <Typography variant="body2" sx={{ mb: 1.5 }}>
                Create your first game on Stardust.
              </Typography>
              <DeprecationTooltip>
                <Button variant="contained" disabled>
                  Create Game
                </Button>
              </DeprecationTooltip>
            </Box>
          </Stack>
        </Card>
      )}
    </Paper>
  );
};
