import { Autocomplete, CircularProgress, TextField } from '@mui/material';
import { Template } from '@stardust-monorepo/types/marketplace';
import { SdPrivateApi } from '@stardust-monorepo/web-sdk-apps-shared';
import React, { useState } from 'react';

export interface TemplateSearchProps {
  disabled?: boolean;
  gameId: number;
  onlyActiveTemplates: boolean;
  placeholder?: string;
  value: Template | null;
  onChange: (t: Template | null) => void;
}

export const TemplateSearch = ({
  disabled,
  gameId,
  onlyActiveTemplates,
  placeholder = '',
  value,
  onChange,
}: TemplateSearchProps) => {
  const [templateFilter, setTemplateFilter] = useState('');
  const { data: templates, isLoading } = SdPrivateApi.useTemplates(
    {
      gameId: gameId,
      start: 0,
      limit: 20,
      filter: templateFilter,
    },
    !!gameId
  );
  return (
    <Autocomplete
      id="template-search"
      data-testid="template-search"
      disabled={disabled}
      loading={isLoading}
      value={value ? { label: value.name, template: value } : null}
      onChange={(event, newValue) => {
        if (newValue) {
          setTemplateFilter('');
        }
        onChange(newValue?.template || null);
      }}
      inputValue={templateFilter}
      onInputChange={(event, newInputValue) => {
        setTemplateFilter(newInputValue);
      }}
      isOptionEqualToValue={(o, t) => o.template.id === t.template.id}
      options={(templates || []).reduce<
        { label: string; template: Template }[]
      >((acc, t) => {
        if ((onlyActiveTemplates && t.activeListing) || !onlyActiveTemplates) {
          acc.push({
            label: t.name,
            template: t,
          });
        }
        return acc;
      }, [])}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={placeholder}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isLoading ? (
                  <CircularProgress
                    color="inherit"
                    size={20}
                    data-testid="template-search-spinner"
                  />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      size="small"
      sx={{
        width: '400px',
        borderRadius: 2,
        backgroundColor: 'white',
        '.MuiAutocomplete-inputRoot': { borderRadius: 2 },
      }}
    />
  );
};
