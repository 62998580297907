import { SdPrivateApi } from '@stardust-monorepo/web-sdk-apps-shared';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

export const teamKeys = {
  teamMembers: (gameId: number, start?: number, limit?: number) =>
    [
      'teamMembers',
      { gameId },
      ...(start || limit ? [{ start, limit }] : []),
    ] as const,
};

export function useTeamMembers(
  args: {
    gameId: number | undefined;
    start?: number;
    limit?: number;
  },
  enabled = true
) {
  return useQuery({
    queryKey: teamKeys.teamMembers(
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      args.gameId!,
      args.start,
      args.limit
    ),
    queryFn: () =>
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      SdPrivateApi.getTeamMembers(args.gameId!, args.start, args.limit),
    enabled: enabled && args.gameId !== undefined,
  });
}

export function useDeleteTeamMember() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: SdPrivateApi.deleteTeamMember,
    onSuccess: (data, variables) => {
      // Refresh the team member list
      queryClient.invalidateQueries({
        queryKey: teamKeys.teamMembers(variables.gameId),
      });
    },
  });
}

export function useCreateTeamMember() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: SdPrivateApi.createTeamMember,
    onSuccess: (data, variables) => {
      // Refresh the team member list
      queryClient.invalidateQueries({
        queryKey: teamKeys.teamMembers(variables.gameId),
      });
    },
  });
}
