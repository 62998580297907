import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import { BasicDialog, track } from '@stardust-monorepo/web-sdk-apps-shared';
import React, { MutableRefObject, PropsWithChildren } from 'react';

export const BaseBalanceModal = ({
  gameId,
  submitFormRef,
  onClose,
  open,
  isLoading,
  dialogLabel,
  dialogSublabel,
  children,
}: PropsWithChildren<{
  gameId: number;
  submitFormRef: MutableRefObject<HTMLButtonElement | null>;
  collectionId?: string;
  onClose: () => void;
  open: boolean;
  isLoading: boolean;
  dialogLabel: string;
  dialogSublabel: string;
}>) => {
  return (
    <BasicDialog open={open} onClose={onClose}>
      <DialogTitle variant="h5">
        {dialogLabel}
        <Typography
          variant={'body2'}
          color={'text.secondary'}
          sx={{
            maxWidth: '300px',
          }}
        >
          {dialogSublabel}
        </Typography>
      </DialogTitle>
      <DialogContent
        sx={{
          width: '480px',
        }}
      >
        {children}
      </DialogContent>
      <DialogActions
        sx={{ borderTop: '1px solid', borderColor: 'divider', mt: 2 }}
      >
        <>
          <Button
            disabled={isLoading}
            onClick={() => onClose()}
            variant="outlined"
            color="secondary"
            sx={{ mt: 1.5 }}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            disabled={isLoading}
            autoFocus
            variant="contained"
            onClick={() => {
              track('Confirm Mint', 'Clicked', {
                gameId,
              });
              submitFormRef.current?.click();
            }}
            sx={{ mt: 1.5 }}
          >
            {isLoading ? (
              <CircularProgress size="18px" color="primary" />
            ) : (
              dialogLabel
            )}
          </Button>
        </>
      </DialogActions>
    </BasicDialog>
  );
};
