import { jwtDecode } from 'jwt-decode';
import { z } from 'zod';

export const StardustIamJwtPayloadSchema = z
  .object({
    sub: z.string().uuid(),
    iss: z.literal('Stardust'),
    id: z.string().uuid(),
    rootUserId: z.string().uuid(),
    analyticsId: z.string(),
    abilities: z.array(z.string()),
    adminAbilities: z.array(z.string()),
    iat: z.number(),
    exp: z.number(),
  })
  .describe('StardustIamJwtPayloadSchema');
export type StardustIamJwtPayload = z.infer<typeof StardustIamJwtPayloadSchema>;

export const AuthenticateResponseSchema = z
  .object({
    access_token: z.string(),
  })
  .transform((data) => jwtDecode<StardustIamJwtPayload>(data.access_token))
  .describe('AuthenticateResponseSchema');
