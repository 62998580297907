import {
  getPurchasable,
  getPurchasableItems,
  getPurchasables,
} from '@stardust-monorepo/web-sdk-apps-shared';
import { useQuery } from '@tanstack/react-query';

export const purchasableKeys = {
  purchasable: (purchasableId: number) =>
    ['purchasable', purchasableId] as const,
  purchasables: (gameId: number, start?: number, limit?: number) =>
    ['purchasables', { gameId, start, limit }] as const,
  purchasableItems: (purchasableId: number) => [
    'purchasable-items',
    { purchasableId },
  ],
};

export function usePurchasable(
  purchasableId: number | undefined,
  enabled = true
) {
  return useQuery({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    queryKey: purchasableKeys.purchasable(purchasableId!),
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    queryFn: () => getPurchasable(purchasableId!),
    enabled: enabled && purchasableId !== undefined,
  });
}

export function usePurchasables(
  args: {
    gameId: number | undefined;
    start?: number;
    limit?: number;
  },
  enabled = true
) {
  return useQuery({
    queryKey: purchasableKeys.purchasables(
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      args.gameId!,
      args.start,
      args.limit
    ),
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    queryFn: () => getPurchasables(args.gameId!, args.start, args.limit),
    enabled: enabled && args.gameId !== undefined,
  });
}

export function usePurchasableItems(
  purchasableId: number | undefined,
  enabled = true
) {
  return useQuery({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    queryKey: purchasableKeys.purchasableItems(purchasableId!),
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    queryFn: () => getPurchasableItems(purchasableId!),
    enabled: enabled && purchasableId !== undefined,
  });
}
