import { CssBaseline, ThemeProvider } from '@mui/material';
import {
  identify,
  initializeAnalytics,
  LoginPage,
  SdPolicies,
  sdTheme,
  userState,
} from '@stardust-monorepo/web-sdk-apps-shared';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { useEffect } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { GameTopNav, MainLayout } from './components/layout';
import { PageHeader } from './components/layout/PageHeader';
import { ApiKeysPage } from './pages/ApiKeys';
import { CollectionPage } from './pages/Collection';
import { CollectionsPage } from './pages/Collections';
import { GamePage } from './pages/Game';
import { GamesPage } from './pages/Games';
import { GameStorePage } from './pages/GameStore';
import { HomePage } from './pages/Home';
import { PaymentsPage } from './pages/Payments';
import { PlayerPage } from './pages/Player';
import { PlayersPage } from './pages/Players';
import { SettingsPage } from './pages/Settings';
import { TeamPage } from './pages/Team';
import { TemplatePage } from './pages/Template';
import { TemplatesPage } from './pages/Templates';

const queryClient = new QueryClient();

initializeAnalytics();

function App() {
  const user = useRecoilValue(userState);
  if (user.user?.attributes?.email) {
    const email = user.user.attributes.email;
    const playerId = user.user.signInUserSession.getAccessToken().payload.sub;
    identify(email, playerId);
  }

  // framekiller code
  useEffect(() => {
    if (window.self === window.top) {
      document.documentElement.style.display = 'block';
    } else if (window.top) {
      window.top.location = window.self.location;
    }
  }, []);

  return (
    <ThemeProvider theme={sdTheme}>
      <CssBaseline />
      <QueryClientProvider client={queryClient}>
        {/*TODO replace with https://reactrouter.com/en/main/route/loader */}
        <Routes>
          {!user.user && !user.loading ? (
            <>
              <Route path="/login/*" element={<LoginPage />} />
              <Route path="*" element={<Navigate to={'/login'} />} />
            </>
          ) : user.unacceptedPolicies && user.unacceptedPolicies.length ? (
            <>
              <Route
                path="/terms-of-service"
                element={<SdPolicies policies={user.unacceptedPolicies} />}
              />
              <Route path="*" element={<Navigate to={'/terms-of-service'} />} />
            </>
          ) : (
            <>
              <Route path="/" element={<MainLayout />}>
                <Route
                  path=""
                  element={
                    <>
                      <PageHeader label="Home" />
                      <HomePage />
                    </>
                  }
                />
                <Route
                  path="settings"
                  element={
                    <>
                      <PageHeader label="Settings" />
                      <SettingsPage />
                    </>
                  }
                />
                <Route path="games">
                  <Route
                    path=""
                    element={
                      <>
                        <PageHeader label="Games" />
                        <GamesPage />
                      </>
                    }
                  />
                  <Route path=":gameId" element={<GameTopNav />}>
                    <Route path="" element={<GamePage />} />
                    <Route path="api-keys" element={<ApiKeysPage />} />
                    <Route path="collections" element={<CollectionsPage />} />
                    <Route path="game-store" element={<GameStorePage />} />
                    <Route path="payments" element={<PaymentsPage />} />
                    <Route path="players">
                      <Route path="" element={<PlayersPage />} />
                    </Route>
                    <Route path="team" element={<TeamPage />} />
                    <Route path="templates">
                      <Route path="" element={<TemplatesPage />} />
                      <Route path=":templateId" element={<TemplatePage />} />
                    </Route>
                  </Route>
                  <Route
                    path=":gameId/collections/:collectionId"
                    element={<CollectionPage />}
                  />
                  <Route
                    path=":gameId/players/:playerId"
                    element={<PlayerPage />}
                  />
                </Route>
              </Route>
              <Route path="/login/*" element={<LoginPage />} />
            </>
          )}
        </Routes>
      </QueryClientProvider>
    </ThemeProvider>
  );
}

export default App;
