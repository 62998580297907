import { Box, Tooltip } from '@mui/material';
import { IconWeight, Info } from '@phosphor-icons/react';
import React, { ReactElement } from 'react';

export interface InfoIcon {
  color?: string;
  info: string;
  weight?: IconWeight;
  icon?: ReactElement;
}

export const InfoIcon = ({
  color = 'info.main',
  info,
  weight = 'duotone',
  icon,
}: InfoIcon) => {
  return (
    <Box sx={{ color, display: 'flex', cursor: 'pointer' }}>
      <Tooltip placement="top" title={info}>
        {icon ? icon : <Info size={16} weight={weight} />}
      </Tooltip>
    </Box>
  );
};
