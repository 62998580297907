import { Breadcrumbs, Button, Stack, Typography } from '@mui/material';
import { PencilSimple, Trash } from '@phosphor-icons/react';
import { Player } from '@stardust-monorepo/types/sd-private';
import {
  ErrorAlert,
  formatAddress,
  formatBlockchainExplorerUrl,
  handleError,
  IconButton,
  InfoItem,
  RemoveModal,
  SdPrivateApi,
  Spinner,
  track,
  useToast,
  useTrack,
} from '@stardust-monorepo/web-sdk-apps-shared';
import React, { useMemo, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';

import { AddItemBalance } from '../components/collections/item-balance/AddItemBalance';
import { PageHeader } from '../components/layout/PageHeader';
import { MintTokenModal } from '../components/mint-token';
import { CreatePlayerModal, InventoryV1 } from '../components/player';
import { Inventory } from '../components/player/Inventory';

interface PlayerPageHeaderParams {
  player: Player;
  walletInfo?: {
    address: string;
    url: string;
  };
  isV2Game: boolean;
  onAddBalanceClick: () => void;
  onEditPlayerClick: () => void;
  onDeletePlayerClick: () => void;
}
const PlayerPageHeader = ({
  player,
  walletInfo,
  isV2Game,
  onAddBalanceClick,
  onEditPlayerClick,
  onDeletePlayerClick,
}: PlayerPageHeaderParams) => {
  return (
    <Stack
      gap={2}
      sx={(theme) => ({
        backgroundColor: theme.palette.background.white,
        padding: 2,
        border: `1px  solid ${theme.palette.divider}`,
        borderRadius: 3,
      })}
    >
      <Stack
        direction="row"
        sx={{
          justifyContent: 'space-between',
        }}
      >
        <Stack gap={1}>
          <Typography variant="h6">Player {player.uniqueId}</Typography>
          <Stack direction="row" gap={0.5}>
            <Typography variant="subtitle2" color="text.secondary">
              Player ID
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {player.playerId}
            </Typography>
          </Stack>
        </Stack>
        <Stack
          direction="row"
          gap={1}
          sx={{
            height: 'min-content',
          }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={onAddBalanceClick}
          >
            {isV2Game ? 'Add Balance' : 'Mint Token'}
          </Button>
          <IconButton
            onClick={onEditPlayerClick}
            data-testid={'player-edit-player-button'}
          >
            <PencilSimple weight="duotone" size={20} />
          </IconButton>
          <IconButton onClick={onDeletePlayerClick}>
            <Trash weight="duotone" size={20} />
          </IconButton>
        </Stack>
      </Stack>
      <Stack
        sx={(theme) => ({
          paddingX: 2,
          paddingY: 1.75,
          border: `1px solid ${theme.palette.divider}`,
          borderRadius: 2,
        })}
      >
        <InfoItem
          label="Game Wallet"
          value={
            walletInfo?.address ? formatAddress(walletInfo?.address) : 'N/A'
          }
          link={walletInfo?.url}
        />
      </Stack>
    </Stack>
  );
};

export const PlayerPage = () => {
  const { gameId, playerId } = useParams<{
    gameId: string;
    playerId: string;
  }>();
  const [mintTokenOpen, setMintTokenOpen] = useState(false);
  const [editPlayerOpen, setEditPlayerOpen] = useState(false);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);

  const {
    data: player,
    error: playerError,
    isLoading: playerLoading,
  } = SdPrivateApi.usePlayer({
    gameId: Number(gameId),
    playerId: String(playerId),
  });

  const {
    data: walletData,
    error: walletError,
    isLoading: walletLoading,
  } = SdPrivateApi.usePlayerWallet({
    gameId: Number(gameId),
    playerId,
  });

  const deletePlayer = SdPrivateApi.useDeletePlayer();
  const toaster = useToast();
  const navigate = useNavigate();

  const onDeletePlayer = async () => {
    const trackingData = { gameId, playerId };
    track('Modal Remove Player', 'Clicked', trackingData);
    try {
      if (game && playerId) {
        await deletePlayer.mutateAsync({
          gameId: Number(gameId),
          playerId,
        });
        track('Remove Player', 'Success', trackingData);
        toaster(`Player ${playerId} successfully removed`);
        navigate('..', { relative: 'path' });
      }
    } catch (e) {
      handleError(
        toaster,
        e,
        `Unable to remove player ${playerId}`,
        'Remove Player',
        trackingData
      );
    }
  };

  const {
    data: game,
    error: gameError,
    isLoading: gameLoading,
  } = SdPrivateApi.useGame(Number(gameId));

  useTrack('Player', { gameId, playerId });

  const gameWalletInfo = useMemo(() => {
    if (!game || !walletData) {
      return undefined;
    }
    const wallet = walletData?.wallet[0];
    return wallet
      ? {
          address: wallet.address,
          url: formatBlockchainExplorerUrl(game.blockchain, wallet.address),
        }
      : undefined;
  }, [game, walletData]);

  if (playerLoading || gameLoading || walletLoading) {
    return <Spinner />;
  }

  return (
    <>
      <ErrorAlert
        error={
          (playerError as Error) ||
          (gameError as Error) ||
          (walletError as Error)
        }
      />
      {game && mintTokenOpen ? (
        SdPrivateApi.isV2Game(game) ? (
          <AddItemBalance
            playerId={playerId}
            gameId={Number(gameId)}
            onClose={() => setMintTokenOpen(false)}
            open={mintTokenOpen}
          />
        ) : (
          <MintTokenModal
            gameId={Number(gameId)}
            open={mintTokenOpen}
            targetPlayer={player}
            onClose={() => setMintTokenOpen(false)}
          />
        )
      ) : null}
      <CreatePlayerModal
        gameId={Number(gameId)}
        open={editPlayerOpen}
        onClose={() => setEditPlayerOpen(false)}
        player={player}
      />
      <RemoveModal
        body={`Are you sure you want to remove player ${playerId}?`}
        label="Player"
        loading={!!deletePlayer.isLoading}
        onClose={() => {
          track('Remove Player Close', 'Clicked', {
            gameId,
            playerId,
          });
          setDeleteConfirmationOpen(false);
        }}
        onConfirm={onDeletePlayer}
        open={deleteConfirmationOpen}
      />
      <PageHeader>
        <Breadcrumbs>
          <Link to={`/games/${gameId}`}>
            <Typography variant="body1" color="text.hint">
              {game?.name}
            </Typography>
          </Link>
          <Link to={`/games/${gameId}/players`}>
            <Typography variant="body1" color="text.hint">
              Players
            </Typography>
          </Link>
          <Typography variant="subtitle1" color="text.primary">
            Player {player?.uniqueId}
          </Typography>
        </Breadcrumbs>
      </PageHeader>
      <Stack
        sx={{
          py: 1.5,
          px: 2,
          height: '100%',
        }}
        gap={1}
      >
        {player && game && (
          <PlayerPageHeader
            player={player}
            isV2Game={SdPrivateApi.isV2Game(game)}
            walletInfo={gameWalletInfo}
            onAddBalanceClick={() => {
              track('Player Add Balance', 'Clicked', {
                gameId,
                playerId,
              });
              setMintTokenOpen(true);
            }}
            onEditPlayerClick={() => {
              track('Player Edit Player', 'Clicked', {
                gameId,
                playerId,
              });
              setEditPlayerOpen(true);
            }}
            onDeletePlayerClick={() => {
              track('Player Remove Player', 'Clicked', {
                gameId,
                playerId,
              });
              setDeleteConfirmationOpen(true);
            }}
          />
        )}
        {gameId && player && game ? (
          SdPrivateApi.isV2Game(game) ? (
            <Inventory gameId={Number(gameId)} playerId={player.playerId} />
          ) : (
            <InventoryV1
              gameId={Number(gameId)}
              player={player}
              setMintTokenOpen={setMintTokenOpen}
            />
          )
        ) : null}
      </Stack>
    </>
  );
};
