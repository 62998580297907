import { useToast } from '@stardust-monorepo/web-sdk-apps-shared';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { paymentsAllowListError } from '../constants';
import { isPaymentsAllowedForGame } from '../util';

export const useGuardOnPaymentsAllowed = (gameId: number) => {
  const navigate = useNavigate();
  const toaster = useToast();
  const isPaymentsAllowed = useMemo(() => {
    return gameId && isPaymentsAllowedForGame(Number(gameId));
  }, [gameId]);

  if (!isPaymentsAllowed) {
    navigate('..');
    toaster(paymentsAllowListError, 'error');
  }
};
