import { useQueries } from '@tanstack/react-query';

import { getStats, StatsArgs } from '../stats-api';

export const statKeys = {
  stats: (args: StatsArgs) => ['stats', args] as const,
};

export function useAllStats({
  games,
  startDate,
  endDate,
  enabled = true,
}: {
  games: number[];
  startDate: number;
  endDate: number;
  enabled?: boolean;
}) {
  return useQueries({
    queries: games.map((gameId) => ({
      queryKey: statKeys.stats({ gameId, startDate, endDate }),
      queryFn: () => getStats({ gameId, startDate, endDate }),
      enabled,
    })),
  });
}
