import {
  Breadcrumbs,
  Button,
  CircularProgress,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Template } from '@stardust-monorepo/types/marketplace';
import {
  Purchasable,
  PurchasableItem,
} from '@stardust-monorepo/types/sd-private';
import {
  CreateDocumentation,
  extractData,
  HeavyDialog,
  HeavyDialogActions,
  HeavyDialogContent,
  HeavyDialogTitle,
  isDoneLoading,
  SdPrivateApi,
  track,
  useHandleError,
  useToast,
} from '@stardust-monorepo/web-sdk-apps-shared';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import { mapFromProperties } from '../properties';
import { CreatePurchasableForm, formControls } from './CreatePurchasableForm';

interface CreatePurchasableModalProps {
  gameId: number;
  open: boolean;
  onClose: () => void;
  purchasable?: Purchasable | null;
  purchasableItems?: PurchasableItem[];
}

export const CreatePurchasableModal = ({
  gameId,
  open,
  onClose,
  purchasable,
  purchasableItems,
}: CreatePurchasableModalProps) => {
  const submitFormButtonRef = useRef<HTMLButtonElement | null>(null);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [loading, setLoading] = useState(false);
  const [selectedTemplates, setSelectedTemplates] = useState<Template[]>([]);
  const toaster = useToast();
  const handleError = useHandleError();

  const defaultValues = useMemo(() => {
    if (purchasable && purchasableItems) {
      return {
        [formControls.name]: purchasable.name,
        [formControls.image]: null,
        [formControls.price]: purchasable.price,
        [formControls.cap]: purchasable.cap || '',
        [formControls.tokens]: purchasableItems.map((pi) => ({
          id: pi.id,
          amount: Number(pi.amount),
          props: mapFromProperties(pi.props?.mutable),
        })),
      };
    }
    return {
      [formControls.name]: '',
      [formControls.image]: null,
      [formControls.price]: '',
      [formControls.cap]: '',
      [formControls.tokens]: [],
    };
  }, [purchasable, purchasableItems]);

  const allTemplateQueries = SdPrivateApi.useSelectedTemplates({
    gameId,
    templateIds: (purchasableItems || []).map((pi) => pi.templateId),
  });
  const doneLoading = isDoneLoading(allTemplateQueries);

  useEffect(() => {
    if (doneLoading) {
      setSelectedTemplates(extractData(allTemplateQueries));
    }
  }, [doneLoading]);

  const doOnClose = () => {
    track(`${purchasable ? 'Edit' : 'Create'} Purchasable Close`, 'Clicked', {
      gameId,
      purchasableId: purchasable?.id,
    });
    onClose();
  };

  const onSave = (result: number | Error) => {
    const trackingData = {
      gameId,
      purchasableId: purchasable?.id,
    };
    const eventName = `Purchasable ${purchasable ? 'Edit' : 'Create'}`;
    if (result instanceof Error) {
      handleError(
        result,
        `An error occurred while ${
          purchasable ? 'editing' : 'creating'
        } the purchasable`,
        eventName,
        trackingData
      );
    } else {
      track(eventName, 'Success', trackingData);
      toaster(`Purchasable ${purchasable ? 'saved' : 'created'} successfully`);
      onClose();
    }
  };

  return (
    <HeavyDialog
      open={open}
      onClose={doOnClose}
      fullScreen={fullScreen}
      fullWidth={true}
      scroll="body"
      maxWidth={'lg'}
    >
      <HeavyDialogTitle>
        <Breadcrumbs>
          <Typography variant="body2">Game Store</Typography>
          <Typography variant="body2" color="text.primary">
            New Purchasable
          </Typography>
        </Breadcrumbs>
      </HeavyDialogTitle>
      <HeavyDialogContent>
        <Stack gap={5}>
          <Typography variant="h3" mt={7}>
            {purchasable ? 'Edit Purchasable' : 'Create New Purchasable'}
          </Typography>
          <Stack direction={'row'} gap={5.5}>
            <CreatePurchasableForm
              defaultValues={defaultValues}
              gameId={gameId}
              purchasable={purchasable}
              submitFormRef={submitFormButtonRef}
              selectedTemplates={selectedTemplates}
              setSelectedTemplates={setSelectedTemplates}
              onLoading={(loading) => setLoading(loading)}
              onSave={onSave}
            />
            <CreateDocumentation
              title="Creating a Purchasable"
              description='"Purchasables" are individual (or collections of) assets that may be listed for sale on your game store. Reference the documentation page below for more information.'
              links={[
                {
                  description: 'Purchasables and Payments',
                  href: 'https://docs.stardust.gg/docs/payments',
                },
              ]}
            />
          </Stack>
        </Stack>
      </HeavyDialogContent>
      <HeavyDialogActions>
        <Button
          disabled={loading}
          onClick={doOnClose}
          size="large"
          variant="outlined"
          color="secondary"
        >
          Cancel
        </Button>
        <Button
          disabled={loading}
          onClick={() => {
            if (purchasable) {
              track('Modal Edit Purchasable', 'Clicked', {
                gameId,
                purchasableId: purchasable.id,
              });
            } else {
              track('Modal Create Purchasable', 'Clicked', { gameId });
            }
            submitFormButtonRef.current?.click();
          }}
          size="large"
          variant="contained"
        >
          {loading ? (
            <CircularProgress size="18px" color="primary" />
          ) : purchasable ? (
            'Save Changes'
          ) : (
            'Create Purchasable'
          )}
        </Button>
      </HeavyDialogActions>
    </HeavyDialog>
  );
};
