import z from 'zod';

export enum Blockchain {
  unassigned = 0,
  solana_mainnet_beta = 1,
  solana = 1,
  immutablex_ropsten_testnet = 2,
  immutableX = 2,
  polygon_mainnet = 3,
  polygon = 3,
  flow_testnet = 4,
  flow = 4,
  solana_testnet = 5,
  immutablex_mainnet = 6,
  polygon_mumbai_testnet = 7,
  flow_mainnet = 8,
  immutablex_goerli_sandbox = 9,
  avalanche_fuji_testnet = 10,
  avalanche_mainnet = 11,
  polygon_stardust_testnet = 12,
  polygon_midnight_society_testnet = 13,
  polygon_midnight_society_mainnet = 14,
}

export const BlockchainNames: Record<number, string> = {
  [Blockchain.polygon]: 'Polygon',
  [Blockchain.polygon_mumbai_testnet]: 'Polygon',
  [Blockchain.polygon_stardust_testnet]: 'Polygon',
  [Blockchain.polygon_midnight_society_mainnet]: 'Polygon',
  [Blockchain.polygon_midnight_society_testnet]: 'Polygon',
  [Blockchain.avalanche_mainnet]: 'Avalanche',
  [Blockchain.avalanche_fuji_testnet]: 'Avalanche',
  [Blockchain.solana]: 'Solana',
  [Blockchain.solana_testnet]: 'Solana',
};

export const BlockchainNetworkNames: Record<number, string> = {
  [Blockchain.solana]: 'mainnet-beta',
  [Blockchain.solana_testnet]: 'testnet',
  [Blockchain.polygon]: 'mainnet',
  [Blockchain.polygon_midnight_society_mainnet]: 'midnight-society-mainnet',
  [Blockchain.polygon_midnight_society_testnet]: 'midnight_society_testnet',
  [Blockchain.polygon_mumbai_testnet]: 'mumbai-testnet',
  [Blockchain.avalanche_mainnet]: 'mainnet',
  [Blockchain.avalanche_fuji_testnet]: 'fuji-testnet',
};

export const BlockchainExplorerBaseUrls: Record<number, string> = {
  [Blockchain.polygon_mainnet]: 'https://polygonscan.com',
  [Blockchain.polygon_mumbai_testnet]: 'https://mumbai.polygonscan.com',
  [Blockchain.avalanche_mainnet]: 'https://snowtrace.io',
  [Blockchain.avalanche_fuji_testnet]: 'https://testnet.snowtrace.io',
};

export const BlockchainNetworkSchema = z.object({
  id: z.number(),
  name: z.enum(['polygon', 'avalanche', 'solana']),
  type: z.string(),
});
export type BlockchainNetwork = z.infer<typeof BlockchainNetworkSchema>;
