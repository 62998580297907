import {
  Breadcrumbs,
  Button,
  CircularProgress,
  Typography,
} from '@mui/material';
import { Collection, FungibleToken } from '@stardust-monorepo/types/sd-core';
import {
  HeavyDialogTitle,
  HeavyDialog,
  HeavyDialogContent,
  useToast,
  useUpdateCollection,
  track,
  HeavyDialogActions,
  useHandleError,
} from '@stardust-monorepo/web-sdk-apps-shared';
import { useRef } from 'react';

import { CollectionForm, CollectionFormValue } from './CollectionForm';
import {
  CurrencyCollectionFormData,
  StandaloneCurrencyCollectionForm,
} from './StandaloneCurrencyCollectionForm';

interface EditCollectionModalProps {
  collection: Collection;
  gameId: number;
  open: boolean;
  onClose: () => void;
  sourcePage: string;
}

export const EditCollectionModal = ({
  collection,
  gameId,
  open,
  onClose,
  sourcePage,
}: EditCollectionModalProps) => {
  const submitFormButtonRef = useRef<HTMLButtonElement | null>(null);
  const toaster = useToast();
  const handleError = useHandleError();

  const updateCollection = useUpdateCollection();

  const doOnClose = () => {
    track(`Edit Collection Close`, 'Clicked', {
      gameId,
      collectionId: collection.id,
    });
    onClose();
  };

  const onSave = async (
    data: CollectionFormValue | CurrencyCollectionFormData
  ) => {
    const trackingData = {
      gameId,
      collectionId: collection.id,
    };
    try {
      let publicMetadata = {};
      if (
        'externalLink' in data ||
        'bannerImage' in data ||
        'promoImage' in data
      ) {
        publicMetadata = {
          ...(data.externalLink ? { externalLink: data.externalLink } : {}),
          ...(data.bannerImage ? { bannerImage: data.bannerImage } : {}),
          ...(data.promoImage ? { promoImage: data.promoImage } : {}),
        };
      }
      publicMetadata = {
        ...publicMetadata,
        ...(data.symbol ? { symbol: data.symbol ?? '' } : {}),
      };

      await updateCollection.mutateAsync({
        id: collection.id,
        name: data.name,
        description: data.description,
        image: data.image,
        publicMetadata: publicMetadata,
      });
      track('Collection Update', 'Success', trackingData);
      toaster(`Collection updated successfully`);
      onClose();
    } catch (e) {
      handleError(
        e,
        'An error occurred while updating the Collection',
        'Collection Update',
        trackingData
      );
    }
  };

  return (
    <HeavyDialog open={open} onClose={doOnClose}>
      <HeavyDialogTitle>
        <Breadcrumbs>
          <Typography variant="subtitle1" color="text.hint">
            {sourcePage}
          </Typography>
          <Typography variant="subtitle1">Edit Collection</Typography>
        </Breadcrumbs>
      </HeavyDialogTitle>
      <HeavyDialogContent>
        {collection.type === FungibleToken ? (
          <StandaloneCurrencyCollectionForm
            collection={collection}
            submitFormRef={submitFormButtonRef}
            onSave={onSave}
          />
        ) : (
          <CollectionForm
            collection={collection}
            collectionType={collection.type}
            submitFormRef={submitFormButtonRef}
            onSave={onSave}
          />
        )}
      </HeavyDialogContent>
      <HeavyDialogActions>
        <Button
          size="large"
          variant="outlined"
          color="secondary"
          onClick={doOnClose}
        >
          Cancel
        </Button>
        <Button
          disabled={updateCollection.isLoading}
          size="large"
          variant="contained"
          onClick={() => {
            submitFormButtonRef.current?.click();
            track('Edit Collection', 'Clicked');
          }}
        >
          {updateCollection.isLoading ? (
            <CircularProgress size="18px" color="primary" />
          ) : (
            'Save Changes'
          )}
        </Button>
      </HeavyDialogActions>
    </HeavyDialog>
  );
};
