import { Blockchain } from '@stardust-monorepo/types/sd-private';

import avalancheIcon from '../../assets/blockchains/avalanche-icon.png';
import polygonIcon from '../../assets/blockchains/polygon-icon.png';
import solanaIcon from '../../assets/blockchains/solana-icon.png';

export interface BlockchainIconProps {
  blockchain: number;
  size?: number;
}

export const BlockchainIcon = ({
  blockchain,
  size = 16,
}: BlockchainIconProps) => {
  let icon;
  if (
    blockchain === Blockchain.polygon_mainnet ||
    blockchain === Blockchain.polygon_mumbai_testnet
  ) {
    icon = polygonIcon;
  } else if (
    blockchain === Blockchain.avalanche_mainnet ||
    blockchain === Blockchain.avalanche_fuji_testnet
  ) {
    icon = avalancheIcon;
  } else if (
    blockchain === Blockchain.solana ||
    blockchain === Blockchain.solana_testnet
  ) {
    icon = solanaIcon;
  }

  return !icon ? null : (
    <img alt="blockchain icon" src={icon} height={size} width={size} />
  );
};
