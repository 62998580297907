import { Add } from '@mui/icons-material';
import { Button, IconButton, Stack, Tooltip } from '@mui/material';
import { Trash } from '@phosphor-icons/react';
import {
  CollapsibleTable,
  ErrorAlert,
  Heading,
  PageTitle,
  RemoveModal,
  SdPrivateApi,
  Spinner,
  track,
  useHandleError,
  userState,
  useToast,
  useTrack,
} from '@stardust-monorepo/web-sdk-apps-shared';
import React, { ReactNode, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';

import { AddTeamMember } from '../components/team/AddTeamMember';

const defaultHeadings = [{ label: 'Email' }, { label: 'Role', width: '150px' }];
const adminHeadings = [...defaultHeadings, { label: '', width: '40px' }];

export const TeamPage = () => {
  const { gameId } = useParams<{
    gameId: string;
  }>();
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [selectedTeamMember, setSelectedTeamMember] = useState('');
  const [addTeamMemberOpen, setAddTeamMemberOpen] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [headings, setHeadings] = useState<Heading[]>(defaultHeadings);
  const [user] = useRecoilState(userState);
  const toaster = useToast();
  const handleError = useHandleError();

  const deleteTeamMember = SdPrivateApi.useDeleteTeamMember();

  useTrack('Team', { gameId });

  const {
    data: teamMembers,
    error: teamMembersError,
    isLoading,
  } = SdPrivateApi.useTeamMembers(
    {
      gameId: Number(gameId),
    },
    !!gameId
  );

  useEffect(() => {
    if (Array.isArray(teamMembers)) {
      const adminMember = teamMembers.find(
        (member) =>
          member.isAdmin && member.email === user.user?.attributes.email
      );
      if (adminMember) {
        setIsAdmin(true);
        setHeadings(adminHeadings);
      }
    }
  }, [teamMembers]);

  const onRemoveTeamMember = async () => {
    const trackingData = { gameId };
    track('Modal Remove Team Member', 'Clicked', trackingData);
    try {
      if (gameId && selectedTeamMember) {
        await deleteTeamMember.mutateAsync({
          gameId: Number(gameId),
          teamMemberEmail: selectedTeamMember,
        });
        track('Modal Remove Team Member', 'Success', trackingData);
        toaster(`${selectedTeamMember} successfully removed`);
      }
    } catch (e) {
      handleError(
        e,
        `Unable to remove ${selectedTeamMember}`,
        'Modal Remove Team Member',
        trackingData
      );
    }
    setSelectedTeamMember('');
    setDeleteConfirmationOpen(false);
  };

  const adminRows: ReactNode[][] = [];
  const regularRows: ReactNode[][] = [];
  (teamMembers || []).forEach((member) => {
    const row: ReactNode[] = [
      member.email,
      member.isAdmin ? 'Admin' : 'Member',
    ];
    if (isAdmin) {
      row.push(
        <Tooltip
          placement="top"
          title={member.isAdmin ? "You can't delete an admin" : ''}
        >
          <span>
            <IconButton
              aria-label="remove"
              disabled={member.isAdmin}
              onClick={() => {
                track('Remove Member', 'Clicked', { gameId });
                setSelectedTeamMember(member.email);
                setDeleteConfirmationOpen(true);
              }}
              sx={{
                color: 'error.main',
                '&:hover': {
                  color: 'error.dark',
                },
              }}
            >
              <Trash weight="duotone" />
            </IconButton>
          </span>
        </Tooltip>
      );
    }
    if (member.isAdmin) {
      adminRows.push(row);
    } else {
      regularRows.push(row);
    }
  });

  return (
    <>
      <RemoveModal
        body={`Are you sure you want to remove ${selectedTeamMember}?`}
        label="Member"
        loading={deleteTeamMember.isLoading}
        onClose={() => {
          track('Remove Member Close', 'Clicked', {
            gameId,
          });
          setDeleteConfirmationOpen(false);
        }}
        onConfirm={onRemoveTeamMember}
        open={deleteConfirmationOpen}
      />
      {addTeamMemberOpen ? (
        <AddTeamMember
          emails={(teamMembers || []).map((member) => member.email)}
          gameId={Number(gameId)}
          onConfirm={(email: string) => {
            toaster(`${email} successfully added`);
            setAddTeamMemberOpen(false);
          }}
          open={addTeamMemberOpen}
          onClose={() => setAddTeamMemberOpen(false)}
        />
      ) : null}
      <ErrorAlert error={teamMembersError as Error} />
      <PageTitle
        title="Team"
        count={teamMembers?.length}
        info={
          !teamMembers || isAdmin
            ? ''
            : 'Only the game admin can add or remove members.'
        }
      >
        {isAdmin ? (
          <Button
            variant="contained"
            onClick={() => {
              track('Add Member', 'Clicked', { gameId });
              setAddTeamMemberOpen(true);
            }}
            startIcon={<Add />}
          >
            Invite
          </Button>
        ) : null}
      </PageTitle>
      {isLoading ? (
        <Spinner grow={true} />
      ) : (
        <Stack spacing={1}>
          <CollapsibleTable
            page={0}
            rows={adminRows}
            label="Admin"
            headings={headings}
            count={adminRows.length}
          />
          <CollapsibleTable
            page={0}
            setPage={() => null}
            rows={regularRows}
            label="Members"
            headings={headings}
            count={regularRows.length}
          />
        </Stack>
      )}
    </>
  );
};
