import {
  Blockchain,
  FeeEntityType,
  Game,
  GameSchema,
} from '@stardust-monorepo/types/sd-private';
import { z } from 'zod';

import { isV2Blockchain } from '../../utils/isV2Blockchain';
import { validatedRequestWithAuth } from './api-utils';
import { createAsset, persistFile } from './asset-api';
import { mutateFee } from './fee-api';

export async function getGames(): Promise<Game[]> {
  return await validatedRequestWithAuth(GameSchema.array(), '/game/get-all');
}

export async function getGame(args: {
  gameId: number | string;
}): Promise<Game> {
  return await validatedRequestWithAuth(GameSchema, '/game/get', {
    params: {
      ...args,
    },
  });
}

const createBannerImageAsset = (gameId: number, image: File) =>
  createAsset({
    gameId,
    path: 'Game/banner',
    fileType: image.type,
    usageType: 'banner',
  })
    .then((res: { uploadUrl: string }) => {
      return {
        uploadUrl: res.uploadUrl,
        file: image,
      };
    })
    .catch((e) => {
      // eslint-disable-next-line no-console
      console.error('An error occurred while saving the game banner'); //TODO
      throw e;
    });

const createGameLogoAsset = (gameId: number, image: File) =>
  createAsset({
    gameId,
    path: 'Game/GameLogo.png',
    fileType: image.type,
  })
    .then((res: { uploadUrl: string }) => {
      return {
        uploadUrl: res.uploadUrl,
        file: image,
      };
    })
    .catch((e) => {
      // eslint-disable-next-line no-console
      console.error('An error occurred while saving the game icon'); //TODO
      throw e;
    });

const saveGameImages = async (
  gameId: number,
  image?: File,
  bannerImage?: File
) => {
  const requests = [];
  if (image) {
    requests.push(createGameLogoAsset(gameId, image));
  }
  if (bannerImage) {
    requests.push(createBannerImageAsset(gameId, bannerImage));
  }
  if (requests.length) {
    const imagePersistenceDetails = await Promise.all<{
      file: File;
      uploadUrl: string;
    }>(requests);
    await Promise.all(
      imagePersistenceDetails.map((details) =>
        persistFile(details.uploadUrl, details.file)
      )
    );
  }
};

export interface CreateGameProps {
  name: string;
  description: string;
  blockchainId: Blockchain;
  royalty?: number;
  image?: File;
  bannerImage?: File;
}

export async function createGame({
  name,
  description,
  blockchainId,
  royalty,
  image,
  bannerImage,
}: CreateGameProps) {
  const { id: gameId } = (await validatedRequestWithAuth(
    z.object({
      id: z.number(),
    }),
    'game/create',
    {
      method: 'POST',
      data: {
        name,
        desc: description,
        image: '',
        blockchainId,
        props: {
          version:
            Number(process.env.NX_GAME_VERSION) === 2 &&
            isV2Blockchain(blockchainId)
              ? 2
              : 1,
        },
      },
    }
  )) as { id: number };

  if (royalty) {
    await mutateFee({
      entityId: gameId,
      entityType: FeeEntityType.game,
      feePercentage: royalty,
      feeType: 'game_royalty',
      gameId: gameId,
    });
  }

  await saveGameImages(gameId, image, bannerImage);

  return {
    id: gameId,
  };
}

export interface MutateGameProps {
  gameId: number;
  description?: string;
  royalty?: number;
  image?: File;
  bannerImage?: File;
  testMode?: boolean;
}
export const mutateGame = async ({
  gameId,
  description,
  royalty,
  image,
  bannerImage,
  testMode,
}: MutateGameProps) => {
  await validatedRequestWithAuth(z.object({}), 'game/mutate', {
    method: 'PUT',
    data: {
      description,
      gameId,
      testMode,
    },
  });
  if (royalty) {
    await mutateFee({
      entityId: gameId,
      entityType: FeeEntityType.game,
      feePercentage: royalty,
      feeType: 'game_royalty',
      gameId: gameId,
    });
  }

  await saveGameImages(gameId, image, bannerImage);
};

export const deleteGame = async ({ gameId }: { gameId: number }) => {
  return validatedRequestWithAuth(z.object({}), 'game/remove', {
    method: 'DELETE',
    params: {
      gameId,
    },
  });
};

export const isV2Game = (game: Game) =>
  game.props.version === 2 && isV2Blockchain(game.blockchain);
