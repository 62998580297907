import { z } from 'zod';

export const isSupportedExternalWalletService = (
  str: string
): str is SupportedExternalWalletService =>
  str === 'EVM' || str === 'SOL' || str === 'SUI';

export type SupportedExternalWalletService = 'EVM' | 'SOL' | 'SUI';
const EvmChainSchema = z
  .enum([
    'eth',
    '0x1',
    'goerli',
    '0x5',
    'sepolia',
    '0xaa36a7',
    'polygon',
    '0x89',
    'mumbai',
    '0x13881',
    'bsc',
    '0x38',
    'bsc testnet',
    '0x61',
    'avalanche',
    '0xa86a',
    'fantom',
    '0xfa',
    'palm',
    '0x2a15c308d',
    'cronos',
    '0x19',
    'arbitrum',
    '0xa4b1',
    'chiliz',
    '0x15b38',
    'chiliz testnet',
    '0x15b32',
    'gnosis',
    '0x64',
    'gnosis testnet',
    '0x27d8',
    'base',
    '0x2105',
    'base testnet',
    '0x14a33',
    'optimism',
    '0xa',
  ])
  .describe('EvmChainSchema');
export type EvmChain = z.infer<typeof EvmChainSchema>;

export const ExternalWalletSchema = z
  .object({
    identifierId: z.string(),
    address: z.string(),
  })
  .describe('ExternalWalletSchema');
export type ExternalWallet = z.infer<typeof ExternalWalletSchema>;

export const CurrencySchema = z
  .object({
    amount: z.string(),
    currency: z.literal('USD'), //TODO only USD now - see SupportedCurrencyCode
  })
  .describe('CurrencySchema');
export type Currency = z.infer<typeof CurrencySchema>;

export const EnsSchema = z
  .object({
    name: z.string(),
  })
  .merge(ExternalWalletSchema)
  .describe('EnsSchema');
export type Ens = z.infer<typeof EnsSchema>;

export const NetWorthSchema = z
  .object({
    total: CurrencySchema,
    expanded: z
      .object({
        chains: z
          .object({
            chain: EvmChainSchema,
            nativeBalance: z.string(),
            nativeBalanceValue: CurrencySchema,
            tokenBalanceValue: CurrencySchema,
            netWorth: CurrencySchema,
          })
          .array(),
      })
      .merge(ExternalWalletSchema)
      .array(),
  })
  .describe('NetWorthSchema');

export const ExternalWalletTransactionStatsSchema = z
  .object({
    totalTransactions: z.number(),
    nfts: z.number(),
    collections: z.number(),
    nftTransactions: z.number(),
    tokenTransactions: z.number(),
  })
  .describe('ExternalWalletTransactionStatsSchema');
export const AllExternalWalletTransactionStatsSchema = z
  .object({
    total: ExternalWalletTransactionStatsSchema,
    expanded: z
      .object({
        transactions: ExternalWalletTransactionStatsSchema,
      })
      .merge(ExternalWalletSchema)
      .array(),
  })
  .describe('AllExternalWalletTransactionStatsSchema');

export const WhaleScoreSchema = z
  .object({
    score: z.number(),
  })
  .merge(ExternalWalletSchema)
  .describe('WhaleScoreSchema');
export type WhaleScore = z.infer<typeof WhaleScoreSchema>;

export const EvmChainTransactionSchema = z
  .object({
    blockNumber: z.string(),
    blockTimestamp: z.number(), //TODO convert from timestamp
    transactionHash: z.string(),
  })
  .describe('EvmChainTransactionSchema');

export const EvmChainFirstAndLastTransactionsSchema = z
  .object({
    chain: EvmChainSchema,
    firstTransaction: EvmChainTransactionSchema,
    lastTransaction: EvmChainTransactionSchema,
  })
  .describe('EvmChainFirstAndLastTransactionsSchema');

export const ProfileExternalWalletStatsOverviewSchema = z
  .object({
    ens: z.object({
      result: EnsSchema.array(),
    }),
    activeChains: z.object({
      uniqueChains: EvmChainSchema.array(),
      expanded: z
        .object({
          chains: EvmChainFirstAndLastTransactionsSchema.array(),
        })
        .merge(ExternalWalletSchema)
        .array(),
    }),
    netWorth: NetWorthSchema,
    transactions: AllExternalWalletTransactionStatsSchema,
    whaleScore: z.object({
      result: WhaleScoreSchema.array(),
    }),
  })
  .describe('ProfileExternalWalletStatsOverviewSchema');
export type ProfileExternalWalletStatsOverview = z.infer<
  typeof ProfileExternalWalletStatsOverviewSchema
>;
