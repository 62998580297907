import { Link } from '@mui/material';
import {
  Blockchain,
  BlockchainExplorerBaseUrls,
} from '@stardust-monorepo/types/sd-private';

export interface BlockchainLinkProps {
  blockchain?: number;
  blockchainExplorerLink?: string;
  tokenId: number;
}

export const BlockchainLink = ({
  blockchain,
  blockchainExplorerLink,
  tokenId,
}: BlockchainLinkProps) => {
  if (blockchainExplorerLink) {
    let name = 'Blockchain Explorer';
    if (
      blockchain === Blockchain.polygon_mainnet ||
      blockchain === Blockchain.polygon_mumbai_testnet
    ) {
      name = 'Polygonscan';
    } else if (
      blockchain === Blockchain.avalanche_mainnet ||
      blockchain === Blockchain.avalanche_fuji_testnet
    ) {
      name = 'Snowtrace';
    }

    return (
      <Link target="_blank" rel="noopener" href={blockchainExplorerLink}>
        {name}
      </Link>
    );
  } else if (blockchain === Blockchain.polygon_mainnet) {
    return (
      <Link
        target="_blank"
        rel="noopener"
        href={`${
          BlockchainExplorerBaseUrls[Blockchain.polygon_mainnet]
        }/token/0x0Eccb65C249c019289505093F28D45e8339E1e64?a=${tokenId}#inventory`}
      >
        Polygonscan
      </Link>
    );
  } else if (blockchain === Blockchain.polygon_mumbai_testnet) {
    return (
      <Link
        target="_blank"
        rel="noopener"
        href={`${
          BlockchainExplorerBaseUrls[Blockchain.polygon_mumbai_testnet]
        }/token/${
          process.env['NX_POLYGON_TESTNET_TOKEN_CONTRACT_ADDRESS']
        }?a=${tokenId}`}
      >
        Polygonscan
      </Link>
    );
  } else if (blockchain === Blockchain.avalanche_mainnet) {
    return (
      <Link
        target="_blank"
        rel="noopener"
        href={`${
          BlockchainExplorerBaseUrls[Blockchain.avalanche_mainnet]
        }/token/${
          process.env['NX_AVALANCHE_MAINNET_TOKEN_CONTRACT_ADDRESS']
        }?a=${tokenId}#inventory`}
      >
        Snowtrace
      </Link>
    );
  } else if (blockchain === Blockchain.avalanche_fuji_testnet) {
    return (
      <Link
        target="_blank"
        rel="noopener"
        href={`https://testnet.snowtrace.io/token/${process.env['NX_AVALANCHE_TESTNET_TOKEN_CONTRACT_ADDRESS']}?a=${tokenId}`}
      >
        Snowtrace
      </Link>
    );
  } else {
    return <>Not Available</>;
  }
};
