import {
  Article,
  CirclesThreePlus,
  CurrencyCircleDollar,
  FlagPennant,
  Key,
  Shapes,
  Storefront,
  UserList,
} from '@phosphor-icons/react';
import {
  SdPrivateApi,
  TopNav,
  userState,
} from '@stardust-monorepo/web-sdk-apps-shared';
import { useMemo } from 'react';
import * as React from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';

import { paymentsDisabledTooltip } from '../../constants';
import { gameState } from '../../state/game-state';
import { isPaymentsAllowedForGame } from '../../util';
import { GameTopHeader } from './GameTopHeader';

export const GameTopNav = () => {
  const { gameId } = useParams<{
    gameId: string;
  }>();

  const [user] = useRecoilState(userState);
  const [games] = useRecoilState(gameState);
  const game = games.find((g) => g.id === Number(gameId));

  const routes = useMemo(() => {
    const isUserGameOwner =
      game && user && game?.ownerId === user.user?.attributes.sub;
    const isPaymentsAllowed = game?.id && isPaymentsAllowedForGame(game.id);

    const routeList = [
      {
        icon: Article,
        label: 'Overview',
        path: '',
      },
      {
        icon: CirclesThreePlus,
        label: 'Templates',
        path: 'templates',
      },
      {
        icon: Storefront,
        label: 'Game Store',
        path: 'game-store',
        disabled: !isPaymentsAllowed,
        disabledMessage: paymentsDisabledTooltip,
      },
      {
        icon: CurrencyCircleDollar,
        label: 'Payments',
        path: 'payments',
        disabled: !isPaymentsAllowed,
        disabledMessage: paymentsDisabledTooltip,
      },
      {
        icon: UserList,
        label: 'Players',
        path: 'players',
      },
      {
        icon: Key,
        label: 'API Keys',
        path: 'api-keys',
        disabled: !isUserGameOwner,
        disabledMessage:
          'Only Game Owners can view and make changes to API Keys.',
      },
      {
        icon: FlagPennant,
        label: 'Team',
        path: 'team',
      },
    ];

    if (game && SdPrivateApi.isV2Game(game)) {
      // Remove Templates, Game Store, and Payments tabs for V2 games
      routeList.splice(1, 3, {
        icon: Shapes,
        label: 'Collections',
        path: 'collections',
      });
    }

    return routeList;
  }, [game, user]);

  return (
    <>
      <GameTopHeader game={game} />
      <TopNav basePath={`/games/${gameId}`} routes={routes} />
    </>
  );
};
