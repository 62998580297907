import { z } from 'zod';

import { PaymentMethodSchema } from './payment-method';

export const CheckoutSchema = z
  .object({
    uuid: z.string().uuid(),
    playerId: z.string().uuid(),
    items: z.object({
      purchasables: z.array(
        z.object({
          id: z.number(),
          amount: z.string(),
        })
      ),
    }),
    externalServiceData: z.object({}),
    price: z.string(),
    finished: z.boolean(),
    createdAt: z.string(),
  })
  .describe('Checkout Schema');
export type Checkout = z.infer<typeof CheckoutSchema>;

export const CheckoutWithClientSecretSchema = z
  .object({
    checkout: CheckoutSchema,
    stripePaymentIntent: z.object({
      client_secret: z.string(),
    }),
    paymentMethods: z.optional(
      z.object({
        data: z.array(PaymentMethodSchema),
      })
    ),
  })
  .describe('Checkout With Client Secret Schema');
export type CheckoutWithClientSecret = z.infer<
  typeof CheckoutWithClientSecretSchema
>;
