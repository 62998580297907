import { paginatedResultsSchema } from '@stardust-monorepo/types/common';
import { z } from 'zod';

export const ItemBalanceSchema = z
  .object({
    id: z.string(),
    itemId: z.string(),
    playerId: z.string(),
    gameId: z.number(),
    amount: z.string(),
    createdAt: z.number(),
    updatedAt: z.number(),
  })
  .describe('ItemBalanceSchema');
export type ItemBalance = z.infer<typeof ItemBalanceSchema>;

export const ItemBalanceResultsSchema =
  paginatedResultsSchema(ItemBalanceSchema);
export type ItemBalanceResults = z.infer<typeof ItemBalanceResultsSchema>;

export const TransferItemBalanceSchema = z.object({
  sourcePlayerItemBalance: ItemBalanceSchema,
  destinationPlayerItemBalance: ItemBalanceSchema,
});
export type TransferItemBalanceResult = z.infer<
  typeof TransferItemBalanceSchema
>;
