import { TokenPropertyRecordSchema } from '@stardust-monorepo/types/marketplace';
import { z } from 'zod';

export const TokenSchema = z.object({
  id: z.number(),
  name: z.string(),
  templateId: z.number(),
  blockchainExplorerLink: z.optional(z.string().url()),
  props: z.object({
    immutable: TokenPropertyRecordSchema,
    mutable: TokenPropertyRecordSchema,
    inherited: TokenPropertyRecordSchema.and(
      z.object({
        description: z.optional(z.string()),
        image: z.string(),
      })
    ),
  }),
});
export type Token = z.infer<typeof TokenSchema>;

export const TokenMintSchema = z.object({
  amount: z.string(),
  playerId: z.string(),
  tokenId: z.number(),
});
export type TokenMint = z.infer<typeof TokenMintSchema>;
