import {
  ApiKey,
  ApiKeySchema,
  ApiLog,
  ApiLogCount,
  ApiLogCountSchema,
  ApiLogSchema,
} from '@stardust-monorepo/types/sd-private';

import { disableCachingHeaders } from '../api-utils';
import { authenticatedRequest, validatedRequestWithAuth } from './api-utils';

export async function getApiKeys(gameId: number): Promise<ApiKey[]> {
  return validatedRequestWithAuth(
    ApiKeySchema.array(),
    'api-key/get-all-by-game-id',
    {
      params: {
        gameId,
      },
      headers: {
        ...disableCachingHeaders,
      },
    }
  );
}

export interface CreateApiKeyArgs {
  gameId: number;
}

export async function createApiKey(args: CreateApiKeyArgs): Promise<ApiKey> {
  return validatedRequestWithAuth(ApiKeySchema, 'api-key/create', {
    method: 'POST',
    data: args,
  });
}

export interface ToggleApiKeyActiveArgs {
  gameId: number;
  apiKey: string;
}

export async function toggleApiKeyActive(
  args: ToggleApiKeyActiveArgs
): Promise<Record<string, never>> {
  return authenticatedRequest('api-key/mutate', {
    method: 'PUT',
    data: args,
  });
}

export interface GetApiKeyLogCountArgs {
  gameId: number;
  startTimeISO: string;
  endTimeISO: string;
}

export async function getApiKeyLogCount(
  args: GetApiKeyLogCountArgs
): Promise<ApiLogCount> {
  return validatedRequestWithAuth(ApiLogCountSchema, 'api-log/get-all', {
    params: args,
  });
}

export interface GetApiKeyLogsArgs {
  gameId: number;
  apiKeyId: string;
  start: number;
  limit: number;
}

export async function getApiKeyLogs(args: GetApiKeyLogsArgs): Promise<ApiLog> {
  return validatedRequestWithAuth(ApiLogSchema, 'api-log/get-all', {
    params: { ...args },
  });
}
