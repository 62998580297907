import { fromUnixTime } from 'date-fns';
import { z } from 'zod';

export function legacyPaginatedResultsSchema<ItemType extends z.ZodTypeAny>(
  itemSchema: ItemType
) {
  return z.object({
    items: z.array(itemSchema),
    meta: z.object({
      currentPage: z.number(),
      itemCount: z.number(),
      itemsPerPage: z.number(),
      totalItems: z.number(),
      totalPages: z.number(),
    }),
  });
}

export const unixTimestampToDateTransformation = () =>
  z.number().transform((createdAt) => fromUnixTime(createdAt));

export const HasCreatedAtTimestamp = z.object({
  //convert from seconds to millis since epoch
  createdAt: unixTimestampToDateTransformation(),
});
