import { Stack, SxProps, Theme, Typography, useTheme } from '@mui/material';
import { PropsWithChildren, ReactNode } from 'react';

export interface ContentModuleProps {
  borderBottom?: boolean;
  title?: string;
  subtitle?: string;
  renderHeaderContent?: () => ReactNode;
  sx?: SxProps<Theme>;
}

export const ContentModule = ({
  children,
  borderBottom = true,
  title,
  subtitle,
  renderHeaderContent,
  sx,
}: PropsWithChildren<ContentModuleProps>) => {
  const theme = useTheme();
  return (
    <Stack
      sx={{
        background: theme.palette.background.default,
        borderRadius: 3,
        border: `1px solid ${theme.palette.divider}`,
        ...(typeof sx === 'function' ? sx(theme) : sx || {}),
      }}
    >
      <Stack
        direction={'row'}
        sx={{
          justifyContent: 'space-between',
          borderBottom: borderBottom
            ? `1px solid ${theme.palette.divider}`
            : 'none',
        }}
      >
        <Stack
          direction={'row'}
          gap={1}
          sx={{
            margin: 2,
            alignItems: 'baseline',
          }}
        >
          <Typography variant={'subtitle2'}>{title}</Typography>
          {subtitle && (
            <Typography
              variant={'helperText'}
              sx={{
                color: theme.palette.text.secondary,
              }}
            >
              {subtitle}
            </Typography>
          )}
        </Stack>
        {renderHeaderContent?.()}
      </Stack>
      {children}
    </Stack>
  );
};
