import { z, ZodRawShape } from 'zod';

export interface BaseCollectionFormValue {
  name: string;
  image: string;
  symbol: string;
  description: string;
}

export const baseCollectionFormValue: BaseCollectionFormValue = {
  name: '',
  image: '',
  description: '',
  symbol: '',
};

export const formSchemaWithMetadata = <T extends ZodRawShape>(metadata?: T) =>
  z.object({
    name: z.string().min(1, 'Name is required'),
    description: z.string().min(1, 'Description is required'),
    image: z.string().min(1, 'Image URL is required').url(),
    symbol: z.string().min(3, 'Symbol must be at least 3 characters long'),
    ...(metadata || {}),
  });
