import { StyledComponent } from '@emotion/styled';
import {
  ToggleButtonGroup,
  ToggleButtonGroupProps,
  styled,
} from '@mui/material';

import { color } from '../../theming/color';

export const LargeToggleButtonGroup: StyledComponent<ToggleButtonGroupProps> =
  styled((props: ToggleButtonGroupProps) => <ToggleButtonGroup {...props} />)(
    ({ theme }) => ({
      border: `none`,
      width: 'fit-content',
      padding: 0,
      '& .MuiToggleButton-root': {
        padding: '5px 16px',
        color: 'text.secondary',
        borderRadius: '90px !important',
        flexGrow: 0,
      },
      '& .MuiToggleButton-root:not(.Mui-selected)': {
        color: theme.palette.text.hint,
      },
      '& .MuiToggleButton-root.Mui-selected': {
        backgroundColor: color.accent.blue[50],
        color: color.accent.blue[900],
        '&:hover': {
          backgroundColor: color.accent.blue[100],
          color: 'text.primary',
        },
        '&:focus': {
          backgroundColor: color.accent.blue[100],
          color: 'text.primary',
        },
      },
      '& .MuiToggleButton-root:hover': {
        color: 'text.primary',
        backgroundColor: color.neutral[100],
      },
      '& .MuiToggleButton-root:focus': {
        color: 'text.primary',
        backgroundColor: color.neutral[100],
      },
      '& .MuiToggleButton-root:active': {
        backgroundColor: color.neutral[500],
        color: theme.palette.text.white,
      },
    })
  );
