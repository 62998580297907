import { StyledComponent } from '@emotion/styled';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails as MuiAccordionDetails,
  AccordionProps,
  styled,
  AccordionSummaryProps,
  AccordionDetailsProps,
} from '@mui/material';

import { color } from '../../theming';

export const Accordion: StyledComponent<AccordionProps> = styled(
  (props: AccordionProps) => (
    <MuiAccordion
      sx={{
        borderRadius: 2.75,
        border: `1px solid ${color.neutral[200]}`,
      }}
      disableGutters
      elevation={0}
      {...props}
    />
  )
)(({ theme }) => ({
  '&:before': {
    display: 'none',
  },
  '&:last-of-type': {
    borderRadius: 2.75 * theme.shape.borderRadius,
  },
}));

export const AccordionSummary: StyledComponent<AccordionSummaryProps> = styled(
  (props: AccordionSummaryProps) => (
    <MuiAccordionSummary
      sx={{
        borderRadius: 2.75,
      }}
      expandIcon={
        <ExpandMoreIcon
          sx={(theme) => ({
            color: theme.palette.text.primary,
          })}
        />
      }
      {...props}
    />
  )
)(({ theme }) => ({}));

export const AccordionDetails: StyledComponent<AccordionDetailsProps> = styled(
  (props: AccordionDetailsProps) => (
    <MuiAccordionDetails
      sx={{
        borderTop: `1px dashed ${color.neutral[200]}`,
        padding: 2,
      }}
      {...props}
    />
  )
)(({ theme }) => ({}));
