import { z } from 'zod';

export const PaymentProviderSchema = z
  .object({
    status: z.enum(['IN_PROGRESS', 'ACTIVE', 'INACTIVE', 'ATTENTION_REQUIRED']),
    metadata: z.object({
      accountId: z.string(),
    }),
  })
  .describe('PaymentProviderSchema');
export type PaymentProvider = z.infer<typeof PaymentProviderSchema>;

export const PaymentProvidersSchema = z
  .enum(['STRIPE'])
  .describe('PaymentProvidersSchema');
export type PaymentProviders = z.infer<typeof PaymentProvidersSchema>;

export const PaymentProviderOnboardSchema = z
  .object({
    redirectUrl: z.string().url(),
  })
  .describe('PaymentProviderSchema');
export type PaymentProviderOnboard = z.infer<
  typeof PaymentProviderOnboardSchema
>;
