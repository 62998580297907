import { Add } from '@mui/icons-material';
import { Button, Chip, Stack, Typography } from '@mui/material';
import { CoinVertical, NotePencil, Trash } from '@phosphor-icons/react';
import { Template } from '@stardust-monorepo/types/marketplace';
import {
  ContainedTable,
  ErrorAlert,
  MoreActions,
  PAGE_SIZE,
  PageTitle,
  RemoveModal,
  SdPrivateApi,
  track,
  UnlimitedTemplateCap,
  useHandleError,
  useToast,
  useTrack,
  ValidatedImage,
} from '@stardust-monorepo/web-sdk-apps-shared';
import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import { MintTokenModal } from '../components/mint-token';
import {
  CreateEditTemplateModal,
  EmptyTemplates,
  TemplateToggle,
} from '../components/templates';

const headings = [
  { width: '64px', label: 'Template' },
  { label: '' },
  { label: 'Type' },
  { label: 'Royalty' },
  { label: 'Circulating' },
  { label: 'Cap' },
  { label: 'Active' },
  { width: '40px', label: '' },
];

export const TemplatesPage = () => {
  const { gameId } = useParams<{
    gameId: string;
  }>();
  const [page, setPage] = React.useState(0);
  const [filter, setFilter] = React.useState('');
  const toaster = useToast();
  const handleError = useHandleError();
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [createTemplateOpen, setCreateTemplateOpen] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState<Template | null>();
  const [mintTokenOpen, setMintTokenOpen] = useState(false);

  const deleteTemplate = SdPrivateApi.useDeleteTemplate();

  useTrack('Templates', { gameId });

  const onDeleteTemplate = async () => {
    const trackingData = { gameId, templateId: selectedTemplate?.id };
    track('Modal Remove Template', 'Clicked', trackingData);
    try {
      if (gameId && selectedTemplate) {
        await deleteTemplate.mutateAsync({
          gameId: Number(gameId),
          templateId: selectedTemplate.id,
        });
        track('Remove Template', 'Success', trackingData);
        toaster(`${selectedTemplate.name} successfully removed`);
      }
    } catch (e) {
      handleError(
        e,
        `Unable to remove ${selectedTemplate?.name}`,
        'Remove Template',
        trackingData
      );
    }
    setSelectedTemplate(null);
    setDeleteConfirmationOpen(false);
  };

  const {
    data: templates,
    error: templatesError,
    isLoading: templatesLoading,
  } = SdPrivateApi.useTemplates(
    {
      gameId: Number(gameId),
      start: page * PAGE_SIZE,
      limit: PAGE_SIZE,
      filter: filter ? filter : undefined,
    },
    !!gameId
  );

  const {
    data: templatesCount,
    error: templatesCountError,
    isLoading: templatesCountLoading,
  } = SdPrivateApi.useTemplateCount(
    {
      gameId: Number(gameId),
      filter: filter ? filter : undefined,
    },
    !!gameId
  );

  const rows = (templates || []).map((template) => {
    return [
      <ValidatedImage size="64px" image={template.image} />,
      <>
        <Stack
          direction="row"
          spacing={0.75}
          sx={{ alignItems: 'center', mb: 0.5 }}
        >
          <Link to={template.id.toString()}>
            <Typography variant="subtitle2" sx={{ color: 'primary.main' }}>
              {template.name}
            </Typography>
          </Link>
          <Typography variant="caption" color="text.hint">
            ID {template.id}
          </Typography>
        </Stack>
        <Typography variant="helperText" color="text.secondary">
          {template.props.mutable.description}
        </Typography>
      </>,
      <Chip label={String(template.type)} />,
      `${((template.royalty || 0) / 100).toFixed(2)}%`,
      template.totalSupply,
      template.cap === UnlimitedTemplateCap ? 'Unlimited' : template.cap,
      <TemplateToggle template={template} />,
      <MoreActions
        actions={[
          {
            icon: <NotePencil weight="duotone" />,
            label: 'Edit Template',
            onClick: () => {
              track('Edit Template', 'Clicked', {
                gameId,
                templateId: template.id,
              });
              setSelectedTemplate(template);
              setCreateTemplateOpen(true);
            },
          },
          {
            icon: <CoinVertical weight="duotone" />,
            label: 'Mint Token',
            onClick: () => {
              track('Templates Mint Token Action', 'Clicked', {
                gameId,
                templateId: template.id,
              });
              setSelectedTemplate(template);
              setMintTokenOpen(true);
            },
          },
          {
            disabledTooltip:
              template.type === 'FT' ? "You can't remove an FT" : '',
            icon: <Trash weight="duotone" />,
            label: 'Remove Template',
            onClick: () => {
              track('Remove Template', 'Clicked', {
                gameId,
                templateId: template.id,
              });
              setSelectedTemplate(template);
              setDeleteConfirmationOpen(true);
            },
            type: 'error',
          },
        ]}
      />,
    ];
  });

  return (
    <>
      <RemoveModal
        body={`Are you sure you want to remove ${selectedTemplate?.name}?`}
        label="Template"
        loading={deleteTemplate.isLoading}
        onClose={() => {
          track('Remove Template Close', 'Clicked', {
            gameId,
            templateId: selectedTemplate?.id,
          });
          setDeleteConfirmationOpen(false);
          setSelectedTemplate(null);
        }}
        onConfirm={onDeleteTemplate}
        open={deleteConfirmationOpen}
      />
      {createTemplateOpen ? (
        <CreateEditTemplateModal
          gameId={Number(gameId)}
          open={createTemplateOpen}
          template={selectedTemplate}
          onClose={() => {
            setCreateTemplateOpen(false);
            setSelectedTemplate(null);
          }}
        />
      ) : null}
      {mintTokenOpen ? (
        <MintTokenModal
          gameId={Number(gameId)}
          open={mintTokenOpen}
          targetTemplate={selectedTemplate}
          onClose={() => {
            setMintTokenOpen(false);
            setSelectedTemplate(null);
          }}
        />
      ) : null}
      <ErrorAlert
        error={(templatesError as Error) || (templatesCountError as Error)}
      />
      <PageTitle title="Templates" count={templatesCount?.count}>
        <Stack direction="row" spacing={1}>
          <Button
            variant="contained"
            onClick={() => {
              track('Templates Create New', 'Clicked', { gameId });
              setCreateTemplateOpen(true);
            }}
            startIcon={<Add />}
          >
            Create New
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => {
              track('Templates Mint Token Button', 'Clicked', {
                gameId,
              });
              setMintTokenOpen(true);
            }}
          >
            Mint Token
          </Button>
        </Stack>
      </PageTitle>
      <ContainedTable
        count={templatesCount?.count}
        page={page}
        setPage={setPage}
        placeholder="Filter by Template Name"
        filter={filter}
        setFilter={setFilter}
        headings={headings}
        rows={rows}
        isLoading={templatesLoading || templatesCountLoading}
        emptyScreen={
          <EmptyTemplates
            onClick={() => {
              track('Empty Templates Create New', 'Clicked', { gameId });
              setCreateTemplateOpen(true);
            }}
          />
        }
      />
    </>
  );
};
