import { CalendarPlus, Envelope, Question } from '@phosphor-icons/react';
import { IconMenu, track } from '@stardust-monorepo/web-sdk-apps-shared';

const contactActions = [
  {
    icon: <CalendarPlus weight="duotone" />,
    label: 'Schedule a Meeting',
    onClick: () => {
      track('Schedule a Meeting', 'Clicked');
      window.open('https://calendly.com/andy-stardust/andy_onboarding');
    },
  },
  {
    icon: <Envelope weight="duotone" />,
    label: 'Contact Us',
    onClick: () => {
      track('Contact Us', 'Clicked');
      window.open('mailto:sales@stardust.gg');
    },
  },
];
export const ContactMenu = () => (
  <IconMenu
    actions={contactActions}
    icon={<Question size="20" weight="duotone" />}
    label="contact"
    secondaryStyle={true}
  />
);
