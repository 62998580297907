import { paginatedResultsWithFilterSchema } from '@stardust-monorepo/types/common';
import { z } from 'zod';

import { IdentityIdSchema } from './identity';

export const IdentitierTypeSchema = z
  .enum(['cognito_id', 'api_key', 'email', 'analytics', 'stripe_id'])
  .describe('IdentitierTypeSchema');
export type IdentifierType = z.infer<typeof IdentitierTypeSchema>;

export const IdentifierIdSchema = z
  .string()
  .uuid()
  .describe('IdentifierIdSchema');
export type IdentifierId = z.infer<typeof IdentifierIdSchema>;

export const IdentifierSchema = z
  .object({
    id: z.string().uuid(),
    identityId: IdentityIdSchema,
    rootUserId: z.string().uuid(),
    type: IdentitierTypeSchema,
    value: z.string(),
    label: z.string().nullable().optional(),
    truncatedValue: z.string().optional(),
  })
  .describe('IdentifierSchema');
export type Identifier = z.infer<typeof IdentifierSchema>;

export const IdentifierResultsSchema = paginatedResultsWithFilterSchema(
  IdentifierSchema
).describe('IdentifierResultsSchema');
export type IdentifierResults = z.infer<typeof IdentifierResultsSchema>;

export const NewApiKeySchema = z
  .object({
    apiKey: z.string().uuid(),
  })
  .merge(IdentifierSchema)
  .describe('NewApiKeySchema');
export type NewApiKey = z.infer<typeof NewApiKeySchema>;
