import { Box, Stack, styled, Typography } from '@mui/material';
import startCase from 'lodash.startcase';
import {
  LineChart as RcLineChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Line,
  Tooltip,
  TooltipProps,
  ResponsiveContainer,
} from 'recharts';

const ChartTooltip = ({ payload: payloads }: TooltipProps<number, string>) => {
  const label = payloads?.[0]?.payload?.period || '';
  return (
    <Stack
      sx={(theme) => ({
        backgroundColor: theme.palette.background.dark,
        padding: 1.5,
        borderRadius: 2,
        alignItems: 'baseline',
        boxShadow: theme.shadows[20],
      })}
      gap={1}
    >
      <Typography
        variant={'tooltip'}
        sx={(theme) => ({
          color: theme.palette.text.white,
        })}
      >
        {label}
      </Typography>
      {payloads
        ?.sort((p1, p2) => ((p1.value || 0) >= (p2.value || 0) ? -1 : 1))
        .map((payload, index) => (
          //in this case using index as the key is fine because their order will define their identity
          <Stack
            direction={'row'}
            key={index}
            gap={1}
            sx={{
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Box
              sx={{
                width: 14,
                height: 14,
                border: `1px solid ${payload.color}`,
                borderRadius: 0.75,
                clear: 'both',
                backgroundColor: payload.color,
                flexGrow: 0,
              }}
            >
              &nbsp;
            </Box>
            <Typography
              variant={'tooltip'}
              sx={(theme) => ({
                color: theme.palette.text.white,
              })}
            >
              {startCase(String(payload?.dataKey || ''))}: {payload?.value}
            </Typography>
          </Stack>
        ))}
    </Stack>
  );
};

const StyledAxisText = styled('text')(({ theme }) => ({
  ...theme.typography.tooltip,
  fill: theme.palette.text.hint,
}));

type PartialRecord<K extends keyof never, T> = Partial<Record<K, T>>;

export interface LineChartConfig<TData> {
  lines: PartialRecord<
    keyof TData,
    {
      color: string;
    }
  >;
  xAxisKey?: keyof TData;
  yAxisKey?: keyof TData;
}

export const LineChart = <TData extends object>({
  data,
  config,
}: {
  data: TData[];
  config: LineChartConfig<TData>;
}) => {
  return (
    <ResponsiveContainer>
      <RcLineChart
        data={data}
        margin={{
          top: 16,
          right: 40,
          bottom: 16,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" vertical={false} />
        <XAxis
          dataKey={config.xAxisKey as string | number | undefined}
          strokeWidth={0}
          tick={({ x, y, stroke, payload }) => (
            <g>
              <StyledAxisText x={x} y={y} dy={16} textAnchor="middle">
                {payload.value}
              </StyledAxisText>
            </g>
          )}
        />
        <YAxis
          dataKey={config.yAxisKey as string | number | undefined}
          tick={({ x, y, stroke, payload }) => (
            <g>
              <StyledAxisText x={x} y={y} dy={12} textAnchor="end">
                {payload.value}
              </StyledAxisText>
            </g>
          )}
        />
        <Tooltip
          content={<ChartTooltip />}
          wrapperStyle={{
            border: 'none',
            outline: 'none',
          }}
        />
        {Object.keys(config.lines).map((lineKey) => (
          <Line
            key={lineKey}
            isAnimationActive={false}
            type="monotone"
            dataKey={lineKey}
            stroke={config.lines[lineKey as keyof TData]?.color}
            strokeWidth={2}
            //i would prefer to pass null but the lib doesn't accept it
            /* eslint-disable-next-line react/jsx-no-useless-fragment */
            dot={<></>}
          />
        ))}
      </RcLineChart>
    </ResponsiveContainer>
  );
};
