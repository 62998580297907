import { zodResolver } from '@hookform/resolvers/zod';
import {
  Button,
  CircularProgress,
  FormHelperText,
  Link,
  Stack,
  Typography,
} from '@mui/material';
import {
  color,
  FormField,
  SdPrivateApi,
} from '@stardust-monorepo/web-sdk-apps-shared';
import { Auth, Hub } from 'aws-amplify';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { z } from 'zod';

import { userState } from '../../../state/user-state';
import { loginFormMaxWidth } from '../LoginForm';

const registrationCodeControl = 'registrationCode';

const registrationCodeFormSchema = z
  .object({
    [registrationCodeControl]: z.string().min(1, 'Login code is required'),
  })
  .required();

export const ConfirmAccount = ({ username }: { username: string }) => {
  const [codeResent, setCodeResent] = useState<boolean>(false);
  const [codeResentError, setCodeResentError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const setUser = useSetRecoilState(userState);
  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      [registrationCodeControl]: '',
    },
    resolver: zodResolver(registrationCodeFormSchema),
  });

  const confirmRegistration = async (code: string) => {
    setLoading(true);
    try {
      Hub.listen('auth', ({ payload }) => {
        const { event } = payload;
        if (event === 'autoSignIn') {
          const user = payload.data;
          SdPrivateApi.getUnacceptedPolicies(user.attributes.sub)
            .then((unacceptedPolicies) => {
              SdPrivateApi.createPolicyHistory(
                user.attributes.sub,
                unacceptedPolicies.map((policy) => policy.id)
              )
                .then(() => {
                  setUser({
                    user,
                    unacceptedPolicies: [],
                    loading: false,
                    error: null,
                  });
                  navigate('/');
                })
                .catch((err) => {
                  setUser({
                    user,
                    unacceptedPolicies,
                    loading: false,
                    error: null,
                  });
                  navigate('/');
                });
            })
            .catch((err) => {
              setUser({
                user,
                unacceptedPolicies: null,
                loading: false,
                error: null,
              });
              navigate('/');
            });
        } else if (event === 'autoSignIn_failure') {
          navigate('../');
        }
      });
      await Auth.confirmSignUp(username, code);
      /* eslint-disable @typescript-eslint/no-explicit-any */
    } catch (error: any) {
      setLoading(false);
      //TODO proper error types?
    }
  };

  const resendCode = async () => {
    await Auth.resendSignUp(username)
      .then(() => {
        setCodeResent(true);
        setCodeResentError(false);
      })
      .catch((err) => {
        setCodeResentError(true);
      });
  };

  return (
    <Stack
      sx={(theme) => ({
        background: theme.palette.background.white,
        border: `1px solid ${color.neutral[100]}`,
        borderRadius: 3,
        padding: 4,
        width: loginFormMaxWidth,
      })}
      component="form"
      onSubmit={handleSubmit((data) => {
        confirmRegistration(data[registrationCodeControl]);
      })}
    >
      <Stack>
        <Typography variant={'h4'}>Enter Confirmation Code</Typography>
        <Typography
          variant={'body1'}
          marginBottom={3}
          marginTop={1}
          sx={(theme) => ({
            color: theme.palette.text.secondary,
          })}
        >
          Please check your email and enter the code provided below.
        </Typography>
        <FormField
          control={control}
          label="Code"
          autoComplete="one-time-code"
          formControlName={registrationCodeControl}
          hasError={!!errors[registrationCodeControl]}
          renderHelpText={() =>
            errors[registrationCodeControl] && (
              <FormHelperText>
                {errors[registrationCodeControl]?.message}
              </FormHelperText>
            )
          }
        />
        <Button
          type={'submit'}
          variant="contained"
          size="large"
          sx={{
            marginTop: 3,
            marginBottom: 4,
          }}
        >
          {loading ? (
            <CircularProgress size="18px" color="secondary" />
          ) : (
            'Complete Registration'
          )}
        </Button>
        <Typography
          variant={'helperText'}
          sx={(theme) => ({
            color: theme.palette.text.secondary,
          })}
        >
          Did not receive a code?
          <Link
            sx={{
              cursor: 'pointer',
              paddingLeft: 0.5,
            }}
            onClick={() => resendCode()}
          >
            Resend code
          </Link>
        </Typography>
        {codeResent && (
          <Typography
            variant={'helperText'}
            sx={(theme) => ({
              color: theme.palette.text.success,
              mt: 0.5,
            })}
          >
            Code sent successfully.
          </Typography>
        )}
        {codeResentError && (
          <Typography
            variant={'helperText'}
            sx={(theme) => ({
              color: theme.palette.text.error,
            })}
          >
            An error occurred while sending the confirmation code.
          </Typography>
        )}
      </Stack>
    </Stack>
  );
};
