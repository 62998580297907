import { Box } from '@mui/material';
import { PropsWithChildren } from 'react';

import { SelectButton } from './select-button';

export const TextSelectButton = ({
  checked,
  onClick,
  disabled,
  children,
}: PropsWithChildren<{
  checked: boolean;
  onClick: () => void;
  disabled?: boolean;
}>) => {
  return (
    <SelectButton
      checked={checked}
      onClick={onClick}
      height={56}
      disabled={disabled}
    >
      <Box
        sx={{
          fontFamily: 'DM Mono, monospace',
          fontSize: '16px',
        }}
      >
        {children}
      </Box>
    </SelectButton>
  );
};
