import { Template } from '@stardust-monorepo/types/marketplace';
import { Purchasable } from '@stardust-monorepo/types/sd-private';
import {
  ActiveToggle,
  SdPrivateApi,
  track,
  useHandleError,
  useToast,
} from '@stardust-monorepo/web-sdk-apps-shared';
import { useState } from 'react';

import { DeactivateWarningModal } from './DeactivateWarningModal';

export interface TemplateToggleProps {
  template: Template;
}

export const TemplateToggle = ({ template }: TemplateToggleProps) => {
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [purchasables, setPurchasables] = useState<Purchasable[]>([]);
  const [isToggling, setIsToggling] = useState(false);
  const toaster = useToast();
  const handleError = useHandleError();

  const toggleTemplateActive = SdPrivateApi.useToggleTemplateActive();

  const doToggle = () =>
    toggleTemplateActive.mutateAsync({
      gameId: Number(template.gameId),
      templateId: template.id,
    });

  const onToggleError = (checked: boolean, e: Error) => {
    handleError(
      e,
      `Unable to  ${checked ? 'activate' : 'deactivate'} ${template.name}`,
      'Template Active Toggle',
      {
        gameId: template.gameId,
        templateId: template.id,
        checked,
      }
    );
  };

  const onToggleSuccess = (checked: boolean) => {
    track('Template Active Toggle', 'Success', {
      gameId: template.gameId,
      templateId: template.id,
      checked,
    });
    toaster(`${template.name} is now ${checked ? 'active' : 'inactive'}`);
  };

  const onToggle = async (checked: boolean) => {
    track('Template Active Toggle', 'Clicked', {
      gameId: template.gameId,
      templateId: template.id,
      checked,
    });
    try {
      if (!checked) {
        const purchasables = await SdPrivateApi.getPurchasables(
          Number(template.gameId),
          template.id
        );
        if (purchasables.length > 0) {
          track('Template Active Toggle Warning Modal', 'Viewed', {
            gameId: template.gameId,
            templateId: template.id,
          });
          setShowWarningModal(true);
          setPurchasables(purchasables);
          return Promise.reject();
        }
      }
      await doToggle();
      onToggleSuccess(checked);
    } catch (e) {
      onToggleError(checked, e as Error);
    }
    return Promise.resolve();
  };

  const onConfirmWarningModal = async () => {
    track('Template Active Toggle Warning Confirm', 'Clicked', {
      gameId: template.gameId,
      templateId: template.id,
    });
    try {
      setIsToggling(true);
      await doToggle();
      onToggleSuccess(false);
    } catch (e) {
      onToggleError(false, e as Error);
    }
    setIsToggling(false);
  };

  return (
    <>
      {showWarningModal ? (
        <DeactivateWarningModal
          isLoading={isToggling}
          onConfirm={onConfirmWarningModal}
          open={showWarningModal}
          onClose={() => setShowWarningModal(false)}
          purchasables={purchasables}
        />
      ) : null}
      <ActiveToggle
        checked={template.activeListing}
        onToggle={onToggle}
        onToggleError={() => null}
        onToggleSuccess={() => null}
      />
    </>
  );
};
