import { Badge, Box, styled, Typography } from '@mui/material';
import {
  PurchasableItem,
  Purchasable,
} from '@stardust-monorepo/types/marketplace';

import { color } from '../../theming';
import { PurchasableItemCard } from './purchasable-item-card';

const StyledItemImage = styled('img')({
  height: '4.875rem',
  width: '4.875rem',
  borderRadius: '0.375rem',
});

interface PurchasableItemBadgeProps {
  item: PurchasableItem;
  purchasable: Purchasable;
}

export const PurchasableItemBadge = ({ item }: PurchasableItemBadgeProps) => {
  return (
    <PurchasableItemCard>
      <StyledItemImage src={item.image} alt={item.template.name} />
      <Badge badgeContent={`x${item.amount}`} color="primary" />
      <Box
        sx={{
          mx: '0.5rem',
          my: '0.125rem',
        }}
      >
        <Typography
          variant="tooltip"
          data-testid={'purchasable-item-badge-name'}
          sx={{
            color: color.neutral.white,
            display: 'block',
            width: 60,
            mt: 0.5,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          {item.template.name}
        </Typography>
      </Box>
    </PurchasableItemCard>
  );
};
