import { Button, Typography } from '@mui/material';
import { CoinVertical } from '@phosphor-icons/react';
import {
  BaseEmptyView,
  ContainedTable,
  ErrorAlert,
  PageTitle,
  SdPrivateApi,
  track,
  useTrack,
} from '@stardust-monorepo/web-sdk-apps-shared';
import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import sword from '../../assets/sword.png';
import { MintTokenModal } from '../components/mint-token';

const headings = [
  { label: 'Owner ID' },
  { label: 'Token ID' },
  { label: 'Amount' },
];

export const TemplatePage = () => {
  const { gameId, templateId } = useParams<{
    gameId: string;
    templateId: string;
  }>();
  const [mintTokenOpen, setMintTokenOpen] = useState(false);

  useTrack('Template', { gameId, templateId });

  const {
    data: template,
    error: templateError,
    isLoading: templateLoading,
  } = SdPrivateApi.useTemplate({
    gameId: Number(gameId),
    templateId: Number(templateId),
  });

  const {
    data: tokenMints,
    error: tokenMintsError,
    isLoading: tokenMintsLoading,
  } = SdPrivateApi.useTokenMints({
    gameId: Number(gameId),
    templateId: Number(templateId),
  });

  const rows = (tokenMints || []).map((mint) => {
    return [
      <Link to={`/games/${gameId}/players/${mint.playerId}`}>
        <Typography variant="subtitle2" sx={{ color: 'primary.main' }}>
          {mint.playerId}
        </Typography>
      </Link>,
      mint.tokenId,
      mint.amount,
    ];
  });

  return (
    <>
      <ErrorAlert
        error={(templateError as Error) || (tokenMintsError as Error)}
      />
      {mintTokenOpen ? (
        <MintTokenModal
          gameId={Number(gameId)}
          open={mintTokenOpen}
          targetTemplate={template}
          onClose={() => setMintTokenOpen(false)}
        />
      ) : null}
      <PageTitle title={`Template ${template?.name || ''}`}>
        <Button
          variant="contained"
          startIcon={<CoinVertical weight="duotone" />}
          onClick={() => {
            track('Template Mint Token', 'Clicked', {
              gameId,
              templateId,
            });
            setMintTokenOpen(true);
          }}
        >
          Mint Token
        </Button>
      </PageTitle>
      <ContainedTable
        count={rows.length}
        page={0}
        type="Token Mints"
        headings={headings}
        rows={rows}
        isLoading={templateLoading || tokenMintsLoading}
        emptyScreen={
          <BaseEmptyView
            image={sword}
            title="Add an item to your player's inventory"
            body="Mint tokens to players from templates you've created."
            buttonText="Mint Token"
            onClick={() => {
              track('Empty Template Mint Token', 'Clicked', {
                gameId,
                templateId,
              });
              setMintTokenOpen(true);
            }}
          />
        }
      />
    </>
  );
};
