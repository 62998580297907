import { Button, CircularProgress, Typography } from '@mui/material';
import { ArrowUpRight } from '@phosphor-icons/react';
import { PurchasableSaleSummary } from '@stardust-monorepo/types/sd-private';
import {
  BaseEmptyView,
  ContainedTable,
  ErrorAlert,
  PAGE_SIZE,
  PageTitle,
  SdPrivateApi,
  track,
  useToast,
  useTrack,
} from '@stardust-monorepo/web-sdk-apps-shared';
import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import paymentsIcon from '../../assets/payments-icon.png';
import {
  CreatePurchasableModal,
  launchOnboarding,
  PaymentsOnboarding,
} from '../components/game-store';
import { useGuardOnPaymentsAllowed } from '../hooks/useGuardOnPaymentsAllowed';

const headings = [
  { label: 'Purchasable ID' },
  { label: 'Purchasable ' },
  { label: 'Amount' },
  { label: 'Price' },
  { label: 'Player ID' },
  { label: 'Date' },
];

export const PaymentsPage = () => {
  const { gameId } = useParams<{
    gameId: string;
  }>();
  useGuardOnPaymentsAllowed(Number(gameId));
  const [totalSold, setTotalSold] = useState<number | undefined>(undefined);
  const [page, setPage] = useState(0);
  const [filter, setFilter] = useState('');
  const [selectedSaleSummary, setSelectedSaleSummary] = useState<
    PurchasableSaleSummary | undefined
  >(undefined);
  const [gettingLink, setGettingLink] = useState(false);
  const [createPurchasableOpen, setCreatePurchasableOpen] = useState(false);
  const toaster = useToast();

  useTrack('Payments', { gameId });

  const { data: paymentProvider, isLoading: paymentProviderLoading } =
    SdPrivateApi.usePaymentProvider(
      {
        gameId: Number(gameId),
        provider: 'STRIPE',
      },
      !!gameId
    );

  const {
    data: purchasableSalesSummary,
    error: purchasableSalesSummaryError,
    isLoading: purchasableSalesSummaryLoading,
  } = SdPrivateApi.usePurchasableSaleHistorySummary(
    {
      gameId: Number(gameId),
    },
    !!gameId && paymentProvider?.status === 'ACTIVE'
  );

  const {
    data: purchasableSales,
    error: purchasableSalesError,
    isLoading: purchasableSalesLoading,
  } = SdPrivateApi.usePurchasableSaleHistory(
    {
      gameId: Number(gameId),
      start: page * PAGE_SIZE,
      limit: PAGE_SIZE,
      purchasableId: selectedSaleSummary?.purchasable.id,
    },
    typeof totalSold === 'number' && paymentProvider?.status === 'ACTIVE'
  );

  useEffect(() => {
    if (Array.isArray(purchasableSalesSummary)) {
      const numSold = purchasableSalesSummary.reduce((numSold, ps) => {
        numSold += Number(ps.totalSold);
        return numSold;
      }, 0);
      setTotalSold(numSold);
    }
  }, [purchasableSalesSummary]);

  useEffect(() => {
    if (Array.isArray(purchasableSalesSummary)) {
      setSelectedSaleSummary(
        purchasableSalesSummary.find(
          (pss) => pss.purchasable.id === Number(filter)
        )
      );
    }
  }, [filter, purchasableSalesSummary]);

  const rows = (purchasableSales || []).map((ps) => {
    return [
      ps.purchasable.id,
      ps.purchasable.name,
      ps.amount,
      `$${ps.price}`,
      <Link to={`/games/${gameId}/players/${ps.playerId}`}>
        <Typography variant="subtitle2" sx={{ color: 'primary.main' }}>
          {ps.playerId}
        </Typography>
      </Link>,
      format(new Date(ps.soldDate), 'MMM. dd, h:mma'),
    ];
  });

  return (
    <>
      {createPurchasableOpen ? (
        <CreatePurchasableModal
          gameId={Number(gameId)}
          open={createPurchasableOpen}
          onClose={() => setCreatePurchasableOpen(false)}
        />
      ) : null}
      <ErrorAlert
        error={
          (purchasableSalesSummaryError as Error) ||
          (purchasableSalesError as Error)
        }
      />
      <PageTitle
        title="Payments"
        count={
          selectedSaleSummary
            ? Number(selectedSaleSummary.totalSales)
            : totalSold
        }
      >
        {paymentProvider?.status === 'ACTIVE' ? (
          <Button
            variant="outlined"
            color="secondary"
            endIcon={<ArrowUpRight />}
            onClick={() => {
              track('Purchasables Payment Settings', 'Clicked', { gameId });
              launchOnboarding(Number(gameId), setGettingLink, toaster);
            }}
          >
            {gettingLink ? (
              <CircularProgress size="18px" color="primary" />
            ) : (
              'Payment Settings'
            )}
          </Button>
        ) : null}
      </PageTitle>
      <ContainedTable
        count={
          selectedSaleSummary
            ? Number(selectedSaleSummary.totalSales)
            : totalSold
        }
        page={page}
        setPage={setPage}
        placeholder="Filter by Purchasable ID"
        filter={filter}
        setFilter={setFilter}
        headings={headings}
        rows={rows}
        isLoading={
          ((purchasableSalesSummaryLoading || purchasableSalesLoading) &&
            paymentProvider?.status === 'ACTIVE') ||
          paymentProviderLoading
        }
        emptyScreen={
          paymentProvider?.status !== 'ACTIVE' ? (
            <PaymentsOnboarding
              gameId={Number(gameId)}
              isLoading={gettingLink}
              setIsLoading={setGettingLink}
              name="Payments"
            />
          ) : (
            <BaseEmptyView
              image={paymentsIcon}
              title="This game has no payments history."
              body="Create a purchasable and test it to see your payment transaction history here."
              buttonText="Create Purchasable"
              onClick={() => {
                track('Payments Create New Purchasable', 'Clicked', { gameId });
                setCreatePurchasableOpen(true);
              }}
            />
          )
        }
      />
    </>
  );
};
