import * as Sentry from '@sentry/react';

export const useClipboard = (onCopy?: () => void, initialValue?: string) => {
  return (e?: React.MouseEvent<unknown, unknown>, passedValue?: string) => {
    try {
      e?.stopPropagation();
      navigator.clipboard
        .writeText(
          initialValue !== undefined ? initialValue : passedValue ?? ''
        )
        .then(() => onCopy?.());
    } catch (e) {
      Sentry?.captureException(e);
    }
  };
};
