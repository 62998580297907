import { StyledComponent } from '@emotion/styled';
import {
  Dialog,
  DialogActions,
  DialogActionsProps,
  DialogContent,
  DialogContentProps,
  DialogTitle,
  DialogTitleProps,
  Stack,
  styled,
} from '@mui/material';
import { DialogProps } from '@mui/material/Dialog/Dialog';
import { TransitionProps } from '@mui/material/transitions';
import { forwardRef, ReactElement, Ref } from 'react';

import { SoftSlide } from '../transitions';

const maxContentWidth = '1200px';
const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: ReactElement;
  },
  ref: Ref<unknown>
) {
  return <SoftSlide ref={ref} {...props} />;
});

export const HeavyDialog: StyledComponent<DialogProps> = styled(
  (props: DialogProps) => (
    <Dialog {...props} fullScreen={true} TransitionComponent={Transition} />
  )
)(({ theme }) => ({
  '.MuiDialog-paper': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  '.MuiDialog-container': {
    display: 'flex',
  },
  '.MuiDialog-paperFullScreen': {
    borderRadius: `${theme.shape.borderRadius * 4}px ${
      theme.shape.borderRadius * 4
    }px 0 0`,
    marginTop: `${theme.spacing(4)} !important`,
    height: 'calc(100% - 32px)',
  },
  '& .MuiDialogTitle-root': {
    maxWidth: maxContentWidth,
    width: '100%',
    display: 'block',
    padding: `0 ${theme.spacing(10)}`,
  },
  '& .MuiDialogContent-root': {
    width: '100%',
    scrollbarGutter: 'stable both-edges',
    padding: 0,
  },
  '& .MuiDialogActions-root': {
    borderTop: `1px solid ${theme.palette.divider}`,
    marginTop: 0,
    justifyContent: 'center',
    width: '100%',
    padding: 0,
  },
}));

export const HeavyDialogTitle = (
  props: DialogTitleProps & { paddingY?: string | number }
) => {
  const { children, paddingY, ...passThroughProps } = props;
  return (
    <Stack
      direction={'row'}
      gap={2}
      sx={(theme) => ({
        justifyContent: 'center',
        width: '100%',
        paddingY: paddingY !== undefined ? paddingY : `${theme.spacing(2.5)}`,
        overflowY: 'hidden', //the scrollbarGutter doesn't apply unless this is set
        scrollbarGutter: 'stable both-edges',
        borderBottom: `1px solid ${theme.palette.divider}`,
        flex: '0 0 auto',
      })}
    >
      <DialogTitle component={'div'} {...passThroughProps}>
        {children}
      </DialogTitle>
    </Stack>
  );
};

export const HeavyDialogContent = (props: DialogContentProps) => {
  const { children, ...passThroughProps } = props;
  return (
    <DialogContent {...passThroughProps}>
      <Stack
        direction={'row'}
        gap={2}
        sx={(theme) => ({
          maxWidth: maxContentWidth,
          padding: `0 ${theme.spacing(10)} ${theme.spacing(6)}`,
          margin: 'auto',
        })}
      >
        {children}
      </Stack>
    </DialogContent>
  );
};

export const HeavyDialogActions = (props: DialogActionsProps) => {
  const { children, ...passThroughProps } = props;

  return (
    <DialogActions {...passThroughProps}>
      <Stack
        direction={'row'}
        gap={2}
        sx={(theme) => ({
          maxWidth: maxContentWidth,
          padding: `${theme.spacing(3)} ${theme.spacing(10)}`,
          justifyContent: 'space-between',
          flexGrow: 1,
        })}
      >
        {children}
      </Stack>
    </DialogActions>
  );
};
