import { Purchasable } from '@stardust-monorepo/types/sd-private';
import {
  ActiveToggle,
  SdPrivateApi,
  track,
  useHandleError,
  useToast,
} from '@stardust-monorepo/web-sdk-apps-shared';

interface PurchasableToggleProps {
  gameId: number;
  purchasable: Purchasable;
}

export const PurchasableToggle = ({
  gameId,
  purchasable,
}: PurchasableToggleProps) => {
  const togglePurchasableActive = SdPrivateApi.useTogglePurchasableActive();
  const toaster = useToast();
  const handleError = useHandleError();

  return (
    <ActiveToggle
      checked={Boolean(purchasable.activeListing)}
      onToggle={(checked: boolean) => {
        track('Purchasable Active Toggle', 'Clicked', {
          gameId,
          purchasableId: purchasable.id,
          checked,
        });
        return togglePurchasableActive.mutateAsync({
          gameId: gameId,
          purchasableId: purchasable.id,
        });
      }}
      onToggleError={(checked, e) => {
        handleError(
          e,
          `Unable to change ${purchasable.name} to ${
            checked ? 'live' : 'offline'
          }`,
          'Purchasable Active Toggle',
          {
            gameId,
            purchasableId: purchasable.id,
            checked,
          }
        );
      }}
      onToggleSuccess={(checked) => {
        track('Purchasable Active Toggle', 'Success', {
          gameId,
          purchasableId: purchasable.id,
          checked,
        });
        toaster(`${purchasable.name} is now ${checked ? 'live' : 'offline'}`);
      }}
    />
  );
};
