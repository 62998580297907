import { Box, Card, Typography } from '@mui/material';
import { CurrencyCircleDollar, Receipt, Trophy } from '@phosphor-icons/react';
import {
  color,
  SdPrivateApi,
  SmallIconStat,
} from '@stardust-monorepo/web-sdk-apps-shared';
import { useEffect, useState } from 'react';

export interface StatRowProps {
  gameId?: string;
}

export const StatRow = ({ gameId }: StatRowProps) => {
  const [bestSelling, setBestSelling] = useState('--');
  const [totalRevenue, setTotalRevenue] = useState(0);
  const [totalSold, setTotalSold] = useState(0);

  const { data: purchasableSales } =
    SdPrivateApi.usePurchasableSaleHistorySummary(
      {
        gameId: Number(gameId),
      },
      !!gameId
    );

  useEffect(() => {
    if (Array.isArray(purchasableSales)) {
      let max = 0;
      let revenue = 0;
      let numSold = 0;
      const bestSelling = purchasableSales.reduce((acc, ps) => {
        const sold = Number(ps.totalSold);
        revenue += Number(ps.totalPrice);
        numSold += sold;
        if (sold > max) {
          max = sold;
          return ps.purchasable.name;
        }
        return acc;
      }, 'N/A');
      setBestSelling(bestSelling);
      setTotalRevenue(revenue);
      setTotalSold(numSold);
    }
  }, [purchasableSales]);

  return (
    <Card
      elevation={0}
      variant="outlined"
      sx={{
        padding: 2,
        borderRadius: 3,
        mb: 1,
      }}
    >
      <Typography variant="subtitle2">Stats</Typography>
      <Box
        sx={{
          display: 'flex',
          gap: 1,
          mt: 2,
        }}
      >
        <SmallIconStat
          Icon={Trophy}
          iconColor={color.accent.gold[900]}
          iconBackgroundColor={color.accent.gold[50]}
          statValue={bestSelling}
          title="Best Selling"
        />
        <SmallIconStat
          Icon={CurrencyCircleDollar}
          iconColor={color.accent.green[900]}
          iconBackgroundColor={color.accent.green[50]}
          valueAdornment="$"
          statValue={totalRevenue}
          title="Revenue"
        />
        <SmallIconStat
          Icon={Receipt}
          iconColor={color.accent.blue[900]}
          iconBackgroundColor={color.accent.blue[50]}
          statValue={totalSold}
          title="Sales"
        />
      </Box>
    </Card>
  );
};
