import { Add } from '@mui/icons-material';
import { Button, Stack } from '@mui/material';
import { Coins, Package, Star } from '@phosphor-icons/react';
import {
  FungibleAsset,
  FungibleToken,
  NonFungibleToken,
} from '@stardust-monorepo/types/sd-core';
import {
  BaseEmptyView,
  ErrorAlert,
  PAGE_SIZE,
  PageTitle,
  Spinner,
  track,
  useCollections,
  useTrack,
} from '@stardust-monorepo/web-sdk-apps-shared';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import sword from '../../assets/sword.png';
import { CollectionTable } from '../components/collections/CollectionTable';
import { CreateCollectionModal } from '../components/collections/CreateCollectionModal';

export const CollectionsPage = () => {
  const { gameId } = useParams<{
    gameId: string;
  }>();
  const [createNewCollectionOpen, setCreateNewCollectionOpen] = useState(false);
  const [faPage, setFaPage] = React.useState(0);
  const [nftPage, setNftPage] = React.useState(0);
  const [ftPage, setFtPage] = React.useState(0);

  useTrack('Collections', { gameId });

  const {
    data: faCollections,
    error: faCollectionsErrors,
    isLoading: faCollectionsLoading,
  } = useCollections({
    gameId: Number(gameId),
    type: FungibleAsset,
    start: faPage * PAGE_SIZE,
    limit: PAGE_SIZE,
  });

  const {
    data: nftCollections,
    error: nftCollectionsErrors,
    isLoading: nftCollectionsLoading,
  } = useCollections({
    gameId: Number(gameId),
    type: NonFungibleToken,
    start: nftPage * PAGE_SIZE,
    limit: PAGE_SIZE,
  });

  const {
    data: ftCollections,
    error: ftCollectionsErrors,
    isLoading: ftCollectionsLoading,
  } = useCollections({
    gameId: Number(gameId),
    type: FungibleToken,
    start: ftPage * PAGE_SIZE,
    limit: PAGE_SIZE,
  });

  return (
    <>
      <CreateCollectionModal
        gameId={Number(gameId)}
        open={createNewCollectionOpen}
        onClose={() => setCreateNewCollectionOpen(false)}
        sourcePage="Collections"
      />
      <ErrorAlert
        error={
          (faCollectionsErrors as Error) ||
          (nftCollectionsErrors as Error) ||
          (ftCollectionsErrors as Error)
        }
      />
      <PageTitle title="Collections">
        <Stack direction="row" spacing={1}>
          <Button
            variant="contained"
            startIcon={<Add />}
            onClick={() => {
              track('Collections New Collection', 'Clicked', {
                gameId,
              });
              setCreateNewCollectionOpen(true);
            }}
          >
            New Collection
          </Button>
        </Stack>
      </PageTitle>
      {faCollectionsLoading || nftCollectionsLoading || ftCollectionsLoading ? (
        <Spinner grow={true} />
      ) : faCollections &&
        faCollections.results.length === 0 &&
        nftCollections &&
        nftCollections.results.length === 0 &&
        ftCollections &&
        ftCollections.results.length === 0 ? (
        <BaseEmptyView
          tableStyle={false}
          image={sword}
          title="Add your first Collection"
          body="Use this page to create and manage collections for your game."
          buttonText="Create Collection"
          onClick={() => {
            track('Empty Collection Create New', 'Clicked', { gameId });
            setCreateNewCollectionOpen(true);
          }}
        />
      ) : (
        <Stack spacing={1}>
          {faCollections && (
            <CollectionTable
              collectionResults={faCollections}
              Icon={Package}
              label="Game Collections"
              page={faPage}
              setPage={setFaPage}
            />
          )}
          {nftCollections && (
            <CollectionTable
              collectionResults={nftCollections}
              Icon={Star}
              label="Standalone Asset Collections"
              page={nftPage}
              setPage={setNftPage}
            />
          )}
          {ftCollections && (
            <CollectionTable
              collectionResults={ftCollections}
              Icon={Coins}
              label="Standalone Currency Collections"
              page={ftPage}
              setPage={setFtPage}
            />
          )}
        </Stack>
      )}
    </>
  );
};
