import { Box, Stack, Typography } from '@mui/material';
import React, { ReactNode } from 'react';

export interface PageTitleProps {
  children?: ReactNode;
  info?: string;
  title: string;
  count?: number;
}

export const PageTitle = ({ children, info, title, count }: PageTitleProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        py: 2,
        px: '2px',
      }}
    >
      <Stack spacing={0.5}>
        <Stack direction="row" spacing={0.5} sx={{ alignItems: 'baseline' }}>
          <Typography variant="h6">{title}</Typography>
          {typeof count === 'number' && (
            <Typography variant="subtitle1" color="text.secondary">
              {count < 10 ? `0${count}` : count.toLocaleString()}
            </Typography>
          )}
        </Stack>
        {info ? (
          <Typography variant="body2" color="text.secondary">
            {info}
          </Typography>
        ) : null}
      </Stack>
      <Box>{children}</Box>
    </Box>
  );
};
