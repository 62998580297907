import { AlertColor } from '@mui/material';
import * as Sentry from '@sentry/react';
import { UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { ZodTypeAny } from 'zod';

import { getAuthToken } from '../auth';
import { useToast } from '../components';
import { track, TrackableProps } from '../utils';

export function getURLSearchParamsForArgs(args: object | undefined = {}) {
  const u = new URLSearchParams();
  for (const [key, value] of Object.entries(args)) {
    if (value) {
      u.append(key, Array.isArray(value) ? value.join(',') : value);
    }
  }
  return u;
}

/**
 * For use when you know that this request never needs to be authenticated, or can't be authenticated (such as when the server is making this request for prerendering).
 * Also doesn't toast the error message.
 * @param path
 * @param options
 * @param baseURL
 * @returns
 */
export async function unauthenticatedRequest(
  path: string,
  options: AxiosRequestConfig = {},
  baseURL: string
) {
  const axiosConfig = {
    baseURL,
    url: path,
    ...options,
  };
  try {
    const result = await axios.request(axiosConfig);
    return result.data;
  } catch (error) {
    const data = (error as AxiosError).response?.data as
      | { message: string; status: number }
      | undefined;
    throw new Error(data?.message || 'Unspecified API error');
  }
}

export async function authenticatedRequest(
  path: string,
  options: AxiosRequestConfig = {},
  baseURL: string
) {
  const axiosConfig = {
    baseURL,
    url: path,
    ...options,
  };

  let jwtToken: string | null = null;
  try {
    jwtToken = await getAuthToken();
  } catch (error) {
    // do nothing
  }
  if (jwtToken) {
    axiosConfig.headers = {
      ...(options.headers || {}),
      Authorization: jwtToken,
    };
  }

  try {
    const result = await axios.request(axiosConfig);
    return result.data;
  } catch (error) {
    const data = (error as AxiosError).response?.data as
      | { message: string; status: number }
      | undefined;
    throw new Error(data?.message || 'Unspecified API error');
  }
}

export async function validatedRequestWithAuth<S extends ZodTypeAny>(
  schema: S,
  path: string,
  baseURL: string,
  options?: AxiosRequestConfig
) {
  return makeRequestAndValidate(
    schema,
    path,
    options,
    baseURL,
    authenticatedRequest
  );
}

export async function validatedRequest<S extends ZodTypeAny>(
  schema: S,
  path: string,
  baseURL: string,
  options?: AxiosRequestConfig
) {
  return makeRequestAndValidate(
    schema,
    path,
    options,
    baseURL,
    unauthenticatedRequest
  );
}

async function makeRequestAndValidate<S extends ZodTypeAny>(
  schema: S,
  path: string,
  options: AxiosRequestConfig = {},
  baseURL: string,
  requestFn: (
    path: string,
    options: AxiosRequestConfig,
    baseUrl: string
  ) => unknown
) {
  const data = await requestFn(path, options, baseURL);
  const parseResult = await schema.safeParseAsync(data);
  if (parseResult.success) {
    return parseResult.data;
  } else {
    const failureMessage = `Error parsing schema: ${
      schema.description || '(no schema description defined)'
    } \n Path: ${path} \n ${parseResult.error.message}`;
    // eslint-disable-next-line no-console
    console.error(failureMessage);
    if (Sentry.getCurrentHub().getClient()) {
      Sentry.captureException(new Error(failureMessage));
    }
    return data;
  }
}

export function isDoneLoading<T>(queryResults: UseQueryResult<T>[]) {
  return queryResults.every((qr) => !qr.isLoading && qr.fetchStatus === 'idle');
}

export function extractData<T>(queryResults: UseQueryResult<T>[]) {
  return queryResults.reduce<T[]>((acc, qr) => {
    if (qr.data) {
      acc.push(qr.data);
    }
    return acc;
  }, []);
}

export const disableCachingHeaders = {
  'Cache-Control': 'no-store, no-cache, max-age=0, must-revalidate',
  Pragma: 'no-cache',
};

// Unpack the nested JSON string if it's there.
const getErrorMessage = (err: Error | unknown, defaultMsg: string) => {
  if (err instanceof Error && err.message) {
    try {
      const parsedError = JSON.parse(err.message);
      return `${defaultMsg}: ${parsedError.message}`;
    } catch (e) {
      return `${defaultMsg}: ${err.message}`;
    }
  } else {
    return defaultMsg;
  }
};

export const handleError = (
  toaster: (msg: string, severity?: AlertColor) => void,
  err: Error | unknown,
  defaultMsg: string,
  trackingEvent: string,
  trackingData: TrackableProps = {}
) => {
  track(trackingEvent, 'Failure', trackingData);
  toaster(getErrorMessage(err, defaultMsg), 'error');
};

export const useHandleError = () => {
  const toaster = useToast();
  return handleError.bind(null, toaster);
};
