import { paginatedResultsSchema } from '@stardust-monorepo/types/common';
import { z } from 'zod';

import { HasPublicMetadata } from './has-public-metadata';

export const FungibleToken = 'FT' as const;
export const NonFungibleToken = 'NFT' as const;
export const FungibleAsset = 'FA' as const;

export type CollectionType =
  | typeof FungibleToken
  | typeof NonFungibleToken
  | typeof FungibleAsset;

const collectionType = [
  FungibleToken,
  NonFungibleToken,
  FungibleAsset,
] as const;

export const CollectionSchema = z
  .object({
    id: z.string().uuid(),
    gameId: z.number(),
    name: z.string(),
    symbol: z.optional(z.nullable(z.string())), //TODO preprocess to check existence if type is not FA
    type: z.enum(collectionType),
    description: z.string(),
    image: z.string().url().or(z.literal('')),
    blockchainId: z.nullable(z.number()),
    address: z.nullable(z.string()),
    totalSupply: z.optional(z.nullable(z.string())), //TODO preprocess to check existence if type is not FA
    createdAt: z.number(),
    updatedAt: z.number(),
  })
  .merge(HasPublicMetadata)
  .describe('CollectionSchema');
export type Collection = z.infer<typeof CollectionSchema>;

export const CollectionResultsSchema = paginatedResultsSchema(CollectionSchema);
export type CollectionResults = z.infer<typeof CollectionResultsSchema>;
