import { Template, TemplateSchema } from '@stardust-monorepo/types/marketplace';
import {
  Count,
  CountSchema,
  FeeEntityType,
  Properties,
} from '@stardust-monorepo/types/sd-private';
import { z } from 'zod';

import { authenticatedRequest, validatedRequestWithAuth } from './api-utils';
import { createAsset, persistFile } from './asset-api';
import { mutateFee } from './fee-api';

export async function getTemplates(
  gameId: number,
  start?: number,
  limit?: number,
  filter?: string
): Promise<Template[]> {
  return await validatedRequestWithAuth(
    TemplateSchema.array(),
    '/template/get-all',
    {
      params: {
        gameId,
        start,
        limit,
        filter,
      },
    }
  );
}

export async function getTemplate(
  gameId: number,
  templateId: number
): Promise<Template> {
  return await validatedRequestWithAuth(TemplateSchema, 'template/get', {
    params: {
      gameId,
      templateId,
    },
  });
}

export async function getTemplateCount(
  gameId: number,
  filter?: string
): Promise<Count> {
  return validatedRequestWithAuth(CountSchema, 'template/count', {
    params: {
      gameId,
      filter,
    },
  });
}

export interface DeleteTemplateArgs {
  gameId: number;
  templateId: number;
}

export async function deleteTemplate(
  args: DeleteTemplateArgs
): Promise<Record<string, never>> {
  return authenticatedRequest('template/remove', {
    method: 'DELETE',
    params: args,
  });
}

export interface CreateTemplateArgs {
  name: string;
  description: string;
  royalty: number;
  type: string;
  cap: string;
  image: File | null;
  properties: Properties;
  gameId: number;
}

export async function createTemplate({
  name,
  description,
  royalty,
  type,
  cap,
  image,
  properties,
  gameId,
}: CreateTemplateArgs) {
  const { id: templateId } = (await validatedRequestWithAuth(
    z.object({
      id: z.number(),
    }),
    'template/create',
    {
      method: 'POST',
      data: {
        activeListing: false,
        cap,
        gameId,
        name,
        royalty: royalty.toFixed(2),
        type,
        props: {
          $mutable: {},
          immutable: {},
          mutable: {
            image: image?.name || '',
            description,
            ...properties,
          },
        },
      },
    }
  )) as { id: number };

  if (royalty) {
    await mutateFee({
      entityId: templateId,
      entityType: FeeEntityType.template,
      feePercentage: royalty * 100,
      feeType: 'template_royalty',
      gameId: gameId,
    });
  }

  if (image) {
    const { uploadUrl } = await createAsset({
      gameId,
      fileType: image.type,
      path: `templates/${templateId}`,
    });

    await persistFile(uploadUrl, image);
  }

  return templateId;
}

export interface MutateTemplateArgs {
  description: string;
  royalty?: number;
  image: File | null;
  properties: Properties;
  gameId: number;
  templateId: number;
}

export async function mutateTemplate({
  description,
  royalty,
  image,
  properties,
  gameId,
  templateId,
}: MutateTemplateArgs) {
  await authenticatedRequest('template/mutate', {
    method: 'PUT',
    data: {
      gameId,
      templateId,
      props: {
        description,
        ...properties,
      },
    },
  });

  if (royalty) {
    await mutateFee({
      entityId: templateId,
      entityType: FeeEntityType.template,
      feePercentage: royalty * 100,
      feeType: 'template_royalty',
      gameId: gameId,
    });
  }

  if (image) {
    const { uploadUrl } = await createAsset({
      gameId,
      fileType: image.type,
      path: `templates/${templateId}`,
    });

    await persistFile(uploadUrl, image);
  }

  return templateId;
}

export interface DeleteTemplatePropertyArgs {
  gameId: number;
  templateId: number;
  name: string;
}

export function deleteTemplateProperty({
  name,
  ...args
}: DeleteTemplatePropertyArgs): Promise<Record<string, never>> {
  return authenticatedRequest('template/props-remove', {
    method: 'DELETE',
    params: {
      ...args,
      props: JSON.stringify([name]),
    },
  });
}

export interface ToggleTemplateActiveArgs {
  gameId: number;
  templateId: number;
}

export async function toggleTemplateActive(
  args: ToggleTemplateActiveArgs
): Promise<Record<string, never>> {
  return authenticatedRequest('template/active-listing', {
    method: 'PUT',
    data: args,
  });
}
