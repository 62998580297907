import { z } from 'zod';

import { BlockchainName } from './blockchain';

export type Balance = {
  balance: string;
  currency: Currency;
};

type WalletAmount = {
  blockchain: BlockchainName;
  address: string;
  balances: Balance[];
};

export type Wallet = {
  currencyISOCode: string;
  amount: WalletAmount;
};

export const CurrencySchema = z
  .object({
    decimalPlaces: z.number(),
    image: z.string(),
    isoCode: z.string(),
    name: z.string(),
    symbol: z.nullable(z.string()),
  })
  .describe('CurrencySchema');
export type Currency = z.infer<typeof CurrencySchema>;
