import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SxProps,
  Typography,
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import { ReactNode } from 'react';
import { Controller } from 'react-hook-form';
import { Control } from 'react-hook-form/dist/types/form';

export interface FormSelectProps {
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any>;
  formControlName: string;
  hasError?: boolean;
  margin?: 'dense' | 'normal' | 'none';
  options: { label: string; value: string | number }[];
  placeholder?: string;
  renderHelpText?: () => ReactNode;
  sx?: SxProps<Theme>;
  startAdornment?: ReactNode;
  endAdornment?: ReactNode;
  size?: 'small' | 'medium';
  label?: string;
  disabled?: boolean;
}

export const FormSelect = ({
  control,
  formControlName,
  hasError,
  margin = 'normal',
  options,
  placeholder = '',
  renderHelpText,
  size = 'small',
  disabled,
  sx = {},
  startAdornment,
  endAdornment,
  label,
}: FormSelectProps) => {
  const helpTextNode = renderHelpText?.();
  return (
    <Controller
      control={control}
      name={formControlName}
      render={({ field: { ref, ...fieldProps } }) => (
        <FormControl sx={{ ...sx }} margin={margin} error={hasError}>
          <>
            {label && <InputLabel id="select-label">{label}</InputLabel>}
            <Select
              {...fieldProps}
              disabled={disabled}
              startAdornment={startAdornment}
              endAdornment={endAdornment}
              displayEmpty
              labelId="select-label"
              renderValue={(selected) => {
                if (!selected) {
                  return (
                    <Typography variant="inputText" color="text.hint">
                      {placeholder}
                    </Typography>
                  );
                }
                return (
                  <Typography variant="inputText">
                    {options.find((option) => option.value === selected)
                      ?.label ?? selected}
                  </Typography>
                );
              }}
              size="small"
              sx={{
                borderRadius: 2,
                height: '34px',
                backgroundColor: 'background.white',
              }}
            >
              {options.map((o) => (
                <MenuItem
                  key={o.value}
                  value={o.value}
                  title={o.label}
                  sx={{
                    //TODO customization of menu item rendering
                    maxWidth: '325px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    display: 'block',
                  }}
                >
                  {o.label}
                </MenuItem>
              ))}
            </Select>
            {helpTextNode}
          </>
        </FormControl>
      )}
    />
  );
};
