import { ExpandMore } from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Menu,
  MenuItem,
  Stack,
  styled,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { PencilSimple } from '@phosphor-icons/react';
import {
  BlockchainNames,
  BlockchainNetworkNames,
  Game,
} from '@stardust-monorepo/types/sd-private';
import {
  BlockchainIcon,
  ChartLegend,
  getGameImageUrl,
  InfoItem,
  SdPrivateApi,
  Spinner,
  track,
  useTrack,
} from '@stardust-monorepo/web-sdk-apps-shared';
import {
  format,
  startOfQuarter,
  subDays,
  subMonths,
  subQuarters,
  subWeeks,
} from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { CreateCollectionModal } from '../components/collections/CreateCollectionModal';
import { ContentModule } from '../components/content-module/ContentModule';
import { CreateEditGameModal } from '../components/game';
import { CreatePurchasableModal } from '../components/game-store';
import { GameApiCallsCard } from '../components/game/GameApiCallsCard';
import { GameOverviewStats } from '../components/game/GameOverviewStats';
import { ApiCallsChart } from '../components/game/visualizations/ApiCallsChart';
import { PlayerCountChart } from '../components/game/visualizations/PlayerCountChart';
import { CreateEditTemplateModal } from '../components/templates';
import { useApiCallCount } from '../hooks/useApiCallCount';
import { useGameStats } from '../hooks/useGameStats';
import { TimeFrame } from '../types/timeframe';
import { isPaymentsAllowedForGame } from '../util';

type CreateMenuValue = 'template' | 'purchasable' | 'collection' | undefined;
export const CreateMenu = ({
  onChange,
  game,
}: {
  onChange: (value: CreateMenuValue | undefined) => void;
  game: Game;
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (value: CreateMenuValue) => {
    setAnchorEl(null);
    onChange(value);
  };

  const { data: paymentProvider } = SdPrivateApi.usePaymentProvider(
    { gameId: game.id, provider: 'STRIPE' },
    !!game.id
  );

  const isPaymentsAllowed = isPaymentsAllowedForGame(game.id);
  const isV2 = SdPrivateApi.isV2Game(game);

  return (
    <Box>
      <Button
        variant="contained"
        endIcon={<ExpandMore />}
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        Create New
      </Button>
      <Menu
        id="create-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={() => handleClose(undefined)}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {!isV2 && (
          <MenuItem onClick={() => handleClose('template')}>Template</MenuItem>
        )}
        {isV2 && (
          <MenuItem onClick={() => handleClose('collection')}>
            Collection
          </MenuItem>
        )}
        {isPaymentsAllowed && paymentProvider?.status === 'ACTIVE' && !isV2 && (
          <MenuItem
            onClick={() => {
              handleClose('purchasable');
            }}
          >
            Purchasable
          </MenuItem>
        )}
      </Menu>
    </Box>
  );
};

const StyledImage = styled('img')(({ theme }) => ({
  borderRadius: theme.shape.borderRadius * 3,
}));

const legendItems = {
  players: [
    { name: 'Active Players', color: 'error.main' },
    { name: 'New Players', color: 'warning.main' },
  ],
  api: [{ name: 'API Logs', color: 'error.main' }],
};

export const GamePage = () => {
  const { gameId } = useParams<{
    gameId: string;
  }>();
  const [showEntityModal, setShowEntityModal] = useState<
    CreateMenuValue | 'game'
  >(undefined);
  const [statsPeriod, setStatsPeriod] = useState<TimeFrame>('week');
  const [statsPeriodDescription, setStatsPeriodDescription] =
    useState<string>();
  const { currentApiCalls, isLoading: isLoadingApiCalls } = useApiCallCount(
    statsPeriod,
    [Number(gameId)]
  );
  const { currentTotals, isLoading: isLoadingStats } = useGameStats(
    statsPeriod,
    [Number(gameId)]
  );
  const [activeChart, setActiveChart] = React.useState<'players' | 'api'>(
    'players'
  );

  const navigate = useNavigate();

  useEffect(() => {
    const now = new Date();
    const getStartOfPeriod =
      statsPeriod === 'week'
        ? (date: Date) => subWeeks(date, 1)
        : statsPeriod === 'month'
        ? (date: Date) => subMonths(date, 1)
        : (date: Date) => subQuarters(startOfQuarter(date), 1);

    setStatsPeriodDescription(
      `${format(getStartOfPeriod(now), 'MMM d')} - ${format(
        subDays(now, 1),
        'MMM d'
      )}`
    );
  }, [statsPeriod]);

  useTrack('Game', { gameId });

  const { data: game, error: gameError } = SdPrivateApi.useGame(
    String(gameId),
    !!gameId
  );

  if (!game) {
    return gameError ? (
      <Typography sx={{ color: 'text.error' }}>
        <>
          An error occurred while loading the game:{' '}
          {(gameError as Error)?.message || gameError}
        </>
      </Typography>
    ) : (
      <Spinner />
    );
  }

  return (
    <>
      <CreateEditGameModal
        game={game}
        onDelete={() => {
          navigate('/');
          setShowEntityModal(undefined);
        }}
        open={showEntityModal === 'game'}
        onClose={() => setShowEntityModal(undefined)}
      />
      <CreateEditTemplateModal
        gameId={Number(gameId)}
        open={showEntityModal === 'template'}
        onClose={() => setShowEntityModal(undefined)}
      />
      <CreatePurchasableModal
        gameId={Number(gameId)}
        open={showEntityModal === 'purchasable'}
        onClose={() => setShowEntityModal(undefined)}
      />
      <CreateCollectionModal
        gameId={Number(gameId)}
        open={showEntityModal === 'collection'}
        onClose={() => setShowEntityModal(undefined)}
        sourcePage="Game"
      />
      <Card
        elevation={0}
        variant="outlined"
        sx={{
          padding: 2,
          borderRadius: 3,
          mt: 2,
          mb: 1,
        }}
      >
        <Stack spacing={2}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'flex-start',
              justifyContent: 'space-between',
            }}
          >
            <StyledImage
              alt="game icon"
              src={getGameImageUrl(game.bucketName, game.image)}
              width="88"
            />
            <Stack direction="row" spacing={1}>
              <CreateMenu
                game={game}
                onChange={(value) => {
                  track(`Game Page Create ${value}`, 'Clicked', {
                    gameId: game.id,
                  });
                  setShowEntityModal(value);
                }}
              />
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => {
                  track('Game Page Edit Game', 'Clicked', {
                    gameId: game.id,
                  });
                  setShowEntityModal('game');
                }}
                startIcon={<PencilSimple />}
              >
                Edit
              </Button>
            </Stack>
          </Box>
          <Stack spacing={1}>
            <Stack direction="row" spacing={1.5} sx={{ alignItems: 'center' }}>
              <Typography variant="h4">{game.name}</Typography>
              <BlockchainIcon blockchain={game.blockchain} size={24} />
            </Stack>
            <Typography variant="body2" color="text.secondary">
              Game ID {game.id}
            </Typography>
          </Stack>
          <Card
            elevation={0}
            variant="outlined"
            sx={{
              padding: 2,
              borderRadius: 2,
            }}
          >
            <Stack direction="row" spacing={2}>
              <InfoItem
                label="Created"
                value={format(new Date(game.createdAt), 'MM/dd/yyyy')}
              />
              <InfoItem
                label="Blockchain"
                value={BlockchainNames[game.blockchain]}
              />
              <InfoItem
                label="Network"
                value={BlockchainNetworkNames[game.blockchain]}
              />
              <InfoItem label="Description" value={game.desc} />
            </Stack>
          </Card>
        </Stack>
      </Card>
      <Card
        elevation={0}
        variant="outlined"
        sx={{
          padding: 2,
          borderRadius: 3,
          flexGrow: 1,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            mb: 2,
          }}
        >
          <Typography variant="subtitle1">Game Data</Typography>
          <Stack direction={'row'} gap={1.5} sx={{ alignItems: 'center' }}>
            {isLoadingStats || isLoadingApiCalls ? (
              <CircularProgress size="18px" color="primary" />
            ) : null}
            <ToggleButtonGroup
              sx={(theme) => ({
                backgroundColor: theme.palette.background.white,
              })}
              exclusive
              value={statsPeriod}
              onChange={(e, value) => {
                track(`Game Toggle Button Group ${value}`, 'Clicked', {
                  gameId,
                });
                setStatsPeriod(value);
              }}
            >
              <ToggleButton value="week">Week</ToggleButton>
              <ToggleButton value="month">Month</ToggleButton>
              <ToggleButton value="quarter">Quarter</ToggleButton>
            </ToggleButtonGroup>
          </Stack>
        </Box>
        {gameId && (
          <Box sx={{ display: 'flex', gap: 1, mb: 2 }}>
            <GameOverviewStats currentTotals={currentTotals} />
            <GameApiCallsCard currentApiCalls={currentApiCalls} />
          </Box>
        )}
        {gameId && (
          <ContentModule
            borderBottom={false}
            title={activeChart === 'players' ? 'Player Count' : 'API Usage'}
            subtitle={statsPeriodDescription}
            renderHeaderContent={() => (
              <>
                <ChartLegend items={legendItems[activeChart]} />
                <ToggleButtonGroup
                  sx={(theme) => ({
                    backgroundColor: theme.palette.background.white,
                    height: 'fit-content',
                    alignSelf: 'center',
                    mr: 2,
                  })}
                  exclusive
                  value={activeChart}
                  onChange={(e, value) => setActiveChart(value)}
                >
                  <ToggleButton value="players">Players</ToggleButton>
                  <ToggleButton value="api">API Calls</ToggleButton>
                </ToggleButtonGroup>
              </>
            )}
            sx={{ height: '588px' }}
          >
            {activeChart === 'players' ? (
              <PlayerCountChart
                gameId={Number(gameId)}
                timeFrame={statsPeriod}
              />
            ) : (
              <ApiCallsChart gameId={Number(gameId)} timeFrame={statsPeriod} />
            )}
          </ContentModule>
        )}
      </Card>
    </>
  );
};
