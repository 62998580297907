import { paginatedResultsWithFilterSchema } from '@stardust-monorepo/types/common';
import { z } from 'zod';

import { HasCreatedAtTimestamp } from './base';
import { EmptyTwitterStatsSchema, TwitterStatsSchema } from './profile-stats';
import { WalletSchema } from './wallet';

const ExpandedStatsSchema = z
  .object({
    twitter: TwitterStatsSchema.or(EmptyTwitterStatsSchema),
  })
  .describe('ExpandedStatsSchema');
export type ExpandedStats = z.infer<typeof ExpandedStatsSchema>;

export const ProfileIdentifierServiceTypeSchema = z
  .enum(['external-wallet', 'custom', 'social'])
  .describe('ProfileIdentifierServiceTypeSchema');
export type ProfileIdentifierServiceType = z.infer<
  typeof ProfileIdentifierServiceTypeSchema
>;

const BaseProfileIdentifierSchema = z
  .object({
    rootUserId: z.string(),
    profileId: z.string(),
    service: z.string(),
    value: z.string(),
    id: z.string(),
    type: ProfileIdentifierServiceTypeSchema,
  })
  .merge(HasCreatedAtTimestamp)
  .describe('BaseProfileIdentiferSchema');

export const ProfileIdentifierSchema = BaseProfileIdentifierSchema.merge(
  z.object({
    stats: z.object({}),
  })
).describe('ProfileIdentiferSchema');
export type ProfileIdentifier = z.infer<typeof ProfileIdentifierSchema>;

export const isExpandedStats = (
  stats?: Record<string, never> | ExpandedStats
): stats is ExpandedStats => {
  const twitterProp: keyof ExpandedStats = 'twitter';
  return stats !== undefined && twitterProp in stats;
};

export const ProfileIdentiferWithStatsSchema =
  BaseProfileIdentifierSchema.merge(
    z.object({
      stats: ExpandedStatsSchema.or(z.object({})),
    })
  );
export type ProfileIdentiferWithStats = z.infer<
  typeof ProfileIdentiferWithStatsSchema
>;

export const ProfileIdentifierResultsSchema = paginatedResultsWithFilterSchema(
  ProfileIdentifierSchema
).describe('ProfileIdentifierResultsSchema');
export type ProfileIdentifierResults = z.infer<
  typeof ProfileIdentifierResultsSchema
>;

export const ProfileSchema = z
  .object({
    rootUserId: z.string().optional(),
    name: z.string().optional().nullable(),
    id: z.string().optional(),
    identifiers: ProfileIdentifierSchema.array().optional(),
    wallets: WalletSchema.array().optional(),
  })
  .merge(HasCreatedAtTimestamp)
  .describe('ProfileSchema');
export type Profile = z.infer<typeof ProfileSchema>;

export const ProfileResultsSchema = paginatedResultsWithFilterSchema(
  ProfileSchema
).describe('ProfileResultsSchema');
export type ProfileResults = z.infer<typeof ProfileResultsSchema>;
