import {
  Table as MuiTable,
  TableBody,
  TableContainer,
  TableHead,
  TableCell,
  TableRow,
  SxProps,
  TableContainerProps,
  Theme,
  IconButton,
} from '@mui/material';
import { SortAscending, SortDescending } from '@phosphor-icons/react';
import { SortDirection } from '@stardust-monorepo/types/common';
import { ReactNode, SyntheticEvent } from 'react';

import { Spinner } from '../spinner/spinner';
import { Row } from './row';

export type OnSortChange = (direction: SortDirection) => void;

//TODO sx should be encapsulated in Heading to avoid needing to filter non-sx props here
export type Heading =
  | ({
      label: ReactNode;
      sortDirection?: SortDirection;
      onSortChange?: OnSortChange;
    } & SxProps)
  | undefined;

export interface BaseTableProps {
  headings?: Heading[];
  rows: ReactNode[][];
  onRowClick?: (event: SyntheticEvent, rowIndex: number) => void;
  rowBorder?: boolean;
  hasSubRow?: boolean;
  showHeadings?: boolean;
  fillHeight?: boolean;
  tableLayout?: 'fixed' | 'auto'; //i think fixed should be default but it will affect all tables
  minWidth?: string;
  sx?: SxProps<Theme>;
  tableContainerProps?: TableContainerProps;
  isLoading?: boolean;
}

const TableSortButton = ({
  direction = null,
  onSortChange,
}: {
  direction?: SortDirection;
  onSortChange: OnSortChange;
}) => {
  return (
    <IconButton
      className="table-sort-button"
      sx={(theme) => ({
        ...(direction === null && {
          opacity: 0,
          '&:hover': {
            opacity: 0.5,
          },
        }),
        color: theme.palette.text.primary,
        //similar outcome to using the edge prop, but there's no option for Y values
        marginY: -1,
      })}
    >
      {direction === 'DESC' || direction === null ? (
        <SortDescending size={16} />
      ) : (
        <SortAscending size={16} />
      )}
    </IconButton>
  );
};

//TODO use skeleton rows instead of spinner while loading
export function BaseTable({
  headings,
  rows,
  onRowClick,
  rowBorder,
  hasSubRow,
  fillHeight,
  showHeadings = true,
  tableLayout,
  isLoading,
  minWidth,
  tableContainerProps,
  sx,
}: BaseTableProps) {
  return (
    <TableContainer
      {...tableContainerProps}
      sx={[
        {
          ...(tableLayout === 'fixed' ? { overflowX: 'unset' } : {}),
          ...(fillHeight ? { flexGrow: 1 } : {}),
        },

        ...(tableContainerProps?.sx !== undefined
          ? Array.isArray(tableContainerProps?.sx)
            ? tableContainerProps.sx
            : [tableContainerProps.sx]
          : []),
      ]}
    >
      <MuiTable
        stickyHeader
        sx={{
          ...(tableLayout ? { tableLayout } : {}),
          ...(isLoading ? { height: '100%' } : {}),
        }}
      >
        {headings && showHeadings && (
          <TableHead>
            <TableRow>
              {headings.map((heading, index) => {
                const { label, onSortChange, sortDirection, ...sx } = heading
                  ? heading
                  : {
                      label: '',
                      onSortChange: undefined,
                      sortDirection: undefined,
                      sx: {},
                    };
                return (
                  <TableCell
                    sx={{
                      ...sx,
                      ...(onSortChange ? { cursor: 'pointer' } : {}),
                      ...(sortDirection === null && {
                        '& .table-sort-button': {
                          opacity: 0,
                        },
                      }),
                      '&:hover .table-sort-button': {
                        opacity: 1,
                        ...(sortDirection === null && {
                          opacity: 0.5,
                        }),
                      },
                    }}
                    key={index}
                    onClick={() =>
                      onSortChange?.(
                        sortDirection === null
                          ? 'DESC'
                          : sortDirection === 'DESC'
                          ? 'ASC'
                          : null
                      )
                    }
                  >
                    {label}{' '}
                    {onSortChange && (
                      <TableSortButton
                        direction={sortDirection}
                        onSortChange={onSortChange}
                      />
                    )}
                  </TableCell>
                );
              })}
              {hasSubRow ? <TableCell sx={{ width: '34px' }} /> : null}
            </TableRow>
          </TableHead>
        )}
        <TableBody>
          {isLoading ? (
            <TableRow>
              <TableCell colSpan={headings?.length}>
                <Spinner grow={true} />
              </TableCell>
            </TableRow>
          ) : (
            rows.map((row, rowIndex) => (
              <Row
                key={rowIndex}
                cells={row}
                hasSubRow={hasSubRow}
                headings={headings}
                onRowClick={onRowClick}
                rowBorder={rowBorder}
                rowIndex={rowIndex}
                totalRows={rows.length}
              />
            ))
          )}
        </TableBody>
      </MuiTable>
    </TableContainer>
  );
}
