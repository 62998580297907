import { Add } from '@mui/icons-material';
import { Button, Link as MuiLink, Stack, Typography } from '@mui/material';
import {
  CoinVertical,
  HandCoins,
  NotePencil,
  Trash,
} from '@phosphor-icons/react';
import { Player } from '@stardust-monorepo/types/sd-private';
import {
  BaseEmptyView,
  ClipboardCopy,
  ContainedTable,
  ErrorAlert,
  MoreActions,
  PAGE_SIZE,
  PageTitle,
  RemoveModal,
  SdPrivateApi,
  track,
  useHandleError,
  useToast,
  useTrack,
} from '@stardust-monorepo/web-sdk-apps-shared';
import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import playerIcon from '../../assets/player-icon.png';
import { AddItemBalance } from '../components/collections/item-balance/AddItemBalance';
import { MintTokenModal } from '../components/mint-token';
import { CreatePlayerModal } from '../components/player';
import { gameState } from '../state/game-state';

const headings = [
  {
    label: 'Unique ID',
    maxWidth: '200px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  {
    label: 'Player ID',
    maxWidth: '200px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  {
    label: 'Wallet Address',
    maxWidth: '200px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  { label: 'Last Seen' },
  { width: '40px', label: '' },
];

export const PlayersPage = () => {
  const { gameId } = useParams<{
    gameId: string;
  }>();
  const [page, setPage] = useState(0);
  const [filter, setFilter] = useState('');
  const toaster = useToast();
  const handleError = useHandleError();
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [selectedPlayer, setSelectedPlayer] = useState<Player | null>(null);
  const [mintTokenOpen, setMintTokenOpen] = useState(false);
  const [createPlayerOpen, setCreatePlayerOpen] = useState(false);
  const games = useRecoilValue(gameState);
  const game = games.find((g) => g.id === Number(gameId));

  const deletePlayer = SdPrivateApi.useDeletePlayer();

  useTrack('Players', { gameId });

  const {
    data: players,
    error: playersError,
    isLoading: playersLoading,
  } = SdPrivateApi.usePlayers(
    {
      gameId: Number(gameId),
      start: page * PAGE_SIZE,
      limit: PAGE_SIZE,
      filter: filter ? filter : undefined,
      includeWallet: true,
    },
    !!gameId
  );

  const {
    data: playersCount,
    error: playersCountError,
    isLoading: playerCountLoading,
  } = SdPrivateApi.usePlayerCount(
    {
      gameId: Number(gameId),
      filter: filter ? filter : undefined,
    },
    !!gameId
  );

  const onDeletePlayer = async () => {
    const trackingData = { gameId, playerId: selectedPlayer?.playerId };
    track('Modal Remove Player', 'Clicked', trackingData);
    try {
      if (gameId && selectedPlayer) {
        await deletePlayer.mutateAsync({
          gameId: Number(gameId),
          playerId: selectedPlayer.playerId,
        });
        track('Remove Player', 'Success', trackingData);
        toaster(`Player ${selectedPlayer.playerId} successfully removed`);
      }
    } catch (e) {
      handleError(
        e,
        `Unable to remove player ${selectedPlayer?.playerId}`,
        'Remove Player',
        trackingData
      );
    }
    setSelectedPlayer(null);
    setDeleteConfirmationOpen(false);
  };

  const rows = (players || []).map((player, index) => {
    return [
      <Link to={player.playerId}>
        <Typography
          variant="subtitle2"
          sx={{
            color: 'primary.main',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            maxWidth: '300px',
          }}
        >
          {player.uniqueId}
        </Typography>
      </Link>,
      <ClipboardCopy
        className="playerId"
        onCopy={() => toaster('Player ID copied')}
        text={player.playerId}
      />,
      player.wallet && player.wallet.length > 0 ? (
        <ClipboardCopy
          className="walletAddress"
          onCopy={() => toaster('Wallet Address copied')}
          text={player.wallet[0].address}
        />
      ) : (
        'N/A'
      ),
      new Date(player.lastSeen).toLocaleString(),
      <MoreActions
        actions={[
          {
            icon: <NotePencil weight="duotone" />,
            label: 'Edit Player',
            onClick: () => {
              track('Edit Player', 'Clicked', {
                gameId,
                playerId: player.playerId,
              });
              setSelectedPlayer(player);
              setCreatePlayerOpen(true);
            },
          },
          {
            icon:
              game && SdPrivateApi.isV2Game(game) ? (
                <HandCoins weight="duotone" />
              ) : (
                <CoinVertical weight="duotone" />
              ),
            label:
              game && SdPrivateApi.isV2Game(game)
                ? 'Add Balance'
                : 'Mint Token',
            onClick: () => {
              track('Players Mint Token Action', 'Clicked', {
                gameId,
                playerId: player.playerId,
              });
              setSelectedPlayer(player);
              setMintTokenOpen(true);
            },
          },
          {
            icon: <Trash weight="duotone" />,
            label: 'Remove Player',
            onClick: () => {
              track('Remove Player', 'Clicked', {
                gameId,
                playerId: player.playerId,
              });
              setSelectedPlayer(player);
              setDeleteConfirmationOpen(true);
            },
            type: 'error',
          },
        ]}
      />,
    ];
  });

  return (
    <>
      <RemoveModal
        body={`Are you sure you want to remove player ${selectedPlayer?.playerId}?`}
        label="Player"
        loading={deletePlayer.isLoading}
        onClose={() => {
          track('Remove Player Close', 'Clicked', {
            gameId,
            playerId: selectedPlayer?.playerId,
          });
          setDeleteConfirmationOpen(false);
        }}
        onConfirm={onDeletePlayer}
        open={deleteConfirmationOpen}
      />
      {game && mintTokenOpen ? (
        SdPrivateApi.isV2Game(game) ? (
          <AddItemBalance
            playerId={selectedPlayer?.playerId}
            gameId={Number(gameId)}
            onClose={() => {
              setMintTokenOpen(false);
              setSelectedPlayer(null);
            }}
            open={mintTokenOpen}
          />
        ) : (
          <MintTokenModal
            gameId={Number(gameId)}
            open={mintTokenOpen}
            targetPlayer={selectedPlayer}
            onClose={() => {
              setMintTokenOpen(false);
              setSelectedPlayer(null);
            }}
          />
        )
      ) : null}
      {createPlayerOpen ? (
        <CreatePlayerModal
          gameId={Number(gameId)}
          open={createPlayerOpen}
          onClose={() => {
            setCreatePlayerOpen(false);
            setSelectedPlayer(null);
          }}
          player={selectedPlayer}
        />
      ) : null}
      <ErrorAlert
        error={(playersError as Error) || (playersCountError as Error)}
      />
      <PageTitle title="Players" count={playersCount?.count}>
        <Stack direction="row" spacing={1}>
          <Button
            variant="contained"
            onClick={() => {
              track('Player Create New', 'Clicked', { gameId });
              setCreatePlayerOpen(true);
            }}
            startIcon={<Add />}
          >
            Create New
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => {
              track('Players Mint Token Button', 'Clicked', {
                gameId,
              });
              setMintTokenOpen(true);
            }}
          >
            {game && SdPrivateApi.isV2Game(game) ? 'Add Balance' : 'Mint Token'}
          </Button>
        </Stack>
      </PageTitle>
      <ContainedTable
        count={playersCount?.count}
        page={page}
        setPage={setPage}
        placeholder="Filter by Unique ID"
        filter={filter}
        setFilter={setFilter}
        headings={headings}
        rows={rows}
        isLoading={playersLoading || playerCountLoading}
        emptyScreen={
          <BaseEmptyView
            image={playerIcon}
            title="Add your first player"
            body={
              <>
                Create and manage player accounts associated with your game.
                Players can also be created via the{' '}
                <MuiLink
                  target="_blank"
                  rel="noopener"
                  href="https://docs.stardust.gg/reference/create-player"
                >
                  Stardust API
                </MuiLink>
                .
              </>
            }
            buttonText="Create Player"
            buttonType="outlined"
            onClick={() => {
              track('Empty Player Create New', 'Clicked', { gameId });
              setCreatePlayerOpen(true);
            }}
          />
        }
      />
    </>
  );
};
