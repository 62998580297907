import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import {
  removeItemBalance,
  getInventory,
  GetInventoryArgs,
  getItemBalance,
  GetItemBalanceArgs,
  getItemBalances,
  GetItemBalancesArgs,
  addItemBalance,
  transferItemBalance,
} from '../item-balance-api';
import { itemKeys } from './useItem';

export const itemBalanceKeyRoot = {
  itemBalance: 'itemBalance',
  itemBalances: 'itemBalances',
} as const;
export const itemBalanceKeys = {
  itemBalances: ({
    gameId,
    playerId,
    itemId,
    start,
    limit,
  }: GetItemBalancesArgs) => [
    itemBalanceKeyRoot.itemBalances,
    { gameId },
    ...(playerId || itemId || start || limit
      ? [{ playerId, itemId, start, limit }]
      : []),
  ],
  itemBalance: ({ id }: GetItemBalanceArgs) => [
    itemBalanceKeyRoot.itemBalance,
    { id },
  ],
};

export function useItemBalance(args: GetItemBalanceArgs) {
  return useQuery({
    queryKey: itemBalanceKeys.itemBalance(args),
    queryFn: () => getItemBalance(args),
    enabled: !(args.id === undefined || args.id === null),
  });
}

export function useItemBalances(args: GetItemBalancesArgs) {
  return useQuery({
    queryKey: itemBalanceKeys.itemBalances(args),
    queryFn: () => getItemBalances(args),
    enabled: !(args.gameId === undefined || args.gameId === null),
  });
}

export function useAddItemBalance() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: addItemBalance,
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({
        queryKey: itemKeys.item({ id: variables.itemId }),
      });
      queryClient.invalidateQueries({
        queryKey: itemBalanceKeys.itemBalance({ id: data.id }),
      });
      queryClient.invalidateQueries({
        queryKey: itemBalanceKeys.itemBalances({ gameId: data.gameId }),
      }); //TODO inventory too?
    },
  });
}

export function useRemoveItemBalance() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: removeItemBalance,
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({
        queryKey: itemKeys.item({ id: variables.itemId }),
      });
      queryClient.invalidateQueries({
        queryKey: itemBalanceKeys.itemBalance({ id: data.id }),
      });
      queryClient.invalidateQueries({
        queryKey: itemBalanceKeys.itemBalances({ gameId: data.gameId }),
      }); //TODO inventory too?
    },
  });
}

export function useTransferItemBalance() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: transferItemBalance,
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({
        queryKey: itemKeys.item({ id: variables.itemId }),
      });
      queryClient.invalidateQueries({
        queryKey: itemBalanceKeys.itemBalance({ id: variables.itemId }),
      });
      queryClient.invalidateQueries({
        queryKey: itemBalanceKeys.itemBalances({
          playerId: data.sourcePlayerItemBalance.playerId,
          gameId: data.sourcePlayerItemBalance.gameId,
        }),
      }); //TODO inventory too?
      queryClient.invalidateQueries({
        queryKey: itemBalanceKeys.itemBalances({
          playerId: data.destinationPlayerItemBalance.playerId,
          gameId: data.destinationPlayerItemBalance.gameId,
        }),
      });
    },
  });
}

export function useInventory(args: GetInventoryArgs) {
  return useQuery({
    queryKey: itemBalanceKeys.itemBalances(args),
    queryFn: () => getInventory(args),
    enabled:
      !(args.gameId === undefined || args.gameId === null) &&
      !(args.playerId === undefined || args.playerId === null),
  });
}
