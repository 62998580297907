import { zodResolver } from '@hookform/resolvers/zod';
import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { Template } from '@stardust-monorepo/types/marketplace';
import { Player } from '@stardust-monorepo/types/sd-private';
import {
  BasicDialog,
  FormField,
  InputLabel,
  SdPrivateApi,
  track,
  useHandleError,
  useToast,
} from '@stardust-monorepo/web-sdk-apps-shared';
import React, { useRef, useState } from 'react';
import { FieldErrors, useForm } from 'react-hook-form';
import { z } from 'zod';

import {
  AddProperties,
  mapToProperties,
  Property,
  ZodPropertyValidation,
} from '../properties';
import { PlayerSearch } from './PlayerSearch';
import { TemplateSearch } from './TemplateSearch';

export const formControls = {
  tokenType: 'tokenType',
  amount: 'amount',
  playerId: 'playerId',
  props: 'props',
} as const;

const formSchema = z
  .object({
    [formControls.tokenType]: z.string().min(1, 'Token is required'),
    [formControls.amount]: z.preprocess(
      (amount) =>
        typeof amount === 'string'
          ? parseInt(z.string().parse(amount || '0'))
          : amount,
      z.number()
    ),
    [formControls.playerId]: z.string().min(1, 'Player is required'),
    [formControls.props]: ZodPropertyValidation,
  })
  .required()
  .refine(
    (data) =>
      (data.tokenType === 'FT' && data.amount >= 1) || data.tokenType === 'NFT',
    {
      path: [formControls.amount],
      message: 'Amount must be at least 1',
    }
  );

interface FormValues {
  tokenType: string;
  amount: number | string;
  playerId: string;
  props: Property[];
}

export interface MintTokenModalProps {
  gameId: number;
  open: boolean;
  targetPlayer?: Player | null;
  targetTemplate?: Template | null;
  onClose: () => void;
}

export const MintTokenModal = ({
  gameId,
  open,
  targetPlayer,
  targetTemplate,
  onClose,
}: MintTokenModalProps) => {
  const theme = useTheme();
  const submitFormButtonRef = useRef<HTMLButtonElement | null>(null);
  const [selectedTemplate, setSelectedTemplate] = useState<Template | null>(
    targetTemplate || null
  );
  const [removeAll, setRemoveAll] = useState(false);
  const [selectedPlayer, setSelectedPlayer] = useState<Player | null>(
    targetPlayer || null
  );
  const toaster = useToast();
  const handleError = useHandleError();

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm<FormValues>({
    defaultValues: {
      [formControls.tokenType]: targetTemplate?.type || '',
      [formControls.amount]: '',
      [formControls.playerId]: targetPlayer?.playerId || '',
      [formControls.props]: [],
    },
    resolver: zodResolver(formSchema),
  });

  const tokenType = watch(formControls.tokenType);
  const mintToken = SdPrivateApi.useMintToken();

  const cancelClicked = () => {
    track('Mint Token Cancel', 'Clicked', {
      gameId,
      playerId: targetPlayer?.playerId,
      templateId: targetTemplate?.id,
    });
    onClose();
  };

  const doMintToken = async (formValues: FormValues) => {
    const trackingData = {
      gameId,
      playerId: formValues.playerId,
      templateId: selectedTemplate?.id,
    };
    try {
      await mintToken.mutateAsync({
        gameId,
        playerId: formValues.playerId,
        amount: formValues.amount,
        templateId: selectedTemplate?.id || 0,
        tokenType: formValues.tokenType,
        props: mapToProperties(formValues.props),
      });
      track('Mint Token', 'Success', trackingData);
      toaster(`${selectedTemplate?.name} successfully minted`);
      onClose();
    } catch (e) {
      handleError(
        e,
        'An error occurred while minting',
        'Mint Token',
        trackingData
      );
    }
  };

  return (
    <BasicDialog open={open} onClose={cancelClicked}>
      <DialogTitle
        variant="h5"
        sx={{
          padding: `${theme.spacing(3)} ${theme.spacing(3)} ${theme.spacing(
            1
          )}!important`,
        }}
      >
        {targetTemplate
          ? `Mint ${targetTemplate?.name} Token`
          : 'Mint Token to Player'}
      </DialogTitle>
      <DialogContent sx={{ width: '600px' }}>
        <Typography variant="body2" sx={{ color: 'text.secondary', pb: 4 }}>
          Add token to a player's inventory
        </Typography>
        <Stack
          component="form"
          sx={{ flexGrow: 1 }}
          onSubmit={handleSubmit((data) => {
            doMintToken(data);
          })}
          gap={3}
        >
          <FormControl error={!!errors[formControls.playerId]}>
            <>
              <InputLabel htmlFor="player-search" sx={{ mb: 0.5 }}>
                {targetPlayer ? '' : 'Search for '}Player Unique ID
                (Destination)
              </InputLabel>
              <PlayerSearch
                disabled={!!targetPlayer}
                gameId={gameId}
                value={selectedPlayer}
                onChange={(p) => {
                  setSelectedPlayer(p);
                  setValue(formControls.playerId, p?.playerId || '');
                }}
              />
              {errors[formControls.playerId] && (
                <FormHelperText>
                  {errors[formControls.playerId]?.message}
                </FormHelperText>
              )}
            </>
          </FormControl>
          <FormControl error={!!errors[formControls.tokenType]}>
            <>
              <InputLabel htmlFor="template-search" sx={{ mb: 0.5 }}>
                {targetTemplate ? '' : 'Search for '}Template to Mint
              </InputLabel>
              <TemplateSearch
                disabled={!!targetTemplate}
                gameId={gameId}
                value={selectedTemplate}
                onlyActiveTemplates={false}
                onChange={(t) => {
                  setSelectedTemplate(t);
                  setValue(formControls.tokenType, t?.type || '');
                  if (!t || t.type === 'FT') {
                    setRemoveAll(true);
                  }
                }}
              />
              {errors[formControls.tokenType] && (
                <FormHelperText>
                  {errors[formControls.tokenType]?.message}
                </FormHelperText>
              )}
            </>
          </FormControl>
          {tokenType === 'FT' ? (
            <FormField
              formControlName={formControls.amount}
              control={control}
              inputSx={{ maxWidth: '120px' }}
              label="Amount"
              type="number"
              hasError={!!errors[formControls.amount]}
              renderHelpText={() =>
                errors[formControls.amount] && (
                  <FormHelperText>
                    {errors[formControls.amount]?.message}
                  </FormHelperText>
                )
              }
            />
          ) : tokenType === 'NFT' ? (
            <AddProperties
              control={control}
              name="token"
              errors={errors[formControls.props] as FieldErrors[]}
              removeAll={removeAll}
              setRemoveAll={setRemoveAll}
              watch={watch}
            />
          ) : null}
          <button ref={submitFormButtonRef} style={{ display: 'none' }} />
        </Stack>
      </DialogContent>
      <DialogActions
        sx={{ borderTop: '1px solid', borderColor: 'divider', mt: 2.5 }}
      >
        <Button
          disabled={mintToken.isLoading}
          onClick={cancelClicked}
          variant="outlined"
          color="secondary"
          sx={{ mt: 1.5 }}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          disabled={mintToken.isLoading}
          autoFocus
          variant="contained"
          onClick={() => {
            track('Confirm Mint', 'Clicked', {
              gameId,
              templateId: selectedTemplate?.id,
            });
            submitFormButtonRef.current?.click();
          }}
          sx={{ mt: 1.5 }}
        >
          {mintToken.isLoading ? (
            <CircularProgress size="18px" color="primary" />
          ) : (
            'Confirm & Mint'
          )}
        </Button>
      </DialogActions>
    </BasicDialog>
  );
};
