import { paginatedResultsSchema } from '@stardust-monorepo/types/common';
import { z } from 'zod';

export const CollectionSchema = z
  .object({
    id: z.string().uuid(),
    gameId: z.number(),
    name: z.string(),
    description: z.string(),
    image: z.string().url().or(z.literal('')),
    publicMetadata: z.record(z.string(), z.any()),
    blockchainId: z.nullable(z.number()),
    address: z.nullable(z.string()),
    createdAt: z.number(),
    updatedAt: z.number(),
  })
  .describe('CollectionSchema');
export type Collection = z.infer<typeof CollectionSchema>;

export const CollectionResultsSchema = paginatedResultsSchema(CollectionSchema);
export type CollectionResults = z.infer<typeof CollectionResultsSchema>;
