export const mockGame = {
  id: 35,
  bucketName: 'game_35',
  name: 'test game',
  ownerId: '',
  image:
    'https://sd-game-assets-dev-lhv834fnkjsd.s3.amazonaws.com/game_35/Game/GameLogo.png',
  blockchain: 1,
  blockchainId: 1,
  desc: 'The creators of the Stardust.gg extended universe bring you Devquest - a next-generation MMO A/JRPG RTS FPS MOBA BR 4X VR simulation game for 2-4 programmers. For development purposes only.',
  banner:
    'https://sd-game-assets-dev-lhv834fnkjsd.s3.amazonaws.com/game_35/Game/banner',
  royalty: 1,
  news: '',
};

export const mockSdGame = {
  ownerId: '57188007-91d9-437f-a1fb-2c9713b3bc33',
  name: 'Dashboard testing',
  desc: 'Testing out dashboard rebuild',
  image: 'Game/GameLogo.png',
  blockchain: 7,
  escrow: '26ae458b-8d92-4c8b-9a7b-ba5ac70d123b',
  royalty: 0,
  stardustFee: 0,
  news: null,
  id: 3,
  props: {},
  fees: [
    { feePercentage: 0, feeType: 'stardust_fee' } as const,
    { feePercentage: 0, feeType: 'game_royalty' } as const,
  ],
  bucketName: 'game_1541',
  testMode: false,
  createdAt: '2023-05-10T15:49:00.480Z',
};
