import { zodResolver } from '@hookform/resolvers/zod';
import { Box, FormHelperText, Stack, Typography } from '@mui/material';
import { CurrencyDollar } from '@phosphor-icons/react';
import { Template } from '@stardust-monorepo/types/marketplace';
import { Purchasable } from '@stardust-monorepo/types/sd-private';
import {
  FilledStartAdornment,
  FormField,
  FormSectionContainer,
  purchasableKeys,
  SdPrivateApi,
  UploadImage,
} from '@stardust-monorepo/web-sdk-apps-shared';
import { useQueryClient } from '@tanstack/react-query';
import React, { MutableRefObject } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { z } from 'zod';

import { mapToProperties, ZodPropertyValidation } from '../properties';
import { TokenSelector } from './TokenSelector';
import { FormValues } from './types';

export const formControls = {
  name: 'name',
  image: 'image',
  price: 'price',
  cap: 'cap',
  tokens: 'tokens',
} as const;

const formSchema = z
  .object({
    [formControls.name]: z.string().min(1, 'Name is required'),
    [formControls.image]: z.instanceof(File).or(z.null()),
    [formControls.price]: z.preprocess(
      (price) =>
        typeof price === 'string'
          ? parseFloat(z.string().parse(price) || '0.0')
          : price,
      z.number().gte(0.99, 'Price must be at least $0.99')
    ),
    [formControls.cap]: z.preprocess(
      (cap) =>
        typeof cap === 'string' ? parseInt(z.string().parse(cap || '0')) : cap,
      z.number().gte(1, 'Cap must be at least 1')
    ),
    [formControls.tokens]: z.array(
      z.object({
        id: z.number().optional(),
        amount: z.preprocess(
          (amount) =>
            typeof amount === 'string'
              ? parseInt(z.string().parse(amount || '0'))
              : amount,
          z.number().gte(1, 'Amount must be at least 1')
        ),
        props: ZodPropertyValidation,
      })
    ),
  })
  .required();

interface CreatePurchasableFormProps {
  defaultValues: FormValues;
  gameId: number;
  purchasable?: Purchasable | null;
  selectedTemplates: Template[];
  setSelectedTemplates: (templates: Template[]) => void;
  submitFormRef: MutableRefObject<HTMLButtonElement | null>;
  onLoading: (loading: boolean) => void;
  onSave: (result: number | Error) => void;
}

export const CreatePurchasableForm = ({
  defaultValues,
  gameId,
  purchasable,
  selectedTemplates,
  setSelectedTemplates,
  submitFormRef,
  onLoading,
  onSave,
}: CreatePurchasableFormProps) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<FormValues>({
    defaultValues,
    resolver: zodResolver(formSchema),
  });
  const createPurchasable = SdPrivateApi.useCreatePurchasable();
  const mutatePurchasable = SdPrivateApi.useMutatePurchasable();
  const createPurchasableItem = SdPrivateApi.useCreatePurchasableItem();
  const mutatePurchasableItem = SdPrivateApi.useMutatePurchasableItem();
  const deletePurchasableItem = SdPrivateApi.useDeletePurchasableItem();
  const queryClient = useQueryClient();

  const submitPurchasable = async (formValues: FormValues) => {
    if (purchasable) {
      try {
        const deletePromises = defaultValues.tokens.reduce<
          Promise<Record<string, never>>[]
        >((acc, token) => {
          if (
            !formValues.tokens.find((formToken) => formToken.id === token.id)
          ) {
            acc.push(
              deletePurchasableItem.mutateAsync({
                gameId,
                purchasableId: purchasable.id,
                purchasableItemId: Number(token.id),
              })
            );
          }
          return acc;
        }, []);

        const createUpdatePromises = formValues.tokens.map<
          Promise<{ id?: number }>
        >((token, index) => {
          const baseRequest = {
            amount: String(token.amount),
            gameId,
            props: {
              immutable: {},
              mutable: mapToProperties(token.props),
            },
            purchasableId: purchasable.id,
          };
          if (token.id) {
            return mutatePurchasableItem.mutateAsync({
              ...baseRequest,
              purchasableItemId: token.id,
            });
          } else {
            return createPurchasableItem.mutateAsync({
              ...baseRequest,
              templateId: selectedTemplates[index].id,
            });
          }
        });

        await Promise.all([...createUpdatePromises, ...deletePromises]);

        const updatedPurchasable = {
          cap: String(formValues.cap),
          gameId,
          image: purchasable.image,
          name: formValues.name,
          newImage: formValues.image,
          price: String(formValues.price),
          purchasableId: purchasable.id,
        };
        // Keeping this call out of the Promise.all since it would impact
        // the cache invalidation order
        const purchasableId = await mutatePurchasable.mutateAsync(
          updatedPurchasable
        );
        onSave(purchasableId);
      } catch (e) {
        await queryClient.invalidateQueries({
          queryKey: purchasableKeys.purchasables(gameId),
        });
        if (e instanceof Error) {
          onSave(e);
        }
      }
    } else {
      try {
        const newPurchasable = {
          cap: String(formValues.cap),
          gameId,
          image: formValues.image,
          name: formValues.name,
          price: String(formValues.price),
          purchasableItems: formValues.tokens.map((token, index) => ({
            amount: String(token.amount),
            props: {
              immutable: {},
              mutable: mapToProperties(token.props),
            },
            templateId: selectedTemplates[index].id,
          })),
        };
        const purchasableId = await createPurchasable.mutateAsync(
          newPurchasable
        );
        onSave(purchasableId);
      } catch (e) {
        if (e instanceof Error) {
          onSave(e);
        }
      }
    }
    onLoading(false);
  };

  return (
    <Stack
      component="form"
      gap={2}
      sx={{
        flexGrow: 1,
      }}
      onSubmit={handleSubmit((data) => {
        onLoading(true);
        submitPurchasable(data);
      })}
    >
      <FormSectionContainer>
        <Typography variant="h6">Details</Typography>
        <Typography
          variant={'body2'}
          color={'text.secondary'}
          sx={{
            mt: 0.5,
          }}
        >
          Provide the name and price of your purchasable. The price specified
          below is the amount customers will pay to acquire the contents of your
          Purchasable.
        </Typography>
        <FormField
          formControlName={formControls.name}
          control={control}
          maxInputWidth="unset"
          label="*Name"
          type="text"
          hasError={!!errors[formControls.name]}
          renderHelpText={() =>
            errors[formControls.name] && (
              <FormHelperText>
                {errors[formControls.name]?.message}
              </FormHelperText>
            )
          }
        />
        <Box sx={{ display: 'flex', gap: 3 }}>
          <FormField
            formControlName={formControls.price}
            control={control}
            label="Price"
            type="number"
            sx={{ flex: '1 1 0' }}
            hasError={!!errors[formControls.price]}
            startAdornment={
              <FilledStartAdornment>
                <CurrencyDollar size={16} />
                USD
              </FilledStartAdornment>
            }
            renderHelpText={() =>
              errors[formControls.price] && (
                <FormHelperText>
                  {errors[formControls.price]?.message}
                </FormHelperText>
              )
            }
          />
          <FormField
            formControlName={formControls.cap}
            control={control}
            label="Total Stock"
            type="number"
            sx={{ flex: '1 1 0' }}
            hasError={!!errors[formControls.cap]}
            renderHelpText={() =>
              errors[formControls.cap] && (
                <FormHelperText>
                  {errors[formControls.cap]?.message}
                </FormHelperText>
              )
            }
          />
        </Box>
      </FormSectionContainer>
      <FormSectionContainer>
        <Typography variant="h6">Purchasable Image</Typography>
        <Typography
          variant={'body2'}
          color={'text.secondary'}
          sx={{
            mt: 0.5,
          }}
        >
          Upload an image for your purchasable. This image will be shown to
          users during the Payments checkout process.
        </Typography>
        <Controller
          control={control}
          name={formControls.image}
          render={({ field: { ref, ...fieldProps } }) => (
            <Stack marginTop={4} gap={1}>
              <UploadImage
                {...fieldProps}
                inputRef={ref}
                previewSize="medium"
                title="Upload a Purchasable Image"
                subtitle="SVG, PNG, JPG or GIF. Recommended 60x60px"
                existingImageHref={purchasable?.image}
              />
            </Stack>
          )}
        />
      </FormSectionContainer>
      <FormSectionContainer>
        <TokenSelector
          control={control}
          errors={errors}
          gameId={gameId}
          selectedTemplates={selectedTemplates}
          setSelectedTemplates={setSelectedTemplates}
          watch={watch}
        />
      </FormSectionContainer>
      <button ref={submitFormRef} style={{ display: 'none' }} />
    </Stack>
  );
};
