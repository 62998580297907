import { AxiosRequestConfig } from 'axios';
import { ZodTypeAny } from 'zod';

import {
  authenticatedRequest as baseAuthenticatedRequest,
  getURLSearchParamsForArgs,
  unauthenticatedRequest as baseUnauthenticatedRequest,
  validatedRequest as baseValidatedRequest,
  validatedRequestWithAuth as baseValidatedRequestWithAuth,
} from '../api-utils';

export { getURLSearchParamsForArgs };
const baseURL = process.env['NX_SD_PUBLIC_API_URL'] || '';

/**
 * For use when you know that this request never needs to be authenticated, or can't be authenticated (such as when the server is making this request for prerendering).
 * Also doesn't toast the error message.
 * @param path
 * @param options
 * @returns
 */
export async function unauthenticatedRequest(
  path: string,
  options: AxiosRequestConfig = {}
) {
  return baseUnauthenticatedRequest(path, options, baseURL);
}

export async function authenticatedRequest(
  path: string,
  options: AxiosRequestConfig = {},
  baseUrl: string = baseURL
) {
  return baseAuthenticatedRequest(path, options, baseUrl);
}

export async function validatedRequestWithAuth<S extends ZodTypeAny>(
  schema: S,
  path: string,
  options?: AxiosRequestConfig,
  baseUrl: string = baseURL
) {
  return baseValidatedRequestWithAuth(schema, path, baseUrl, options);
}

export async function validatedRequest<S extends ZodTypeAny>(
  schema: S,
  path: string,
  options?: AxiosRequestConfig
) {
  return baseValidatedRequest(schema, path, baseURL, options);
}
