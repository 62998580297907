import { StatRecord, Stats } from '@stardust-monorepo/types/sd-private';
import {
  extractData,
  isDoneLoading,
  SdPrivateApi,
} from '@stardust-monorepo/web-sdk-apps-shared';
import { UseQueryResult } from '@tanstack/react-query';
import {
  addDays,
  startOfQuarter,
  subMonths,
  subQuarters,
  subWeeks,
} from 'date-fns';
import { useEffect, useMemo, useState } from 'react';

import { TimeFrame } from '../types/timeframe';

const totalStatRecord = (stats: StatRecord) =>
  Object.keys(stats).reduce((total, timeBlock) => {
    total += stats[timeBlock];
    return total;
  }, 0);

export interface StatTotals {
  totalActivePlayers: number;
  totalNewPlayers: number;
}
const emptyTotals: StatTotals = {
  totalActivePlayers: 0,
  totalNewPlayers: 0,
};
const getTotals = (statQueries: UseQueryResult<Stats>[]) => {
  const allStats = extractData(statQueries);
  return allStats.reduce(
    (acc, gameStats) => {
      acc.totalActivePlayers += gameStats.distinctActivePlayerCount;
      acc.totalNewPlayers += totalStatRecord(gameStats.dailyNewPlayerCounts);
      return acc;
    },
    { ...emptyTotals }
  );
};

export const useGameStats = (
  timeFrame: TimeFrame,
  gameIds: number[],
  calculatePriorStats?: boolean
) => {
  const now = useMemo(() => new Date(), []);
  const [currentTotals, setCurrentTotals] = useState<StatTotals>({
    ...emptyTotals,
  });
  const [priorTotals, setPriorTotals] = useState<StatTotals>({
    ...emptyTotals,
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // oddly its 6 instead of 7 for a week of data...
  const startDate = addDays(
    timeFrame === 'week'
      ? subWeeks(now, 1)
      : timeFrame === 'month'
      ? subMonths(now, 1)
      : startOfQuarter(now),
    1
  );

  //Prior stats don't really make sense w/ quarter because current quarter stats show QTD and prior will be the whole quarter
  const priorEndDate =
    timeFrame === 'week'
      ? subWeeks(now, 1)
      : timeFrame === 'month'
      ? subMonths(now, 1)
      : startOfQuarter(now);
  const priorStartDate = addDays(
    timeFrame === 'week'
      ? subWeeks(now, 2)
      : timeFrame === 'month'
      ? subMonths(now, 2)
      : subQuarters(now, 2),
    1
  );

  const allCurrentStatQueries = SdPrivateApi.useAllStats({
    games: gameIds,
    startDate: startDate.getTime(),
    endDate: now.getTime(),
  });
  const doneLoadingCurrent = isDoneLoading(allCurrentStatQueries);

  const allPriorStatQueries = SdPrivateApi.useAllStats({
    games: gameIds,
    startDate: priorStartDate.getTime(),
    endDate: priorEndDate.getTime(),
    enabled: calculatePriorStats,
  });
  const doneLoadingPrior =
    !calculatePriorStats || isDoneLoading(allPriorStatQueries);

  useEffect(() => {
    if (doneLoadingCurrent && doneLoadingPrior) {
      setCurrentTotals(getTotals(allCurrentStatQueries));
      setPriorTotals(getTotals(allPriorStatQueries));
      setIsLoading(false);
    } else {
      setIsLoading(true);
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [doneLoadingCurrent, doneLoadingPrior]);
  return {
    currentTotals,
    priorTotals,
    isLoading,
  };
};
