import { z } from 'zod';

const StatRecordSchema = z
  .record(z.string(), z.number())
  .describe('StatRecordSchema');
export type StatRecord = z.infer<typeof StatRecordSchema>;

export const StatsSchema = z
  .object({
    dailyActivePlayerCounts: StatRecordSchema,
    dailyNewPlayerCounts: StatRecordSchema,
    dailyRevenueAmounts: StatRecordSchema,
    dailyTokensTradedCounts: StatRecordSchema,
    distinctActivePlayerCount: z.number(),
  })
  .describe('StatsSchema');
export type Stats = z.infer<typeof StatsSchema>;
