import { IconButton, Link as MuiLink } from '@mui/material';
import { Files, GearSix } from '@phosphor-icons/react';
import {
  ContactMenu,
  PageHeaderBase,
  PageHeaderBaseProps,
  documentationState,
  selectIsSsoUser,
} from '@stardust-monorepo/web-sdk-apps-shared';
import { PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

export const PageHeader = ({
  label,
  renderActions,
  children,
}: PropsWithChildren<PageHeaderBaseProps>) => {
  const documentationLink = useRecoilValue(documentationState);
  const isSsoUser = useRecoilValue(selectIsSsoUser);

  return (
    <PageHeaderBase
      label={label}
      renderActions={() => {
        return (
          <>
            {renderActions?.()}
            {documentationLink ? (
              <MuiLink target="_blank" rel="noopener" href={documentationLink}>
                <IconButton sx={{ color: 'text.secondary' }}>
                  <Files size="20" weight="duotone" />
                </IconButton>
              </MuiLink>
            ) : null}
            <ContactMenu />
            {!isSsoUser && (
              <Link to="/settings">
                <IconButton
                  sx={{ color: 'text.secondary' }}
                  data-testid="top-header-settings-button"
                >
                  <GearSix size="20" weight="duotone" />
                </IconButton>
              </Link>
            )}
          </>
        );
      }}
    >
      {children}
    </PageHeaderBase>
  );
};
