import {
  BlockchainNetwork,
  BlockchainNetworkSchema,
} from '@stardust-monorepo/types/sd-private';

import { validatedRequestWithAuth } from './api-utils';

export const getBlockchainNetworks = async () => {
  return validatedRequestWithAuth(
    BlockchainNetworkSchema.array(),
    'blockchain-networks/get-all'
  ) as Promise<BlockchainNetwork[]>;
};
