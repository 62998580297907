import { TablePagination } from '@mui/material';
import React from 'react';

export const PAGE_SIZE = 20;

export interface PaginationProps {
  count?: number;
  page: number;
  setPage: (page: number) => void;
}

export function Pagination({ count = 0, page, setPage }: PaginationProps) {
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  return (
    <TablePagination
      component="div"
      count={count}
      onPageChange={handleChangePage}
      page={page}
      rowsPerPage={PAGE_SIZE}
      rowsPerPageOptions={[PAGE_SIZE]}
    />
  );
}
