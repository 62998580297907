import { Box, Card, Link, Paper, Stack, Typography } from '@mui/material';
import { ArrowSquareOut, Files } from '@phosphor-icons/react';
import React, { useState } from 'react';

interface DocumentationItem {
  link: string;
  title: string;
  body: string;
}

const docItems: DocumentationItem[] = [
  {
    link: 'https://docs.stardust.gg/docs/creating-your-first-game',
    title: 'Creating Your First Game',
    body: 'To add a game, click the "+ Add Game" button.',
  },
  {
    link: 'https://docs.stardust.gg/docs/creating-a-token-template',
    title: 'Creating a Token Template',
    body: 'In order to mint fungible tokens (FT) and non-fungible tokens (NFT) you will need to create a token template.',
  },
  {
    link: 'https://docs.stardust.gg/docs/minting-tokens',
    title: 'Minting Tokens',
    body: 'Using Stardust, you can mint tokens using an API without ever writing a smart contract.',
  },
  {
    link: 'https://docs.stardust.gg/docs/erc721',
    title: 'Deploy an ERC721 Collection',
    body: 'This feature lets you deploy a unique ERC-721 contract that is fully interoperable with the Stardust Core API, Stardust Vaults, and marketplaces like OpenSea.',
  },
  {
    link: 'https://docs.stardust.gg/docs/deploy-an-erc20',
    title: 'ERC20 Tokens',
    body: 'This feature lets you deploy an ERC-20 contract that you can use to mint Fungible Tokens (FTs).',
  },
  {
    link: 'https://docs.stardust.gg/docs/feature-support-by-blockchain',
    title: 'Feature Support by Blockchain',
    body: 'Stardust currently supports three blockchains: Polygon, Avalanche, and Solana.',
  },
];

interface DocumentationRowProps {
  item: DocumentationItem;
}

const DocumentationRow = ({ item }: DocumentationRowProps) => {
  const [hover, setHover] = useState(false);

  return (
    <Link
      target="_blank"
      rel="noopener"
      href={item.link}
      sx={{ '&:hover': { textDecoration: 'none' } }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          py: 2,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'center',
            width: '30px',
            height: '42px',
            pt: 0.5,
            mr: 1.5,
            backgroundColor: '#EDDB39',
            color: '#EDDB39',
            borderRadius: 1.5,
            flexShrink: 0,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '24px',
              height: '24px',
              borderRadius: 0.5,
              backgroundColor: 'background.white',
              flexShrink: 0,
            }}
          >
            <Files size={20} weight="duotone" />
          </Box>
        </Box>
        <Stack
          spacing={0.5}
          sx={{
            flexShrink: 1,
            overflow: 'hidden',
            width: 'calc(100% - 30px)',
          }}
        >
          <Typography
            variant="subtitle2"
            color={hover ? 'primary.main' : 'text.primary'}
          >
            {item.title}
          </Typography>
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            {item.body}
          </Typography>
        </Stack>
      </Box>
    </Link>
  );
};

export const DocumentationCard = () => (
  <Paper
    elevation={1}
    sx={{
      padding: 2,
      backgroundColor: 'background.white',
      width: '100%',
      borderRadius: 3,
    }}
  >
    <Stack direction="row" spacing={0.5}>
      <Typography variant="subtitle2">Documentation</Typography>
      <Link
        target="_blank"
        rel="noopener"
        href="https://docs.stardust.gg/"
        sx={{ color: 'text.hint' }}
      >
        <ArrowSquareOut size={18} />
      </Link>
    </Stack>
    <Card
      variant="outlined"
      sx={{
        borderRadius: 2,
        px: 2,
        mt: 1,
      }}
    >
      {docItems.map((item, index) => (
        <DocumentationRow item={item} key={index} />
      ))}
    </Card>
  </Paper>
);
