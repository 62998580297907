import {
  Breadcrumbs,
  Button,
  CircularProgress,
  Typography,
} from '@mui/material';
import { Collection } from '@stardust-monorepo/types/sd-core';
import {
  HeavyDialog,
  HeavyDialogActions,
  HeavyDialogContent,
  HeavyDialogTitle,
  Spinner,
  track,
  UnlimitedTemplateCap,
  useCreateItem,
  SdPrivateApi,
  useToast,
} from '@stardust-monorepo/web-sdk-apps-shared';
import React, { useRef } from 'react';

import { mapToProperties } from '../../properties';
import { CreateItemForm } from './CreateItemForm';

interface CreateItemProps {
  gameId: number;
  collection: Collection;
  open: boolean;
  onClose: () => void;
  sourcePage: string;
}

export const CreateItemModal = ({
  gameId,
  collection,
  open,
  onClose,
  sourcePage,
}: CreateItemProps) => {
  const submitFormButtonRef = useRef<HTMLButtonElement | null>(null);
  const toaster = useToast();
  const createItem = useCreateItem();
  const { data: game, error } = SdPrivateApi.useGame(gameId);

  const doOnClose = () => {
    track(`Create Item Close`, 'Clicked', {
      gameId,
    });
    onClose();
  };

  const eventName = 'Create Item';

  if (!game) {
    if (!error) {
      return <Spinner />;
    } else {
      return (
        <Typography>
          An error has occurred:{' '}
          {error instanceof Error && error.message
            ? error.message
            : 'unknown error loading Game'}
        </Typography>
      );
    }
  }

  return (
    <HeavyDialog open={open} onClose={doOnClose}>
      <HeavyDialogTitle>
        <Breadcrumbs>
          <Typography variant="subtitle1" color="text.hint">
            {sourcePage}
          </Typography>
          <Typography variant="subtitle1">New Item</Typography>
        </Breadcrumbs>
      </HeavyDialogTitle>
      <HeavyDialogContent>
        <CreateItemForm
          collectionType={collection.type}
          submitFormRef={submitFormButtonRef}
          onSave={async (data) => {
            const trackingData = {
              gameId,
            };

            try {
              await createItem.mutateAsync({
                gameId,
                collectionId: collection.id,
                blockchainId: game.blockchain,
                name: data.name,
                description: data.description,
                publicMetadata: mapToProperties(data.publicMetadata),
                totalSupply:
                  data.supplyType === 'Limited'
                    ? data.totalSupply
                    : UnlimitedTemplateCap,
                image: data.image,
              });

              track(eventName, 'Success', trackingData);
              toaster('Item created successfully');
              onClose();
            } catch (e) {
              if (e instanceof Error) {
                track(eventName, 'Failure', trackingData);
                toaster(
                  `An error occurred while Creating the Item : ${e.message}`,
                  'error'
                );
              }
            }
          }}
        />
      </HeavyDialogContent>
      <HeavyDialogActions>
        <Button
          size="large"
          variant="outlined"
          onClick={doOnClose}
          color="secondary"
        >
          Cancel
        </Button>
        <Button
          disabled={createItem.isLoading}
          size="large"
          variant="contained"
          onClick={() => {
            submitFormButtonRef.current?.click();
            track(`{eventName} Save`, 'Clicked');
          }}
        >
          {createItem.isLoading ? (
            <CircularProgress size="18px" color="primary" />
          ) : (
            'Create Item'
          )}
        </Button>
      </HeavyDialogActions>
    </HeavyDialog>
  );
};
