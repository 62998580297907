import { Stack, Typography } from '@mui/material';
import { Blockchain as BlockchainId } from '@stardust-monorepo/types/sd-private';
import { InputLabel, Spinner } from '@stardust-monorepo/web-sdk-apps-shared';
import { PropsWithChildren, useEffect, useMemo, useState } from 'react';

import { useBlockchainNetworks } from '../../api/sd-private/hooks/useBlockchainNetworks';
import polygon from '../../assets/blockchains/polygon.png';
import solana from '../../assets/blockchains/solana.png';
import { SelectButton, TextSelectButton } from '../select-button';

const blockchainImageMap: Record<string, { image: string; height: string }> = {
  solana: {
    image: solana,
    height: '22px',
  },
  polygon: {
    image: polygon,
    height: '28px',
  },
} as const;
type BlockchainNetworkMap = Record<
  string,
  {
    id: number;
    label: string;
  }[]
>;

const BlockchainButton = ({
  checked,
  onClick,
  disabled,
  children,
}: PropsWithChildren<{
  checked: boolean;
  onClick: () => void;
  disabled?: boolean;
}>) => {
  return (
    <SelectButton
      checked={checked}
      onClick={onClick}
      height={80}
      disabled={disabled}
    >
      <Stack
        sx={{
          paddingX: 2,
        }}
      >
        {children}
      </Stack>
    </SelectButton>
  );
};

export const SelectBlockchain = ({
  onChange,
  value: passedValue,
  disabled,
}: {
  value?: BlockchainId;
  onChange?: (newValue: BlockchainId) => void;
  disabled?: boolean;
}) => {
  const [chain, setChain] = useState<string | undefined>();
  const [value, setValue] = useState<BlockchainId | undefined>(passedValue);
  const { data: blockchainNetworks, isLoading } = useBlockchainNetworks();

  const setDefaultValueForChain = (
    chain: string,
    networkMap: BlockchainNetworkMap
  ) => {
    if (networkMap) {
      setChain(chain);
      setValue(networkMap[chain][0].id);
      onChange?.(networkMap[chain][0].id);
    }
  };

  const blockchainNetworkMap = useMemo(() => {
    return (
      blockchainNetworks
        ?.filter((network) => network.name !== 'avalanche') //support for avax dropped, filtering from blockchain-networks response
        .reduce((networkMap, network) => {
          const mapEntry = {
            id: network.id,
            label: network.type,
          };
          networkMap[network.name] = networkMap[network.name]
            ? [...networkMap[network.name], mapEntry]
            : [mapEntry];
          return networkMap;
        }, {} as BlockchainNetworkMap) || {}
    );
  }, [blockchainNetworks, passedValue]);

  useEffect(() => {
    if (passedValue && blockchainNetworkMap) {
      const chainForNetworkId: string | undefined = Object.keys(
        blockchainNetworkMap
      ).find((chain) =>
        blockchainNetworkMap[chain].find((network) => network.id === value)
      );
      if (chain !== chainForNetworkId) {
        setChain(chainForNetworkId);
      }
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [passedValue, blockchainNetworkMap]);

  return (
    <Stack>
      <InputLabel sx={{ mt: 3, mb: 1.5 }}>*Select a Blockchain</InputLabel>
      {isLoading ? (
        <Spinner height="150px" text="Loading Blockchains" />
      ) : (
        <>
          <Stack
            direction="row"
            gap={1}
            sx={{
              width: '100%',
            }}
          >
            {Object.keys(blockchainNetworkMap ?? {})
              .sort((chain1: string, chain2: string) => {
                const chainWeight: Record<string, number> = {
                  polygon: 3,
                  solana: 1,
                };
                return (
                  (chainWeight[chain2] || 1.5) - (chainWeight[chain1] || 1.5)
                );
              })
              .map((blockchain) => (
                <BlockchainButton
                  key={blockchain}
                  disabled={disabled}
                  onClick={() => {
                    setDefaultValueForChain(blockchain, blockchainNetworkMap);
                  }}
                  checked={blockchain === chain}
                >
                  {blockchainImageMap[blockchain] ? (
                    <img
                      style={{
                        maxHeight: blockchainImageMap[blockchain].height,
                        ...(blockchain !== chain && chain
                          ? {
                              filter: 'grayscale(1) opacity(0.75)',
                            }
                          : {}),
                      }}
                      src={blockchainImageMap[blockchain].image}
                      alt={`${blockchain} logo`}
                    />
                  ) : (
                    //this is just a fallback - there should always be an image
                    <Typography variant={'body1'}>{blockchain}</Typography>
                  )}
                </BlockchainButton>
              ))}
          </Stack>
          <>
            <InputLabel sx={{ mt: 3, mb: 1.5 }}>*Select a Network</InputLabel>
            <Stack
              direction="row"
              gap={1.5}
              sx={{
                width: '100%',
              }}
            >
              {blockchainNetworkMap?.[chain || 'polygon'].map((network) => (
                <TextSelectButton
                  disabled={disabled || !chain}
                  key={network.id}
                  checked={value === network.id}
                  onClick={() => {
                    setValue(network.id);
                    onChange?.(network.id);
                  }}
                >
                  {network.label}
                </TextSelectButton>
              ))}
            </Stack>
          </>
        </>
      )}
    </Stack>
  );
};
