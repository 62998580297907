import { Tooltip } from '@mui/material';
import { PropsWithChildren } from 'react';

export const DeprecationTooltip = ({
  open,
  children,
}: PropsWithChildren<{ open?: boolean }>) => {
  return (
    <Tooltip title="The managed platform is deprecated. Game creation has been disabled.">
      <span>{children}</span>
    </Tooltip>
  );
};
