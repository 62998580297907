import {
  Documentation,
  DocumentationProps,
} from '@stardust-monorepo/web-sdk-apps-shared';

export const CreateDocumentation = (props: DocumentationProps) => (
  <Documentation
    {...props}
    sx={{
      width: '260px',
      minWidth: '260px',
      ...props.sx,
    }}
  />
);
