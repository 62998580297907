import { zodResolver } from '@hookform/resolvers/zod';
import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Stack,
} from '@mui/material';
import { SdError } from '@stardust-monorepo/types/common';
import {
  BasicDialog as Dialog,
  Input,
  InputLabel,
  SdPrivateApi,
  track,
} from '@stardust-monorepo/web-sdk-apps-shared';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { z } from 'zod';

export interface AddTeamMemberProps {
  emails: string[];
  gameId: number;
  onConfirm: (email: string) => void;
  open: boolean;
  onClose: () => void;
}

const formControls = {
  email: 'email',
} as const;

const formSchema = z
  .object({
    [formControls.email]: z.string().email(),
  })
  .required();

export const AddTeamMember = ({
  emails,
  gameId,
  onClose,
  onConfirm,
  open,
}: AddTeamMemberProps) => {
  const [invalidSubmissionError, setInvalidSubmissionError] =
    useState<string>('');
  const createTeamMember = SdPrivateApi.useCreateTeamMember();
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      [formControls.email]: '',
    },
    resolver: zodResolver(formSchema),
  });

  useEffect(() => {
    const subscription = watch(() => setInvalidSubmissionError(''));
    return () => subscription.unsubscribe();
  }, [watch]);

  const onCreateTeamMember = async (email: string) => {
    const trackingData = { gameId };
    track('Modal Add Team Member', 'Clicked', trackingData);
    if (emails.includes(email)) {
      track('Modal Add Team Member', 'Failure', trackingData);
      setInvalidSubmissionError(`${email} is already a team member`);
      return;
    }
    try {
      await createTeamMember.mutateAsync({
        gameId,
        teamMemberEmail: email,
      });
      track('Modal Add Team Member', 'Success', trackingData);
      onConfirm(email);
    } catch (e) {
      track('Modal Add Team Member', 'Failure', trackingData);
      const error = e as SdError;
      if (error?.message.startsWith(`User ${email} not found`)) {
        setInvalidSubmissionError(
          `Looks like ${email} has not registered for a developer account.`
        );
      } else {
        setInvalidSubmissionError('Unexpected error. Please try again.');
      }
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <Stack
        component="form"
        data-testid="add-team-member-form"
        onSubmit={handleSubmit((data) => {
          onCreateTeamMember(data[formControls.email]);
        })}
        sx={{ width: '300px' }}
      >
        <DialogTitle variant="h5">Add Team Member</DialogTitle>
        <DialogContent>
          <Controller
            control={control}
            name={formControls.email}
            render={({ field: { ref, ...fieldProps } }) => (
              <FormControl
                margin={'normal'}
                error={
                  Boolean(invalidSubmissionError) ||
                  !!errors[formControls.email]
                }
                sx={{ width: '100%' }}
              >
                <>
                  <InputLabel htmlFor={formControls.email}>
                    Email Address
                  </InputLabel>
                  <Input
                    {...fieldProps}
                    inputRef={ref}
                    id={formControls.email}
                    fullWidth
                    autoComplete="off"
                  />
                  {errors[formControls.email] && (
                    <FormHelperText>
                      {errors[formControls.email]?.message}
                    </FormHelperText>
                  )}
                  {invalidSubmissionError ? (
                    <FormHelperText>{invalidSubmissionError}</FormHelperText>
                  ) : null}
                </>
              </FormControl>
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button
            disabled={createTeamMember.isLoading}
            onClick={onClose}
            variant="outlined"
            color="secondary"
          >
            Cancel
          </Button>
          <Button
            type="submit"
            disabled={createTeamMember.isLoading}
            autoFocus
            variant="contained"
          >
            {createTeamMember.isLoading ? (
              <CircularProgress size="18px" color="primary" />
            ) : (
              `Add`
            )}
          </Button>
        </DialogActions>
      </Stack>
    </Dialog>
  );
};
