export const color = {
  brand: {
    primary: {
      900: '#211E3B',
      800: '#2A236C',
      700: '#362197',
      600: '#4E28C3',
      500: '#642FED',
      400: '#8C62EF',
      300: '#A98CEE',
      200: '#C2AEEF',
      100: '#DFD3F8',
      50: '#F3EEFC',
    },
    secondary: {
      900: '#2F1C54',
      800: '#400297',
      700: '#5C0BC1',
      600: '#7314E1',
      500: '#871DF6',
      400: '#9C4AFD',
      300: '#B170FE',
      200: '#D1AEFE',
      100: '#E9D8FE',
      50: '#F7F0FF',
    },
  },
  neutral: {
    black: '#000000',
    900: '#1B1D22',
    800: '#3B3E43',
    700: '#5A5D63',
    600: '#6D7177',
    500: '#9598A1',
    400: '#B5B9C0',
    300: '#D8DCE4',
    200: '#EAECF1',
    100: '#F2F4F9',
    50: '#F8FAFC',
    white: '#FFFFFF',
  },
  intent: {
    error: {
      dark: '#872122',
      main: '#F25757',
      light: '#FFE0E5',
    },
    warning: {
      dark: '#9B5D27',
      main: '#EBC934',
      light: '#F9F4C2',
    },
    success: {
      dark: '#196C34',
      main: '#48D28A',
      light: '#E2F7EB',
    },
    info: {
      dark: '#005692',
      main: '#009BDB',
      light: '#E1F5FC',
    },
  },
  accent: {
    //TODO these need to be updated
    green: {
      900: '#007A51',
      800: '#00996E',
      700: '#00AA7D',
      600: '#00BC8E',
      500: '#00CB9C',
      400: '#15D6AA',
      300: '#41E2BA',
      200: '#6FEECD',
      100: '#A3F6E0',
      50: '#DAFCF4',
    },
    aqua: {
      900: '#36787C',
      800: '#439CA8',
      700: '#49B1C0',
      600: '#51C7DB',
      500: '#57D9F0',
      400: '#5FDFF2',
      300: '#74E6F6',
      200: '#98EDF9',
      100: '#BFF4FB',
      50: '#E5FBFE',
    },
    blue: {
      900: '#00578D',
      800: '#0F76AD',
      700: '#1087C0',
      600: '#1999D3',
      500: '#1FA7E1',
      400: '#38B5E5',
      300: '#57C1E8',
      200: '#83D3EF',
      100: '#B3E4F5',
      50: '#E1F5FB',
    },
    magenta: {
      900: '#69006E',
      800: '#840079',
      700: '#930080',
      600: '#A40086',
      500: '#B1008B',
      400: '#BF339A',
      300: '#CB5CAA',
      200: '#DB8CC2',
      100: '#E9BADA',
      50: '#F6E4F0',
    },
    orange: {
      900: '#B04C00',
      800: '#C85A01',
      700: '#D56103',
      600: '#E26908',
      500: '#ED6F0D',
      400: '#EF823C',
      300: '#F09763',
      200: '#F3B391',
      100: '#F7D0BC',
      50: '#F8EBE7',
    },
    gold: {
      900: '#5E4900',
      800: '#7B6C00',
      700: '#8E8100',
      600: '#9F9400',
      500: '#ABA400',
      400: '#B7AF26',
      300: '#C3BB4F',
      200: '#D5CE80',
      100: '#E7E1B2',
      50: '#F5F3E0',
    },
  },
};
