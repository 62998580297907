import { zodResolver } from '@hookform/resolvers/zod';
import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  Stack,
} from '@mui/material';
import { Player } from '@stardust-monorepo/types/sd-private';
import {
  BasicDialog,
  FormField,
  SdPrivateApi,
  track,
  useHandleError,
  useToast,
} from '@stardust-monorepo/web-sdk-apps-shared';
import React, { useRef } from 'react';
import { FieldErrors, useForm } from 'react-hook-form';
import { z } from 'zod';

import {
  AddProperties,
  mapFromProperties,
  mapToProperties,
  Property,
  ZodPropertyValidation,
} from '../properties';

export const formControls = {
  uniqueId: 'uniqueId',
  props: 'props',
} as const;

const formSchema = z
  .object({
    [formControls.uniqueId]: z.string().min(1, 'Unique ID is required'),
    [formControls.props]: ZodPropertyValidation,
  })
  .required();

interface FormValues {
  uniqueId: string;
  props: Property[];
}

export interface CreatePlayerModalProps {
  gameId: number;
  open: boolean;
  onClose: () => void;
  player?: Player | null;
}

export const CreatePlayerModal = ({
  gameId,
  open,
  onClose,
  player,
}: CreatePlayerModalProps) => {
  const submitFormButtonRef = useRef<HTMLButtonElement | null>(null);
  const toaster = useToast();
  const handleError = useHandleError();

  const createPlayer = SdPrivateApi.useCreatePlayer();
  const mutatePlayer = SdPrivateApi.useMutatePlayer();
  const deletePlayerProperty = SdPrivateApi.useDeletePlayerProperty();

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<FormValues>({
    defaultValues: {
      [formControls.uniqueId]: player?.uniqueId || '',
      [formControls.props]: player ? mapFromProperties(player.userData) : [],
    },
    resolver: zodResolver(formSchema),
  });

  const doCreatePlayer = async (formValues: FormValues) => {
    if (player) {
      try {
        const names = Object.keys(player.userData).reduce<string[]>(
          (acc, propName) => {
            if (!formValues.props.find((p) => p.name === propName)) {
              acc.push(propName);
            }
            return acc;
          },
          []
        );
        if (names.length > 0) {
          await deletePlayerProperty.mutateAsync({
            gameId,
            playerId: player.playerId,
            names,
          });
        }
        await mutatePlayer.mutateAsync({
          gameId,
          playerId: player.playerId,
          props: mapToProperties(formValues.props),
        });
        track('Edit Player', 'Success', {
          gameId,
        });
        toaster('Player saved successfully');
        onClose();
      } catch (e) {
        handleError(
          e,
          'An error occurred while editing player',
          'Edit Player',
          {
            gameId,
          }
        );
      }
    } else {
      try {
        await createPlayer.mutateAsync({
          gameId,
          uniqueId: formValues.uniqueId,
          userData: mapToProperties(formValues.props),
        });
        track('Create Player', 'Success', {
          gameId,
        });
        toaster('Player created successfully');
        onClose();
      } catch (e) {
        handleError(
          e,
          'An error occurred while creating player',
          'Create Player',
          {
            gameId,
          }
        );
      }
    }
  };

  const doOnClose = () => {
    track(`${player ? 'Edit' : 'Create'} Player Close`, 'Clicked', {
      gameId,
      playerId: player?.id,
    });
    onClose();
  };

  const isLoading =
    createPlayer.isLoading ||
    mutatePlayer.isLoading ||
    deletePlayerProperty.isLoading;

  return (
    <BasicDialog open={open} onClose={doOnClose}>
      <DialogTitle variant="h5">
        {player ? 'Edit Player' : 'Create New Player'}
      </DialogTitle>
      <DialogContent sx={{ width: '600px' }}>
        <Stack
          component="form"
          sx={{ flexGrow: 1 }}
          onSubmit={handleSubmit((data) => {
            doCreatePlayer(data);
          })}
          gap={2}
        >
          <FormField
            maxInputWidth="unset"
            disabled={!!player}
            formControlName={formControls.uniqueId}
            control={control}
            label="Unique ID"
            hasError={!!errors[formControls.uniqueId]}
            renderHelpText={() =>
              errors[formControls.uniqueId] && (
                <FormHelperText>
                  {errors[formControls.uniqueId]?.message}
                </FormHelperText>
              )
            }
          />
          <AddProperties
            control={control}
            name="player"
            errors={errors[formControls.props] as FieldErrors[]}
            watch={watch}
          />
          <button ref={submitFormButtonRef} style={{ display: 'none' }} />
        </Stack>
      </DialogContent>
      <DialogActions
        sx={{ borderTop: '1px solid', borderColor: 'divider', mt: 2.5 }}
      >
        <Button
          disabled={isLoading}
          onClick={doOnClose}
          variant="outlined"
          color="secondary"
          sx={{ mt: 1.5 }}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          disabled={isLoading}
          autoFocus
          variant="contained"
          onClick={() => {
            track('Confirm Create Player', 'Clicked', {
              gameId,
            });
            submitFormButtonRef.current?.click();
          }}
          sx={{ mt: 1.5 }}
        >
          {isLoading ? (
            <CircularProgress size="18px" color="primary" />
          ) : player ? (
            'Save Changes'
          ) : (
            'Create Player'
          )}
        </Button>
      </DialogActions>
    </BasicDialog>
  );
};
