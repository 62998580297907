import {
  AlertColor,
  Box,
  Button,
  Card,
  CircularProgress,
  Paper,
  Skeleton,
  Stack,
  styled,
  Typography,
} from '@mui/material';
import { ArrowUpRight, GameController } from '@phosphor-icons/react';
import {
  color,
  EmptyContainer,
  handleError,
  SdPrivateApi,
  track,
  useToast,
} from '@stardust-monorepo/web-sdk-apps-shared';

import logo from '../../../assets/logo.svg';
import { paymentsAllowListError } from '../../constants';
import { isPaymentsAllowedForGame } from '../../util';

const StyledGameController = styled(GameController)({ opacity: 0.8 });

interface PaymentsOnboardingProps {
  gameId: number;
  isLoading: boolean;
  setIsLoading: (loading: boolean) => void;
  name: string;
}

export const launchOnboarding = async (
  gameId: number,
  setIsLoading: (loading: boolean) => void,
  toaster: (msg: string, severity?: AlertColor) => void
) => {
  // Calling the onboard api changes the status so don't call it from
  // view useQuery
  try {
    setIsLoading(true);
    if (isPaymentsAllowedForGame(gameId)) {
      const paymentOnboarding = await SdPrivateApi.getPaymentProviderOnboarding(
        {
          gameId,
          provider: 'STRIPE',
          refreshUrl: window.location.href,
          returnUrl: window.location.href,
        }
      );
      track('Get Payment Provider URL', 'Success', { gameId });
      window.open(paymentOnboarding.redirectUrl);
    } else {
      toaster(paymentsAllowListError, 'error');
    }
  } catch (e) {
    handleError(
      toaster,
      e,
      'Error launching payments onboarding',
      'Get Payment Provider URL',
      { gameId }
    );
  }
  setIsLoading(false);
};

export const PaymentsOnboarding = ({
  gameId,
  isLoading,
  setIsLoading,
  name,
}: PaymentsOnboardingProps) => {
  const toaster = useToast();
  const skeletonRows = [];
  for (let i = 0; i < 3; i++) {
    skeletonRows.push(
      <Box key={i}>
        <Skeleton
          variant="rectangular"
          animation={false}
          height="6px"
          width="33px"
          sx={{ borderRadius: 0.5, backgroundColor: 'divider' }}
        />
        <Skeleton
          variant="rounded"
          animation={false}
          height="16px"
          width="100%"
          sx={{
            borderRadius: 1.5,
            backgroundColor: color.neutral[100],
            mt: 0.75,
          }}
        />
      </Box>
    );
  }
  return (
    <EmptyContainer
      sx={{
        height: '276px',
        border: '1px solid',
        borderColor: 'divider',
        borderRadius: 3,
        padding: 1.5,
      }}
    >
      <Box
        sx={{
          backgroundColor: 'background.cool',
          borderRadius: 2,
          width: '100%',
          height: '100%',
          px: 5.5,
          py: 4.5,
        }}
      >
        <Box sx={{ py: 2.5, pl: 29.5, width: '100%', position: 'relative' }}>
          <Paper
            elevation={3}
            sx={{
              width: '40px',
              height: '40px',
              backgroundColor: 'primary.main',
              border: '2px solid',
              borderColor: 'background.white',
              borderRadius: '50%',
              position: 'absolute',
              top: '-20px',
              left: '80px',
              zIndex: 1,
              color: 'background.white',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              pb: 0.25,
            }}
          >
            <StyledGameController size={24} weight="duotone" />
          </Paper>
          <Card
            variant="outlined"
            sx={{
              width: '200px',
              height: '280px',
              position: 'absolute',
              left: 0,
              top: 0,
              borderRadius: 2,
              borderColor: 'text.secondary',
            }}
          >
            <Stack
              direction="column"
              spacing={0.5}
              sx={{
                backgroundColor: 'background.cool',
                pt: 4,
                pb: 1.5,
                alignItems: 'center',
              }}
            >
              <Typography variant="tooltip" sx={{ color: 'text.hint' }}>
                powered by
              </Typography>
              <img alt="Stardust Logo" width="98px" src={logo} />
            </Stack>
            <Box sx={{ pt: 2, pb: 2.5, px: 3 }}>
              <Stack spacing={2}>{skeletonRows}</Stack>
              <Skeleton
                variant="rounded"
                animation={false}
                height="32px"
                width="100%"
                sx={{
                  borderRadius: 2.5,
                  mt: 2.5,
                  border: '1px solid',
                  borderColor: 'primary.main',
                  backgroundColor: color.brand.primary[50],
                }}
              />
            </Box>
          </Card>
          <Typography
            variant="subtitle1"
            sx={{ color: 'text.secondary', mb: 1 }}
          >
            Getting Started...
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary', mb: 3 }}>
            Before you can get started creating and listing items, you will need
            to set up your merchant account.
          </Typography>
          <Button
            variant="contained"
            endIcon={<ArrowUpRight />}
            onClick={() => {
              track(`${name} Verify My Business`, 'Clicked');
              launchOnboarding(gameId, setIsLoading, toaster);
            }}
            disabled={isLoading}
          >
            {isLoading ? (
              <CircularProgress size="18px" color="primary" />
            ) : (
              'Verify My Business'
            )}
          </Button>
        </Box>
      </Box>
    </EmptyContainer>
  );
};
