import {
  Box,
  CircularProgress,
  CircularProgressProps,
  Typography,
} from '@mui/material';

interface Props {
  color?: CircularProgressProps['color'];
  height?: string;
  text?: string;
  grow?: boolean;
}

export function Spinner({
  color,
  grow,
  height = '100vh',
  text = 'Loading...',
}: Props) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: grow ? undefined : height,
        flexGrow: grow ? 1 : undefined,
      }}
      data-testid="spinner"
    >
      <Box sx={{ marginBottom: '20px' }}>
        <CircularProgress color={color} />
      </Box>
      <Typography variant="body1">{text}</Typography>
    </Box>
  );
}
