import { Box, Button, Typography } from '@mui/material';
import { HandCoins } from '@phosphor-icons/react';
import { Item } from '@stardust-monorepo/types/sd-core';
import {
  ContainedTable,
  EmptyContainer,
  track,
  useItemBalances,
} from '@stardust-monorepo/web-sdk-apps-shared';
import { format } from 'date-fns';
import React, { useMemo, useState } from 'react';

import { AddItemBalance } from '../item-balance/AddItemBalance';

const headings = [
  { label: 'Player ID' },
  { label: 'Amount' },
  { label: 'First Obtained' },
  { label: 'Last Updated' },
];
export const ItemOwnerTable = ({
  item,
  gameId,
  isLoading,
}: {
  item: Item;
  gameId: number;
  isLoading: boolean;
}) => {
  const {
    data: balances,
    error,
    isLoading: isLoadingBalances,
  } = useItemBalances({
    gameId: gameId,
    itemId: item.id,
  });
  const [addingBalance, setAddingBalance] = useState<boolean>(false);

  const rows = useMemo(
    () =>
      (balances?.results || []).map((balance) => {
        return [
          <b>{balance.playerId}</b>,
          balance.amount,
          format(new Date(balance.createdAt), 'MM/dd/yyyy, h:mma'), //TODO consult about uniform date formats
          format(new Date(balance.updatedAt), 'MM/dd/yyyy, h:mma'),
        ];
      }),
    [balances]
  );

  return error ? (
    <Typography sx={{ color: 'text.error' }}>
      <>
        An error occurred while loading the owners list:
        {(error as Error).message}
      </>
    </Typography>
  ) : (
    <>
      <AddItemBalance
        collectionId={item.collectionId}
        item={item}
        gameId={item.gameId}
        open={addingBalance}
        onClose={() => setAddingBalance(false)}
      />
      <ContainedTable
        count={rows.length}
        page={0}
        type="Owners"
        headings={headings}
        rows={rows}
        rowBorder={true}
        isLoading={isLoading || isLoadingBalances}
        emptyScreen={
          <EmptyContainer
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              pt: 6.25,
            }}
          >
            <Box sx={{ color: 'primary.main', mb: 2, display: 'flex' }}>
              <HandCoins size={32} weight="duotone" />
            </Box>
            <Typography variant="subtitle1">No Current Owners</Typography>
            <Typography
              variant="body2"
              sx={{ color: 'text.secondary', mt: 1, mb: 2 }}
            >
              Owners will be listed here after a balance is added.
            </Typography>
            <Button
              variant="contained"
              data-testid="item-owner-table-add-balance-button"
              onClick={() => {
                track('Empty Owners Add Balance', 'Clicked', {
                  gameId,
                  itemId: item.id,
                });
                setAddingBalance(true);
              }}
            >
              Add Balance
            </Button>
          </EmptyContainer>
        }
      />
    </>
  );
};
