import { Add } from '@mui/icons-material';
import {
  Button,
  Chip,
  CircularProgress,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  useTheme,
} from '@mui/material';
import { CheckCircle, Prohibit } from '@phosphor-icons/react';
import { ApiKey } from '@stardust-monorepo/types/sd-private';
import {
  BaseEmptyView,
  ClipboardCopy,
  ContainedTable,
  ErrorAlert,
  PageTitle,
  SdPrivateApi,
  track,
  useHandleError,
  userState,
  useToast,
  useTrack,
} from '@stardust-monorepo/web-sdk-apps-shared';
import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';

import apiKeyIcon from '../../assets/api-key-icon.png';
import { ApiKeyToggle, ApiLogs } from '../components/api-key';
import { gameState } from '../state/game-state';

const headings = [
  { width: '20px', label: '' },
  {
    label: 'API Key',
    width: '450px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  { label: 'Created At' },
  { label: 'Active' },
];
type ViewModeOptions = 'view-all' | 'active' | 'inactive';

export const ApiKeysPage = () => {
  const { gameId } = useParams<{
    gameId: string;
  }>();
  const [newApiKeys, setNewApiKeys] = useState<string[]>([]);
  const [viewMode, setViewMode] = useState<ViewModeOptions>('view-all');
  const [filteredKeys, setFilteredKeys] = useState<ApiKey[]>([]);
  const [activeKeys, setActiveKeys] = useState<ApiKey[]>([]);
  const [inactiveKeys, setInactiveKeys] = useState<ApiKey[]>([]);
  const [selectedRow, setSelectedRow] = useState<number | null>(null);
  const toaster = useToast();
  const handleError = useHandleError();
  const theme = useTheme();
  const [games] = useRecoilState(gameState);
  const [user] = useRecoilState(userState);
  const navigate = useNavigate();

  const createApiKey = SdPrivateApi.useCreateApiKey();

  useTrack('API Keys', { gameId });

  useEffect(() => {
    const game = games.find((g) => g.id === Number(gameId));
    const isUserGameOwner =
      game && user && game?.ownerId === user.user?.attributes.sub;
    if (!isUserGameOwner) {
      navigate('..');
      toaster(
        'Only Game Owners can view and make changes to API Keys.',
        'error'
      );
    }
  }, [games, user, gameId]);

  const {
    data: apiKeys,
    error: apiKeysError,
    isLoading,
  } = SdPrivateApi.useApiKeys(
    {
      gameId: Number(gameId),
    },
    !!gameId
  );

  const onCreateNewKey = async () => {
    const trackingData = { gameId };
    track('Create New API Key', 'Clicked', trackingData);
    try {
      const newApiKey = await createApiKey.mutateAsync({
        gameId: Number(gameId),
      });
      setNewApiKeys([...newApiKeys, newApiKey.apiKey]);
      track('Create New API Key', 'Success', trackingData);
      toaster(
        'API key successfully created. It may take up to 3 minutes for the API key to be fully activated.'
      );
    } catch (e) {
      handleError(
        e,
        'An error occurred while creating the API key',
        'Create New API Key',
        trackingData
      );
    }
  };

  useEffect(() => {
    if (Array.isArray(apiKeys)) {
      const active: ApiKey[] = [];
      const inactive: ApiKey[] = [];
      apiKeys.forEach((apiKey) => {
        if (apiKey.enabled) {
          active.unshift(apiKey);
        } else {
          inactive.unshift(apiKey);
        }
      });
      setActiveKeys(active);
      setInactiveKeys(inactive);
      let filteredKeys = [...active, ...inactive];
      if (viewMode === 'active') {
        filteredKeys = [...active];
      } else if (viewMode === 'inactive') {
        filteredKeys = [...inactive];
      }
      setFilteredKeys(filteredKeys);
    }
  }, [viewMode, apiKeys]);

  const rows = filteredKeys.map((apiKey, index) => {
    return [
      apiKey.enabled ? (
        <CheckCircle
          color={theme.palette.success.main}
          size={20}
          weight="fill"
        />
      ) : (
        <Prohibit color={theme.palette.text.hint} size={20} weight="fill" />
      ),
      <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
        <ClipboardCopy
          className="apiKey"
          maskValue={true}
          maxWidth="400px"
          onClick={() => setSelectedRow(index)}
          onCopy={() => toaster('API Key copied')}
          text={apiKey.apiKey}
        />
        {newApiKeys.includes(apiKey.apiKey) && (
          <Chip label="New" color="success" />
        )}
      </Stack>,
      format(new Date(apiKey.createdAt), 'MMM. dd yyyy, h:mma'),
      <ApiKeyToggle
        apiKey={apiKey}
        gameId={Number(gameId)}
        toggleTrackEvent="API Key Table Toggle"
      />,
    ];
  });

  return (
    <>
      <ErrorAlert error={apiKeysError as Error} />
      <PageTitle title="API Keys" count={rows.length}>
        <Button
          variant="contained"
          disabled={createApiKey.isLoading}
          onClick={onCreateNewKey}
          startIcon={<Add />}
        >
          {createApiKey.isLoading ? (
            <CircularProgress size="18px" color="primary" />
          ) : (
            'Create New'
          )}
        </Button>
      </PageTitle>
      <ApiLogs
        apiKey={
          typeof selectedRow === 'number'
            ? filteredKeys[selectedRow]
            : undefined
        }
        gameId={Number(gameId)}
        onClose={() => setSelectedRow(null)}
      />
      <ContainedTable
        count={rows.length}
        page={0}
        headings={headings}
        rows={rows}
        isLoading={isLoading}
        filter={rows.length === 0 && apiKeys && apiKeys.length > 0 ? ' ' : ''}
        additionalInfo={
          <>
            <Chip
              color="success"
              label={`(${activeKeys.length}) ACTIVE`}
              sx={{ ml: 1 }}
              icon={<CheckCircle size={13} weight="fill" />}
            />
            <Chip
              color="error"
              label={`(${inactiveKeys.length}) INACTIVE`}
              sx={{ ml: 1 }}
              icon={<Prohibit size={13} weight="fill" />}
            />
          </>
        }
        actions={
          <ToggleButtonGroup
            disabled={isLoading}
            value={viewMode}
            exclusive
            onChange={(event, newViewMode) => {
              track(`API Keys Toggle Button Group ${newViewMode}`, 'Clicked', {
                gameId,
              });
              setViewMode(newViewMode);
            }}
          >
            <ToggleButton value="view-all">View All</ToggleButton>
            <ToggleButton value="active">Active</ToggleButton>
            <ToggleButton value="inactive">Inactive</ToggleButton>
          </ToggleButtonGroup>
        }
        emptyScreen={
          <BaseEmptyView
            image={apiKeyIcon}
            title="Create your first API Key."
            body="Use API Keys to authenticate requests sent to Stardust by your game. They should not be shared outside of your organization."
            buttonText="Add API Key"
            buttonType="outlined"
            isLoading={createApiKey.isLoading}
            onClick={onCreateNewKey}
          />
        }
      />
    </>
  );
};
