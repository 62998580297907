import { AssetMetadata } from '@stardust-monorepo/types/common';
import {
  Collection,
  CollectionResults,
  CollectionResultsSchema,
  CollectionSchema,
  CollectionType,
} from '@stardust-monorepo/types/sd-core';

import { validatedRequestWithAuth } from './api-utils';

export type GetCollectionArgs = { id: Collection['id'] | undefined };

export const getCollection = ({
  id,
}: GetCollectionArgs): Promise<Collection> => {
  return validatedRequestWithAuth(CollectionSchema, `collection/${id}`);
};

export interface GetCollectionsArgs {
  gameId: number;
  type?: CollectionType;
  blockchainId?: number;
  start?: number;
  limit?: number;
}

export const getCollections = (
  args: GetCollectionsArgs
): Promise<CollectionResults> => {
  return validatedRequestWithAuth(CollectionResultsSchema, 'collection', {
    params: args,
  });
};

export interface CreateCollectionFaArgs {
  gameId: number;
  name: string;
  symbol?: string | null;
  description: string;
  image: string;
  publicMetadata: AssetMetadata;
  blockchainId: number;
}
export type CreateCollectionFtArgs = CreateCollectionFaArgs & {
  totalSupply?: string | null;
};
export type CreateCollectionNftArgs = CreateCollectionFtArgs;

export const createCollectionFa = (args: CreateCollectionFaArgs) => {
  return validatedRequestWithAuth(CollectionSchema, 'collection/fa', {
    data: args,
    method: 'POST',
  });
};

export const createCollectionFt = (args: CreateCollectionFtArgs) => {
  return validatedRequestWithAuth(CollectionSchema, 'collection/ft', {
    data: args,
    method: 'POST',
  });
};

export const createCollectionNft = (args: CreateCollectionNftArgs) => {
  return validatedRequestWithAuth(CollectionSchema, 'collection/nft', {
    data: args,
    method: 'POST',
  });
};

export interface UpdateCollectionArgs {
  id: Collection['id'];
  name: string;
  description: string;
  image: string;
  publicMetadata: AssetMetadata;
}

export const updateCollection = ({ id, ...params }: UpdateCollectionArgs) => {
  return validatedRequestWithAuth(CollectionSchema, `collection/${id}`, {
    data: params,
    method: 'PUT',
  });
};
