import { z } from 'zod';

export const StatsOverviewSchema = z
  .object({
    profiles: z.object({
      created: z.number(),
      active: z.number(),
      lifetime: z.number(),
    }),
    signatures: z.object({
      count: z.number(),
    }),
    requests: z.object({
      count: z.number(),
      errorCount: z.number(),
      maxRate: z.number(),
    }),
    responses: z.object({
      medianTime: z.number(),
    }),
  })
  .describe('StatsOverviewSchema');
export type StatsOverview = z.infer<typeof StatsOverviewSchema>;

export const StatsHistogramSchema = z
  .object({
    results: z.record(z.string()),
  })
  .describe('StatsHistogramSchema');

export type StatsHistogram = z.infer<typeof StatsHistogramSchema>;
