import { StyledComponent } from '@emotion/styled';
import { Dialog, DialogProps, styled } from '@mui/material';

export const BasicDialog: StyledComponent<DialogProps> = styled(
  (props: DialogProps) => <Dialog {...props} />
)(({ theme }) => ({
  '.MuiDialog-paper': {
    borderRadius: `${theme.shape.borderRadius * 3}px`,
  },
  '& .MuiDialogTitle-root': {
    padding: `${theme.spacing(3)} ${theme.spacing(3)} ${theme.spacing(2)}`,
  },
  '& .MuiDialogActions-root': {
    padding: `${theme.spacing(0.5)}  ${theme.spacing(3)} ${theme.spacing(2)}`,
    '>:not(:first-of-type)': {
      marginLeft: theme.spacing(1.5),
    },
  },
}));
