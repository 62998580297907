import { paginatedResultsWithFilterSchema } from '@stardust-monorepo/types/common';
import { z } from 'zod';

const PlanIdSchema = z.string();

export const PlanConfigSchema = z
  .object({
    name: z.string(),
    description: z.string(),
    limits: z
      .object({
        walletCreate: z.object({
          hard: z.optional(z.number().min(1)),
          soft: z.optional(z.number().min(1)),
        }),
      })
      .optional(),
    priceList: z.object({
      base: z
        .object({
          //base price
          amount: z.number(),
          stripeId: z.string(),
        })
        .optional(),
      wallet: z
        .object({
          //per-wallet overage fees
          amount: z.number(),
          stripeId: z.string(),
        })
        .optional(),
    }),
    period: z.enum(['month', 'year']),
    selfServe: z.boolean(),
    allowedUpgrades: z.array(PlanIdSchema),
    features: z.array(z.string()), //display strings
  })
  .describe('PlanConfigSchema');
export type PlanConfig = z.infer<typeof PlanConfigSchema>;

export const PlanSchema = z
  .object({
    config: PlanConfigSchema,
    active: z.boolean(),
    id: PlanIdSchema,
  })
  .describe('PlanSchema');
export type Plan = z.infer<typeof PlanSchema>;

export const PlanResultsSchema =
  paginatedResultsWithFilterSchema(PlanSchema).describe('PlanResultsSchema');
export type PlanResults = z.infer<typeof PlanResultsSchema>;

export const walletLimitType = {
  soft: 'soft',
  hard: 'hard',
  unlimited: 'unlimited',
} as const;

export const getPlanWalletLimitType = (plan: PlanConfig) => {
  return plan.limits?.walletCreate.soft !== undefined
    ? walletLimitType.soft
    : plan.limits?.walletCreate.hard !== undefined
    ? walletLimitType.hard
    : walletLimitType.unlimited;
};
