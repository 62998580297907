import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Stack,
  styled,
  Typography,
} from '@mui/material';
import { NotePencil, Trash } from '@phosphor-icons/react';
import { BlockchainNames, Game } from '@stardust-monorepo/types/sd-private';
import {
  BlockchainIcon,
  getGameImageUrl,
  MoreActions,
  track,
} from '@stardust-monorepo/web-sdk-apps-shared';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import defaultBanner from '../../../assets/default-banner.png';

const IconImage = styled('img')(({ theme }) => ({
  border: '2px solid',
  borderColor: theme.palette.background.white,
  borderRadius: 1.5 * theme.shape.borderRadius,
  backgroundColor: theme.palette.background.white,
  position: 'absolute',
  height: '48px',
  width: '48px',
  top: '-24px',
  left: '16px',
}));

export interface GameCardProps {
  game: Game;
  openDeleteModal: (game: Game) => void;
  openEditModal: (game: Game) => void;
}

export const GameCard = ({
  game,
  openDeleteModal,
  openEditModal,
}: GameCardProps) => {
  const [hover, setHover] = useState(false);
  // banner isn't on the game from game/get-all so instead of calling game/get we
  // use an onError fallback for displaying the banner.
  const [useDefaultBanner, setUseDefaultBanner] = useState(false);

  return (
    <Card
      sx={{ width: '270px', padding: 1, borderRadius: 2.5 }}
      variant={hover ? 'elevation' : 'outlined'}
      elevation={hover ? 4 : 0}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <Link to={`/games/${game.id}`}>
        <CardMedia
          component="img"
          image={
            useDefaultBanner
              ? defaultBanner
              : getGameImageUrl(game.bucketName, 'Game/banner', 252, 158)
          }
          alt={game.name}
          height="158px"
          onError={() => setUseDefaultBanner(true)}
          sx={{ borderRadius: 1.5, cursor: 'pointer' }}
        />
      </Link>
      <CardContent
        sx={{
          position: 'relative',
          padding: 1,
          paddingBottom: '0!important',
        }}
      >
        <IconImage src={getGameImageUrl(game.bucketName, game.image)} />
        <Stack sx={{ mt: 3.5 }} spacing={0}>
          <Link to={`/games/${game.id}`}>
            <Typography
              variant="h6"
              sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                color: 'text.primary',
              }}
            >
              {game.name}
            </Typography>
          </Link>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <Stack direction="row" spacing={0.5}>
              <BlockchainIcon blockchain={game.blockchain} size={18} />
              <Typography variant="body2" color="text.secondary">
                {BlockchainNames[game.blockchain]}
              </Typography>
            </Stack>
            <MoreActions
              actions={[
                {
                  icon: <NotePencil weight="duotone" />,
                  label: 'Edit Game',
                  onClick: () => {
                    track('Game Card Edit Game', 'Clicked', {
                      gameId: game.id,
                    });
                    openEditModal({
                      ...game,
                      banner: useDefaultBanner ? undefined : 'Game/banner',
                    });
                  },
                },
                {
                  icon: <Trash weight="duotone" />,
                  label: 'Remove Game',
                  onClick: () => {
                    track('Game Card Remove Game', 'Clicked', {
                      gameId: game.id,
                    });
                    openDeleteModal(game);
                  },
                  type: 'error',
                },
              ]}
            />
          </Box>
        </Stack>
      </CardContent>
    </Card>
  );
};
