import { Stack, StackProps, useTheme } from '@mui/material';

export const FormSectionContainer = ({
  density = 'normal',
  sx = {},
  children,
  ...props
}: StackProps<'div'> & { density?: 'normal' | 'compact' }) => {
  const theme = useTheme();
  return (
    <Stack
      sx={{
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: 3,
        padding: density === 'normal' ? 4 : 3,
        '.MuiFormControl-root:last-child': {
          mb: 0,
        },
        ...(typeof sx === 'function' ? sx(theme) : sx),
      }}
      {...props}
    >
      {children}
    </Stack>
  );
};
