import { DeleteForever } from '@mui/icons-material';
import { Box, Radio, Stack, Typography } from '@mui/material';
import { PaymentMethod } from '@stardust-monorepo/types/marketplace';
import React from 'react';
import { PropsWithChildren } from 'react';

import { color } from '../../../theming';
import { CreditCardBrandIcon } from './credit-card-brand-icon';

interface PaymentMethodOptionProps {
  paymentMethod: PaymentMethod;
  checked: boolean;
  onChange: (checked: boolean) => unknown;
  showDeleteConfirmation: () => void;
}

const CardDetailsTypography = ({ children }: PropsWithChildren<unknown>) => (
  <Typography
    sx={(theme) => ({
      color: theme.palette.text.secondary,
    })}
    variant="body2"
  >
    {children}
  </Typography>
);

export const paymentMethodOptionBaseStyle = {
  border: `1px solid ${color.neutral[100]}`,
  borderRadius: 1.5,
  cursor: 'pointer',
};

export const PaymentMethodOption = ({
  paymentMethod,
  checked,
  onChange,
  showDeleteConfirmation,
}: PaymentMethodOptionProps) => {
  return (
    <Box
      data-testid={'payment-method-option'}
      sx={(theme) => ({
        backgroundColor: theme.palette.background.cool,
        ...paymentMethodOptionBaseStyle,
        p: checked ? 0 : '1px',
        border: checked
          ? `2px solid ${theme.palette.primary.main}`
          : paymentMethodOptionBaseStyle.border,
      })}
      onClick={() => {
        if (!checked) {
          onChange(true);
        }
      }}
    >
      <Stack px={2.5} py={2} direction="row" justifyContent="space-between">
        <Stack sx={{ width: '100%' }} direction="row" gap={2}>
          <Radio
            sx={{
              padding: 0,
              alignItems: 'baseline',
            }}
            value={paymentMethod}
            checked={checked}
            onChange={(event) => onChange(event.target.checked)}
            size="small"
          />
          <Stack sx={{ width: '100%' }} justifyContent="space-between">
            <Stack direction="row" gap={1.5}>
              <Typography
                variant="subtitle1"
                sx={{
                  mb: 0.75,
                }}
              >
                Card ending: {paymentMethod.card.last4}
              </Typography>
              <CreditCardBrandIcon
                fontSize="small"
                brand={paymentMethod.card.brand}
              />
            </Stack>
            <CardDetailsTypography>
              Expires {paymentMethod.card.exp_month}/
              {paymentMethod.card.exp_year}
            </CardDetailsTypography>
            <CardDetailsTypography>
              {paymentMethod.billing_details.address.country},{' '}
              {paymentMethod.billing_details.address.postal_code}
            </CardDetailsTypography>
          </Stack>
          <Stack
            data-testid="delete-payment-method-button"
            alignItems="center"
            direction="row"
            gap={0.4}
            onClick={showDeleteConfirmation}
            sx={(theme) => ({
              color: theme.palette.text.error,
              transition: 'all 0.2s ease-out',
              '&:hover': {
                opacity: 0.6,
                transition: 'all 0.2s ease-out',
              },
            })}
          >
            <DeleteForever fontSize="medium" />
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
};
