import {
  createItem,
  getItem,
  GetItemArgs,
  getItems,
  GetItemsArgs,
  itemBalanceKeys,
  updateItem,
} from '@stardust-monorepo/web-sdk-apps-shared';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

export const itemKeyRoot = {
  item: 'item',
  results: 'items',
} as const;
export const itemKeys = {
  results: ({
    gameId,
    collectionId,
    generatorId,
    blockchainId,
    start,
    limit,
  }: GetItemsArgs) => [
    itemKeys.results,
    { gameId },
    ...(collectionId || generatorId || blockchainId || start || limit
      ? [{ collectionId, generatorId, blockchainId, start, limit }]
      : []),
  ],
  item: ({ id }: GetItemArgs) => [itemKeyRoot.item, { id }],
};

export function useItem(args: GetItemArgs) {
  return useQuery({
    queryKey: itemKeys.item(args),
    queryFn: () => getItem(args),
    enabled: !(args.id === undefined || args.id === null),
  });
}

export function useItems(args: GetItemsArgs) {
  return useQuery({
    queryKey: itemKeys.results(args),
    queryFn: () => getItems(args),
    enabled: !(args.gameId === undefined || args.gameId === null),
  });
}

export function useCreateItem() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: createItem,
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({
        queryKey: itemKeys.results({ gameId: data.gameId }),
      });
      queryClient.invalidateQueries({
        queryKey: itemBalanceKeys.itemBalances({ gameId: data.gameId }),
      });
    },
  });
}

export function useUpdateItem() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: updateItem,
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({
        queryKey: itemKeys.item({ id: variables.id }),
      });
      queryClient.invalidateQueries({
        queryKey: itemKeys.results({ gameId: data.gameId }),
      });
    },
  });
}
