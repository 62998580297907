import { ApiKey } from '@stardust-monorepo/types/sd-private';
import {
  ActiveToggle,
  SdPrivateApi,
  track,
  useHandleError,
  useToast,
} from '@stardust-monorepo/web-sdk-apps-shared';

export interface ApiKeyToggleProps {
  apiKey: ApiKey;
  gameId: number;
  toggleTrackEvent: string;
}

export const ApiKeyToggle = ({
  apiKey,
  gameId,
  toggleTrackEvent,
}: ApiKeyToggleProps) => {
  const toaster = useToast();
  const handleError = useHandleError();
  const toggleApiKeyActive = SdPrivateApi.useToggleApiKeyActive();

  return (
    <ActiveToggle
      checked={Boolean(apiKey.enabled)}
      onToggle={() => {
        track(toggleTrackEvent, 'Clicked', {
          gameId,
          id: apiKey.apiKeyId,
        });
        return toggleApiKeyActive.mutateAsync({
          gameId: Number(gameId),
          apiKey: apiKey.apiKey,
        });
      }}
      onToggleError={(checked, e) => {
        handleError(
          e,
          `Unable to change API key to ${checked ? 'active' : 'inactive'}`,
          'API Key Toggle',
          {
            gameId,
            id: apiKey.apiKeyId,
            checked,
          }
        );
      }}
      onToggleSuccess={(checked) => {
        track('API Key Toggle', 'Success', {
          gameId,
          id: apiKey.apiKeyId,
        });
        toaster(`API key is now ${checked ? 'active' : 'inactive'}`);
      }}
    />
  );
};
