import { useQuery } from '@tanstack/react-query';

import { getUnacceptedPolicies } from '../policy-api';

export const policyKeys = {
  unacceptedPolicies: (userId: string) =>
    ['policies', 'unaccepted', { userId }] as const,
};

export const useUnacceptedPolicies = (userId?: string, enabled = true) => {
  return useQuery({
    queryKey: policyKeys.unacceptedPolicies(
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      userId!
    ),
    queryFn: () =>
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      getUnacceptedPolicies(userId!),
    enabled: enabled && userId !== undefined,
  });
};
