import {
  Box,
  CircularProgress,
  Divider,
  Link,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material';
import { ReactNode } from 'react';

type NavItemIconProps = {
  color?: string;
  endIcon?: ReactNode;
  icon?: ReactNode;
  iconColor?: string;
};

type BaseNavItemProps = {
  label?: string;
  renderNavItem?: () => ReactNode;
  onClick?: () => void;
  selected?: boolean;
  href?: string;
  itemKey?: string;
};

type NavItemProps = {
  subGroup?: BaseNavItemProps[];
} & BaseNavItemProps &
  NavItemIconProps;

const NavItem = ({
  color = '',
  endIcon,
  icon,
  iconColor,
  label,
  renderNavItem,
  onClick,
  selected = false,
  href,
  _hasSubGroup,
}: NavItemProps & { _hasSubGroup: boolean }) => {
  const navItemColor = selected ? 'primary.main' : color;
  const navIconColor = selected
    ? 'primary.main'
    : iconColor
    ? iconColor
    : color;

  return renderNavItem ? (
    <>{renderNavItem()}</>
  ) : (
    <Link href={href} underline="none" target={(href && '_blank') || undefined}>
      <ListItemButton
        sx={{
          color: navItemColor,
          padding: 0.375,
          paddingX: icon ? 0.375 : 1,
          ...(_hasSubGroup
            ? { '&.Mui-selected': { backgroundColor: 'unset' } }
            : {}),
        }}
        selected={selected}
        onClick={onClick}
      >
        {icon && (
          <ListItemIcon
            sx={{
              color: selected
                ? _hasSubGroup
                  ? 'text.secondary'
                  : 'text.white'
                : navIconColor,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                px: 1,
                py: 0.25,
                borderRadius: 1.5,
                backgroundColor:
                  selected && !_hasSubGroup ? 'primary.main' : 'transparent',
              }}
            >
              {icon}
            </Box>
          </ListItemIcon>
        )}
        <ListItemText
          disableTypography={true}
          primary={
            <Typography
              sx={{
                maxWidth: '183px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                color: 'text.primary',
              }}
              variant="subtitle2"
            >
              {label}
            </Typography>
          }
          sx={{ flexGrow: 0 }}
        />
        {endIcon && (
          <Box sx={{ display: 'flex', color: 'text.disabled', ml: 0.5 }}>
            {endIcon}
          </Box>
        )}
      </ListItemButton>
    </Link>
  );
};

const NavItemSubGroup = ({ group }: { group: BaseNavItemProps[] }) => {
  return (
    <Stack
      direction={'row'}
      sx={{
        my: 0.25,
      }}
    >
      <Divider
        orientation="vertical"
        sx={{ width: '21px', mr: 2.75, pl: 0.25 }}
        flexItem
      />
      <Stack flexGrow={1} gap={0.25}>
        {group.map((item, index) => (
          <NavItem
            {...item}
            key={item.itemKey || `${item.label}${item.href}`}
            _hasSubGroup={false}
          />
        ))}
      </Stack>
    </Stack>
  );
};

export interface NavGroup {
  emptyView?: ReactNode;
  isLoading?: boolean;
  title?: string;
  items: NavItemProps[];
}

export interface LeftNavProps {
  logo: string;
  logoClick: () => void;
  navGroups: NavGroup[];
}

export const LeftNav = ({ logo, logoClick, navGroups }: LeftNavProps) => {
  return (
    <Box
      sx={{
        flexGrow: 1,
        width: '100%',
        borderColor: 'divider',
        backgroundColor: 'background.default',
      }}
    >
      <Box
        sx={{
          padding: '14px 26px 15px 18px',
          borderBottom: '1px solid',
          borderColor: 'divider',
          cursor: 'pointer',
          height: '74px',
          display: 'flex',
        }}
        onClick={logoClick}
      >
        <img alt="Stardust Logo" width="100%" src={logo} />
      </Box>
      <List
        component="nav"
        aria-label="main-nav"
        sx={{
          padding: 0,
        }}
      >
        {navGroups.map((group, index) => (
          <Stack
            key={`NavGroup/${group.title}`}
            sx={(theme) => ({
              px: 1.5,
              py: 2,
              ...(index > 0
                ? { borderTop: `1px solid ${theme.palette.divider}` }
                : {}),
            })}
          >
            {group.title && (
              <Typography
                variant="overline"
                sx={(theme) => ({
                  color: 'text.disabled',
                  px: 1.5,
                  mb: 1,
                })}
              >
                {group.title}
              </Typography>
            )}
            {group.isLoading ? (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  width: '100%',
                }}
              >
                <CircularProgress size="18px" color="primary" />
              </Box>
            ) : group.items.length === 0 && group.emptyView ? (
              group.emptyView
            ) : (
              group.items.map((item) => (
                <Stack key={item.itemKey ?? `${item.label}${item.href}`}>
                  <NavItem {...item} _hasSubGroup={!!item.subGroup} />
                  {item.subGroup && item.selected && (
                    <NavItemSubGroup group={item.subGroup} />
                  )}
                </Stack>
              ))
            )}
          </Stack>
        ))}
      </List>
    </Box>
  );
};
