import { Box, Button, Stack, Typography } from '@mui/material';
import { GameController } from '@phosphor-icons/react';
import { userState } from '@stardust-monorepo/web-sdk-apps-shared';
import { format } from 'date-fns';
import { useRecoilState } from 'recoil';

import { DeprecationTooltip } from '../rip/DeprecationTooltip';

const WelcomeInfo = () => {
  const [user] = useRecoilState(userState);
  const authTime =
    user.user?.signInUserSession.getAccessToken().payload.auth_time;

  return (
    <Stack direction="row" spacing={2} sx={{ alignItems: 'center' }}>
      <img alt="stardustIcon" src="/assets/stardust-icon.png" width="64px" />
      <Stack spacing={0.5}>
        <Typography variant="h5">Welcome to Stardust.</Typography>
        {authTime ? (
          <Typography variant="body2" color="text.secondary">
            Last Login {format(new Date(authTime * 1000), 'PP')}
          </Typography>
        ) : null}
      </Stack>
    </Stack>
  );
};

export interface TitleSectionProps {
  setShowCreateGame: (show: boolean) => void;
}

export const TitleSection = ({ setShowCreateGame }: TitleSectionProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        borderBottom: '1px solid',
        borderColor: 'divider',
        pb: 2,
      }}
    >
      <WelcomeInfo />
      <Box>
        <DeprecationTooltip>
          <Button disabled variant="contained" startIcon={<GameController />}>
            Create Game
          </Button>
        </DeprecationTooltip>
      </Box>
    </Box>
  );
};
