import { Box, Button, Chip, Paper, Stack, Typography } from '@mui/material';
import { HandCoins, Plus } from '@phosphor-icons/react';
import { FungibleToken } from '@stardust-monorepo/types/sd-core';
import {
  formatBlockchainExplorerUrl,
  InfoItem,
  Spinner,
  track,
  UnlimitedTemplateCap,
  useCollection,
  useItems,
  useTrack,
  ValidatedImage,
} from '@stardust-monorepo/web-sdk-apps-shared';
import { format } from 'date-fns';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import { EditCollectionModal } from '../components/collections/EditCollectionModal';
import { AddItemBalance } from '../components/collections/item-balance/AddItemBalance';
import { CreateItemModal } from '../components/collections/items/CreateItemModal';
import { ItemOwnerTable } from '../components/collections/items/ItemOwnerTable';
import { ItemTable } from '../components/collections/items/ItemTable';
import { collectionTypeToTechnicalDisplayString } from '../components/collections/util';
import { CollectionsTopNav } from '../components/layout';

export const CollectionPage = () => {
  const trackingData = useParams<{
    gameId: string;
    collectionId: string;
  }>();
  const { gameId, collectionId } = trackingData;

  const { data: collection, error: collectionError } = useCollection({
    id: collectionId || '',
  });

  const {
    data: items,
    error: itemsError,
    isLoading: itemsLoading,
  } = useItems({
    gameId: Number(gameId),
    collectionId: collectionId,
  });

  const [editingCollection, setEditingCollection] = useState<boolean>(false);
  const [creatingItem, setCreatingItem] = useState<boolean>(false);
  const [addingBalance, setAddingBalance] = useState<boolean>(false);

  useTrack('Collection', trackingData);

  if (!collection || !items) {
    return collectionError || itemsError ? (
      <Typography sx={{ color: 'text.error' }}>
        <>
          An error occurred while loading the collection:{' '}
          {(collectionError as Error)?.message ||
            collectionError ||
            (itemsError as Error)?.message ||
            itemsError}
        </>
      </Typography>
    ) : (
      <Spinner />
    );
  }

  const isFt = collection && gameId && collection.type === FungibleToken;

  return (
    <>
      <CreateItemModal
        collection={collection}
        gameId={collection.gameId}
        open={creatingItem}
        onClose={() => setCreatingItem(false)}
        sourcePage={collection.name}
      />
      <EditCollectionModal
        collection={collection}
        gameId={collection.gameId}
        open={editingCollection}
        onClose={() => setEditingCollection(false)}
        sourcePage={`${collection.name} Details`}
      />
      <AddItemBalance
        collectionId={collection.id}
        gameId={collection.gameId}
        open={addingBalance}
        onClose={() => setAddingBalance(false)}
      />
      <CollectionsTopNav collection={collection} />
      <Stack sx={{ padding: 2, flexGrow: 1 }}>
        <Paper
          elevation={0}
          sx={{
            width: '100%',
            mb: 2,
            border: '1px solid',
            borderColor: 'divider',
            borderRadius: 3,
            backgroundColor: 'background.white',
            px: 1,
          }}
        >
          <Box
            sx={{
              width: '100%',
              background: `no-repeat url("${
                collection.publicMetadata.bannerImage
                  ? collection.publicMetadata.bannerImage
                  : '/assets/motif_topo.png'
              }")`,
              backgroundSize: 'cover',
              backgroundColor: 'divider',
              height: '160px',
              position: 'relative',
              mt: 4,
              borderRadius: 2.5,
            }}
          >
            <Stack
              direction="row"
              spacing={1}
              sx={{
                alignItems: 'center',
                position: 'absolute',
                top: '-16px',
                right: '16px',
              }}
            >
              {isFt ? (
                <Button
                  variant="contained"
                  size="medium"
                  startIcon={<HandCoins size={18} />}
                  disabled={!items || items.results.length === 0}
                  onClick={() => {
                    setAddingBalance(true);
                    track('Collection Page Add Balance', 'Clicked', {
                      gameId: collection.gameId,
                      collectionId: collection.id,
                    });
                  }}
                >
                  Add Balance
                </Button>
              ) : (
                <>
                  <Button
                    variant="contained"
                    size="medium"
                    startIcon={<Plus size={18} />}
                    onClick={() => {
                      track('Collection Page Create Item', 'Clicked', {
                        gameId: collection.gameId,
                        collectionId: collection.id,
                      });
                      setCreatingItem(true);
                    }}
                  >
                    <Typography variant="subtitle2">New Item</Typography>
                  </Button>
                  <Button
                    variant="outlined"
                    color="secondary"
                    size="medium"
                    disabled={!items || items.results.length === 0}
                    onClick={() => {
                      setAddingBalance(true);
                      track('Collection Page Add Balance', 'Clicked', {
                        gameId: collection.gameId,
                        collectionId: collection.id,
                      });
                    }}
                  >
                    Add Balance
                  </Button>
                </>
              )}
              <Button
                variant="outlined"
                color="secondary"
                size="medium"
                onClick={() => {
                  track('Collection Page Edit Collection', 'Clicked', {
                    gameId: collection.gameId,
                    collectionId: collection.id,
                  });
                  setEditingCollection(true);
                }}
              >
                Edit
              </Button>
            </Stack>
            <ValidatedImage
              size="112px"
              image={collection.image}
              sx={{
                borderRadius: 3,
                position: 'absolute',
                left: '16px',
                bottom: '-16px',
                border: '2px solid white',
              }}
            />
          </Box>
          <Box sx={{ pt: 5, px: 2, pb: 3 }}>
            <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
              <Typography variant="h4">{collection.name}</Typography>
              <Chip
                label={collectionTypeToTechnicalDisplayString(
                  collection.type
                ).toUpperCase()}
              />
            </Stack>
            <Typography variant="body2" color="text.secondary" sx={{ pt: 1 }}>
              Collection ID {collection.id}
            </Typography>
            <Stack
              direction="row"
              spacing={2}
              sx={{
                border: '1px solid',
                borderColor: 'divider',
                px: 2,
                py: 1.25,
                mt: 2,
                borderRadius: 2,
              }}
            >
              <InfoItem
                label="Created"
                value={format(new Date(collection.createdAt), 'MM/dd/yyyy')}
              />
              {collection.publicMetadata.symbol && (
                <InfoItem
                  label="Symbol"
                  value={String(collection.publicMetadata.symbol)}
                />
              )}
              <InfoItem
                label="Total Supply"
                value={
                  collection.totalSupply === UnlimitedTemplateCap
                    ? 'Unlimited'
                    : collection.totalSupply || 'N/A'
                }
              />
              {collection.id && (
                <InfoItem
                  label="Contract Address"
                  value={
                    collection.address
                      ? `${collection.address.slice(
                          0,
                          4
                        )}...${collection.address.slice(
                          collection.address.length - 4,
                          collection.address.length
                        )}`
                      : 'N/A'
                  }
                  link={formatBlockchainExplorerUrl(
                    collection.blockchainId,
                    collection.address
                  )}
                />
              )}
            </Stack>
          </Box>
        </Paper>
        {!isFt ? (
          <ItemTable
            collection={collection}
            items={items.results}
            isLoading={itemsLoading}
            error={itemsError as Error}
          />
        ) : (
          <ItemOwnerTable
            item={items.results[0]}
            gameId={Number(gameId)}
            isLoading={itemsLoading}
          />
        )}
      </Stack>
    </>
  );
};
