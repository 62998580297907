import { z } from 'zod';

import { Blockchain } from './blockchain';
import { TokenType, TokenTypeSchema } from './token-type';

export const TokenPropertyValueSchema = z
  .string()
  .or(z.boolean())
  .or(z.number())
  .describe('TokenPropertyValueSchema');
export const TokenPropertyRecordSchema = z
  .record(z.string(), TokenPropertyValueSchema)
  .describe('TokenPropertyRecordSchema');

export const ApiTokenSchema = z
  .object({
    id: z.number(),
    gameId: z.number(),
    gameName: z.string(),
    templateId: z.number(),
    props: z.object({
      immutable: TokenPropertyRecordSchema,
      mutable: TokenPropertyRecordSchema,
      inherited: TokenPropertyRecordSchema.and(
        z.object({
          description: z.string(),
          image: z.string(),
        })
      ),
    }),
    name: z.string(),
    type: TokenTypeSchema,
  })
  .describe('ApiTokenSchema');
export type ApiToken = z.infer<typeof ApiTokenSchema>;

export type TokenDetails = {
  id: number;
  gameId: number;
  templateId: number;
  name: string;
  image: string;
  description: string;
  properties: Record<string, string | number | boolean>;
  type: TokenType;
};
/**
 * For use in some situations where it's possible to know the quantity of a particular token, such as when it's being fetched from someone's inventory, or as part of an existing order
 */
export type TokenDetailsWithQuantity = TokenDetails & { quantity: string };

export interface TokenWithAdditionalDetails {
  token: TokenDetails & {
    gameName: string;
    type: TokenType;
  };
  blockchain: Blockchain;
}
