import {
  Breadcrumbs,
  Button,
  CircularProgress,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Template } from '@stardust-monorepo/types/marketplace';
import {
  CreateDocumentation,
  HeavyDialog,
  HeavyDialogActions,
  HeavyDialogContent,
  HeavyDialogTitle,
  track,
  UnlimitedTemplateCap,
  useHandleError,
  useToast,
} from '@stardust-monorepo/web-sdk-apps-shared';
import React, { useMemo, useRef, useState } from 'react';

import { mapFromProperties } from '../properties';
import { formControls, TemplateForm } from './TemplateForm';

interface CreateEditTemplateModalProps {
  gameId: number;
  open: boolean;
  template?: Template | null;
  onClose: () => void;
}

export const CreateEditTemplateModal = ({
  gameId,
  open,
  template,
  onClose,
}: CreateEditTemplateModalProps) => {
  const submitFormButtonRef = useRef<HTMLButtonElement | null>(null);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [loading, setLoading] = useState(false);
  const toaster = useToast();
  const handleError = useHandleError();

  const defaultValues = useMemo(() => {
    if (template) {
      const props = mapFromProperties(template.props.mutable);
      return {
        [formControls.name]: template.name,
        [formControls.description]: template.props.mutable.description || '',
        [formControls.royalty]: (template.royalty || 0) / 100,
        [formControls.tokenType]: template.type,
        [formControls.capType]:
          template.cap === UnlimitedTemplateCap ? 'Unlimited' : 'Limited',
        [formControls.capLimit]:
          template.cap === UnlimitedTemplateCap ? '' : template.cap,
        [formControls.image]: null,
        [formControls.props]: props.filter(
          (p) => !['image', 'description'].includes(p.name)
        ),
      };
    }
    return {
      [formControls.name]: '',
      [formControls.description]: '',
      [formControls.royalty]: 0,
      [formControls.tokenType]: '',
      [formControls.capType]: '',
      [formControls.capLimit]: '',
      [formControls.image]: null,
      [formControls.props]: [],
    };
  }, [template]);

  const doOnClose = () => {
    track(`${template ? 'Edit' : 'Create '} Template Close`, 'Clicked', {
      gameId,
      templateId: template?.id,
    });
    onClose();
  };

  const onSave = (result: number | Error) => {
    const eventName = `Template ${template ? 'Edit' : 'Create'}`;
    const trackingData = { gameId, templateId: template?.id };
    if (result instanceof Error) {
      handleError(
        result,
        `An error occurred while ${
          template ? 'editing' : 'creating'
        } the template`,
        eventName,
        trackingData
      );
    } else {
      track(eventName, 'Success', trackingData);
      toaster(`Template ${template ? 'saved' : 'created'} successfully`);
      onClose();
    }
  };

  return (
    <HeavyDialog
      open={open}
      onClose={doOnClose}
      fullScreen={fullScreen}
      fullWidth={true}
      scroll="body"
      maxWidth={'lg'}
    >
      <HeavyDialogTitle variant="h4">
        <Breadcrumbs>
          <Typography variant="body2">Templates</Typography>
          <Typography variant="body2" color="text.primary">
            New Template
          </Typography>
        </Breadcrumbs>
      </HeavyDialogTitle>
      <HeavyDialogContent>
        <Stack gap={5}>
          <Typography variant="h3" mt={7}>
            {template ? 'Edit Template' : 'Create New Template'}
          </Typography>
          <Stack direction={'row'} gap={5.5}>
            <TemplateForm
              defaultValues={defaultValues}
              gameId={gameId}
              submitFormRef={submitFormButtonRef}
              template={template}
              onLoading={(loading) => setLoading(loading)}
              onSave={onSave}
            />
            <CreateDocumentation
              title="Creating a Template"
              description="Templates define the common traits that categories of assets will share. In addition to creating templates in the Stardust Dashboard, all Stardust Dashboard actions can be performed using the Stardust Core API. Reference the documentation pages below for more information."
              links={[
                {
                  description: 'Creating a Template',
                  href: 'https://docs.stardust.gg/docs/templates',
                },
                {
                  description: 'Fungible Tokens (FTs)',
                  href: 'https://docs.stardust.gg/docs/templates#ft-templates',
                },
                {
                  description: 'Non-Fungible Tokens (NFTs)',
                  href: 'https://docs.stardust.gg/docs/templates#nft-templates',
                },
              ]}
            />
          </Stack>
        </Stack>
      </HeavyDialogContent>
      <HeavyDialogActions>
        <Button
          disabled={loading}
          onClick={doOnClose}
          size="large"
          variant="outlined"
          color="secondary"
        >
          Cancel
        </Button>
        <Button
          disabled={loading}
          onClick={() => {
            if (template) {
              track('Modal Edit Template', 'Clicked', {
                gameId,
                templateId: template.id,
              });
            } else {
              track('Modal Create Template', 'Clicked', { gameId });
            }
            submitFormButtonRef.current?.click();
          }}
          size="large"
          variant="contained"
        >
          {loading ? (
            <CircularProgress size="18px" color="primary" />
          ) : template ? (
            'Save Changes'
          ) : (
            'Create Template'
          )}
        </Button>
      </HeavyDialogActions>
    </HeavyDialog>
  );
};
