import { Box, Stack, useMediaQuery, useTheme } from '@mui/material';
import { PropsWithChildren } from 'react';
import { Route, Routes } from 'react-router-dom';

import { ForgotPassword } from '../components/login/forgot-password/ForgotPassword';
import { LoginForm } from '../components/login/LoginForm';
import { LoginFormContainer } from '../components/login/LoginFormContainer';
import { Register } from '../components/login/registration/Register';

export const LoginPage = ({
  onRegister,
  onLogin,
  onSocialLogin,
  children,
}: PropsWithChildren<{
  onRegister?: () => void;
  onLogin?: () => void;
  onSocialLogin?: () => void;
}>) => {
  const theme = useTheme();
  const isLgScreen = useMediaQuery(theme.breakpoints.up('lg'));

  return (
    <Stack
      direction="row"
      sx={{
        height: '100%',
        width: '100%',
      }}
    >
      <LoginFormContainer>
        <Routes>
          <Route
            path="/"
            element={
              <LoginForm onLogin={onLogin} onSocialLogin={onSocialLogin} />
            }
          />
          <Route
            path="/register"
            element={<Register onRegister={onRegister} />}
          />
          <Route path="/forgot-password" element={<ForgotPassword />} />
        </Routes>
      </LoginFormContainer>
      {isLgScreen &&
        (children || (
          <Box
            sx={{
              backgroundImage: 'url(/assets/login-accent.png)',
              backgroundPosition: 'center',
              backgroundSize: 'cover',
              width: '648px',
            }}
          />
        ))}
    </Stack>
  );
};
