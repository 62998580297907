import { Breadcrumbs, Typography } from '@mui/material';
import { Collection } from '@stardust-monorepo/types/sd-private';
import { Link, useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';

import { gameState } from '../../state/game-state';
import { PageHeader } from './PageHeader';

interface CollectionsTopNavProps {
  collection?: Collection;
}

export const CollectionsTopNav = ({ collection }: CollectionsTopNavProps) => {
  const { gameId } = useParams<{
    gameId: string;
  }>();

  const [games] = useRecoilState(gameState);
  const game = games.find((g) => g.id === Number(gameId));

  return (
    <PageHeader>
      <Breadcrumbs>
        <Link to={`/games/${gameId}`}>
          <Typography variant="subtitle1" color="text.hint">
            {game?.name}
          </Typography>
        </Link>
        <Link to={`/games/${gameId}/collections`}>
          <Typography variant="subtitle1" color="text.hint">
            Collections
          </Typography>
        </Link>
        <Typography variant="subtitle1" color="text.primary">
          {collection?.name}
        </Typography>
      </Breadcrumbs>
    </PageHeader>
  );
};
