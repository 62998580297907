import { Button, ButtonProps } from '@mui/material';
import React from 'react';

export const IconButton = (props: ButtonProps) => (
  <Button
    {...props}
    variant="outlined"
    color="secondary"
    sx={{ padding: '5px 12px', minWidth: 'unset' }}
  />
);
