import {
  Box,
  Button,
  IconButton,
  Stack,
  styled,
  Typography,
  useTheme,
} from '@mui/material';
import { ImageSquare, Trash } from '@phosphor-icons/react';
import { color } from '@stardust-monorepo/web-sdk-apps-shared';
import {
  DragEventHandler,
  RefCallback,
  useEffect,
  useId,
  useRef,
  useState,
} from 'react';

const StyledImage = styled('img')({ borderRadius: 4, objectFit: 'cover' });

// adapted from https://gist.github.com/zentala/1e6f72438796d74531803cc3833c039c
const formatFilesize = (bytes: number) => {
  if (!bytes) {
    return '0';
  } else {
    const k = 1024,
      sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
      i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
  }
};

const dragAndDropTransitionDuration = '200ms';

export interface UploadImageProps {
  title?: string;
  subtitle?: string;
  value?: File | null;
  previewSize?: 'normal' | 'medium' | 'wide' | 'square';
  inputRef?: RefCallback<HTMLInputElement | null>;
  onChange?: (file: File | null) => void;
  existingImageHref?: string;
}

//TODO previewSize "square" should probably be its own component
export const UploadImage = ({
  title,
  subtitle,
  value,
  previewSize = 'normal',
  inputRef,
  onChange,
  existingImageHref,
}: UploadImageProps) => {
  const theme = useTheme();
  const [file, setFile] = useState<File | null>(value || null);
  const [dragAndDropping, setDragAndDropping] = useState<boolean>(false);
  const inputRefInternal = useRef<HTMLInputElement | null>();
  const id = useId();

  useEffect(() => {
    onChange?.(file);
  }, [file, onChange]);

  const handleDragAndDropEvent: DragEventHandler = (e) => {
    setDragAndDropping(e.type === 'dragover' || e.type === 'dragenter');
    e.preventDefault();
  };

  const clearFile = () => {
    setFile(null);
    if (inputRefInternal.current) {
      inputRefInternal.current.value = '';
    }
  };

  return (
    <>
      <input
        accept="image/*"
        ref={(ref) => {
          if (inputRef) {
            inputRef(ref);
          }
          inputRefInternal.current = ref;
        }}
        hidden
        id={`upload-image${id}`}
        type="file"
        onChange={(e) => {
          if (e.target.files?.[0]) {
            setFile(e.target.files?.[0]);
          }
        }}
      />
      <Stack
        direction="row"
        sx={{
          justifyContent: 'space-between',
          alignItems: 'center',
          border:
            file && previewSize === 'square'
              ? 'none'
              : `1px dashed ${color.neutral[300]}`,
          borderRadius: 2,
          padding: 0.5,
        }}
        onDrop={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setDragAndDropping(false);
          if (e.dataTransfer.files?.[0]) {
            setFile(e.dataTransfer.files?.[0]);
          }
        }}
        onDragEnter={handleDragAndDropEvent}
        onDragLeave={handleDragAndDropEvent}
        onDragOver={handleDragAndDropEvent}
      >
        <label
          htmlFor={`upload-image${id}`}
          style={{ zIndex: theme.zIndex.modal + 1, flexGrow: 1 }}
        >
          <Stack
            direction={previewSize === 'square' ? 'column' : 'row'}
            sx={(theme) => ({
              borderRadius: 1.25,
              transitionDuration: dragAndDropTransitionDuration,
              backgroundColor: file
                ? theme.palette.background.default
                : dragAndDropping
                ? color.brand.primary[50]
                : theme.palette.background.default,
              alignItems: 'center',
            })}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width:
                  previewSize === 'wide'
                    ? 192
                    : previewSize === 'square'
                    ? 182
                    : previewSize === 'medium'
                    ? 148
                    : 96,
                height: previewSize === 'square' ? 182 : 96,
              }}
            >
              {file || existingImageHref ? (
                <StyledImage
                  src={file ? URL.createObjectURL(file) : existingImageHref}
                  height={previewSize === 'square' ? 182 : 96}
                  width={
                    previewSize === 'wide'
                      ? 192
                      : previewSize === 'square'
                      ? 182
                      : previewSize === 'medium'
                      ? 148
                      : 96
                  }
                />
              ) : (
                <Box
                  sx={(theme) => ({
                    borderRadius: 2.5,
                    flexGrow: 1,
                    margin: 1,
                    transitionDuration: dragAndDropTransitionDuration,
                    backgroundColor: dragAndDropping
                      ? color.brand.primary[100]
                      : color.neutral[200],
                    height: `calc(100% - ${theme.spacing(2)})`, //100% - marginY
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  })}
                >
                  <ImageSquare
                    style={{
                      transitionDuration: dragAndDropTransitionDuration,
                    }}
                    color={
                      dragAndDropping
                        ? theme.palette.text.link
                        : theme.palette.text.hint
                    }
                    weight="duotone"
                    size={22}
                    alt="Image preview placeholder"
                  />
                </Box>
              )}
            </Box>
            {!(previewSize === 'square') && (
              <Stack gap={0.5} paddingLeft={2} flexGrow={1}>
                <Typography variant="body2">
                  {file ? file.name : existingImageHref ? '' : 'Upload Image'}
                </Typography>
                <Typography variant="helperText" color="text.secondary">
                  {file ? formatFilesize(file.size) : ''}
                </Typography>
              </Stack>
            )}
            {(!file || previewSize === 'square') && (
              <Button
                component="div"
                size="small"
                variant="outlined"
                color="secondary"
                sx={(theme) =>
                  previewSize === 'square'
                    ? {
                        //width: `calc(100% - ${theme.spacing(2)})`,
                        marginX: 1,
                        marginTop: file ? 1.5 : 0.5,
                        marginBottom: 0.5,
                      }
                    : { marginRight: 3 }
                }
              >
                {previewSize === 'square' ? 'Upload Image' : 'Browse'}
              </Button>
            )}
            {file && previewSize === 'square' && (
              <Button size="small" onClick={() => clearFile()}>
                Remove
              </Button>
            )}
          </Stack>
        </label>
        {file && previewSize !== 'square' && (
          <IconButton
            onClick={() => clearFile()}
            sx={{
              height: 'fit-content',
              marginRight: 2,
            }}
          >
            <Trash
              size={18}
              weight="duotone"
              color={theme.palette.text.secondary}
            />
          </IconButton>
        )}
      </Stack>
      {previewSize === 'square' && !file && (
        <Typography
          variant="helperText"
          color="text.secondary"
          sx={(theme) => ({
            paddingX: 1.5,
            // width: `calc(100% - ${theme.spacing(3)})`,
            margin: '0 auto',
            textAlign: 'center',
          })}
        >
          {subtitle}
        </Typography>
      )}
    </>
  );
};
