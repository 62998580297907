import { z } from 'zod';

import { BlockchainSchema } from './blockchain';

export const GameSchema = z
  .object({
    id: z.number(),
    name: z.string(),
    image: z.string().url(),
    desc: z.string(),
    blockchain: BlockchainSchema,
    royalty: z.optional(z.number()),
    stardustFee: z.optional(z.number()),
    banner: z.optional(z.string()),
    news: z.optional(z.nullable(z.string())),
    testMode: z.boolean(),
  })
  .describe('GameSchema');
export type Game = z.infer<typeof GameSchema>;
