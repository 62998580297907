import { Wallet } from '@stardust-monorepo/types/marketplace';
import {
  amplifyConfig,
  amplifyConfigDev,
  configureAmplify,
  DetailedCognitoUser,
  identify,
  login,
  signUp,
  verifyAuthCode,
} from '@stardust-monorepo/web-sdk-apps-shared';
import { Auth } from 'aws-amplify';
import { AxiosError } from 'axios';
import { createContext, ReactNode, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

export interface User {
  playerId: string;
  email: string;
  wallets: Wallet[];
  cognitoUser: DetailedCognitoUser;
}

export interface AuthContextValue {
  user: User | null;
  loading: boolean;
  gameId: string;
  logInOrSignUpIfNoAccountExists: (
    email: string,
    gameId: string,
    redirect?: string
  ) => Promise<unknown>;
  verifyAuthCodeAndLogin: (
    code: string,
    email: string,
    gameId: string
  ) => Promise<unknown>;
  logOut: () => Promise<unknown>;
}

const notInitializedError =
  'UserContext functions cannot be called without initialization. Please ensure you are providing values for the context via UserProvider.';

export const initialAuthContextValue = {
  user: null,
  loading: true,
  gameId: '',
  verifyAuthCodeAndLogin: () => Promise.reject(notInitializedError),
  logInOrSignUpIfNoAccountExists: () => Promise.reject(notInitializedError),
  logOut: () => Promise.reject(notInitializedError),
};
export const AuthContext = createContext<AuthContextValue>(
  initialAuthContextValue
);

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  useMemo(() => {
    configureAmplify(
      process.env['REACT_APP_TARGET_ENVIRONMENT'] === 'dev'
        ? amplifyConfigDev
        : amplifyConfig
    );
  }, []);

  //web-sdk apps have an implicit dependency on react-router since their function relies on parameters passed via the query string.
  //if this context needs to be made portable, gameId should be passed as an arg instead
  const [searchParams] = useSearchParams();
  const [user, setUserState] = useState<User | null>(
    initialAuthContextValue.user
  );
  const [loading, setLoading] = useState<boolean>(
    initialAuthContextValue.loading
  );

  //gameId is typically a number, but AWS relies on it being a string. instead of coercing the value for all usages,
  //we use the string type for most usages in the auth module
  const [gameId, setGameId] = useState<string>(
    searchParams.get('gameId') || ''
  );

  const syncUserState = async () => {
    setLoading(true);
    Auth.currentAuthenticatedUser()
      .then((user) => {
        if (user)
          if (user['attributes']?.email) {
            const email = user.attributes.email;
            const playerId =
              user.signInUserSession.getAccessToken().payload.sub;
            identify(email, playerId);
          }
        setUserState({
          cognitoUser: user,
          email: user.attributes.email,
          playerId: user.signInUserSession.getAccessToken().payload.sub,
          wallets: [], //TODO fetch these or let it be lazy?
        });
        setLoading(false);
      })
      .catch((e) => {
        //no authenticated user. amplify will debug log
        setUserState(null);
        setLoading(false);
      });
  };

  const verifyAuthCodeAndLogin = useMemo(
    () => async (code: string, email: string, gameId: string) => {
      verifyAuthCode({
        code,
        email,
        gameId,
      })
        .then(() => {
          Auth.configure({
            clientMetadata: {
              'custom:gameId': gameId,
            },
          });
          return Auth.signIn(email);
        })
        .then((user) => {
          Auth.sendCustomChallengeAnswer(user, code).then(() =>
            syncUserState()
          );
        })
        .catch((e) => {
          setLoading(false);
        });
    },
    []
  );

  const logInOrSignUpIfNoAccountExists = useMemo(
    () => async (email: string, gameId: string, redirect?: string) => {
      try {
        await login(email, gameId, redirect);
      } catch (e: unknown) {
        if (e instanceof AxiosError && e?.response?.status === 403) {
          await signUp(email, gameId);
          await login(email, gameId, redirect);
        } else {
          throw e;
        }
      }
    },
    []
  );

  const logOut = useMemo(
    () => async () => {
      const cognitoUser = await Auth.currentAuthenticatedUser();
      cognitoUser.signOut();
      await Auth.signOut({ global: true }).then(() => (error: unknown) => {
        throw new Error('An error occurred while logging out');
      });
      setUserState(null);
    },
    []
  );

  //normally this would be a good use-case for useMemo, but here we use a previously provided gameId if it isn't present in the query string for convenience.
  //authentication will be unavailable if gameId is unset
  useEffect(() => {
    const gameIdFromQuery = searchParams.get('gameId');
    if (gameIdFromQuery && gameIdFromQuery !== gameId) {
      setGameId(gameIdFromQuery);
    }
  }, [searchParams.get('gameId')]);

  useEffect(() => {
    syncUserState();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        user,
        loading,
        gameId,
        verifyAuthCodeAndLogin,
        logInOrSignUpIfNoAccountExists,
        logOut,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
