import { z } from 'zod';

export const enum FeeEntityType {
  game = 0,
  template = 1,
}

export const FeeTypeSchema = z.enum([
  'stardust_fee',
  'game_royalty',
  'template_royalty',
]);
export type FeeType = z.infer<typeof FeeTypeSchema>;

export const FeeSchema = z.object({
  feePercentage: z.number(),
  feeType: FeeTypeSchema,
});
export type Fee = z.infer<typeof FeeSchema>;
