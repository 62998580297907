import {
  Player,
  PlayerGameInventory,
  PlayerSchema,
  ApiTokenSchema,
} from '@stardust-monorepo/types/marketplace';
import { z } from 'zod';

import {
  authenticatedRequest,
  unauthenticatedRequest,
  validatedRequest,
} from './api-utils';
import { getTokenDetailsFromApiToken } from './token-api';

export const InventoryDataSchema = z
  .array(
    z.object({
      gameId: z.number(),
      desc: z.string(),
      image: z.string(),
      name: z.string(),
      inventory: z.array(
        z.object({
          tokenId: z.number(),
          quantity: z.string(),
          token: ApiTokenSchema.omit({
            gameId: true,
            gameName: true,
          }),
        })
      ),
    })
  )
  .describe('InventoryDataSchema');
export type InventoryData = z.infer<typeof InventoryDataSchema>;

export async function getUserInventory(
  gameId: number
): Promise<PlayerGameInventory[]> {
  const inventoryData: InventoryData = await authenticatedRequest(
    '/player/inventory-get'
  );

  return inventoryData.reduce((acc: PlayerGameInventory[], game) => {
    if (game.gameId === gameId) {
      acc.push({
        gameId: game.gameId,
        gameName: game.name,
        gameIcon: game.image,
        userItems: game.inventory.map((item) => ({
          ...getTokenDetailsFromApiToken({
            ...item.token,
            gameName: game.name,
            gameId: game.gameId,
          }),
          quantity: item.quantity,
        })),
      });
    }
    return acc;
  }, []);
}

export async function getPlayer(playerId: string): Promise<Player> {
  return await validatedRequest(
    PlayerSchema,
    `/player/get?playerId=${playerId}`
  );
}

export async function login(
  email: string,
  gameId?: string,
  redirect?: string
): Promise<unknown> {
  return unauthenticatedRequest('/player/login', {
    method: 'POST',
    data: {
      email,
      gameId: gameId ? Number(gameId) : undefined, //providing this adds the game id to the cognito user if not present. this is necessary to login w/ code
      redirect,
      version: 'N/A', //TODO this is supposed to be the version of web-login. verify if necessary
      link: false,
    },
  });
}

export async function verifyAuthCode({
  email,
  code,
  gameId,
}: {
  email: string;
  code: string;
  gameId: number | string; //we only take number | string here for convenience. most auth code uses gameId: string
}): Promise<unknown> {
  return await unauthenticatedRequest('/player/login-verify-code', {
    method: 'POST',
    data: {
      email,
      code,
      gameId: Number(gameId),
    },
  });
}
