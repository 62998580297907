import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import { Properties } from '@stardust-monorepo/types/sd-private';
import {
  BasicDialog,
  SearchTable,
} from '@stardust-monorepo/web-sdk-apps-shared';
import { ReactNode } from 'react';

const headings = [
  { label: 'Name' },
  { label: 'Type' },
  { label: 'Value' },
  { label: 'Mutability' },
];

export interface ViewPropertiesModal {
  allProperties: Record<string, Properties>[];
  open: boolean;
  onClose: () => void;
}

export const ViewPropertiesModal = ({
  allProperties,
  open,
  onClose,
}: ViewPropertiesModal) => {
  const rows: ReactNode[][] = [];
  let totalProperties = 0;
  allProperties.forEach((propSet) => {
    Object.keys(propSet).forEach((mutability) => {
      const propMap = propSet[mutability];
      const propKeys = Object.keys(propMap);
      totalProperties += propKeys.length;
      propKeys.forEach((propKey) => {
        rows.push([
          propKey,
          typeof propMap[propKey],
          `${propMap[propKey]}`,
          mutability,
        ]);
      });
    });
  });

  return (
    <BasicDialog open={open} onClose={onClose}>
      <DialogTitle>Properties ({totalProperties})</DialogTitle>
      <DialogContent>
        <SearchTable
          count={totalProperties}
          showSearchBar={false}
          page={0}
          headings={headings}
          rows={rows}
          isLoading={false}
          emptyScreen={
            <Box
              sx={{
                textAlign: 'center',
                minWidth: '300px',
                border: '1px solid',
                borderColor: 'divider',
                borderBottomRightRadius: '12px',
                borderBottomLeftRadius: '12px',
                padding: 2,
              }}
            >
              <Typography>No properties</Typography>
            </Box>
          }
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined" color="secondary">
          Close
        </Button>
      </DialogActions>
    </BasicDialog>
  );
};
