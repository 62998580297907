import { paginatedResultsWithFilterSchema } from '@stardust-monorepo/types/common';
import { z } from 'zod';

export const featureFlagValue = {
  enabled: 'true',
  disabled: 'false',
} as const;

export const FeatureFlagSchema = z
  .object({
    rootUserId: z.string().nullable(),
    key: z.string(),
    value: z.string(), //arbitrary string but will typically be true or false
    id: z.string().uuid(),
  })
  .describe('FeatureFlagSchema');
export type FeatureFlag = z.infer<typeof FeatureFlagSchema>;

export const FeatureFlagResultsSchema = paginatedResultsWithFilterSchema(
  FeatureFlagSchema
).describe('FeatureFlagResultsSchema');
export type FeatureFlagResults = z.infer<typeof FeatureFlagResultsSchema>;
