export const mockTemplate = {
  gameId: 70,
  cap: '39614081257132168796771975168',
  name: 'Harley Ape',
  type: 'FT',
  props: {
    mutable: {
      image: 'templates/83',
      strength: 89,
      duplicate: 123,
      description: 'Harley Quinn, ape form',
      personality: 'evil',
    },
    $mutable: {},
    immutable: {},
  },
  id: 83,
  totalSupply: '198',
  royalty: 200,
  activeListing: true,
  metadata: null,
  template_contract_type: 'internal-mint',
  image:
    'https://sd-game-assets-dev-lhv834fnkjsd.s3.amazonaws.com/game_70/templates/83',
};

export const mockNftTemplate = {
  ...mockTemplate,
  type: 'NFT',
  name: 'Test NFT',
};
