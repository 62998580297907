import { z } from 'zod';

import { TokenSchema } from './token';

const WalletsSchema = z
  .array(
    z.object({
      address: z.string(),
      blockchain: z.string(),
    })
  )
  .describe('WalletsSchema');

export const WalletSchema = z
  .object({
    wallet: WalletsSchema,
  })
  .describe('WalletSchema');

export type Wallet = z.infer<typeof WalletSchema>;
export const PlayerSchema = z
  .object({
    gameId: z.number(),
    id: z.number(),
    image: z.string(),
    lastSeen: z.string(),
    playerId: z.string().uuid(),
    uniqueId: z.string(),
    userData: z.record(z.string(), z.string().or(z.number()).or(z.boolean())),
    wallet: z.optional(WalletsSchema),
  })
  .describe('PlayerSchema');
export type Player = z.infer<typeof PlayerSchema>;

export const PlayerInventoryItemSchema = TokenSchema.extend({
  amount: z.string(),
  tokenId: z.number(),
}).describe('PlayerInventorySchema');
export type PlayerInventoryItem = z.infer<typeof PlayerInventoryItemSchema>;
