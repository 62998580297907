import { LockOutlined } from '@mui/icons-material';
import { Autocomplete, CircularProgress, FormControl } from '@mui/material';
import { Input, InputLabel } from '@stardust-monorepo/web-sdk-apps-shared';
import React from 'react';
import { Controller } from 'react-hook-form';
import { Control } from 'react-hook-form/dist/types/form';

type Option<T> = {
  value: T;
  label: string;
};
export interface AutocompleteFieldProps<T> {
  formControlName: string;
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any>;
  options: Option<T>[];
  onChange?: (value?: T) => void;
  isLoading?: boolean;
  label?: string;
  disabled?: boolean;
  lockField?: boolean;
  isOptionEqualToValue?: (option: Option<T>, value: T) => boolean; //this could just compare two values bc all options store their value in "value"
  getOptionLabel?: (option: Option<T>) => string;
}
export const AutocompleteField = <T,>({
  formControlName,
  control,
  label,
  disabled,
  lockField,
  isLoading,
  options,
  onChange: fieldOnChange,
  isOptionEqualToValue = (option, value) => option.value === value,
  getOptionLabel = (option: Option<T>) => option.label,
}: AutocompleteFieldProps<T>) => {
  return (
    <Controller
      control={control}
      name={formControlName}
      render={({ field: { onChange, value, ...fieldProps } }) => (
        <Autocomplete
          id="item"
          disabled={disabled || lockField}
          {...fieldProps}
          value={
            options.find((option) =>
              isOptionEqualToValue(option, control._formValues[formControlName])
            ) || null
          }
          onChange={(event, newValue) => {
            onChange(newValue?.value);
            fieldOnChange?.(newValue?.value);
          }}
          isOptionEqualToValue={(option, valueOption) =>
            isOptionEqualToValue(option, valueOption.value)
          }
          getOptionLabel={getOptionLabel}
          options={options}
          renderInput={(params) => (
            <FormControl margin={'normal'} fullWidth>
              <>
                <InputLabel htmlFor={formControlName}>{label}</InputLabel>
                <Input
                  className={params.InputProps.className}
                  inputRef={params.InputProps.ref}
                  sx={{
                    borderRadius: 2,
                    position: 'relative',
                  }}
                  id={params.id}
                  fullWidth
                  inputProps={params.inputProps}
                  endAdornment={
                    <>
                      {isLoading && (
                        <CircularProgress
                          color="inherit"
                          size={20}
                          data-testid="template-search-spinner"
                        />
                      )}
                      {params.InputProps.endAdornment}
                    </>
                  }
                  disabled={disabled || lockField}
                  startAdornment={
                    <>
                      {lockField && (
                        <LockOutlined
                          data-testid="autocomplete-lock-icon"
                          sx={{ paddingLeft: 1 }}
                          color="disabled"
                        />
                      )}
                      {params.InputProps.startAdornment}
                    </>
                  }
                />
              </>
            </FormControl>
          )}
          size="small"
        />
      )}
    />
  );
};
