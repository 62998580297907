import { Purchasable } from '@stardust-monorepo/types/marketplace';
import mixpanel from 'mixpanel-browser';
import { useEffect } from 'react';

const MixpanelToken =
  process.env['REACT_APP_MIXPANEL_TOKEN'] || process.env['NX_MIXPANEL_TOKEN'];

// We wrap calls to mixpanel in this file so we can add/change analytics providers
// and can control actually calling into mixpanel via an env variable.
export function initializeAnalytics() {
  if (MixpanelToken) {
    mixpanel.init(MixpanelToken, {
      debug:
        process.env['REACT_APP_TARGET_ENVIRONMENT'] === 'dev' ||
        process.env['NX_TARGET_ENVIRONMENT'] === 'dev',
    });
  }
}

export function identify(email: string, playerId?: string) {
  if (MixpanelToken) {
    mixpanel.identify(email);
    mixpanel.people.set({
      $email: email,
      $name: email,
      'Player ID': playerId,
    });
  }
}

export type TrackableProps = Record<
  string,
  string | number | boolean | null | undefined
>;

export function purchasableToTrackable(
  purchasable: Purchasable
): TrackableProps {
  return {
    gameId: purchasable.gameId,
    purchasableId: purchasable.id,
    purchasableName: purchasable.name,
  };
}

export function track(
  event: string,
  action: 'Viewed' | 'Clicked' | 'Success' | 'Failure',
  properties: TrackableProps = {},
  sendImmediately = false
) {
  if (MixpanelToken) {
    mixpanel.track(`${event} ${action}`, properties, {
      send_immediately: sendImmediately,
    });
  }
}

export function useTrack(event: string, properties: TrackableProps = {}) {
  useEffect(() => {
    track(event, 'Viewed', properties);
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);
}
