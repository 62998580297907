import {
  Button,
  Card,
  Stack,
  SxProps,
  Theme,
  Typography,
  styled,
} from '@mui/material';
import { Copy } from '@phosphor-icons/react';
import { useMemo } from 'react';

import { useClipboard } from '../../hooks/useClipboard';
import { useToast } from '../toast';

const StyledPre = styled('pre')({ margin: 0 });

export const PreformattedJsonBlock = ({
  value,
  label,
  sx,
}: {
  value: object;
  label: string;
  sx: SxProps<Theme>;
}) => {
  const jsonString = useMemo(
    () => JSON.stringify(value, null, 2).trim(),
    [value]
  );
  const toast = useToast();
  const copyValue = useClipboard(() => {
    toast(`${label} copied successfully`, 'success');
  }, jsonString);
  return (
    <Card
      elevation={0}
      variant="outlined"
      sx={{
        padding: 2,
        borderRadius: 2.5,
        width: 'min-content',
        ...sx,
      }}
    >
      <Stack gap={1.5}>
        <Stack
          direction="row"
          sx={{
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography variant="subtitle2">{label}</Typography>
          <Button
            sx={(theme) => ({
              color: theme.palette.primary.main,
            })}
            startIcon={<Copy size={18} />}
            onClick={() => copyValue()}
          >
            Copy
          </Button>
        </Stack>
        <Stack direction="row" gap={0.5}>
          <Typography variant="subtitle2" color="text.secondary">
            Value:
          </Typography>
          <StyledPre>{jsonString}</StyledPre>
        </Stack>
      </Stack>
    </Card>
  );
};
