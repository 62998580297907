import {
  SvgIcon,
  Switch as MuiSwitch,
  Box,
  SwitchProps as MuiSwitchProps,
} from '@mui/material';
import React from 'react';

export interface SwitchProps extends MuiSwitchProps {
  checkedIcon?: React.ReactNode;
  uncheckedIcon?: React.ReactNode;
  size?: 'medium';
}
export const Switch = ({
  uncheckedIcon,
  checkedIcon,
  ...switchProps
}: SwitchProps) => {
  return (
    <MuiSwitch
      {...switchProps}
      icon={
        uncheckedIcon ? (
          <Box
            sx={(theme) => ({
              width: '16px',
              height: '16px',
              backgroundColor: theme.palette.background.default,
              borderRadius: '50%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            })}
          >
            <SvgIcon sx={{ color: '#808080', fontSize: '12px' }}>
              {uncheckedIcon}
            </SvgIcon>
          </Box>
        ) : (
          <Box
            sx={(theme) => ({
              width: '16px',
              height: '16px',
              backgroundColor: theme.palette.background.default,
              borderRadius: '50%',
            })}
          />
        )
      }
      checkedIcon={
        <Box
          sx={(theme) => ({
            width: '16px',
            height: '16px',
            backgroundColor: theme.palette.background.default,
            borderRadius: '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          })}
        >
          <SvgIcon
            sx={(theme) => ({
              color: theme.palette.primary.main,
              fontSize: '12px',
            })}
          >
            {checkedIcon}
          </SvgIcon>
        </Box>
      }
    />
  );
};
