import { zodResolver } from '@hookform/resolvers/zod';
import { ArrowForwardRounded } from '@mui/icons-material';
import {
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormHelperText,
  Link,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import {
  FormField,
  useManualValidationError,
  track,
  FederatedLoginButtons,
} from '@stardust-monorepo/web-sdk-apps-shared';
import { Auth } from 'aws-amplify';
import { Controller } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import { NavLink as RouterLink } from 'react-router-dom';
import { z, ZodIssueCode } from 'zod';

import { loginFormMaxWidth } from '../LoginForm';

const formControls = {
  email: 'email',
  password: 'password',
  confirmPassword: 'confirmPassword',
  tos: 'tos',
} as const;

interface NewAccountFormValues {
  [formControls.email]: string;
  [formControls.password]: string;
  [formControls.confirmPassword]: string;
  [formControls.tos]: boolean;
}

const formSchema = z
  .object({
    [formControls.email]: z.string().email(),
    [formControls.password]: z
      .string()
      .min(1, 'Password is required')
      .min(8, 'Password must be at least 8 characters long'),
    [formControls.confirmPassword]: z
      .string()
      .min(1, 'Password confirmation is required'),
    [formControls.tos]: z.literal(true, {
      errorMap: (err) => ({
        message:
          err.code === ZodIssueCode.invalid_literal
            ? 'You must accept the privacy policy and terms of service.'
            : err.message || '',
      }),
    }),
  })
  .required();

export const NewAccountForm = ({
  onAccountCreateSuccess,
}: {
  onAccountCreateSuccess: (username: string) => void;
}) => {
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<NewAccountFormValues>({
    defaultValues: {
      [formControls.email]: '',
      [formControls.password]: '',
      [formControls.confirmPassword]: '',
      [formControls.tos]: false,
    },
    resolver: zodResolver(formSchema),
  });
  const [passwordMismatch, setPasswordMismatch] =
    useManualValidationError<boolean>(watch);
  const [accountCreationError, setAccountCreationError] =
    useManualValidationError<string>(watch);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const register = async (email: string, password: string) => {
    //TODO is this an acceptable way to generate a username?
    track('Signup', 'Clicked', { referrer: document.referrer }, true);
    const username = `Stardust_${Number(new Date())}`;
    await Auth.signUp({
      username,
      password: password,
      attributes: {
        email: email,
      },
      autoSignIn: {
        enabled: true,
      },
    })
      .then(() => {
        track('Signup', 'Success', { referrer: document.referrer }, true);
        onAccountCreateSuccess(username);
      })
      .catch((err: { code: string; message: string }) => {
        track(
          'Signup',
          'Failure',
          {
            referrer: document.referrer,
            error: err.message,
          },
          true
        );
        if (err.code === 'UserLambdaValidationException') {
          setAccountCreationError('Cannot sign up user');
        } else {
          setAccountCreationError(err.message);
        }
      });
  };

  return (
    <Stack
      component="form"
      sx={{
        maxWidth: loginFormMaxWidth,
      }}
      onSubmit={handleSubmit((data) => {
        if (
          data[formControls.password] === data[formControls.confirmPassword]
        ) {
          register(data[formControls.email], data[formControls.password]);
        } else {
          setPasswordMismatch(true);
        }
      })}
    >
      {!isMobile && (
        <>
          <Typography variant={'h4'} marginBottom={1}>
            Welcome to Stardust!
          </Typography>
          <Typography variant={'body1'} marginBottom={4} color="text.secondary">
            Sign up to get started.
          </Typography>
        </>
      )}
      <FederatedLoginButtons />
      <Divider
        sx={{
          mt: 4,
          mb: 2.5,
        }}
      >
        <Typography
          variant={'overline'}
          sx={(theme) => ({
            color: theme.palette.text.hint,
          })}
        >
          OR
        </Typography>
      </Divider>
      <FormField
        control={control}
        label="Email Address"
        autoComplete="username"
        formControlName={formControls.email}
        hasError={!!(errors[formControls.email] || accountCreationError)}
        renderHelpText={() =>
          (errors[formControls.email] || accountCreationError) && (
            <FormHelperText>
              {errors[formControls.email]?.message ?? accountCreationError}
            </FormHelperText>
          )
        }
      />
      <FormField
        control={control}
        label="Password"
        type="password"
        autoComplete="new-password"
        formControlName={formControls.password}
        hasError={!!errors[formControls.password]}
        renderHelpText={() =>
          errors[formControls.password] && (
            <FormHelperText>
              {errors[formControls.password]?.message}
            </FormHelperText>
          )
        }
      />
      <FormField
        control={control}
        label="Confirm Password"
        type="password"
        autoComplete="new-password"
        formControlName={formControls.confirmPassword}
        hasError={!!(errors[formControls.confirmPassword] || passwordMismatch)}
        renderHelpText={() =>
          (errors[formControls.confirmPassword] || passwordMismatch) && (
            <FormHelperText>
              {errors[formControls.confirmPassword]?.message ||
                (passwordMismatch && 'Password confirmation does not match')}
            </FormHelperText>
          )
        }
      />
      <Stack direction={'row'} marginTop={1.5} gap={1.5}>
        <Controller
          control={control}
          name={formControls.tos}
          render={({ field: { ref, ...fieldProps } }) => (
            <FormControl
              sx={{
                minWidth: 'min-content',
              }}
            >
              <Checkbox
                sx={{
                  padding: '0',
                  background: theme.palette.background.white,
                }}
                {...fieldProps}
                inputRef={ref}
                inputProps={{
                  'aria-label':
                    'Stardust Terms of Service and Privacy Policy Confirmation',
                }}
              />
            </FormControl>
          )}
        />
        <Typography variant={'body2'}>
          I confirm that I am at least 13 years of age and have read and accept
          the{' '}
          <Link
            target="_blank"
            rel="noopener"
            href="https://www.stardust.gg/terms-of-service"
          >
            Stardust Terms of Service
          </Link>{' '}
          and{' '}
          <Link
            target="_blank"
            rel="noopener"
            href="https://www.stardust.gg/privacy-policy"
          >
            Privacy Policy
          </Link>
        </Typography>
      </Stack>
      {errors[formControls.tos] && (
        <FormHelperText error>
          {errors[formControls.tos]?.message}
        </FormHelperText>
      )}
      <Button
        type="submit"
        variant="contained"
        size="large"
        endIcon={<ArrowForwardRounded />}
        sx={{
          my: 4,
        }}
      >
        Register
      </Button>
      <Typography
        variant={'helperText'}
        sx={(theme) => ({
          color: theme.palette.text.secondary,
        })}
      >
        Already have an account?{' '}
        <Link
          component={RouterLink}
          to="/login"
          sx={(theme) => ({
            fontWeight: 500,
            color: theme.palette.text.link,
          })}
        >
          Sign in.
        </Link>
      </Typography>
    </Stack>
  );
};
