import {
  Button,
  CircularProgress,
  Stack,
  styled,
  Typography,
} from '@mui/material';
import React, { ReactNode } from 'react';

import { EmptyContainer } from '../empty';

const StyledBackgroundImg = styled('img')({
  position: 'absolute',
  zIndex: 10,
});

interface BaseEmptyViewProps {
  image: string;
  title: string;
  body?: ReactNode;
  buttonText: string;
  buttonType?: 'outlined' | 'contained';
  isLoading?: boolean;
  onClick: () => void;
  tableStyle?: boolean;
}

export const BaseEmptyView = ({
  image,
  title,
  body,
  buttonText,
  buttonType = 'contained',
  isLoading = false,
  onClick,
  tableStyle = true,
}: BaseEmptyViewProps) => (
  <EmptyContainer
    tableStyle={tableStyle}
    sx={{
      height: '312px',
      display: 'flex',
      position: 'relative',
      alignItems: 'center',
      justifyContent: 'center',
      flexGrow: 1,
    }}
  >
    <StyledBackgroundImg src="/assets/emptyStateBackground.png" />
    <Stack
      sx={{
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        flexGrow: 1,
        zIndex: 100,
        maxWidth: '375px',
      }}
    >
      <img alt={buttonText} src={image} width="64px" height="64px" />
      <Typography
        variant="subtitle1"
        sx={{ color: 'text.secondary', mt: 4.75, mb: 1 }}
      >
        {title}
      </Typography>
      {body && (
        <Typography variant="body2" sx={{ color: 'text.hint' }}>
          {body}
        </Typography>
      )}
      <Button
        variant={buttonType}
        disabled={isLoading}
        onClick={onClick}
        sx={{
          color: buttonType === 'outlined' ? 'primary.main' : undefined,
          mt: 3,
        }}
      >
        {isLoading ? (
          <CircularProgress size="18px" color="primary" />
        ) : (
          buttonText
        )}
      </Button>
    </Stack>
  </EmptyContainer>
);
