import { Stack } from '@mui/material';
import {
  SearchTable,
  SearchTableProps,
  showPagination,
} from '@stardust-monorepo/web-sdk-apps-shared';

export const ContainedTable = ({
  fillHeight = true,
  ...props
}: SearchTableProps) => (
  <Stack
    sx={{
      border: '1px solid',
      borderColor: 'divider',
      px: 2,
      pt: 2,
      pb: showPagination(props.setPage, props.count) ? 0 : 2,
      borderRadius: 3,
      backgroundColor: 'background.default',
      ...(fillHeight ? { flexGrow: 1 } : {}),
    }}
  >
    <SearchTable {...{ ...props, fillHeight }} />
  </Stack>
);
