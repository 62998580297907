import { FeeType, FeeEntityType } from '@stardust-monorepo/types/sd-private';

import { authenticatedRequest } from './api-utils';

export const mutateFee = (fee: {
  entityId: number;
  entityType: FeeEntityType;
  feePercentage: number;
  feeType: FeeType;
  gameId: number;
}) => {
  return authenticatedRequest('/fee/mutate', {
    method: 'POST',
    data: {
      ...fee,
    },
  });
};
