import { zodResolver } from '@hookform/resolvers/zod';
import { FormHelperText, Stack, Typography } from '@mui/material';
import { Collection, FungibleToken } from '@stardust-monorepo/types/sd-core';
import {
  FormField,
  FormSectionContainer,
  CreateDocumentation,
  FormButtonBar,
  UnlimitedTemplateCap,
} from '@stardust-monorepo/web-sdk-apps-shared';
import React, { MutableRefObject } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import {
  BaseCollectionFormValue,
  baseCollectionFormValue,
  formSchemaWithMetadata,
} from './constants/base-collection-form';
import {
  collectionTypeToDisplayString,
  collectionTypeToTechnicalDisplayString,
} from './util';

export type CurrencyCollectionFormData = BaseCollectionFormValue & {
  supplyType: string;
  totalSupply: string;
};

const collectionToFormValue = (
  collection: Collection
): CurrencyCollectionFormData => ({
  name: collection.name,
  image: collection.image,
  symbol: collection.symbol ?? '',
  description: collection.description,
  supplyType:
    collection.totalSupply === UnlimitedTemplateCap ? 'Unlimited' : 'Limited',
  totalSupply: collection.totalSupply ?? '',
});

export const StandaloneCurrencyCollectionForm = ({
  collection,
  submitFormRef,
  onSave,
}: {
  collection?: Collection;
  submitFormRef: MutableRefObject<HTMLButtonElement | null>;
  onSave: (collection: CurrencyCollectionFormData) => void;
}) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<CurrencyCollectionFormData>({
    defaultValues: collection
      ? collectionToFormValue(collection)
      : {
          ...baseCollectionFormValue,
          symbol: '',
          totalSupply: '0', //256 bit int
        },
    resolver: zodResolver(
      formSchemaWithMetadata({
        ...{
          symbol: z
            .string()
            .min(3, 'Symbol must be at least 3 characters long'),
          supplyType: z.string().min(1, 'Capacity (Supply) is required'),
          totalSupply: z.string(),
        },
      }).refine(
        (data) =>
          ((data as CurrencyCollectionFormData).supplyType === 'Limited' &&
            ((data as CurrencyCollectionFormData).totalSupply || 0) >= 1) ||
          (data as CurrencyCollectionFormData).supplyType === 'Unlimited',
        {
          path: ['totalSupply'],
          message: 'Limit must be at least 1',
        }
      )
    ),
  });

  const capTypeOptions = [
    {
      label: 'Fixed Quantity',
      value: 'Limited',
      description: 'Define max number of tokens that can be minted.',
      renderSubField: () => (
        <FormField
          disabled={!!collection}
          formControlName="totalSupply"
          control={control}
          sx={{ mt: 2 }}
          placeholder="Limit"
          type="number"
          hasError={!!errors['totalSupply']}
          renderHelpText={() =>
            errors['totalSupply'] && (
              <FormHelperText>{errors['totalSupply']?.message}</FormHelperText>
            )
          }
        />
      ),
    },
    {
      label: 'Unlimited',
      value: 'Unlimited',
      description: 'No defined max number of tokens that can be minted.',
    },
  ];

  return (
    <Stack
      component="form"
      onSubmit={handleSubmit((data) => {
        onSave(data);
      })}
    >
      <button
        data-testid="collection-form-submit-button"
        ref={submitFormRef}
        style={{ display: 'none' }}
      />
      <Typography variant="h4" mt={7}>
        {collection
          ? `Edit ${collection.name}`
          : `New ${collectionTypeToDisplayString(FungibleToken)}`}
      </Typography>
      <Typography variant="body1" color="text.secondary" mt={1}>
        {collectionTypeToTechnicalDisplayString(FungibleToken)}
      </Typography>
      <Stack
        gap={4}
        direction={'row'}
        sx={{
          mt: 5,
        }}
        /* eslint-disable-next-line @typescript-eslint/no-empty-function */
        onSubmit={() => {}}
      >
        <Stack gap={2}>
          <FormSectionContainer>
            <Stack>
              <Stack>
                <Typography variant="h6">Collection Metadata</Typography>
                <Typography
                  variant="body2"
                  sx={{ color: 'text.secondary', mt: 0.5, mb: 3 }}
                >
                  Collection metadata is public and used to identify your items
                  across the blockchain.
                </Typography>
              </Stack>
              <Stack direction={'row'} gap={4}>
                <Stack
                  sx={{
                    flexGrow: 1,
                  }}
                >
                  <FormField
                    formControlName={'name'}
                    control={control}
                    subtitle="The name that will appear on marketplaces. This name is written into your smart contract and  cannot be changed."
                    label="Name your Collection"
                    type="text"
                    hasError={!!errors['name']}
                    renderHelpText={() =>
                      errors['name'] && (
                        <FormHelperText>
                          {errors['name']?.message}
                        </FormHelperText>
                      )
                    }
                  />
                  <FormField
                    formControlName={'description'}
                    control={control}
                    rows={3}
                    label="Collection Description"
                    subtitle="Short description of your collection that will appear on marketplaces."
                    type="text"
                    hasError={!!errors['description']}
                    renderHelpText={() =>
                      errors['description'] && (
                        <FormHelperText>
                          {errors['description']?.message}
                        </FormHelperText>
                      )
                    }
                  />
                  <FormField
                    formControlName={'symbol'}
                    control={control}
                    label="Symbol"
                    fullWidth={false}
                    maxInputWidth="small"
                    subtitle="Shorthand abbreviation of your currency."
                    type="text"
                    hasError={!!errors['symbol']}
                    renderHelpText={() =>
                      errors['symbol'] && (
                        <FormHelperText>
                          {errors['symbol']?.message}
                        </FormHelperText>
                      )
                    }
                  />
                  <FormField
                    formControlName={'image'}
                    control={control}
                    label="Collection Image"
                    subtitle="Main image that appears on marketplaces."
                    type="text"
                    hasError={!!errors['image']}
                    renderHelpText={() =>
                      errors['image'] && (
                        <FormHelperText>
                          {errors['image']?.message}
                        </FormHelperText>
                      )
                    }
                  />
                  <FormButtonBar
                    width={'512px'}
                    disabled={!!collection}
                    control={control}
                    formControlName={'supplyType'}
                    hasError={!!errors['supplyType']}
                    label="Total Supply Type"
                    description="Max number of items that can be created for this collection."
                    options={capTypeOptions}
                    renderHelpText={() =>
                      errors['supplyType'] && (
                        <FormHelperText>
                          {errors['supplyType']?.message}
                        </FormHelperText>
                      )
                    }
                  />
                </Stack>
              </Stack>
            </Stack>
          </FormSectionContainer>
        </Stack>
        <CreateDocumentation
          title="Collections"
          description="A Standalone Currency Collection will only have one Item, since there is no differentiation between Items within this Collection. This Collection is meant for currencies or resources inside of your game: gold, wood, etc."
        />
      </Stack>
    </Stack>
  );
};
