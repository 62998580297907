import { Box, Button, Link, styled, Typography } from '@mui/material';
import { GameController, House, Plus } from '@phosphor-icons/react';
import {
  Banner,
  color,
  DefaultLayout,
  documentationState,
  getGameImageUrl,
  NavGroup,
  SdPrivateApi,
} from '@stardust-monorepo/web-sdk-apps-shared';
import React, { useEffect, useState } from 'react';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';

import logo from '../../../assets/purple-logo.svg';
import { gameState } from '../../state/game-state';
import { CreateEditGameModal } from '../game';
import { DeprecationTooltip } from '../rip/DeprecationTooltip';

const mainRoutes = [
  {
    path: '/',
    icon: <House size={20} weight="duotone" />,
    label: 'Home',
  },
  {
    path: '/games',
    icon: <GameController size={20} weight="duotone" />,
    label: 'Games',
  },
];

const StyledImage = styled('img')(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
}));

export const MainLayout = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [showCreateGame, setShowCreateGame] = useState(false);

  const { data: games, isLoading: gamesLoading } = SdPrivateApi.useGames();
  const [, setGames] = useRecoilState(gameState);
  const [, setDocumentation] = useRecoilState(documentationState);

  useEffect(() => {
    setDocumentation('https://docs.stardust.gg');
  }, []);

  useEffect(() => {
    if (games) {
      setGames(games);
    }
  }, [games, setGames]);

  const mainNavGroup: NavGroup = {
    items: mainRoutes.map((route) => ({
      ...route,
      onClick: () => navigate(route.path),
      selected: Boolean(matchPath(route.path, pathname)),
    })),
  };

  const gameNavGroup: NavGroup = {
    title: 'GAMES',
    items: (games ?? []).map((game) => ({
      itemKey: String(game.id),
      icon: (
        <StyledImage
          alt="game icon"
          src={getGameImageUrl(game.bucketName, game.image)}
          width="20"
        />
      ),
      label: game.name,
      onClick: () => navigate(`/games/${game.id}`),
      selected: Boolean(
        matchPath({ path: `/games/${game.id}`, end: false }, pathname)
      ),
    })),
    isLoading: gamesLoading && !Array.isArray(games),
    emptyView: (
      <Box
        sx={{
          backgroundColor: 'background.cool',
          borderRadius: 2,
          border: '1px solid',
          borderColor: color.neutral[100],
          py: 1.5,
          px: 2,
          textAlign: 'center',
          mb: 0.5,
        }}
      >
        <Typography variant="body2" color="text.secondary">
          Start building with Stardust.
        </Typography>
        <DeprecationTooltip>
          <Button
            variant="outlined"
            color="secondary"
            size="small"
            disabled
            startIcon={<Plus weight="regular" size={16} />}
            sx={{
              color: 'primary.main',
              width: '100%',
              mt: 1.5,
              borderColor: 'primary.main',
            }}
          >
            New Game
          </Button>
        </DeprecationTooltip>
      </Box>
    ),
  };
  const navGroups = [mainNavGroup, gameNavGroup];

  return (
    <>
      <Banner color="warning" variant="filled">
        The Stardust Managed Platform is now deprecated and will be sunset by
        Dec 31, 2024. Please reach out to
        <Link
          sx={{
            cursor: 'pointer',
            color: 'inherit',
            textDecoration: 'underline',
            mx: 0.5,
          }}
          href={'mailto:support@stardust.gg'}
          target="_blank"
        >
          support@stardust.gg
        </Link>
        for migration options.
      </Banner>
      <DefaultLayout
        logo={logo}
        logoClick={() => navigate('/')}
        navGroups={navGroups}
      >
        <CreateEditGameModal
          open={showCreateGame}
          onClose={() => setShowCreateGame(false)}
        />
      </DefaultLayout>
    </>
  );
};
