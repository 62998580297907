import { Heartbeat, UserPlus } from '@phosphor-icons/react';
import { color, SmallIconStat } from '@stardust-monorepo/web-sdk-apps-shared';
import { ReactNode } from 'react';

import { StatTotals } from '../../hooks/useGameStats';

export const GameOverviewStats = ({
  currentTotals,
  renderChildStats,
}: {
  currentTotals: StatTotals;
  renderChildStats?: () => ReactNode;
}) => {
  return (
    <>
      <SmallIconStat
        Icon={UserPlus}
        iconColor={color.accent.orange[900]}
        iconBackgroundColor={color.accent.orange[50]}
        statValue={currentTotals.totalNewPlayers}
        title="New Players"
      />
      <SmallIconStat
        Icon={Heartbeat}
        iconColor={color.accent.orange[900]}
        iconBackgroundColor={color.accent.orange[50]}
        statValue={currentTotals.totalActivePlayers}
        title="Active Players"
      />
      {renderChildStats?.()}
    </>
  );
};
