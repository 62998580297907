import {
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from '@mui/material';
import { ReactElement, ReactNode } from 'react';

export type MenuAction = {
  disabledTooltip?: string;
  icon?: ReactNode;
  label: string;
  onClick?: () => void;
  type?: 'normal' | 'error';
  renderMenuItem?: (onClose: () => void) => ReactElement;
  key?: string;
};

export type BasicMenuProps = {
  actions?: MenuAction[];
  renderMenu?: (onClose: () => void) => ReactNode;
  anchorEl: null | HTMLElement;
  onClose: () => void;
  open: boolean;
};

export const BasicMenu = ({
  actions,
  renderMenu,
  anchorEl,
  onClose,
  open,
}: BasicMenuProps) => {
  return (
    <Menu anchorEl={anchorEl} open={open} onClose={onClose}>
      {actions
        ? actions.map((action) => (
            <Tooltip
              key={action.key ?? action.label}
              placement="top"
              title={action.disabledTooltip}
            >
              {action.renderMenuItem ? (
                action.renderMenuItem(onClose)
              ) : (
                <MenuItem
                  disabled={!!action.disabledTooltip}
                  onClick={() => {
                    action.onClick?.();
                    onClose();
                  }}
                  sx={{
                    '.MuiListItemIcon-root': {
                      color:
                        action.type === 'error'
                          ? 'error.main'
                          : 'text.secondary',
                    },
                    '&:hover': {
                      '.MuiListItemIcon-root': {
                        color:
                          action.type === 'error'
                            ? 'error.dark'
                            : 'text.primary',
                      },
                    },
                  }}
                >
                  {action.icon && <ListItemIcon>{action.icon}</ListItemIcon>}
                  <ListItemText
                    disableTypography={true}
                    primary={
                      <Typography
                        variant="body2"
                        color={
                          action.type === 'error' ? 'error.dark' : undefined
                        }
                      >
                        {action.label}
                      </Typography>
                    }
                  />
                </MenuItem>
              )}
            </Tooltip>
          ))
        : renderMenu?.(onClose)}
    </Menu>
  );
};
