import { paginatedResultsSchema } from '@stardust-monorepo/types/common';
import { z } from 'zod';

export const BlueprintSchema = z
  .object({
    id: z.string().uuid(),
    gameId: z.number(),
    name: z.string(),
    description: z.string(),
    image: z.string().url(),
    publicMetadata: z.record(z.string(), z.any()),
    createdAt: z.string().datetime(),
    updatedAt: z.string().datetime(),
  })
  .describe('BlueprintSchema');
export type Blueprint = z.infer<typeof BlueprintSchema>;

export const BlueprintResultsSchema = paginatedResultsSchema(BlueprintSchema);
export type BlueprintResults = z.infer<typeof BlueprintResultsSchema>;
