import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import React from 'react';

import { BasicDialog as Dialog } from './basic-dialog';

export interface RemoveModalProps {
  body: string;
  label: string;
  loading: boolean;
  onClose: () => void;
  onCloseTransitionComplete?: () => void;
  onConfirm: () => void;
  open: boolean;
}

export const RemoveModal = ({
  body,
  label,
  loading,
  onClose,
  //this is kind of inconvenient but allows content to be cleared only after the close transition has completed and the dialog is no longer visible
  onCloseTransitionComplete,
  onConfirm,
  open,
}: RemoveModalProps) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      closeAfterTransition={true}
      TransitionProps={{ onExited: () => onCloseTransitionComplete?.() }}
    >
      <DialogTitle variant="h5">Remove {label}?</DialogTitle>
      <DialogContent>{body}</DialogContent>
      <DialogActions>
        <Button
          disabled={loading}
          onClick={onClose}
          variant="outlined"
          color="secondary"
        >
          Cancel
        </Button>
        <Button
          disabled={loading}
          onClick={onConfirm}
          autoFocus
          color="error"
          variant="contained"
        >
          {loading ? (
            <CircularProgress size="18px" color="primary" />
          ) : (
            `Remove ${label}`
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
