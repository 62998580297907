import { z } from 'zod';

import { unixTimestampToDateTransformation } from '../base';

export const TwitterStatsSchema = z
  .object({
    createdAt: unixTimestampToDateTransformation(),
    description: z.string(),
    profileImageURL: z.string().url(),
    verified: z.boolean(),
    followerCount: z.number(),
    followingCount: z.number(),
    tweetCount: z.number(),
    listedCount: z.number(),
    statsUpdatedAt: unixTimestampToDateTransformation(),
  })
  .describe('TwitterStatsSchema');
export type TwitterStats = z.infer<typeof TwitterStatsSchema>;

export const EmptyTwitterStatsSchema = z
  .object({
    statsUpdatedAt: unixTimestampToDateTransformation(),
  })
  .describe('TwitterStatsSchema');
export type EmptyTwitterStats = z.infer<typeof EmptyTwitterStatsSchema>;

//todo better name? - statsUpdatedAt is not present if handle doesnt exist
export const isPopulatedTwitterStats = (
  twitterStats: TwitterStats | EmptyTwitterStats
): twitterStats is TwitterStats => {
  const followerProp: keyof TwitterStats = 'followerCount';
  const updatedProp: keyof TwitterStats = 'statsUpdatedAt';
  return followerProp in twitterStats && updatedProp in twitterStats;
};
