import {
  Breadcrumbs,
  Button,
  CircularProgress,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { Check } from '@phosphor-icons/react';
import {
  Collection,
  CollectionType,
  FungibleToken,
} from '@stardust-monorepo/types/sd-core';
import {
  HeavyDialog,
  HeavyDialogActions,
  HeavyDialogContent,
  HeavyDialogTitle,
  SdPrivateApi,
  Spinner,
  track,
  UnlimitedTemplateCap,
  useCreateCollection,
  useHandleError,
} from '@stardust-monorepo/web-sdk-apps-shared';
import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';

import { CollectionForm } from './CollectionForm';
import { CreateCollectionSuccess } from './CreateCollectionSuccess';
import { SelectCollectionType } from './SelectContract';
import { StandaloneCurrencyCollectionForm } from './StandaloneCurrencyCollectionForm';
import { collectionTypeToDisplayString } from './util';

type WorkflowStepType = 'type' | 'collection' | 'submission' | 'success';
type WorkflowStepStatus = 'done' | 'in-progress' | 'not-started';

interface Workflow {
  data: {
    collectionType: CollectionType | null;
    collection: Partial<
      Omit<
        Collection,
        'createdAt' | 'updatedAt' | 'id' | 'blockchainId' | 'address'
      >
    > | null;
  };
  steps: WorkflowStepType[];
}

const initialWorkflow: Workflow = {
  data: {
    collectionType: null,
    collection: null,
  },
  steps: ['type', 'collection', 'submission', 'success'],
};

interface CreateCollectionModalProps {
  gameId: number;
  open: boolean;
  onClose: () => void;
  onDelete?: () => void;
  sourcePage: string;
}

const StepCrumb = ({
  children,
  status,
}: PropsWithChildren<{
  status: WorkflowStepStatus;
}>) => {
  const theme = useTheme();
  return (
    <Stack direction={'row'} alignItems={'center'}>
      <Typography
        color={
          status === 'done'
            ? theme.palette.success.main
            : theme.palette.text.primary
        }
        variant="body2"
        marginRight={status === 'done' ? 0.75 : 0}
      >
        {children}
      </Typography>
      {status === 'done' && (
        <Check size={16} color={theme.palette.success.main} />
      )}
    </Stack>
  );
};

export const CreateCollectionModal = ({
  gameId,
  open,
  onClose,
  sourcePage,
}: CreateCollectionModalProps) => {
  const submitFormButtonRef = useRef<HTMLButtonElement | null>(null);
  const [workflow, setWorkflow] = useState<Workflow>({ ...initialWorkflow });
  const [saving, setSaving] = useState<boolean>(false);
  const handleError = useHandleError();
  const [currentStep, setCurrentStep] = useState<WorkflowStepType>(
    initialWorkflow.steps[0]
  );
  const [newCollection, setNewCollection] = useState<Collection | undefined>();

  const getCurrentStepIndex = () =>
    workflow.steps.findIndex((step) => step === currentStep);

  const advanceWorkflow = (stepCount: number) => {
    setCurrentStep(workflow.steps[getCurrentStepIndex() + stepCount]);
  };

  const getStepStatus = (stepType: WorkflowStepType): WorkflowStepStatus => {
    const stepIndex = workflow.steps.findIndex((step) => step === stepType);
    const currentStepIndex = getCurrentStepIndex();
    return currentStepIndex > stepIndex
      ? 'done'
      : currentStepIndex === stepIndex
      ? 'in-progress'
      : 'not-started';
  };

  const getCollectionType = () => workflow.data.collectionType;

  const resetWorkflow = () => {
    setWorkflow({ ...initialWorkflow });
    setCurrentStep(initialWorkflow.steps[0]);
  };

  const { data: game, error } = SdPrivateApi.useGame(gameId);
  const createCollection = useCreateCollection(getCollectionType());

  useEffect(() => {
    if (newCollection) {
      setCurrentStep('success');
    }
  }, [newCollection]);

  useEffect(() => {
    if (!open) {
      setTimeout(() => {
        resetWorkflow(); //TODO this feels a little jank but how else should resetting the component on close be handled?
      }, 1000);
    }
  }, [open]);

  useEffect(() => {
    if (currentStep === 'submission') {
      setSaving(true);
      (async () => {
        const collection = workflow.data.collection;
        if (collection && createCollection !== null && game) {
          createCollection
            .mutateAsync({
              ...(collection as Omit<
                //TODO find way to deal with partial instead of assert
                Collection,
                'createdAt' | 'updatedAt' | 'id' | 'address'
              >),
              blockchainId: game.blockchain,
            })
            .then((collection) => {
              track('Save New Collection', 'Success');
              setSaving(false);
              setNewCollection(collection);
            })
            .catch((e) => {
              advanceWorkflow(-1); //go back to collection step
              handleError(
                e,
                'An error occurred while saving the collection',
                'Save New Collection',
                {
                  gameId,
                }
              );
              setSaving(false);
            });
        }
      })();
    }
  }, [currentStep]);

  useEffect(() => {
    //this should never happen
    if (getStepStatus('type') === 'done' && getCollectionType() === undefined) {
      setCurrentStep('type');
    }
  }, [currentStep, workflow]);

  if (!game) {
    if (!error) {
      return <Spinner />;
    } else {
      return (
        <Typography>
          An error has occurred:{' '}
          {error instanceof Error && error.message
            ? error.message
            : 'unknown error loading Game'}
        </Typography>
      );
    }
  }

  return (
    <HeavyDialog open={open}>
      <HeavyDialogTitle>
        <Stack
          direction={'row'}
          justifyContent={'space-between'}
          sx={{
            flexGrow: 1,
            alignItems: 'center',
          }}
        >
          <Breadcrumbs>
            <Typography variant="body2">{sourcePage}</Typography>
            <StepCrumb status={getStepStatus('type')}>
              Select Contract Type{' '}
              {workflow.data.collectionType
                ? `(${collectionTypeToDisplayString(
                    workflow.data.collectionType
                  )})`
                : ''}
            </StepCrumb>
            <StepCrumb status={getStepStatus('collection')}>
              Define Collection
            </StepCrumb>
          </Breadcrumbs>
          <Button
            size="medium"
            variant="outlined"
            color="secondary"
            onClick={onClose}
          >
            {currentStep === 'success' ? 'Close' : 'Cancel'}
          </Button>
        </Stack>
      </HeavyDialogTitle>
      <HeavyDialogContent>
        <Stack gap={5} sx={{ width: '100%' }}>
          {currentStep === 'success' && newCollection ? (
            <CreateCollectionSuccess
              collectionType={workflow.data.collectionType}
              collection={newCollection}
              gameId={gameId}
            />
          ) : currentStep === 'type' ? (
            <>
              <Stack spacing={1}>
                <Typography variant="h3" mt={7}>
                  Create New Collection
                </Typography>
                <Typography variant="body1" color="text.secondary">
                  Select the type of contract below.
                </Typography>
              </Stack>
              <SelectCollectionType
                collectionTypeSelected={(type) => {
                  setWorkflow({
                    ...workflow,
                    data: {
                      ...workflow.data,
                      collectionType: type,
                    },
                  });
                  advanceWorkflow(1);
                }}
              />
            </>
          ) : (
            <Stack gap={4} direction={'row'}>
              {getCollectionType() === FungibleToken ? (
                <StandaloneCurrencyCollectionForm
                  onSave={(data) => {
                    setWorkflow({
                      ...workflow,
                      data: {
                        ...workflow.data,
                        collection: {
                          gameId,
                          name: data.name,
                          description: data.description,
                          symbol: data.symbol,
                          image: data.image,
                          publicMetadata: {},
                          totalSupply: !data.supplyType
                            ? undefined
                            : data.supplyType === 'Limited'
                            ? String(data.totalSupply)
                            : UnlimitedTemplateCap,
                        },
                      },
                    });
                    setCurrentStep('submission');
                  }}
                  submitFormRef={submitFormButtonRef}
                />
              ) : (
                <CollectionForm
                  /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
                  collectionType={getCollectionType()!}
                  onSave={(data) => {
                    setWorkflow({
                      ...workflow,
                      data: {
                        ...workflow.data,
                        collection: {
                          gameId,
                          name: data.name,
                          description: data.description,
                          symbol: data.symbol,
                          image: data.image,
                          publicMetadata: {
                            externalLink: data.externalLink ?? '',
                            ...(data.bannerImage
                              ? { bannerImage: data.bannerImage }
                              : {}),
                            ...(data.promoImage
                              ? { promoImage: data.promoImage }
                              : {}),
                          },
                          totalSupply: !data.supplyType
                            ? undefined
                            : data.supplyType === 'Limited'
                            ? String(data.totalSupply)
                            : UnlimitedTemplateCap,
                        },
                      },
                    });
                    advanceWorkflow(1);
                  }}
                  submitFormRef={submitFormButtonRef}
                />
              )}
            </Stack>
          )}
        </Stack>
      </HeavyDialogContent>
      {getStepStatus('type') !== 'in-progress' && currentStep !== 'success' && (
        <HeavyDialogActions>
          <Button
            size="large"
            variant="outlined"
            color="secondary"
            onClick={() => advanceWorkflow(-1)}
          >
            Back
          </Button>
          <Button
            disabled={saving}
            size="large"
            variant="contained"
            onClick={() => {
              submitFormButtonRef.current?.click();
              track('Save New Collection', 'Clicked');
            }}
          >
            {saving ? (
              <CircularProgress size="18px" color="primary" />
            ) : (
              'Create Collection'
            )}
          </Button>
        </HeavyDialogActions>
      )}
    </HeavyDialog>
  );
};
