import { zodResolver } from '@hookform/resolvers/zod';
import { PercentRounded } from '@mui/icons-material';
import { Button, FormHelperText, Stack, Typography } from '@mui/material';
import { Trash } from '@phosphor-icons/react';
import { Blockchain, Game } from '@stardust-monorepo/types/sd-private';
import {
  FormField,
  UploadImage,
  SelectBlockchain,
  SdPrivateApi,
  getGameImageUrl,
  track,
  FormSectionContainer,
} from '@stardust-monorepo/web-sdk-apps-shared';
import React, { MutableRefObject, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { z, ZodIssueCode } from 'zod';

import { ContentModule } from '../../content-module/ContentModule';
import { DeleteGameModal } from '../DeleteGameModal';

const formControls = {
  name: 'name',
  icon: 'icon',
  banner: 'banner',
  description: 'description',
  royalty: 'royalty',
  blockchainId: 'blockchainId',
} as const;

const formSchema = z
  .object({
    [formControls.name]: z.string().min(1, 'Name is required'),
    [formControls.description]: z.string().min(1, 'Description is required'),
    [formControls.royalty]: z.preprocess(
      (royalty) =>
        typeof royalty === 'string'
          ? parseFloat(z.string().parse(royalty) || '0.0')
          : royalty,
      z
        .number({
          //blank cases are covered by the help text message
          errorMap: (err) => ({
            message:
              err.code === ZodIssueCode.invalid_type ? '' : err.message || '',
          }),
        })
        .nonnegative('')
        .and(z.number().lte(15, ''))
        .transform((royalty) => royalty * 100)
    ),
    [formControls.blockchainId]: z.number(),
    [formControls.icon]: z.nullable(z.instanceof(File)),
    [formControls.banner]: z.nullable(z.instanceof(File)),
  })
  .required();

export const GameForm = ({
  submitFormRef,
  onLoading,
  onSave,
  onDelete,
  game, //edit is implied if a game is passed
}: {
  submitFormRef: MutableRefObject<HTMLButtonElement | null>;
  onLoading: (loading: boolean) => void;
  onSave: (success: { gameId: number } | Error) => void;
  onDelete?: () => void;
  game?: Game;
}) => {
  const createGame = SdPrivateApi.useCreateGame();
  const mutateGame = SdPrivateApi.useMutateGame();
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] =
    useState<boolean>(false);
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<{
    name: string;
    icon: File | null;
    banner: File | null;
    description: string;
    royalty: number;
    blockchainId: Blockchain | null;
  }>({
    defaultValues: {
      [formControls.name]: game?.name || '',
      [formControls.icon]: null,
      [formControls.banner]: null,
      [formControls.description]: game?.desc || '',
      [formControls.royalty]: game?.royalty ? game.royalty / 100 : 0,
      [formControls.blockchainId]: game?.blockchain,
    },
    resolver: zodResolver(formSchema),
  });

  //TODO banner, royalty not hooked up but a type could be extracted here
  const submitGame = async (formValues: {
    [formControls.name]: string;
    [formControls.icon]: File | null;
    [formControls.banner]: File | null;
    [formControls.description]: string;
    [formControls.blockchainId]: Blockchain | null;
    [formControls.royalty]: number | null;
  }) => {
    if (!game) {
      await createGame
        .mutateAsync({
          name: formValues.name,
          description: formValues.description,
          blockchainId: formValues.blockchainId || 0,
          royalty: formValues.royalty || 0,
          image: formValues.icon ?? undefined,
          bannerImage: formValues.banner ?? undefined,
        })
        .then(({ id }) => {
          onSave({ gameId: id });
        })
        .catch((e) => {
          onSave(e);
        })
        .finally(() => {
          onLoading(false);
        });
    } else {
      await mutateGame
        .mutateAsync({
          gameId: game.id,
          description: formValues.description,
          royalty: formValues.royalty || 0,
          image: formValues.icon ?? undefined,
          bannerImage: formValues.banner ?? undefined,
        })
        .then(() => {
          onSave({ gameId: game.id });
        })
        .catch((e) => {
          onSave(e);
        })
        .finally(() => {
          onLoading(false);
        });
    }
  };

  return (
    <>
      {game && onDelete ? (
        <DeleteGameModal
          game={game}
          open={deleteConfirmationOpen}
          onClose={() => setDeleteConfirmationOpen(false)}
          onDelete={onDelete}
        />
      ) : null}
      <Stack
        component="form"
        gap={2}
        sx={{
          flexGrow: 1,
        }}
        onSubmit={handleSubmit((data) => {
          onLoading(true);
          submitGame(data);
        })}
      >
        <FormSectionContainer>
          <Typography variant="h6">Game Details</Typography>
          {!game && (
            <Typography
              variant="body2"
              sx={{ color: 'text.secondary', mt: 0.5 }}
            >
              Create a new game on the blockchain of your choice. Once complete,
              you can create templates and custodial player wallets, mint
              objects to players, and retrieve your API Keys.
            </Typography>
          )}
          <FormField
            formControlName={formControls.name}
            control={control}
            label="*Name"
            type="text"
            disabled={!!game}
            hasError={!!errors[formControls.name]}
            renderHelpText={() =>
              errors[formControls.name] && (
                <FormHelperText>
                  {errors[formControls.name]?.message}
                </FormHelperText>
              )
            }
          />
          <FormField
            formControlName={formControls.description}
            control={control}
            rows={3}
            label="*Description"
            type="text"
            hasError={!!errors[formControls.description]}
            renderHelpText={() =>
              errors[formControls.description] && (
                <FormHelperText>
                  {errors[formControls.description]?.message}
                </FormHelperText>
              )
            }
          />
          {process.env.NX_FEATURE_ROYALTY === 'enabled' ? (
            <FormField
              formControlName={formControls.royalty}
              control={control}
              label="Royalty"
              type="text"
              fullWidth={false}
              inputSx={{
                maxWidth: '96px',
              }}
              endAdornment={
                <PercentRounded
                  sx={(theme) => ({
                    fontSize: 16,
                    color: theme.palette.text.secondary,
                    marginRight: 1,
                  })}
                />
              }
              hasError={!!errors[formControls.royalty]}
              renderHelpText={() => (
                <>
                  <FormHelperText>
                    Royalty must be a percentage between 0% and 15%
                  </FormHelperText>
                  {errors[formControls.royalty] && (
                    <FormHelperText>
                      {errors[formControls.royalty]?.message}
                    </FormHelperText>
                  )}
                </>
              )}
            />
          ) : null}
        </FormSectionContainer>
        <FormSectionContainer>
          <Typography variant="h6">Blockchain & Network</Typography>
          <Typography
            variant={'body2'}
            color={'text.secondary'}
            sx={{
              mt: 0.5,
            }}
          >
            Choose the blockchain your game contracts will be deployed to.
          </Typography>
          <SelectBlockchain
            disabled={!!game}
            value={game?.blockchain}
            onChange={(value) => setValue(formControls.blockchainId, value)}
          />
        </FormSectionContainer>
        <FormSectionContainer>
          <Typography variant="h6">Add Images</Typography>
          <Typography
            variant={'body2'}
            color={'text.secondary'}
            sx={{
              mt: 0.5,
            }}
          >
            Upload a game icon and banner image. These will be displayed in your
            Stardust Developer Dashboard.
          </Typography>
          <Controller
            control={control}
            name={formControls.icon}
            render={({ field: { ref, ...fieldProps } }) => (
              <Stack marginTop={4} gap={1}>
                <UploadImage
                  {...fieldProps}
                  inputRef={ref}
                  title="Upload a Game Icon"
                  subtitle="SVG, PNG, JPG or GIF. 60x60px recommended"
                  existingImageHref={
                    game?.image
                      ? getGameImageUrl(game.bucketName, game.image)
                      : undefined
                  }
                />
              </Stack>
            )}
          />
          <Controller
            control={control}
            name={formControls.banner}
            render={({ field: { ref, ...fieldProps } }) => (
              <Stack marginTop={4} marginBottom={2} gap={1}>
                <UploadImage
                  {...fieldProps}
                  inputRef={ref}
                  title="Upload a Game Banner"
                  subtitle="SVG, PNG, JPG or GIF. 1400x350px recommended"
                  previewSize="wide"
                  existingImageHref={
                    game?.banner
                      ? getGameImageUrl(game.bucketName, game.banner)
                      : undefined
                  }
                />
              </Stack>
            )}
          />
        </FormSectionContainer>
        {game && (
          <ContentModule
            sx={(theme) => ({
              background: theme.palette.background.cool,
            })}
            title="Remove Game"
          >
            <Stack
              gap={2}
              sx={{
                padding: 3,
              }}
            >
              <Typography
                variant={'body2'}
                sx={(theme) => ({
                  color: theme.palette.text.secondary,
                })}
              >
                Removing a game is permanent and cannot be undone.
              </Typography>
              <Button
                variant={'contained'}
                color={'error'}
                sx={{
                  width: 'fit-content',
                }}
                startIcon={<Trash />}
                onClick={() => {
                  track('Game Modal Remove Game', 'Clicked', {
                    gameId: game.id,
                  });
                  setDeleteConfirmationOpen(true);
                }}
              >
                Remove Game
              </Button>
            </Stack>
          </ContentModule>
        )}
        <button
          data-testid="game-form-submit-button"
          ref={submitFormRef}
          style={{ display: 'none' }}
        />
      </Stack>
    </>
  );
};
