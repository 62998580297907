import { InputAdornment } from '@mui/material';
import { ReactNode } from 'react';

interface FilledStartAdornmentProps {
  children: ReactNode;
}
export const FilledStartAdornment = ({
  children,
}: FilledStartAdornmentProps) => (
  <InputAdornment
    position="start"
    sx={{
      px: 1,
      py: 2,
      borderTopLeftRadius: '8px',
      borderBottomLeftRadius: '8px',
      borderRight: '1px solid',
      borderColor: 'divider',
      color: 'text.secondary',
      backgroundColor: 'background.cool',
    }}
  >
    {children}
  </InputAdornment>
);
