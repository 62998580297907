import { Box, Stack, useTheme } from '@mui/material';
import { Minus, Plus } from '@phosphor-icons/react';
import { PropsWithChildren, ReactNode, useState } from 'react';

export const Collapsible = ({
  titleContent,
  expanded = true,
  children,
}: PropsWithChildren<{
  expanded?: boolean;
  titleContent?: ReactNode;
}>) => {
  const [expand, setExpand] = useState<boolean>(expanded);
  const theme = useTheme();
  return (
    <Stack
      sx={{
        width: '100%',
      }}
    >
      <Stack
        direction={'row'}
        sx={{
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
        }}
      >
        {titleContent}
        <Box
          onClick={() => setExpand(!expand)}
          sx={{
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {expand ? (
            <Minus size={20} color={theme.palette.text.secondary} />
          ) : (
            <Plus size={20} color={theme.palette.text.secondary} />
          )}
        </Box>
      </Stack>
      {expand && children}
    </Stack>
  );
};
